import { Layout } from '../../components/Layout/Layout';
import { PanicRecipientsSelection } from '../../containers/PanicButton/PanicRecipientsSelection';

export const PanicSelectRecipientsPage = () => {
  return (
    <Layout isMessageLayout message="send_alarm_to" to="/panic/create">
      <PanicRecipientsSelection />
    </Layout>
  );
};
