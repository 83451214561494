import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button } from '../../components/Button/Button';
import { Page } from '../../components/Page/Page';
import { TextField } from '../../components/TextField/TextField';
import { palette } from '../../theme/colors';
import { SButtonParams, STextFieldParams } from './Login';

export const SForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`;

export const SPage = styled(Page)`
  padding-top: 0;
  padding-bottom: 20px;
  background-color: ${props => props.theme.palette.background.primary};
`;

export const SUserImg = styled.img`
  margin: 0 auto;
  display: block;
`;

export const SFaTwoEmptyBox = styled.div`
  height: 55.5px;
`;

export const SLoginEmptyBox = styled.div`
  height: 24px;
`;

export const STextField = styled(TextField)<STextFieldParams>`
  input {
    color: ${palette.grayx11gray};
    font-family: 'Roboto-Regular';
    font-size: 1rem;
    ${props =>
      props.error &&
      css`
        border-color: ${palette.tartOrange};
      `}
  }
  input::placeholder {
    font-size: 1rem;
    font-family: 'Roboto-Regular';
    color: ${palette.grayx11gray};
  }

  &.email {
    margin-bottom: 0.625rem;
  }
`;

export const STitle = styled.h1`
  font-size: 1.125rem;
  font-family: 'Roboto-Medium';
  color: ${palette.silver};
  text-align: left;
  padding-left: 4px;
  margin: 1rem auto 1rem auto;
`;

export const SForgotPassLink = styled(Link)`
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  color: ${palette.honeyYellow};
  text-decoration: none;
`;

export const SErroText = styled.p`
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  color: ${palette.tartOrange};
  margin-right: auto;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const STextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.625rem;
`;

export const SSSoLink = styled(Link)`
  text-decoration: none;
  align-self: center;
  font-size: 1rem;
  font-family: 'Roboto-Regular';
  color: ${palette.honeyYellow};
  margin: 2rem 0 1.75rem 0;
  cursor: pointer;
`;

export const SPrivacyText = styled.p`
  text-align: center;
  max-width: 80%;
  font-size: 1rem;
  font-family: 'Roboto-Regular';
  a {
    color: ${palette.honeyYellow};
    text-decoration: none;
    /* cursor: pointer; */
  }
`;

export const SButton = styled(Button)<SButtonParams>`
  width: 100%;
  margin-top: 1.5rem;
  button {
    max-width: 200rem;
    ${props =>
      props.$error &&
      css`
        opacity: 0.5;
      `}

    ${props =>
      props.$textOnly &&
      css`
        background-color: ${palette.transparent};
        color: ${palette.honeyYellow};
      `}
    ${props =>
      props.disabled &&
      css`
        color: ${palette.silver};
        opacity: 0.5;
      `}
  }
`;
