import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SProfilePictureWrapper = styled.div`
  margin-right: 1rem;
`;

export const SItemContainer = styled.li`
  display: flex;
  place-content: space-between;

  margin-top: 21px;
  :first-child {
    margin-top: 0;
  }
`;

export const SLeft = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const SItem = styled.div`
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: ${palette.white};
`;

export const SCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .availabilityChip {
    margin-top: 3px;
  }
`;
