import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { ChecklistStatus } from '../../../utils/enums';
import { SelectListUser } from '../../CreateMessage/createMessageSlice/types';
import {
  hasMultipleAccounts,
  searchGroups,
  extractAccountsFromGroups,
} from '../../../common/groupsHelpers';
import { getGroupById } from '../../../apis/groupsAPI';
import { Group } from '../../GroupsList/groupsSlice/types';
import { ChecklistFilters } from '../ChecklistFilter';
import {
  Checklist,
  ChecklistItem,
  ChecklistsState,
  ChecklistsSummary,
  ChecklistTask,
} from './types';
import { filterGroupChecklists } from './helpers';

const initialState: ChecklistsState = {
  selectedGroups: {},
  singleUsersSelected: {},
  groupsSelectedUsers: [],

  users: [],
  groups: [],

  usersLoading: false,
  groupsLoading: false,
  isCommentSending: false,
  tempChecklistTasks: null,

  // Checklist List ===== Start =====
  checklists: [],
  isChecklistsLoading: false,
  activeTab: ChecklistStatus.Started,
  checklistSummary: { active: 0, ended: 0, template: 0 },
  // ===== End =====
  // Current Checklist ===== Start =====
  activeChecklist: null,
  checklistItems: [],
  // ===== End =====
};

export const checklistsSlice = createSlice({
  name: 'checklists',
  initialState,
  reducers: {
    setChecklistSummary: (state, action: PayloadAction<ChecklistsSummary>) => {
      state.checklistSummary = action.payload;
    },

    setUsers: (state, action: PayloadAction<SelectListUser[]>) => {
      state.users = action.payload;
    },
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
    setIsGroupsLoading: (state, action: PayloadAction<boolean>) => {
      state.groupsLoading = action.payload;
    },
    setIsUsersLoading: (state, action: PayloadAction<boolean>) => {
      state.usersLoading = action.payload;
    },
    setIsCommentSending: (state, action: PayloadAction<boolean>) => {
      state.isCommentSending = action.payload;
    },
    setChecklists: (state, action: PayloadAction<Checklist[]>) => {
      state.checklists = action.payload;
    },
    setActiveChecklist: (state, action: PayloadAction<Checklist>) => {
      state.activeChecklist = action.payload;
    },
    setChecklistItems: (state, action: PayloadAction<ChecklistItem[]>) => {
      state.checklistItems = action.payload;
    },
    setTempChecklistTasks: (state, action: PayloadAction<ChecklistTask[] | null>) => {
      state.tempChecklistTasks = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isChecklistsLoading = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<ChecklistStatus>) => {
      state.activeTab = action.payload;
    },
    handleInitialMassSelect: (
      state,
      action: PayloadAction<{
        groupIds: number[];
        groupsUsersIds: number[];
        singleUserIds: number[];
      }>
    ) => {
      const groupIds = action.payload.groupIds;
      const groupsUsersIds = action.payload.groupsUsersIds;
      const singleUserIds = action.payload.singleUserIds;

      groupIds.forEach(groupId => {
        state.selectedGroups[groupId] = true;
      });
      singleUserIds.forEach(userId => {
        state.singleUsersSelected[userId] = true;
      });
      state.groupsSelectedUsers = [...groupsUsersIds];
    },
    handleSelectGroup: (state, action: PayloadAction<{ groupId: number; usersIds: number[] }>) => {
      const groupId = action.payload.groupId;
      const usersIds = action.payload.usersIds;
      const group = state.selectedGroups[groupId];

      if (!group) {
        // add groupId to the selected groupsID
        state.selectedGroups[groupId] = true;
        // add its userID to the selected usersIDs
        state.groupsSelectedUsers = [...state.groupsSelectedUsers, ...usersIds];
      } else if (group) {
        // it's safe here to mutate the state directly
        delete state.selectedGroups[groupId];

        for (const userId of usersIds) {
          // remove groups' usersID from the groups selected array
          const foundMemberIndex = state.groupsSelectedUsers.findIndex(
            memberId => memberId === userId
          );
          if (foundMemberIndex > -1) {
            // it's safe here to mutate the state directly
            state.groupsSelectedUsers.splice(foundMemberIndex, 1);
          }
        }
      }
    },
    handleSelectUser: (state, action: PayloadAction<{ usersId: number }>) => {
      const userId = action.payload.usersId;
      const user = state.singleUsersSelected[userId];
      // maybe we can add check if the userID is not exist in groups ID for this feature exclusively

      if (!user) {
        state.singleUsersSelected[userId] = true;
      }

      // for the create checklist we cannot unselect member of groups selected users
      const foundMemberIndex = state.groupsSelectedUsers.findIndex(memberId => memberId === userId);
      if (foundMemberIndex === -1) {
        if (user) {
          delete state.singleUsersSelected[userId];
        }
      }
      // will be needed when refactoring create message
      // remove userID from both groupsSelectedUsers and single users
      // if (foundMemberIndex > -1) {
      //   state.groupsSelectedUsers.splice(foundMemberIndex, 1);
      // }
    },

    reset: state => {
      state.selectedGroups = {};
      state.singleUsersSelected = {};
      state.groupsSelectedUsers = [];
      state.activeChecklist = null;
      state.tempChecklistTasks = null;
    },
    resetCheckListSelections: state => {
      state.selectedGroups = {};
      state.singleUsersSelected = {};
      state.groupsSelectedUsers = [];
    },
    resetCompleteChecklistStore: () => {
      return initialState;
    },
  },
});

// export store actions
export const {
  setChecklistSummary,
  setUsers,
  setGroups,
  setIsGroupsLoading,
  setIsUsersLoading,
  setIsCommentSending,
  setChecklists,
  setActiveChecklist,
  setIsLoading,
  setChecklistItems,
  setTempChecklistTasks,
  setActiveTab,
  reset,
  handleSelectGroup,
  handleSelectUser,
  handleInitialMassSelect,
  resetCheckListSelections,
  resetCompleteChecklistStore,
} = checklistsSlice.actions;

export const selectCheckListSummary = (state: RootState) => state.checklists.checklistSummary;
export const isGroupsLoading = (state: RootState) => state.checklists.groupsLoading;

export const selectUserSelection = createSelector(
  [
    (state: RootState) => state.checklists.groupsSelectedUsers,
    (state: RootState) => state.checklists.singleUsersSelected,
  ],
  (groupsSelectedUsers, singleUsersSelected) => {
    const singleSelectedUserArray = Object.keys(singleUsersSelected).map(key => +key);
    const selectedUsers = new Set([...groupsSelectedUsers, ...singleSelectedUserArray]);

    const isUserSelected = (id: number) => {
      return selectedUsers.has(id);
    };
    const isUserLocked = (id: number) => {
      return groupsSelectedUsers.findIndex(memberId => memberId === id) > -1;
    };

    return {
      isUserSelected,
      isUserLocked,
      selectedUsers: Array.from(selectedUsers),
      singleSelected: singleSelectedUserArray,
    };
  }
);
export const selectGroupsSelection = createSelector(
  (state: RootState) => state.checklists.selectedGroups,
  selectedGroups => {
    const isGroupsSelected = (id: number) => !!selectedGroups[id];
    return { isGroupsSelected, selectedGroups: Object.keys(selectedGroups).map(key => +key) };
  }
);

export const selectUsers = (state: RootState) => state.checklists.users;
export const selectGroups = (state: RootState) => state.checklists.groups;
export const isUsersLoading = (state: RootState) => state.checklists.usersLoading;
export const isCommentSending = (state: RootState) => state.checklists.isCommentSending;
export const isChecklistsLoading = (state: RootState) => state.checklists.isChecklistsLoading;
export const getChecklists = (state: RootState) => state.checklists.checklists;

export const getActiveChecklist = (state: RootState) => state.checklists.activeChecklist;
export const getChecklistItems = (state: RootState) => state.checklists.checklistItems;
export const getActiveTab = (state: RootState) => state.checklists.activeTab;
export const getTempChecklistTasks = (state: RootState) => state.checklists.tempChecklistTasks;
export const selectGroupsAccountsWithFilter = (filters: ChecklistFilters) => (state: RootState) =>
  extractAccountsFromGroups(filterGroupChecklists(state, { memberFilter: filters.memberFilter }));
export const selectHasMultipleAccounts = (state: RootState) =>
  hasMultipleAccounts(state.checklists.groups);
export const selectChecklistGroups = (state: RootState) => state.checklists.groups;
export const selectChecklistGroupsWithSearchFilter =
  (filters: ChecklistFilters, searchTerm = '') =>
  (state: RootState) =>
    searchGroups(searchTerm, filterGroupChecklists(state, filters));
export const getChecklistSharedGroupsDataCreatePromise = (state: RootState) => {
  const sharedGroupsIds = state.checklists.activeChecklist?.sharedGroups ?? [];
  return () => Promise.all(sharedGroupsIds.map(async groupId => getGroupById(groupId)));
};

export default checklistsSlice.reducer;
