/* eslint-disable import/named */

import { batch } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import {
  setAlarmData,
  setError,
  setGroups,
  setGroupsLoading,
  setIsLoading,
  setIsLocationDenied,
  setLocationData,
  setMessageText,
  setSelectedGroups,
  setSelectedUsers,
  setUsers,
  setUsersLoading,
} from '.';
import { createMessage } from '../../../apis/chatAPI';
import { getSingleGroupsMembers } from '../../../apis/createMessageAPI';
import { getGroups } from '../../../apis/groupsAPI';
import { GetGroupsQuery } from '../../../apis/groupsAPI/types';
import { saveLocation } from '../../../apis/locationAPI';
import { activatePanicAlarm, deactivatePanicAlarm } from '../../../apis/panicButton';
import { GetUserQuery, getAllUsers } from '../../../apis/userAPI';
import { AppThunk } from '../../../store';
import Geocoder from '../../../utils/geocoder';
import { CreateMessageModel } from '../../Chat/models';
import { GroupMember } from '../../GroupDetail/groupDetailSlice/types';
import { Group } from '../../GroupsList/groupsSlice/types';
import { PanicAlarmActivationData, PanicAlarmModel, PanicAlarmState } from './types';
import { SelectListUser } from '../../CreateMessage/createMessageSlice/types';

export const fetchGroups =
  (query?: GetGroupsQuery): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setGroupsLoading(true));
      const currentGroups = getState().panicButton.groups || [];
      let allGroups = [...currentGroups];
      const newGroups = await getGroups({
        ...query,
        menuitem: 'group_alarm',
        member: true,
        minGroupMembersCount: 2,
      });
      const currentGroupsIdsList = [...currentGroups].map(group => group.id);
      if (query?.skip) {
        const filteredNewGroups = newGroups.filter(
          group => !currentGroupsIdsList.includes(group.id)
        );
        allGroups = [...allGroups, ...filteredNewGroups];
      } else {
        allGroups = [...newGroups];
      }
      batch(() => {
        dispatch(setGroups(allGroups));
        dispatch(setGroupsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      dispatch(setGroupsLoading(false));
    }
  };

export const fetchUsers =
  (query?: GetUserQuery): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setUsersLoading(true));
      const currentUsers = getState().panicButton.users || [];
      let allUsers = [...currentUsers];
      const newUsers = await getAllUsers({ ...query, menuitem: 'personalAlarm' });
      if (query?.skip) {
        const filteredNewUsers = [...newUsers].filter(
          user => !allUsers.find(u => u.id === user.id)
        );
        allUsers = [...allUsers, ...filteredNewUsers];
      } else {
        allUsers = [...newUsers];
      }
      batch(() => {
        dispatch(setUsers(allUsers));
        dispatch(setUsersLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setUsersLoading(false));
      });
    }
  };

const isMemberAlreadyAdded = (userId: number, list: GroupMember[]) => {
  const foundIndex = list.findIndex((member: GroupMember) => member.userID === userId);
  return foundIndex > -1;
};

const getGroupsMembersList = async (selectedGroups: Group[], currentUserId: number) => {
  try {
    const members: GroupMember[] = [];
    for await (const group of selectedGroups) {
      const usersInGroup = await getSingleGroupsMembers(group.id);
      usersInGroup.forEach((member: GroupMember) => {
        if (member.userID != currentUserId && !isMemberAlreadyAdded(member.userID, members)) {
          const memberData = { ...member, isSelected: true, groupID: member.groupID };
          members.push(memberData);
        }
      });
    }
    return members;
  } catch (error) {
    return [];
  }
};
export const fetchGroupMembers =
  (
    selectedGroups: Group[],
    currentUserId: number,
    onMembersLoad?: (members: GroupMember[]) => void
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const members: GroupMember[] = await getGroupsMembersList(selectedGroups, currentUserId);
      if (onMembersLoad) {
        onMembersLoad(members);
      }
      batch(() => {
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
      });
    }
  };

export const activateAlarm =
  (activateAlarmData: PanicAlarmActivationData): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await activatePanicAlarm(activateAlarmData);
      dispatch(setIsLoading(false));
    } catch (error) {
      batch(() => {
        dispatch(setError(`${error}`));
        dispatch(setIsLoading(false));
      });
    }
  };

export const tryToActivateAlarm =
  (navigate: NavigateFunction, alarmData: PanicAlarmModel): AppThunk =>
  async dispatch => {
    try {
      dispatch(setAlarmData(alarmData));
      const { text = '', groupIds = [], recipientIds = [] } = alarmData;
      const alarmActivationData: PanicAlarmActivationData = {
        text,
        groupIds,
        userIds: recipientIds,
      };
      dispatch(activateAlarm(alarmActivationData));
      navigate('/panic/timer');
      dispatch(setIsLoading(false));
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const createAlarm =
  (callback: (isSuccess: boolean) => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      const alarmData = getState().panicButton.alarmData;
      const location = getState().panicButton.locationData;
      const user = getState().user.user;

      const messageModel: CreateMessageModel = {
        type: 2,
        subType: 3,
        senderId: user!.id,
        groupIds: alarmData!.groupIds ?? [],
        recipientIds: alarmData!.recipientIds,
        text: alarmData!.text ?? '',
      };

      if (location?.lat && location?.lng) {
        const result = await Geocoder({
          latitude: location.lat,
          longitude: location.lng,
        });
        const address = result?.addressName ?? `${location.lat} | ${location.lng}`;
        const locationResult = await saveLocation({
          latitude: location!.lat,
          longitude: location!.lng,
          name: address,
        });
        messageModel.locationId = locationResult!.id;
        dispatch(setLocationData({ ...location, address: address }));
      }

      const messageResponse = await createMessage(messageModel);

      if (!!messageResponse?.text) {
        const newAlarmData = { ...alarmData };
        newAlarmData.alarmStatus = PanicAlarmState.Unlocked;
        newAlarmData.messageId = messageResponse.id;
        localStorage.setItem(
          'panicButtonData',
          JSON.stringify({
            alarmData: JSON.stringify(newAlarmData),
            location: JSON.stringify(location),
          })
        );
        dispatch(setAlarmData(newAlarmData));
        callback(true);
      } else {
        callback(false);
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      batch(() => {
        dispatch(setAlarmData(null));
        dispatch(setIsLoading(false));
      });
      callback(false);
    }
  };

export const deactivateAlarm =
  (
    deactivateAlarmData: PanicAlarmActivationData,
    navigate: NavigateFunction,
    path: string
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await deactivatePanicAlarm(deactivateAlarmData);
      navigate(path);
      batch(() => {
        dispatch(setAlarmData(null));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setError(`${error}`));
        dispatch(setIsLoading(false));
      });
    }
  };

export const clearCreateData = (): AppThunk => async (dispatch, getState) => {
  try {
    const authUser = getState().user.user;
    dispatch(setIsLoading(true));
    if (authUser) {
      dispatch(
        setSelectedUsers([
          {
            id: authUser.id,
            displayName: authUser.name ?? '',
            groupIds: null,
            isSelected: true,
          } as SelectListUser,
        ])
      );
    }
    batch(() => {
      dispatch(setSelectedGroups([]));
      dispatch(setGroups([]));
      dispatch(setUsers([]));
      dispatch(setMessageText(''));
      dispatch(setIsLoading(false));
      dispatch(setIsLocationDenied(false));
      dispatch(setLocationData(null));
      dispatch(setAlarmData(null));
      dispatch(setError(null));
    });
  } catch (error) {
    console.error(error);
    batch(() => {
      dispatch(setError(`${error}`));
      dispatch(setIsLoading(false));
    });
  }
};
