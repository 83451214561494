/* eslint-disable @typescript-eslint/naming-convention */

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import { Chat } from '../containers/Chat/Chat';
import { selectLogNoteById } from '../containers/ChatsList/chatListSlice';
import { fetchChats } from '../containers/ChatsList/chatListSlice/actionCreators';
import { MessageInList } from '../containers/ChatsList/chatListSlice/types';
import { useAppDispatch, useAppSelector } from '../hooks';

export const LogNotePage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [subMessageText, setSubMessageText] = useState<string>('');

  const _message: MessageInList | undefined = useAppSelector(selectLogNoteById(+id!));

  useEffect(() => {
    if (!_message) dispatch(fetchChats({}));
    setSubMessageText('');
  }, []);

  return (
    <Layout isMessageLayout to="/log-notes" message={'logNote'} subMessageText={subMessageText}>
      <Chat id={id!} setSubMessage setSubMessageText={setSubMessageText} />
    </Layout>
  );
};
