import { useEffect, useState } from 'react';
import { getGroupMembers } from '../../apis/groupsAPI';
import { getUserById } from '../../apis/userAPI';
import { SelectableGroupMember } from '../../components/FilterOrSelectBottomSheet/types';

export enum MemberNotMemberGroupsFilters {
  MemberOfGroup,
  NotMemberOfGroup,
}

export const MemberNotMemberFiltersTX: Record<MemberNotMemberGroupsFilters, string> = {
  0: 'messages_groups_where_member',
  1: 'messages_groups_where_not_member',
};

export const useGetMembers = (props: { id: number; setUserIds?: (set: Set<number>) => void }) => {
  const { id, setUserIds } = props;

  const [isGettingMembers, setIsLoading] = useState<boolean>(false);

  const [members, setMembers] = useState<SelectableGroupMember[]>([]);

  const init = async () => {
    if (!id) return;

    const getPhotos = async (userID: number, index: number) => {
      const { photoFileName } = await getUserById(userID);
      setMembers(prev => {
        const cloned = [...prev];
        cloned[index] = { ...cloned[index], imageName: photoFileName };
        return cloned;
      });
    };

    setIsLoading(true);
    const groupMembers = await getGroupMembers({ id: id });
    const selectableUser = groupMembers.map((member, index) => {
      getPhotos(+member.userID, index);
      return {
        ...member,
        name: member.userName,
        id: +member.userID,
        showAvailability: !member.available,
      };
    });
    const selectedGroupMembers = new Set(groupMembers.map(member => member.userID));
    if (setUserIds) setUserIds(selectedGroupMembers);
    setMembers(selectableUser);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, [id]);

  return {
    members,
    isGettingMembers,
  };
};
