import styled from 'styled-components';
import AlarmIcon from '../../../assets/imgs/chats/blocked-alarm.svg';
import { palette } from '../../../theme/colors';
import { translate } from '../../../utils/translate';

const SContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 0.9rem 0 0.3rem 0;
  p {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 16px;
    color: ${palette.silver};
    margin-left: 0.25rem;
  }
`;

export interface IAlarmBlockedStateSeparatorProps {
  isActivated: boolean;
}

export const AlarmBlockedStateSeparator = (props: IAlarmBlockedStateSeparatorProps) => {
  const { isActivated } = props;
  return (
    <SContainer className="block-separator" role="blockedStateSeperator">
      <img src={AlarmIcon} alt={'AlarmIcon'} role="alarmIcon" />
      <p role="alarmTitle">
        {isActivated ? translate('messages_alarm_activated') : translate('messages_alarm_ended')}
      </p>
    </SContainer>
  );
};
