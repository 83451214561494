import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';

export const SPage = styled(Page)`
  display: flex;
  flex-direction: column;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  margin: 0 0 1.25rem 0;
`;

export const STutorialText = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
`;

export const STabContainer = styled.div`
  min-height: 0;
  height: 100%;
`;

export const SButtonWrapper = styled.div``;
