import { DataSelectableListType, SelectedAllType } from './DocumentFilterV2';

export const getSelectedDataValue = ({
  isAllDataSelected,
  isNoneOfDataSelected,
  selectedData,
}: {
  isAllDataSelected: boolean;
  isNoneOfDataSelected: boolean;
  selectedData: Set<number | string>;
}): Set<number> | SelectedAllType => {
  if (isAllDataSelected) return 'SELECTED_ALL';
  if (isNoneOfDataSelected) return 'UNSELECTED_ALL';
  return new Set(selectedData) as Set<number>;
};

export function generateInitialSelectedData<T extends DataSelectableListType>(
  selected: Set<number> | SelectedAllType | undefined,
  data: T[]
): Set<number> | undefined {
  if (selected === 'SELECTED_ALL') return new Set(data.map(g => g.id));
  if (selected !== 'UNSELECTED_ALL') return selected;
  return undefined;
}
