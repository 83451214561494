import styled, { keyframes } from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';
import isElectron from 'is-electron';
import { Page } from '../../components/Page/Page';
import { CheckBoxWithSubTitle } from '../../components/CheckBoxWithSubTitle/CheckBoxWithSubTitle';

export const SPage = styled(Page)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  font-family: 'Roboto-Regular';
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0px;
  padding-right: 0px;
  .mainContainer {
    padding: 1.25rem;
    padding-bottom: ${isElectron() ? '100px' : '0px'};
    flex: ${isElectron() ? undefined : 1};
  }
`;

export const STitle = styled.p`
  font-family: 'Roboto-Medium';
  font-size: 20px;
  line-height: 24px;
  color: ${palette.white};
  margin-bottom: 1.25rem;
`;

export const SLabel = styled.p`
  font-family: 'Roboto-Medium';
  font-size: 14px;
  line-height: 16px;
  margin-bottom: ${toRem(10)};
`;

export const SInputContainer = styled.div`
  padding: 0.75rem 1rem;
  background-color: ${palette.prussianBlue};
  border-radius: 0.75rem;
  border: 1px solid ${palette.queenBlue};
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-family: 'Roboto-Regular';
    font-size: 14px;
    line-height: 16px;
    color: ${palette.grayx11gray};
  }
`;

export const SIcon = styled.img`
  height: 30px;
  width: 30px;
`;

const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
 
    100% {
      opacity: 1;
    }
`;
export const SActivateButtonContainer = styled.div`
  width: ${isElectron() ? '100vw' : '100%'};
  position: ${isElectron() ? 'absolute' : 'relative'};
  bottom: 0;
  left: 0;
  padding: 24px 16px;
  z-index: 2;
  background: ${palette.prussianBlue2};
  animation: ${fadeIn} 0.5s ease-in-out forwards;
`;

export const SActivateButton = styled.button`
  max-width: calc(26rem - 32px);
  min-height: ${toRem(45)};
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
  z-index: 1;
  background: ${palette.earthYellow};
  font-size: 1rem;
  font-family: 'Roboto-Medium';
  color: ${palette.raisinBlack3};
  border-radius: 25.5px;
  cursor: pointer;
`;

export const SDescription = styled.p`
  font-family: 'Roboto-Regular';
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${palette.white};
  font-weight: 400;
  margin-bottom: 1.25rem;
`;

export const SCheckBoxWithSubTitle = styled(CheckBoxWithSubTitle)`
  margin-bottom: 1.25rem;
  .STitle {
    font-family: 'Roboto-Regular';
    font-size: 0.875rem;
  }
`;

const slideIn = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-5%);
    }
 
    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;

export const SlidingContainer = styled.div`
  opacity: 0;
  animation: ${slideIn} 0.3s ease-out forwards;
`;
