import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const STab = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${palette.prussianBlue};
  border: 1px solid ${palette.queenBlue};
  border-radius: 10px;
  padding: 1.25rem 1.5rem;
  width: 100%;
  text-decoration: none;

  box-shadow: 6px 20px 20px ${palette.davysGrey1};
  backdrop-filter: blur(21.7463px);

  p {
    flex-grow: 1;
    margin: 0rem 1rem;
    font-family: 'Roboto-Regular';
    font-size: 1rem;
    line-height: 1.18rem;

    color: ${palette.white};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
