import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button } from '../../components/Button/Button';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';

export const SPage = styled(Page)`
  padding-top: 0;
  display: flex;
  flex-direction: column;
`;

export const SVerticalContainer = styled.div<{
  $maxWidth?: boolean;
  $fixedSize?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto-Regular';
  color: ${palette.lightGrey};
  .intro-pic {
    margin-bottom: 0.625rem;
  }

  .top-margin {
    color: ${palette.silver};
    margin-top: 1.25rem;
  }

  &.middle {
    margin-top: 2.5rem;
    height: 10rem;
    justify-content: flex-start;
  }

  ${props =>
    props.$maxWidth &&
    css`
      max-width: 84%;
      margin: 0 auto 0 auto;
      text-align: center;
    `}
  ${props =>
    props.$fixedSize &&
    css`
      min-height: 6.625rem;
    `}
`;

export const SGapFiller = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 6rem;
`;

export const SButton = styled(Button)`
  width: 100%;
  margin-top: 0.8125rem;
  button {
    width: 100%;
    max-width: 100rem;
    font-size: 1rem;
    padding: 0.8125rem 0;
    font-family: 'Roboto-Medium';
    font-weight: 500;
    background-color: ${palette.honeyYellow};
    color: ${palette.raisinBlack3};
  }
`;

export const SSkip = styled(Link)`
  text-decoration: none;
  align-self: center;
  font-size: 1rem;
  font-family: 'Roboto-Regular';
  &:visited {
    color: ${palette.honeyYellow};
  }
  color: ${palette.honeyYellow};
  font-weight: 500;
`;
