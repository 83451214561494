import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';
import { SearchBarBase } from '../SearchBarBase/SearchBarBase';

export const SContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  width: 100%;
`;

export const SFilterButton = styled.button<{
  $isFilterApplied?: boolean;
  $svgOnly?: boolean;
}>`
  cursor: pointer;
  margin-left: 10px;
  min-height: 3.25rem;
  min-width: 3.25rem;
  background-color: ${({ theme }) => theme.palette.background.searchBar};
  border: 1px solid
    ${({ theme, $isFilterApplied }) =>
      $isFilterApplied ? palette.honeyYellow : theme.palette.border.primary};
  ${props =>
    props.$svgOnly &&
    css`
      border: none;
      background-color: transparent;
    `}
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
