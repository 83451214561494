import styled, { css } from 'styled-components';
import { CheckBoxWithSubTitle } from '../CheckBoxWithSubTitle/CheckBoxWithSubTitle';
import { palette } from '../../theme/colors';

export const SCheckBoxWithSubTitle = styled(CheckBoxWithSubTitle)<{
  $isSubTitleSize?: boolean;
  $extraPadding?: boolean;
}>`
  padding: ${props => (props.$extraPadding ? `1rem 0` : `1.05rem 0 0 0`)};
  .STitle {
    margin-left: 0;
    ${props =>
      props.$isSubTitleSize &&
      css`
        font-family: 'Roboto-Regular';
        font-weight: 400;
      `}
  }
`;

export const SMainTitle = styled.h1`
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.125rem;
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  line-height: 1.18rem;
`;
