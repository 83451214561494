import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  SPage,
  STabs,
  STabList,
  STab,
  STabPanel,
  SRecipientsButtonsContainer,
  SOutlinedActionButton,
  SSearchBar,
  RecipientsTabsListWrapper,
} from './LoneWorkerTimer.styles';
import { t } from 'i18next';
import {
  getSelectedGroups,
  getSelectedUsers,
  setSelectedGroups,
  setSelectedUsers,
} from './loneWorkerTimerSlice';
import { Group } from '../GroupsList/groupsSlice/types';
import { fetchGroupMembers } from './loneWorkerTimerSlice/actionCreator';
import { selectUser } from '../Login/LoginSlice';
import { ActionButton } from '../../components/ActionButtons/ActionButtons.style';
import { LoneWorkerTimerUsersList } from './LoneWorkerTimerUsersList';
import { GroupMember } from '../GroupDetail/groupDetailSlice/types';
import { LoneWorkerTimerGroupsList } from './LoneWorkerTimerGroupsList';
import {
  addGroupMembersToSelectedAndGetList,
  getActiveGroupsAfterUserUnselection,
  getSelectedUsersAfterGroupUnselection,
} from './helpers';
import { RecipientsSelectionTabIndex } from '../../utils/enums';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';

export const LoneWorkerTimerRecipientsSelection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authUser = useAppSelector(selectUser);
  const stateSelectedGroups = useAppSelector(getSelectedGroups);
  const stateSelectedUsers = useAppSelector(getSelectedUsers);
  const [selectedGroupList, setSelectedGroupList] = useState<Group[]>(stateSelectedGroups);
  const [selectedUsersList, setSelectedUsersList] = useState<SelectListUser[]>(stateSelectedUsers);
  const [tabIndex, setTabIndex] = useState<RecipientsSelectionTabIndex>(
    RecipientsSelectionTabIndex.GROUPS
  );
  const [searchText, setSearchText] = useState('');

  const selectedUsersIdsList = useMemo(
    () => selectedUsersList.map(user => user.id),
    [selectedUsersList]
  );
  const selectedGroupsIdsList = useMemo(
    () => selectedGroupList.map(group => group.id),
    [selectedGroupList]
  );
  const showActionButtons = useMemo(
    () => !!selectedUsersList.filter(user => user.id !== authUser?.id).length,
    [authUser?.id, selectedUsersList]
  );

  useEffect(() => {
    setSearchText('');
  }, [tabIndex]);

  const addGroupMembersToSelected = (members: GroupMember[]) => {
    const newSelectedUsers = addGroupMembersToSelectedAndGetList(members, selectedUsersList);
    setSelectedUsersList(newSelectedUsers);
  };

  const onGroupClick = async (selectedGroup: Group) => {
    const groupId = selectedGroup.id;
    const selectedGroups = [...selectedGroupList];
    const foundGroupIndex = selectedGroups.findIndex(group => group.id === groupId);
    if (foundGroupIndex > -1) {
      const newSelectedUsers = getSelectedUsersAfterGroupUnselection(selectedUsersList, groupId);
      setSelectedUsersList(newSelectedUsers);
      selectedGroups.splice(foundGroupIndex, 1);
    } else {
      selectedGroups.push(selectedGroup);
      dispatch(fetchGroupMembers(selectedGroup, authUser?.id, addGroupMembersToSelected));
    }
    setSelectedGroupList(selectedGroups);
  };

  const onUserClick = (selectedUser: SelectListUser) => {
    const selectedUsers = [...selectedUsersList];
    const selectedGroups = [...selectedGroupList];
    const foundUserIndex = selectedUsers.findIndex(user => user.id === selectedUser.id);
    if (foundUserIndex > -1) {
      const { groupIds } = selectedUsers[foundUserIndex];
      selectedUsers.splice(foundUserIndex, 1);
      // START  Check if there is are users in the group, if not then remove the group
      if (groupIds?.length) {
        const newGroupsList = getActiveGroupsAfterUserUnselection(
          selectedUsers,
          selectedGroups,
          authUser?.id ?? 0
        );
        setSelectedGroupList(newGroupsList);
      }
      // END  Check if there is are users in the group, if not then remove the group
    } else {
      selectedUsers.push(selectedUser);
    }
    setSelectedUsersList(selectedUsers);
  };
  const clearSelection = () => {
    setSelectedGroupList([]);
    setSelectedUsersList([
      {
        id: authUser?.id ?? 0,
        displayName: authUser?.name ?? '',
        groupIds: null,
        isSelected: true,
      } as SelectListUser,
    ]);
  };
  const saveRecipientsSelection = () => {
    dispatch(setSelectedGroups(selectedGroupList));
    dispatch(setSelectedUsers(selectedUsersList));
    navigate('/aloneWorker/create');
  };
  const handleSearch = (search: string) => {
    setSearchText(search);
  };

  return (
    <SPage className="recipientsSelection">
      <SSearchBar
        onChangeText={handleSearch}
        value={searchText}
        placeholderTx="documents_search"
        fallback={setSearchText}
      />
      <RecipientsTabsListWrapper role="recipientsSelectionContainer">
        <STabs
          selectedTabClassName="is-selected"
          selectedTabPanelClassName="is-selected"
          role="tabsContainer"
          forceRenderTabPanel
          selectedIndex={tabIndex}
          onSelect={index => setTabIndex(index)}
        >
          <STabList>
            <STab>
              {t('home_groups')} ({selectedGroupList?.length})
            </STab>
            <STab>
              {t('messages_users')} ({selectedUsersList?.length})
            </STab>
          </STabList>
          <STabPanel>
            <LoneWorkerTimerGroupsList
              isActive={tabIndex === RecipientsSelectionTabIndex.GROUPS}
              showActionButtons={showActionButtons}
              selectedGroupsIdsList={selectedGroupsIdsList}
              onGroupClick={onGroupClick}
              searchText={searchText}
            />
          </STabPanel>
          <STabPanel>
            <LoneWorkerTimerUsersList
              isActive={tabIndex === RecipientsSelectionTabIndex.USERS}
              showActionButtons={showActionButtons}
              selectedUsersIdsList={selectedUsersIdsList}
              onUserClick={onUserClick}
              searchText={searchText}
            />
          </STabPanel>
        </STabs>
      </RecipientsTabsListWrapper>
      {showActionButtons && (
        <SRecipientsButtonsContainer role="actionButtonsContainer">
          <SOutlinedActionButton type="button" tx={'clear_selection'} onClick={clearSelection} />
          <ActionButton type="button" tx={'choose_recipients'} onClick={saveRecipientsSelection} />
        </SRecipientsButtonsContainer>
      )}
    </SPage>
  );
};
