import styled, { css } from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';

export const SPage = styled(Page)`
  padding: 0;
`;

export const SMessageContainer = styled.div`
  padding: 1rem;
  max-height: 50%;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .messageItem-SMessageDateSeparator,
  .reply-SMessageDateSeparator {
    padding: 0;
  }
`;

interface SUsersContainerProps {
  $height?: number;
}

export const SUsersContainer = styled.div<SUsersContainerProps>`
  flex-grow: 1;

  ${props =>
    props.$height &&
    css`
      height: calc(100% - ${props.$height}px);
    `}

  display: flex;
  flex-direction: column;

  background-color: ${palette.prussianBlue2};
  padding: 1.25rem;
  .usersCount {
    font-family: 'Roboto-Regular';
    font-weight: 500;
    font-size: 1rem;
    color: ${palette.white};
    padding-bottom: 1rem;
    border-bottom: 1px solid ${palette.pickedBluewood};
  }
`;

export const SUserList = styled.ul`
  margin-top: 1rem;

  list-style-type: none;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
