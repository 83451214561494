/* eslint-disable @typescript-eslint/no-shadow */

import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { checkHasReply, generateMessageReplyToMessage, isReplyInSameDay } from '../Chat/helpers';
import {
  isChatsLoading,
  selectChatReply,
  selectCurrentChat,
  selectCurrentReadReceipt,
  selectError,
  selectMessagesRecipients,
  setCurrentReplyReadReceipt,
  setMessagesRecipients,
} from '../ChatsList/chatListSlice';
import { SMessageContainer, SPage, SUserList, SUsersContainer } from './ReadReceipts.styles';
import { generateReadDateElement, generateReadUserCountsForTranslation } from './helpers';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader/Loader';
import ChatReply from '../../components/MessageItem/ChatReply/ChatReply';
import { MessageItem } from '../../components/MessageItem/MessageItem';
import { ReadReceiptUserThumb } from '../../components/ReadReceiptUserThumb/ReadReceiptUserThumb';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { useMapModal } from '../../utils/customHooks/useMapModal';
import { getImageLink } from '../../utils/formatImageLink';
import { fetchReadReceipt } from '../ChatsList/chatListSlice/actionCreators';
import { selectUser } from '../Login/LoginSlice';

export const ReadReceipts = () => {
  const { id: messageId, replyId } = useParams();

  const navigate = useNavigate();
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const user = useAppSelector(selectUser);
  const readReceipt = useAppSelector(selectCurrentReadReceipt);
  const messageReceipt = useAppSelector(selectMessagesRecipients);
  const message = useAppSelector(selectCurrentChat);
  const loading = useAppSelector(isChatsLoading);
  const error = useAppSelector(selectError);
  const reply = useAppSelector(selectChatReply(+replyId!));

  // we should expect a message reply to be since the selected id (replyId) is not the same as message id
  const isReplyExpected = replyId !== messageId;
  const noReplyFound = !reply && isReplyExpected;

  const { renderMapModal, toggleMapModal } = useMapModal(message);

  useLayoutEffect(() => {
    dispatch(
      fetchReadReceipt({
        isReplyExpected,
        messageId: +messageId!,
        replyId: +replyId!,
      })
    );
    return () => {
      dispatch(setCurrentReplyReadReceipt(undefined));
      dispatch(setMessagesRecipients([]));
    };
  }, []);

  useEffect(() => {
    if (message === null && error === 'Message is deleted') {
      confirm({
        title: 'message_not_found_title',
        description: 'message_not_found_desc',
        onSubmit: () => navigate('/chat'),
        confirmText: 'ok',
        blockOutsideClick: true,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (noReplyFound && message?.replies && message?.replies.length !== 0) {
      confirm({
        title: 'message_reply_not_found_title',
        description: 'message_not_found_desc',
        onSubmit: () => navigate('/chat'),
        confirmText: 'ok',
        blockOutsideClick: true,
      });
    }
  }, [reply]);

  // could be encapsulated in a custom hook for reusability - start
  const [messageHeight, setMessageHeight] = useState<number | undefined>();
  const [needRemeasureMessage, setNeedRemeasureMessage] = useState<number>(0);

  const measuredMessageRef = useCallback(
    node => {
      if (node !== null) {
        setMessageHeight(node.offsetHeight);
      }
    },
    [needRemeasureMessage]
  );

  const handleDidFetchPhoto = () => {
    setNeedRemeasureMessage(prev => ++prev);
  };
  // - end

  if (!message || message.id !== Number(messageId) || loading || !user || noReplyFound) {
    return (
      <SPage>
        <Loader />
      </SPage>
    );
  }

  return (
    <SPage>
      {renderMapModal()}
      <SMessageContainer ref={measuredMessageRef} role="readReceiptsContainer">
        {reply && (
          <ChatReply
            key={reply.id}
            reply={reply}
            userId={user?.id as number}
            isSameDay={isReplyInSameDay(
              message,
              reply,
              message.replies.findIndex(_reply => _reply.id === reply.id)
            )}
            isSameSender={false}
            emergencyTypeName={message.emergencyTypeName}
            onPlayClick={() => {}}
            isAudioActive={false}
            toggleOptions={undefined}
            onMapClick={toggleMapModal}
            hasReply={checkHasReply(reply)}
            ReplyToMessage={generateMessageReplyToMessage(reply, message)}
            didFetchPhoto={handleDidFetchPhoto}
          />
        )}
        {!isReplyExpected && message && (
          <MessageItem
            key={message.id}
            alert={message.type === 2}
            message={message}
            onPlayClick={() => {}}
            isAudioActive={false}
            toggleOptions={undefined}
            onMapClick={toggleMapModal}
            didFetchPhoto={handleDidFetchPhoto}
          />
        )}
      </SMessageContainer>
      <SUsersContainer $height={messageHeight} role="usersCountContainer">
        <p className="usersCount" role="usersCount">
          {t(
            'message_users_seen_count',
            generateReadUserCountsForTranslation(isReplyExpected, readReceipt, messageReceipt)
          )}
        </p>
        <SUserList role="usersList">
          {readReceipt?.reads.map((user, index) => (
            <ReadReceiptUserThumb
              key={`${index}-${user.userId}`}
              title={user.name}
              subTitle={user.title}
              dateElements={generateReadDateElement(user.readTime)}
              imageSource={
                user.photoFileName &&
                getImageLink({
                  imageName: user.photoFileName,
                })
              }
            />
          ))}
          {readReceipt?.notReads.map((user, index) => (
            <ReadReceiptUserThumb
              key={`${index}-${user.userID}`}
              title={user.displayName}
              showAvailability={user.available === false}
              imageSource={
                user.photoFileName &&
                getImageLink({
                  imageName: user.photoFileName,
                })
              }
            />
          ))}
          {!isReplyExpected &&
            messageReceipt &&
            messageReceipt.map((user, index) => (
              <ReadReceiptUserThumb
                key={`${index}-${user.userID}`}
                title={user.displayName}
                imageSource={
                  user.photoFileName &&
                  getImageLink({
                    imageName: user.photoFileName,
                  })
                }
                showAvailability={user.available === false}
                subTitle={user.title}
                dateElements={
                  user.read && user.firstRead ? generateReadDateElement(user.firstRead) : undefined
                }
              />
            ))}
        </SUserList>
      </SUsersContainer>
    </SPage>
  );
};
