/* eslint-disable import/named */

import { batch } from 'react-redux';
import {
  setIsLoading,
  setError,
  setSelectedUsers,
  removeUser,
  setOrgs,
  removeOrg,
  setSelectedOrgs,
  setBroadcastMsg,
} from '.';
import { AppThunk } from '../../../store';
import {
  deleteBroadastMessage,
  getBroadcastMessage,
  getOrganizations,
  postBroadcastMessage,
} from '../../../apis/broadcast';
import { CreateMessageModel } from '../../Chat/models';
import { NavigateFunction } from 'react-router-dom';
import { setAreFilesSending } from '../../ChatsList/chatListSlice';

export const fetchOrganizations = (): AppThunk => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    const orgs = await getOrganizations();
    batch(() => {
      dispatch(setOrgs(orgs));
      dispatch(setIsLoading(false));
    });
  } catch (error) {
    console.log('error log ', error);
    batch(() => {
      dispatch(setIsLoading(false));
      dispatch(setError(`${error}`));
    });
  }
};

export const setSelectedOrgsAction =
  (orgs: any[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(setSelectedOrgs(orgs));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const removeSelectedOrgAction =
  (id: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(removeOrg(id));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const fetchBroadcastMessage =
  (id: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const msg = await getBroadcastMessage(id);
      batch(() => {
        dispatch(setBroadcastMsg(msg));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const setBroadcastMsgAction =
  (msg: any): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(setBroadcastMsg(msg));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const deleteBroadcastMessageAction =
  (id: number): AppThunk =>
  async dispatch => {
    try {
      setIsLoading(true);
      await deleteBroadastMessage(id);
      batch(() => {
        dispatch(setBroadcastMsg(null));
      });
      setIsLoading(false);
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        setIsLoading(false);
        dispatch(setError(`${error}`));
      });
    }
  };

export const setSelectedUsersAction =
  (users: any[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(setSelectedUsers(users));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const removeSelectedUserAction =
  (id: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      batch(() => {
        dispatch(removeUser(id));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };

export const sendBroadcastMsg =
  (messageModel: CreateMessageModel, navigate: NavigateFunction): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const msg = await postBroadcastMessage(messageModel);
      if (msg) {
        batch(() => {
          dispatch(setBroadcastMsgAction(msg));
          dispatch(setIsLoading(false));
          dispatch(setAreFilesSending(false));
        });
        navigate(`/message/${msg.id}`);
      }
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setError(`${error}`));
      });
    }
  };
