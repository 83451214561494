/* eslint-disable @typescript-eslint/naming-convention */

import * as React from 'react';
import { palette } from '../../theme/colors';
import { useSelectlist } from '../../utils/customHooks/useSelectList';
import { translate } from '../../utils/translate';
import { searchData } from '../../containers/ExternalContacts/helpers';
import {
  SButton,
  SCheckBoxTitle,
  SCheckBoxWithSubTitlePhotoWrapper,
  SFilter,
  SHeader,
  SList,
  SSearchBarBase,
  STitle,
} from './styles';
import { Backdrop } from '../Backdrop/Backdrop';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { ResetFiltersButton } from '../Button/ResetFiltersButton';
import AvailabilityChip from '../AvailabilityChip/AvailabilityChip';
import { DataSelectableListType, IFilterOrSelectBottomSheet } from './types';

export const FilterOrSelectBottomSheet = <T extends DataSelectableListType>(
  props: IFilterOrSelectBottomSheet<T>
) => {
  const {
    isOpen,
    setIsOpen,
    onFilter,
    data,
    titleTx = 'messages_filter_by_contacts_lists',
    filterTx = 'messages_filter',
    searchPlacholderTx = 'documents_search',
    selectAllTx = 'select_all',
    selectShapeType = 'circle',
    keyToSearchBy = 'name',
    withPhoto = false,
    atLeastOneReq = false,
    initialSelected,
    hideFooter,
    atLeastOneReqTx,

    hideCurrentUserId,
  } = props;

  const _data = React.useMemo(
    () => (hideCurrentUserId ? data.filter(item => item.id !== hideCurrentUserId) : data),
    [data, hideCurrentUserId]
  );
  const _initialSelected = React.useMemo(
    () =>
      hideCurrentUserId
        ? new Set(Array.from(initialSelected).filter(id => id !== hideCurrentUserId))
        : initialSelected,
    [initialSelected, hideCurrentUserId]
  );

  const {
    handleSelect,
    isStagedSelectedAll,
    isNonStagedSelected,
    stagedSelectedItems,
    onFinishSelecting,
  } = useSelectlist({
    data: _data,
    multiStage: true,
    initialSelected: _initialSelected,
  });

  const [searchTerm, setSearchTerm] = React.useState<string | undefined>();

  const listSource = searchData(_data, searchTerm, keyToSearchBy);

  const confirm = useConfirmation();

  const layout = useLayoutContext();

  React.useLayoutEffect(() => {
    if (hideFooter) {
      layout.setTabsState(!isOpen);
    }
  }, [isOpen]);

  const onCloseHandle = () => {
    onFinishSelecting(false);
    setIsOpen(false);
    setSearchTerm('');
  };
  const filterValid = atLeastOneReq ? stagedSelectedItems.size !== 0 : true;

  const handleFilter = () => {
    if (!filterValid) {
      confirm({
        title: 'warning',
        description: translate(atLeastOneReqTx || 'messages_no_member_modal_info') as string,
        onSubmit: () => {},
        confirmText: 'ok',
      });
      return;
    }
    onFinishSelecting(true);
    setSearchTerm('');
    onFilter(
      isStagedSelectedAll
        ? 'SELECTED_ALL'
        : isNonStagedSelected
        ? 'UNSELECTED_ALL'
        : hideCurrentUserId
        ? (new Set([...Array.from(stagedSelectedItems), hideCurrentUserId]) as Set<number>)
        : (new Set(stagedSelectedItems) as Set<number>)
    );
  };

  const handleSearch = (text: string) => {
    setSearchTerm(text);
  };

  const handleReset = () => {
    handleSelect(0);
    onFinishSelecting(true);
    setSearchTerm('');
    onFilter('SELECTED_ALL');
  };

  const renderAvailability = (item: T) => {
    if (!item.showAvailability) return undefined;
    return () => <AvailabilityChip isAvailable={false} />;
  };

  return (
    <>
      {isOpen && (
        <>
          <SFilter role="filterBottomsheet">
            <SHeader role="header">
              <rect width="400" height="100" fill={palette.silver} />
            </SHeader>
            <STitle role="filterTitle">{translate(titleTx)}</STitle>
            <SSearchBarBase
              role="searchBar"
              placeholderTx={searchPlacholderTx}
              fallback={handleSearch}
              value={searchTerm}
            />
            <SList role="itemsList" $isRound={selectShapeType === 'circle'} className="SList">
              <SCheckBoxTitle
                className="SFilterCheckboxItem-header"
                selected={isStagedSelectedAll}
                title={translate(selectAllTx) as string}
                onToggleCheck={handleSelect}
                valueId={0}
                checkBoxType={selectShapeType}
                withoutSeparator
                clickAll
              />

              {listSource.map((item, index) =>
                withPhoto ? (
                  <SCheckBoxWithSubTitlePhotoWrapper
                    className="SFilterCheckboxItem"
                    selected={stagedSelectedItems.has(item.id)}
                    title={item.name}
                    onToggleCheck={handleSelect}
                    valueId={item.id}
                    key={`${item.id}-${index}`}
                    checkBoxType={selectShapeType}
                    imageName={item.imageName}
                    renderAdditionalInfo={renderAvailability(item)}
                    withoutSeparator
                    clickAll
                  />
                ) : (
                  <SCheckBoxTitle
                    className="SFilterCheckboxItem"
                    selected={stagedSelectedItems.has(item.id)}
                    title={item.name}
                    onToggleCheck={handleSelect}
                    key={`${item.id}-${index}`}
                    valueId={item.id}
                    checkBoxType={selectShapeType}
                    withoutSeparator
                    clickAll
                    renderAdditionalInfo={renderAvailability(item)}
                  />
                )
              )}
            </SList>
            <ResetFiltersButton
              onClick={handleReset}
              isFullWidth
              isDisplayed={!isStagedSelectedAll}
              marginTop="0.8125rem"
            />
            <SButton tx={filterTx} onClick={handleFilter} />
          </SFilter>
          <Backdrop onClick={onCloseHandle} setModal={setIsOpen} />
        </>
      )}
    </>
  );
};
