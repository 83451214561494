import React from 'react';
import { palette } from '../../theme/colors';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import { Backdrop } from '../Backdrop/Backdrop';
import DescriptionIcon from '../../assets/imgs/loneWorkerTimer/description.svg';
import {
  SContainer,
  SBottomSheet,
  SCurtainHandle,
  STitleText,
  SIntroIcon,
  SDescriptionTextContainer,
  SDescriptionTextRow,
} from './PanicDescriptionBottomSheet.styles';
import { t } from 'i18next';

export interface PanicDescriptionBottomSheetProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PanicDescriptionBottomSheet = (props: PanicDescriptionBottomSheetProps) => {
  const { isOpen, setIsOpen } = props;

  const descriptionsTranslationKeys: string[] = [
    'panic_tutorial_desc1',
    'panic_tutorial_desc2',
    'panic_tutorial_desc3',
  ];

  if (!isOpen) return <></>;

  return (
    <>
      <SBottomSheet>
        <SCurtainHandle>
          <rect width="400" height="100" fill={palette.silver} />
        </SCurtainHandle>
        <SContainer>
          <SIntroIcon src={DescriptionIcon} />
          <STitleText>{t('panic_tutorial_title_1')}</STitleText>
          <STitleText>{t('panic_tutorial_title_2')}</STitleText>
          <SDescriptionTextContainer>
            {descriptionsTranslationKeys.map((item, index) => (
              <SDescriptionTextRow
                key={`howItWorks-${item}`}
                $isLastRow={index === descriptionsTranslationKeys.length - 1}
              >
                <div className="numberWrapper">
                  <div className="numberContainer">
                    <p>{index + 1}</p>
                  </div>
                  <div className="progressLine"></div>
                </div>
                <div>
                  <p>{t(item)}</p>
                </div>
              </SDescriptionTextRow>
            ))}
          </SDescriptionTextContainer>
          <ActionButton type="button" tx={'okay'} onClick={() => setIsOpen(false)} />
        </SContainer>
      </SBottomSheet>
      <Backdrop setModal={() => setIsOpen(false)} />
    </>
  );
};
