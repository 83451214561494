import styled from 'styled-components';
import { palette } from '../../theme/colors';

import { Page } from '../../components/Page/Page';

export const SSummaryContainer = styled.div`
  padding: 0 1.25rem;
`;

export const SFlexDiv = styled.div`
  flex: 1;
`;

export const SPage = styled(Page)`
  padding: 1.25rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
`;

export const SLine = styled.hr`
  margin: 0 0 1px 0;
  height: 1px;
  border: none;
  background-color: ${palette.queenBlue};
  border-radius: 49px;
  &.moreMargin {
    margin: 0.625rem 0 1px 0;
  }
`;

export const SItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem 0;

  &.input {
    cursor: default;
  }
  .left {
    width: 5rem;
  }

  cursor: pointer;
`;

export const SIcon = styled.img`
  vertical-align: text-bottom;
  /* margin-right: 1rem; */
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
