import React from 'react';
import { SidebarSection } from '../../../containers/Sidebar/Sidebar';
import { SElementContainer } from '../../../containers/Sidebar/Sidebar.styles';
import { Layout } from '../../Layout/Layout';
import EnglishIcon from '../../../assets/imgs/general/united-kingdom-round.svg';
import NetherlandIcon from '../../../assets/imgs/general/netherlands-round.svg';
import SwedishIcon from '../../../assets/imgs/general/sweden-round.svg';
import UsIcon from '../../../assets/imgs/general/us-round.svg';
import SpainIcon from '../../../assets/imgs/general/spain-round.svg';
import BrazilIcon from '../../../assets/imgs/general/brazil-round.svg';
import SidebarElement from '../../../containers/Sidebar/SidebarElement';
import i18n, { defaultLanguage, ELanguages } from '../../../i18n';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../Page/Page';
import { setLanguage } from '../Settings/settingsSlice/actionCreators';
import { useAppDispatch } from '../../../hooks';

function Language() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const saveLanguage = (language: ELanguages) => {
    dispatch(
      setLanguage(language, () => {
        i18n.changeLanguage(language);
        navigate('/sidebar');
      })
    );
  };

  const languageElements: SidebarSection[] = [
    ...(defaultLanguage === ELanguages.se
      ? [
          {
            name: ELanguages.se,
            icon: SwedishIcon,
            onClick: () => {
              saveLanguage(ELanguages.se);
            },
          },
          {
            name: 'en',
            icon: EnglishIcon,
            onClick: () => {
              saveLanguage(ELanguages.en);
            },
          },
          {
            name: 'nl',
            icon: NetherlandIcon,
            onClick: () => {
              saveLanguage(ELanguages.nl);
            },
          },
        ]
      : [
          {
            name: ELanguages.pt,
            icon: BrazilIcon,
            onClick: () => {
              saveLanguage(ELanguages.pt);
            },
          },
          {
            name: ELanguages.es,
            icon: SpainIcon,
            onClick: () => {
              saveLanguage(ELanguages.es);
            },
          },
          {
            name: 'en',
            icon: UsIcon,
            onClick: () => {
              saveLanguage(ELanguages['en-TT']);
            },
          },
        ]),
  ];

  return (
    <Layout isMessageLayout message="language" to="/sidebar" showBottomTabs>
      <Page>
        <SElementContainer role="languagesContainer">
          {languageElements.map((element, key) => (
            <SidebarElement
              key={key}
              name={element.name}
              icon={element.icon}
              onClick={element.onClick}
            />
          ))}
        </SElementContainer>
      </Page>
    </Layout>
  );
}

export default Language;
