import React from 'react';
import {
  SColumnsContainers,
  SInfo,
  SInfoContainer,
  SMemberInfoContainer,
  SMemberInfoText,
  SName,
} from '../../containers/GroupDetail/GroupDetail.styles';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import Eye from '../../assets/imgs/groups/eye.svg';
import { getImageLink } from '../../utils/formatImageLink';

interface IGroupMemberItemProps {
  title: string;
  subTitle?: string;
  date?: string;
  userName?: string;
  memberID: number;

  onClick: (memberID: number) => void;
  photoFileName: string | null;

  isAdmin?: boolean;
  isGroupHidden?: boolean;
}

export const GroupMemberItem = (props: IGroupMemberItemProps) => {
  const {
    title,
    date,
    subTitle,

    onClick,
    memberID,
    userName,
    photoFileName,

    isAdmin,
    isGroupHidden,
  } = props;

  const handleClick = () => onClick(memberID);
  return (
    <>
      <SColumnsContainers
        onClick={handleClick}
        role={`groupMember-${userName}`}
        id={`groupMember-${memberID}`}
      >
        <ProfilePicture
          // TODO make profilePictureFileName not required
          profilePictureFileName=""
          readyPhotoSource={getImageLink({
            imageName: photoFileName,
            size: 'small',
          })}
        />
        <SMemberInfoContainer role="groupMemberInfoContainer">
          <SInfoContainer role="groupMemberTitleAndSubtitleRow" marginTop={false}>
            <SMemberInfoText>
              <SName role="groupMemberTitle"> {title}</SName>&nbsp;
              <SInfo role="groupMemberSubtitle">{subTitle}</SInfo>
            </SMemberInfoText>
            {isAdmin && isGroupHidden && <img className="margin-left" src={Eye} alt="hidden" />}
          </SInfoContainer>
          {date && (
            <SInfo role="groupMemberDate">
              {getNumbersBasedDateTextByLanguage({ dateText: date })}
            </SInfo>
          )}
        </SMemberInfoContainer>
      </SColumnsContainers>
    </>
  );
};
