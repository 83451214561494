import styled, { css } from 'styled-components';

export const SChat = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const SRepliesLoader = styled.div`
  height: 50px;
`;

export const SChatContainer = styled.div<{ topPadding?: boolean }>`
  position: relative;
  min-height: 0;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0.5rem 1.25rem;

  ${props =>
    props.topPadding &&
    css`
      padding-top: 3.125rem;
    `}

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SScrollDownBtw = styled.button`
  opacity: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
  z-index: 8;
  position: absolute;
  right: 1rem;
  bottom: 6rem;
  width: 34px;
  height: 34px;
  background-color: grey;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s linear, opacity 0.1s linear;

  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(6px);
`;
export const SFilesLoaderWrapper = styled.div`
  height: 50px;
`;
