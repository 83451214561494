import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SCalendar = styled.div`
  &.calendar {
    width: 100%;
    background-color: ${palette.transparent};
  }

  .weekDay {
    color: ${palette.white};
    font-size: ${toRem(14)} !important;
    background-color: ${palette.transparent};
  }
  .dayBtn,
  .dayBtnDisabled {
    background-color: ${palette.transparent};
    font-size: ${toRem(14)} !important;
    color: ${palette.white};
    height: ${toRem(40)};
    width: ${toRem(40)};
  }
  .dayBtnDisabled {
    pointer-events: none;
  }
  .dayBtnToday {
    border-radius: 50%;
    border: 1px solid ${palette.honeyYellow};
    color: ${palette.honeyYellow};
  }
  .dayBtnSelected {
    border-radius: 50%;
    background-color: ${palette.honeyYellow};
    color: ${palette.raisinBlack3};
  }
`;
