import { SImg, SLeft, SRight } from './ImagesModal.styles';
import { Modal } from '../../Modal/Modal';
import { ReactComponent as Arrow } from '../../../assets/imgs/alarms/arrow.svg';

export interface ImagesModalProps {
  imgKey: number;
  isOpen: boolean;
  img: string | null;
  imagesCount?: number;
  closeModal: () => void;
  handleImageChange?: (imgKey: number, next: boolean) => void;
}

function ImagesModal(props: ImagesModalProps) {
  const { isOpen = false, img, imagesCount = 0, closeModal, handleImageChange, imgKey } = props;
  const showPreviousImg = imgKey > 0;
  const showNextImg = imagesCount - 1 > imgKey && imgKey < imagesCount - 1;

  const handleChangeImage = (index: number) => {
    if (!handleImageChange) return;
    if (index === 1) {
      handleImageChange(imgKey, true);
    } else {
      handleImageChange(imgKey, false);
    }
  };
  return (
    <div role="imagesModalContainer">
      <Modal isOpen={isOpen} setIsOpen={closeModal} role="modal">
        <SImg role="image" src={img!} alt="" />
        {showPreviousImg && (
          <SLeft role="prevIcon" onClick={() => handleChangeImage(-1)}>
            <Arrow className="prevArrow" />
          </SLeft>
        )}
        {showNextImg && (
          <SRight role="nextIcon" onClick={() => handleChangeImage(+1)}>
            <Arrow className="nextArrow" />
          </SRight>
        )}
      </Modal>
    </div>
  );
}

export default ImagesModal;
