import styled from 'styled-components';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';
import { SearchFilterBar } from '../../components/SearchFilterBar/SearchFilterBar';

export const SSearchFilterBar = styled(SearchFilterBar)`
  width: 100%;
  margin: 0 0 1rem 0;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  margin: 0 0 1rem 0;
`;

export const SGroupsList = styled.div`
  min-height: 0;
  height: 100%;

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
