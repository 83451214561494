import { SelectListUser } from '../../CreateMessage/createMessageSlice/types';
import { Group } from '../../GroupsList/groupsSlice/types';

export interface PanicButtonState {
  selectedGroups: Group[];
  selectedUsers: SelectListUser[];
  groups: Group[];
  users: SelectListUser[];
  usersLoading: boolean;
  groupsLoading: boolean;
  isLoading: boolean;
  messageText: string;
  locationData: LocationData | null;
  alarmData: PanicAlarmData | null;
  error: string | null;
  isLocationDenied: boolean;
}

export interface LocationData {
  lat: number | null;
  lng: number | null;
  name?: string | null;
  address?: string | null;
}

export interface PanicAlarmData {
  groupIds: Array<number>;
  recipientIds: Array<number>;
  alarmStatus: 0 | 1 | 2;
  text: string;
  messageId: number;
}

export type PanicAlarmActivationData = {
  text: string;
  groupIds: number[];
  userIds: number[];
};

export type PanicAlarmModel = {
  groups: unknown[];
  recipients?: unknown[];
  text?: string;
  dispatchTime?: Date;
  locationId?: number;
  deviceId?: string;
  groupIds?: number[];
  recipientIds?: number[];
  active?: boolean;
  messageId?: number;
  userId?: number;
  alarmStatus?: PanicAlarmState;
};

export enum PanicAlarmState {
  Active,
  Unlocked,
  Sent,
}
