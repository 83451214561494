import * as React from 'react';
import { HoldingStatementCard } from '../../components/HoldingStatementCard/HoldingStatementCard';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { translate } from '../../utils/translate';
import { selectUser } from '../Login/LoginSlice';
import {
  getMessageSender,
  getMessageSubject,
  getRecipientsMedia,
  handleDate,
  messageShowGenerator,
  getStatementTitle,
} from './helpers';
import { ChatListFilter } from '../../components/Chat/ChatListFilter';
import NoMessages from '../../assets/imgs/NotFound/no-result.svg';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { useNavigate } from 'react-router-dom';
import {
  getActiveStatementGroups,
  isChatsLoading,
  selectHoldingStatementWithFilter,
} from '../ChatsList/chatListSlice';
import {
  deleteHoldingStatement,
  fetchHoldingStatement,
} from '../ChatsList/chatListSlice/actionCreators';
import { fetchGroups } from '../GroupsList/groupsSlice/actionCreators';
import Options from '../../components/Options/Options';
import Trash from '../../assets/imgs/chats/trash.svg';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { isEmpty } from 'lodash';
import {
  NoMessagesWrapper,
  SSearchFilterBar,
  SList,
  SLoader,
  SPage,
} from './HoldingStatement.styles';

export const HoldingStatement = () => {
  const confirm = useConfirmation();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeGroups = useAppSelector(getActiveStatementGroups);

  const isLoading = useAppSelector(isChatsLoading);
  const [filterOnlyGroupIds, setFilterOnlyGroupIds] = React.useState<number[] | undefined>();
  const statements = useAppSelector(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: Need to be investigated
    selectHoldingStatementWithFilter(filterOnlyGroupIds, activeGroups)
  );

  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
  const [selectedGroups, setSelectedGroups] = React.useState<Array<number>>([]);
  const [searchTerm, setSearchTerm] = React.useState<string | undefined>();
  const [optionsOpen, setOptionsOpen] = React.useState<boolean>(false);
  const [selectedChatId, setSelectedChatId] = React.useState<number>();

  const chatListContainer = React.useRef<HTMLUListElement>(null);

  const isFilterApplied = React.useMemo(() => {
    return !!filterOnlyGroupIds?.length && activeGroups?.length !== filterOnlyGroupIds?.length;
  }, [filterOnlyGroupIds]);

  const toggleFilterOpen = () => setFilterOpen(prev => !prev);

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const submitFilteredGroups = (selectedGroupsList: number[]) => {
    setFilterOpen(false);
    const filterGroupIdsList = selectedGroupsList.length
      ? selectedGroupsList
      : activeGroups.map(g => g.id);
    setFilterOnlyGroupIds(filterGroupIdsList);
    setSelectedGroups(filterGroupIdsList);
  };

  const handleRedirect = (id: number) => {
    navigate(`/message/${id}/fromHoldingStatement`);
  };
  const handleHide = (id: number) => () => {
    confirm({
      title: 'messages_delete_title',
      description: 'statement_deleteConfirmDesc',
      onSubmit: () => {
        dispatch(deleteHoldingStatement(id, searchTerm));
        setOptionsOpen(false);
      },
      onCancel: () => {
        setOptionsOpen(false);
      },
      confirmText: 'messages_delete',
      cancelText: 'cancel',
    });
  };
  const onclickDots = (id: number) => {
    setSelectedChatId(id);
    setOptionsOpen(!optionsOpen);
  };

  React.useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);
  React.useEffect(() => {
    try {
      dispatch(fetchHoldingStatement({ search: searchTerm }));
    } catch (e) {}
  }, [dispatch, searchTerm]);

  return (
    <SPage>
      <SSearchFilterBar
        onSearch={onSearch}
        handleOpenFilter={toggleFilterOpen}
        value={searchTerm}
        isFilterApplied={isFilterApplied}
      />
      {!isEmpty(activeGroups) && (
        <ChatListFilter
          serachbarTitle="documents_filter_groups"
          title="groups_all"
          label={'messages_filter'}
          isOpen={filterOpen}
          groupsList={activeGroups}
          setIsOpen={toggleFilterOpen}
          selected={selectedGroups}
          setSelected={setSelectedGroups}
          onFilter={submitFilteredGroups}
        />
      )}
      {isLoading && <SLoader />}
      {statements.length === 0 && !isLoading && (
        <NoMessagesWrapper role="noMessagesContainer">
          <img role="image" src={NoMessages} alt="" />
          <p role="emptyText">{translate('statement_empty')}</p>
        </NoMessagesWrapper>
      )}
      {statements.length > 0 && (
        <>
          <Options
            isOpen={optionsOpen}
            setIsOpen={setOptionsOpen}
            setTabBar
            items={[
              {
                name: 'messages_delete',
                icon: Trash,
                callback: handleHide(selectedChatId ?? 0),
              },
            ]}
          />
          <SList ref={chatListContainer} role="holdingStatementsList">
            {statements.map(statement => (
              <HoldingStatementCard
                key={`${statement.id}`}
                date={handleDate(statement, 'sent')}
                time={handleDate(statement, 'lastReplySent')}
                title={getStatementTitle(statement)}
                subTitleName={getMessageSender(statement, user?.id ?? 0)}
                subTitle={messageShowGenerator(statement, user?.id ?? 0)}
                subject={getMessageSubject(statement)}
                media={getRecipientsMedia(statement)}
                id={statement.id}
                onClick={handleRedirect}
                searchText={searchTerm}
                onMoreOptionClick={onclickDots}
              />
            ))}
          </SList>
        </>
      )}
      <BigFloatButton link={'/createHoldingStatement'} tx={'statement_create'} />
    </SPage>
  );
};
