import styled, { css } from 'styled-components';
import { palette } from '../../../theme/colors';
import { toRem } from '../../../utils/toRem';

interface SMessageProps {
  deleted?: boolean;
  margin?: boolean;
  withHyperLinks?: boolean;
}

export const SMessageText = styled.span<SMessageProps>`
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: ${toRem(16)};
  line-height: ${toRem(20.8)};
  color: ${palette.navy20};
  margin-bottom: ${props => props.deleted && '12px'};
  ${props =>
    props.deleted &&
    css`
      padding-top: 0.5rem;
      margin-right: 1rem;
      color: ${palette.silver};
    `}

  white-space: pre-line;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  .deletedSign {
    margin-right: 0.75rem;
  }
  .message-link {
    color: ${palette.honeyYellow};
  }

  &:child a {
    text-decoration: none;
  }

  .sentTime {
    font-family: 'Roboto-Medium';
    float: right;
    font-size: ${toRem(11)};
    color: ${palette.navy20};
    margin-left: 0.5rem;
    margin-top: ${toRem(2)};
  }
  .editedReplacer {
    color: ${palette.transparent};
    font-size: ${toRem(12)};
  }
`;

export const SSenderText = styled.p`
  color: ${palette.honeyYellow};
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
`;
