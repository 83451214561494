import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { Button } from '../Button/Button';
import { toRem } from '../../utils/toRem';

export const SOverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`;

export const SModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const SDialog = styled.div`
  z-index: 100;
  background: ${palette.navyBlue};
  position: relative;
  margin: 10rem auto;
  width: 23rem;
  padding: ${toRem(31)} ${toRem(24)} ${toRem(24)} ${toRem(24)};
  text-align: center;
  font-family: 'Roboto-Regular';
  box-shadow: 0px ${toRem(11)} ${toRem(15)} rgba(90, 90, 90, 0.1);
  border-radius: ${toRem(5)};
`;

export const SText = styled.p`
  color: ${palette.white};
  font-size: ${toRem(14)};

  &.title {
    font-family: 'Roboto-Medium';
    font-size: ${toRem(16)};
    line-height: ${toRem(18.75)};
  }
  &.continueQuestion {
    margin-top: ${toRem(16)};
  }
  &.moreAlarms,
  &.alarmTitle {
    font-family: 'Roboto-Bold';
    font-size: ${toRem(14)};
    line-height: ${toRem(20)};
  }
  &.moreAlarms {
    margin-left: ${toRem(8)};
  }
  &.alarmTitle {
    margin-bottom: ${toRem(2)};
  }
  &.sendAt {
    font-family: 'Roboto-Medium';
    font-size: ${toRem(12)};
    color: ${palette.ashesGray};
  }
  &.sendBy {
    font-family: 'Roboto-Regular';
    font-size: ${toRem(14)};
    line-height: ${toRem(18)};
  }
  &.sendTo {
    font-family: 'Roboto-Regular';
    font-size: ${toRem(10)};
    color: ${palette.ashesGray};
    line-height: ${toRem(12)};
    overflow: hidden;
  }
`;

export const SAlarmInfoContainer = styled.div`
  margin-left: ${toRem(10)};
  text-align: left;
`;

export const SWarningIconContainer = styled.div`
  margin-bottom: ${toRem(16)};
`;

export const SRowCenterAligned = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.marginTop {
    margin-top: ${toRem(8)};
  }
`;

export const SMoreAlarmsContainer = styled.div`
  border-bottom: ${toRem(1)} solid ${palette.prussianBlue5};
  margin-bottom: ${toRem(16)};
  padding: ${toRem(16)} 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SSendAlarmButton = styled(Button)`
  button {
    background-color: ${palette.danger};
    color: ${palette.white};
    font-family: 'Roboto-Medium';
  }
  margin: ${toRem(16)} 0;
`;

export const SCancelButton = styled(Button)`
  button {
    background-color: ${palette.transparent};
    border: ${toRem(1)} solid ${palette.honeyYellow};
    color: ${palette.white};
    font-family: 'Roboto-Medium';
  }
`;

export const SActiveAlarmsModalItem = styled.div`
  border-bottom: ${toRem(1)} solid ${palette.prussianBlue5};
  padding: ${toRem(16)} 0;
  cursor: pointer;
`;

export const SAlarmIconContainer = styled.div`
  height: ${toRem(24)};
  width: ${toRem(24)};
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
