import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SModalContainer = styled.div`
  z-index: 100;
  background: ${palette.prussianBlue2};
  position: relative;
  margin: 10rem auto;
  min-width: 20rem;
  padding: 1.2rem 1.6rem 2rem 1.6rem;
  text-align: center;
  font-family: 'Roboto-Regular';
  box-shadow: 0 11px 15px ${palette.notificationModalBoxShadow};
  backdrop-filter: blur(21.7463px);
  border-radius: 0.3rem;
`;

export const STextContainer = styled.div`
  max-width: 16.8rem;
  margin: 0 auto 1.25rem;

  .activatedAlarmTitle {
    text-align: center;
    font-size: 1.5rem;
  }

  .test-alarm-description {
    margin-top: -0.6rem;
    margin-bottom: 2.5rem;
    font-size: 0.9rem;
    font-family: 'Roboto-Medium';
  }
`;

export const SNotificationIcon = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  margin: 0.9rem;
`;

export const STitle = styled.p<{ titleColor: string }>`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.9rem;
  color: ${props => props.titleColor};
`;

export const SButtonWrapper = styled.div<{ closeBtnOnly: boolean | undefined }>`
  display: flex;
  justify-content: ${props => (props.closeBtnOnly ? 'center' : 'space-between')};
  width: 100%;
  gap: 1.2rem;

  .button {
    font-family: 'Roboto-Regular';
    min-width: ${props => (props.closeBtnOnly ? '100%' : '7.8rem')};
  }

  .failedBtn {
    min-width: 7.8rem;
    background-color: ${palette.tartOrange};
    border: none;
  }

  .workedBtn {
    min-width: 7.8rem;
    background-color: ${palette.applGreen};
    color: ${palette.white};
  }
`;
