import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';

interface BaseProps {
  $extraPadding: boolean;
  type?: 'yellow' | 'red';
  disabled?: boolean;
}

export const Base = styled.div<BaseProps>`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: ${props => (props.$extraPadding ? '6.688rem' : '3.125rem')};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.4rem;
  height: 45px;
  width: 90%;
  max-width: 22rem;
  border: none;
  z-index: 5;

  font-size: 1rem;
  font-family: 'Roboto-Medium';
  font-weight: 500;

  background-color: ${palette.honeyYellow};
  color: ${palette.black};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  ${props =>
    props.color === 'red' &&
    css`
      background-color: ${palette.tartOrange};
      color: ${palette.white};
    `}
`;
