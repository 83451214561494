"use strict";
exports.__esModule = true;
exports.ENotificationType = exports.SendMessageType = exports.EIdTypes = exports.ENotificationDataProperties = exports.EAlertType = void 0;
var EAlertType;
(function (EAlertType) {
    EAlertType["none"] = "none";
    EAlertType["active"] = "active";
    EAlertType["recalled"] = "recalled";
    EAlertType["callAlert"] = "callAlert";
    EAlertType["callAlertEnded"] = "callAlertEnded";
    EAlertType["reactivate"] = "reactivate";
    EAlertType["ended"] = "ended";
    EAlertType["started"] = "started";
    EAlertType["shared"] = "shared";
    EAlertType["newMuster"] = "newMuster";
    EAlertType["muster"] = "muster";
    EAlertType["alarmWarn"] = "alarmWarn";
    EAlertType["selfAlarmOpenApp"] = "selfAlarmOpenApp";
    EAlertType["selfAlarmClosedApp"] = "selfAlarmClosedApp";
    EAlertType["replay"] = "replay";
})(EAlertType = exports.EAlertType || (exports.EAlertType = {}));
var ENotificationDataProperties;
(function (ENotificationDataProperties) {
    ENotificationDataProperties["groupMessage"] = "groupMessage";
    ENotificationDataProperties["reply"] = "reply";
    ENotificationDataProperties["recalled"] = "recalled";
    ENotificationDataProperties["onCallAlert"] = "onCallAlert";
    ENotificationDataProperties["onCallAlertEnded"] = "onCallAlertEnded";
    ENotificationDataProperties["checklistReactivated"] = "checklistReactivated";
    ENotificationDataProperties["checklistEnded"] = "checklistEnded";
    ENotificationDataProperties["checklistStarted"] = "checklistStarted";
    ENotificationDataProperties["checklistShared"] = "checklistShared";
    ENotificationDataProperties["newMuster"] = "newMuster";
    ENotificationDataProperties["muster"] = "muster";
    ENotificationDataProperties["personalAlarmWarning"] = "personalAlarmWarning";
    ENotificationDataProperties["data"] = "data";
    ENotificationDataProperties["entityChangedEvent"] = "entityChangedEvent";
    ENotificationDataProperties["messageId"] = "messageId";
})(ENotificationDataProperties = exports.ENotificationDataProperties || (exports.ENotificationDataProperties = {}));
var EIdTypes;
(function (EIdTypes) {
    EIdTypes["mID"] = "mID";
    EIdTypes["replyMID"] = "replyMID";
    EIdTypes["recalledMID"] = "recalledMID";
    EIdTypes["iD"] = "iD";
    EIdTypes["messageId"] = "messageId";
})(EIdTypes = exports.EIdTypes || (exports.EIdTypes = {}));
var SendMessageType;
(function (SendMessageType) {
    SendMessageType[SendMessageType["RegularMessage"] = 0] = "RegularMessage";
    SendMessageType[SendMessageType["Chat"] = 1] = "Chat";
    SendMessageType[SendMessageType["Broadcast"] = 3] = "Broadcast";
    SendMessageType[SendMessageType["LogNotes"] = 10] = "LogNotes";
    SendMessageType[SendMessageType["HoldingStatement"] = 9] = "HoldingStatement";
})(SendMessageType = exports.SendMessageType || (exports.SendMessageType = {}));
var ENotificationType;
(function (ENotificationType) {
    ENotificationType["message"] = "message";
    ENotificationType["checklist"] = "checklist";
    ENotificationType["IamOk"] = "IamOk";
    ENotificationType["onCallAlert"] = "onCallAlert";
})(ENotificationType = exports.ENotificationType || (exports.ENotificationType = {}));
