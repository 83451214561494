import {
  BottomRow,
  LeftContainer,
  MessagesTextContainer,
  Row,
  SimpleText,
} from './SelectGroupList.styles';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { isNil } from 'lodash';
import SCheckbox from '../FilterItem/SBoxButton';
import { trunctateText } from '../../utils/truncate';
import { translate } from '../../utils/translate';
import { SelectItemContainer } from './UserSelectItem.styles';

export interface IGroupProps {
  name: string;
  membersCount: number;
  onCardPress: () => void;
  isSelected: boolean;
  type: string | null;
  disable?: boolean;
  photoFileName?: string;
  id?: string;
}

export const GroupSelectItem = (props: IGroupProps) => {
  const { name, membersCount, onCardPress, isSelected, type, disable, photoFileName, id } = props;

  const subtitle = type
    ? type
    : `${membersCount} ${membersCount === 1 ? translate('member') : translate('groups_members')}`;

  return (
    <SelectItemContainer
      onClick={() => !disable && onCardPress()}
      className="item-container"
      role={`groupSelectItem-${name}`}
      id={id ?? `group-${name}`}
    >
      <BottomRow role="itemContainer">
        <LeftContainer role="imageContainer">
          <ProfilePicture profilePictureFileName={photoFileName || null} isGroup />
        </LeftContainer>
        <MessagesTextContainer role="titleTextContainer">
          <SimpleText className="groupName" margin="0.2rem 0 0" fontSize={'0.8rem'} role="name">
            {trunctateText(name, 50)}
          </SimpleText>
          <Row role="membersCountOrType">
            {!isNil(membersCount) && (
              <SimpleText fontSize={'0.7rem'} gray role="subtitle">
                {subtitle}
              </SimpleText>
            )}
          </Row>
        </MessagesTextContainer>
        <SCheckbox isChecked={isSelected} disabled={disable} />
      </BottomRow>
    </SelectItemContainer>
  );
};
