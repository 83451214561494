import { decode } from 'html-entities';
import Autolinker from 'autolinker';
import parse from 'html-react-parser';
import { useState } from 'react';
import { translate } from '../../utils/translate';
import Options, { OptionItemProps } from '../Options/Options';

import Copy from '../../assets/imgs/chats/copy.svg';
import GreenCheck from '../../assets/imgs/chats/green-check.svg';
import Call from '../../assets/imgs/chats/call.svg';
import { HighlightSearchTerm } from '../Checklists/HighlightSearchTerm/HighlightSearchTerm';

interface HyperLinkTextProps {
  text: string;
  searchTerm?: string;
}

function HyperLinkText(props: HyperLinkTextProps) {
  const { text, searchTerm } = props;

  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [copyState, setCopyState] = useState<string>(Copy);

  const copyText = () => {
    if (selectedLink) {
      navigator.clipboard.writeText(selectedLink);
      setCopyState(GreenCheck);

      setTimeout(() => {
        setSelectedLink(null);
        setCopyState(Copy);
      }, 1000);
    }
  };

  const optionsBottomSheet: OptionItemProps[] = [
    {
      name: `${translate('call')} ${selectedLink}`,
      icon: Call,
      callback: () => {
        window.location.href = `tel:${selectedLink}`;
      },
    },
    {
      name: 'messages_copy',
      icon: copyState,
      callback: copyText,
    },
  ];

  const options = {
    replace: (domNode: any) => {
      if (domNode.parent?.name === 'button') {
        return <span onClick={() => setSelectedLink(domNode.data)}>{domNode.data}</span>;
      }
    },
  };

  const getHtml = () => {
    return parse(
      Autolinker.link(decode(text), {
        email: true,
        phone: true,
        urls: { schemeMatches: true, tldMatches: true, ipV4Matches: false },
        stripPrefix: true,
        newWindow: true,
        // defined in globalStyle.ts
        className: 'hyperlink',
        sanitizeHtml: true,
        replaceFn: function (match) {
          // in case we have a phone, let's show a dropdown menu
          if (match.type === 'phone') {
            const tag = match.buildTag();
            tag.setTagName('button');
            return tag;
          }
          // else just show regular hyperlink
          return;
        },
      }),
      options
    );
  };

  return (
    <span>
      {HighlightSearchTerm(getHtml(), searchTerm)}
      <Options
        items={optionsBottomSheet}
        isOpen={selectedLink !== null}
        setIsOpen={() => setSelectedLink(null)}
      />
    </span>
  );
}

export default HyperLinkText;
