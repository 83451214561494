import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const Container = styled.div<{ isClickable: boolean }>`
  cursor: ${props => props.isClickable && 'pointer'};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto-Regular';
  .endDate {
    margin-top: ${toRem(8)};
    font-size: ${toRem(13)};
    line-height: ${toRem(15)};
    color: ${palette.ashesGray};
    text-align: center;
  }
`;

export const SStatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .status {
    font-size: ${toRem(15)};
    line-height: ${toRem(18)};
  }
`;

export const SCircle = styled.div<{ isAvailable: boolean }>`
  height: ${toRem(14)};
  width: ${toRem(14)};
  margin-right: ${toRem(12)};
  border-radius: 50%;
  border: ${toRem(4)} solid ${props => (props.isAvailable ? palette.applGreen : palette.queenBlue)};
`;
