import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { SButtonWrapper, SCancelButton, SConfirmButton } from '../ConfirmDialog/style';

export const SModalSButtonWrapper = styled(SButtonWrapper)`
  justify-content: space-between;
  margin-top: 35;
`;

export const SModalCancelButton = styled(SCancelButton)`
  margin-right: 10;
  min-width: 9rem;
`;

export const SModalConfirmButton = styled(SConfirmButton)`
  margin-left: 10;
  min-width: 9rem;
`;

export const SAlertTitle = styled.p`
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 2rem;
`;

export const SAlertForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SFieldInput = styled.div`
  width: 100%;
  input {
    font-size: 16px;
    color: ${props => palette.white};
  }
`;

export const SAlertError = styled.span`
  position: absolute;
  display: flex;
  justtify-content: flex-start;
  align-items: center;
  font-size: 13px;
  margin: 10px 0;
  color: ${props => palette.tartOrange};
`;
