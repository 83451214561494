import { useLocation } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import CreateMessageSummary from '../containers/CreateMessage/CreateMessageSummary';

export const CreateMessageNew = () => {
  const location = useLocation();
  const prevPath = location.state?.prevPath;
  const disableRecipientsChange = location.state?.disableRecipientsChange;

  return (
    <Layout to={prevPath ?? '/createMessage'} isMessageLayout message={'messages_createMessage'}>
      <CreateMessageSummary disableRecipientsChange={disableRecipientsChange} />
    </Layout>
  );
};
