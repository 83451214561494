import { ReadReceiptDateElementType } from '../../components/ReadReceiptUserThumb/ReadReceiptUserThumb';
import {
  checkIfDateIsToday,
  dateFormats,
  getDateFormatCustom,
  getNumbersBasedDateTextByLanguage,
} from '../../utils/date';
import { MessagesRecipients, ReadReceipt } from '../ChatsList/chatListSlice/types';

export const generateReadUserCountsForTranslation = (
  isReplyExpected: boolean,
  readReceipt: ReadReceipt | undefined,
  messageReceipt: MessagesRecipients[]
): {
  count: number;
  total: number;
} => {
  const readCount = isReplyExpected
    ? readReceipt?.reads.length ?? 0
    : messageReceipt.filter(user => user.read).length;

  const totalUsers = isReplyExpected
    ? readReceipt
      ? readReceipt.notReads.length + readReceipt.reads.length
      : 0
    : messageReceipt.length;
  return { count: readCount, total: totalUsers };
};

export const generateReadDateElement = (readTime: string): ReadReceiptDateElementType => {
  return checkIfDateIsToday(readTime)
    ? { time: getDateFormatCustom(readTime, dateFormats.simpleTime24) }
    : {
        time: getDateFormatCustom(readTime, dateFormats.simpleTime24),
        date: getNumbersBasedDateTextByLanguage({ dateText: readTime, excludeTime: true }),
      };
};
