import React from 'react';
import { isNil } from 'lodash';
import { GroupItemContainer } from './AlarmGroupSelectItem.styles';
import { trunctateText } from '../../utils/truncate';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import SCheckbox from '../FilterItem/SBoxButton';
import {
  BottomRow,
  LeftContainer,
  MessagesTextContainer,
  Row,
  SimpleText,
} from '../Chat/SelectGroupList.styles';
import { translate } from '../../utils/translate';

interface AlarmGroupSelectItemProps {
  id: number;
  name: string;
  checked: boolean;
  onCardPress: () => void;
  membersCount: number;
  type: string | null;
  disable: boolean;
  isPreselected?: boolean;
}

function AlarmGroupSelectItem(props: AlarmGroupSelectItemProps) {
  const { id, name, checked, onCardPress, membersCount, disable, type, isPreselected } = props;

  const subtitle = type
    ? type
    : `${membersCount} ${membersCount === 1 ? translate('member') : translate('groups_members')}`;

  const handleClick = () => {
    if (!disable) {
      onCardPress();
    }
  };

  return (
    <GroupItemContainer id={`groupCard-${id}`} role="groupContainer" onClick={handleClick}>
      <BottomRow role="bottomRow">
        <LeftContainer role="imageContainer">
          <ProfilePicture profilePictureFileName={''} isGroup />
        </LeftContainer>
        <MessagesTextContainer role="infoContainer">
          <SimpleText margin="0.2rem 0 0" fontSize={'0.8rem'} role="title">
            {trunctateText(name, 50)}
          </SimpleText>
          <Row role="subtitleContainer">
            {!isNil(props) && (
              <SimpleText role="subtitle" fontSize={'0.7rem'} gray>
                {subtitle}
              </SimpleText>
            )}
          </Row>
        </MessagesTextContainer>
        <SCheckbox isChecked={checked} disabled={disable} grey={isPreselected} />
      </BottomRow>
    </GroupItemContainer>
  );
}

export default AlarmGroupSelectItem;
