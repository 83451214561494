import { batch } from 'react-redux';
import { createTestAlarm, sendTestAlarmResult } from '../../../../apis/testAlarmApi';
import { getDeviceToken } from '../../../../notifications/device.service';
import { AppThunk } from '../../../../store';
import { setIsTestAlarmLoading, setTestId } from './index';

export const startTestAlarm = (): AppThunk => async dispatch => {
  try {
    dispatch(setIsTestAlarmLoading(true));
    const res = await createTestAlarm(getDeviceToken());
    if (res) {
      batch(() => {
        dispatch(setTestId(res.testId));
        dispatch(setIsTestAlarmLoading(false));
      });
    } else {
      dispatch(setIsTestAlarmLoading(false));
    }
  } catch (error) {
    dispatch(setIsTestAlarmLoading(false));
    console.log('error log ', error);
  }
};

export const reportTestAlarmResult =
  (success: boolean, testId: number | null): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsTestAlarmLoading(true));
      if (testId) {
        await sendTestAlarmResult(success, testId);
      }
      dispatch(setIsTestAlarmLoading(false));
    } catch (error) {
      dispatch(setIsTestAlarmLoading(false));
      console.log('error log ', error);
    }
  };
