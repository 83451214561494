//ipcRenderer.send reusable function
export const ipcRendererSend = (signalName: string, payload: any = null) => {
  const { ipcRenderer } = window.require('electron');
  if (signalName) ipcRenderer.send(signalName, payload);
};
//ipcRenderer.on reusable function
export const ipcRenderOn = (signalName: string, callbackFunction?: any) => {
  const { ipcRenderer } = window.require('electron');
  if (signalName)
    ipcRenderer.on(signalName, (_, payload) => {
      if (callbackFunction) callbackFunction(payload);
    });
};
export const removeIpcListener = (listenerName: string, callbackFunction: () => void) => {
  const { ipcRenderer } = window.require('electron');
  ipcRenderer.removeListener(listenerName, callbackFunction);
};
