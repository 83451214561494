import React from 'react';
import { translate } from '../../utils/translate';
import { ImOkEnded } from './ImOk.styles';

const ImOkStatusMessage = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <ImOkEnded role="endedMessage" ref={ref}>
      {translate('imOk_ended')}
    </ImOkEnded>
  );
});

export default ImOkStatusMessage;
