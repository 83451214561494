import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLongClickEvent from '../../utils/customHooks/useLongClickEvent';
import { dateFormats, getDateFormat } from '../../utils/date';
import { translate } from '../../utils/translate';
import { setIncomingMessages } from '../ChatsList/chatListSlice';
import { getAlarmData, getIsLoading, getLocationData, setAlarmData } from './PanicButtonSlice.tsx';
import {
  createAlarm,
  deactivateAlarm,
  clearCreateData,
} from './PanicButtonSlice.tsx/actionCreators';
import { PanicAlarmActivationData, PanicAlarmState } from './PanicButtonSlice.tsx/types';
import {
  SLoaderContainer,
  SMsgText,
  STimerBtn,
  STimerContent,
  STimerOperationsContainer,
  STimerScreenContainer,
  STimerText,
} from './panicButton.style';

enum DisplayedButton {
  Recall = 'recall',
  Unlock = 'unlock',
}

export const PanicButtonTimer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLongClick, handleClickDown, handleClickUp } = useLongClickEvent();

  const alarmData = useAppSelector(getAlarmData);
  const isLoading = useAppSelector(getIsLoading);
  const location = useAppSelector(getLocationData);

  const secondsForCountDown = 3;
  const [seconds, setSeconds] = useState(0);
  const [currentTime, setCurrentTime] = useState('');
  const [isLongPressActive, setIsLongPressActive] = useState(false);
  const [countDownSeconds, setCountDownSeconds] = useState(secondsForCountDown);
  const [displayedButton, setDisplayedButton] = useState<DisplayedButton | null>(null);
  const [alarmTriggerText, setAlarmTriggerText] = useState(null);

  const hideButton = () => {
    setTimeout(() => {
      setDisplayedButton(null);
    }, 3000);
  };

  const enableUnlockBtn = () => {
    if (!isLongPressActive) {
      setDisplayedButton(DisplayedButton.Unlock);
      hideButton();
    }
  };

  const sendAlarm = () => {
    if (!alarmData && !location) {
      return;
    }
    dispatch(
      createAlarm((isSuccess: boolean) => {
        if (isSuccess) {
          enableUnlockBtn();
          setAlarmTriggerText(t('alarm_succesfully_triggered'));
        } else {
          setAlarmTriggerText(t('alarm_sending_failed'));
        }
        setTimeout(() => {
          setAlarmTriggerText(null);
          setIsLongPressActive(false);
        }, 2000);
      })
    );
  };

  const onScreenLongPress = () => {
    if (alarmData?.alarmStatus !== PanicAlarmState.Active) {
      setIsLongPressActive(false);
      return;
    }
    setIsLongPressActive(true);
  };

  useEffect(() => {
    const panicButtonDataFromStorage = localStorage.getItem('panicButtonData');
    if (!panicButtonDataFromStorage) {
      localStorage.setItem(
        'panicButtonData',
        JSON.stringify({
          alarmData: JSON.stringify(alarmData),
          location: JSON.stringify(location),
        })
      );
    }
    const interval = setInterval(() => {
      setCurrentTime(`${getDateFormat(new Date().toISOString(), dateFormats.simpleTime24)}`);
    }, 1000);
    return () => {
      clearInterval(interval);
      dispatch(clearCreateData());
    };
  }, []);

  useEffect(() => {
    if (+seconds.toFixed(0) > 0) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(+seconds.toFixed(0) - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);

  useEffect(() => {
    if (isLongPressActive && !displayedButton) {
      let timeleft = countDownSeconds;
      const interval = setInterval(() => {
        timeleft--;
        if (timeleft === 0) {
          sendAlarm();
        }
        if (timeleft >= 0) {
          setCountDownSeconds(timeleft);
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCountDownSeconds(secondsForCountDown);
    }
  }, [isLongPressActive, displayedButton]);

  useEffect(() => {
    if (isLongClick) {
      onScreenLongPress();
    }
  }, [isLongClick]);

  const onPressOut = () => {
    if (countDownSeconds > 0) {
      setIsLongPressActive(false);
    }
  };

  const enableRecall = () => {
    if (!isLongPressActive) {
      setDisplayedButton(DisplayedButton.Recall);
      hideButton();
    }
  };

  const onScreenPress = () => {
    if (!location && !alarmData) {
      enableUnlockBtn();
    }
    if (alarmData && !displayedButton) {
      if (alarmData.alarmStatus === PanicAlarmState.Unlocked) {
        enableUnlockBtn();
      } else {
        enableRecall();
      }
    }
  };

  const onDeactivateAlarm = () => {
    localStorage.removeItem('panicButtonData');
    if (!alarmData) return;
    const deactivateAlarmData: PanicAlarmActivationData = {
      text: alarmData.text,
      groupIds: alarmData.groupIds,
      userIds: alarmData.recipientIds,
    };
    dispatch(deactivateAlarm(deactivateAlarmData, navigate, '/dashboard'));
  };

  const onUnlockAlarm = () => {
    localStorage.removeItem('panicButtonData');
    if (!alarmData) {
      navigate('/');
      return;
    }
    navigate(`/message/${alarmData.messageId}`);
    dispatch(setAlarmData(null));
    dispatch(setIncomingMessages(true));
  };

  const onMouseUp = (event: unknown) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleClickUp(event);
    onPressOut();
  };

  if (isLoading) {
    return (
      <SLoaderContainer role="loaderContainer">
        <Loader />
      </SLoaderContainer>
    );
  }

  return (
    <STimerScreenContainer
      onClick={onScreenPress}
      onMouseDown={handleClickDown}
      onMouseUp={onMouseUp}
      role="timerScreenContainer"
    >
      <STimerContent role="timerContent">
        {isLongPressActive && countDownSeconds > 0 && (
          <STimerText role="countDownSeconds">{countDownSeconds.toString()}</STimerText>
        )}
        <STimerOperationsContainer role="operationsList">
          {alarmTriggerText && <SMsgText role="triggerText">{alarmTriggerText}</SMsgText>}
          {!displayedButton && !isLongPressActive && (
            <STimerText role="currentTime">{currentTime}</STimerText>
          )}
          {displayedButton === DisplayedButton.Recall && !isLongPressActive && (
            <STimerBtn className="recall-btn" onClick={onDeactivateAlarm} role="deactivateButton">
              {translate('alarm_deactivate')}
            </STimerBtn>
          )}
          {displayedButton === DisplayedButton.Unlock && (
            <STimerBtn className="deactive-btn" onClick={onUnlockAlarm} role="recallButton">
              {translate('alarm_unlock')}
            </STimerBtn>
          )}
        </STimerOperationsContainer>
      </STimerContent>
    </STimerScreenContainer>
  );
};
