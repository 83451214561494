import * as React from 'react';
import {
  SColumnsContainers,
  SInfo,
  SInfoContainer,
  SName,
  SRowContainers,
} from '../../containers/GroupDetail/GroupDetail.styles';
import { getImageLink } from '../../utils/formatImageLink';
import { translate } from '../../utils/translate';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { HighlightSearchTerm } from '../Checklists/HighlightSearchTerm/HighlightSearchTerm';
interface IGroupListItemProps {
  name: string;
  memberCount: number;
  hidden: boolean;
  groupID: number;
  imageFileName: string;
  account: string;
  showAccName: boolean;
  onClick: (groupID: number) => void;
  searchText: string;
}

const GroupListItem = (props: IGroupListItemProps) => {
  const { name, memberCount, onClick, groupID, imageFileName, account, showAccName, searchText } =
    props;

  return (
    <SColumnsContainers
      onClick={() => onClick(groupID)}
      id={`groupContainer-${groupID}`}
      role={`groupContainer-${name}`}
    >
      <ProfilePicture
        readyPhotoSource={getImageLink({
          imageName: imageFileName,
          size: 'small',
        })}
        profilePictureFileName={''}
        isGroup
      />
      <SRowContainers role="groupInfoContainer">
        <SName role="groupName">{HighlightSearchTerm(name, searchText)}</SName>
        {showAccName && account && (
          <SInfoContainer role="broadcastAccount" marginTop={false}>
            <SInfo>
              {translate('messages_broadcast_account')}: {account}
            </SInfo>
          </SInfoContainer>
        )}
        <SInfoContainer marginTop role="membersCount">
          <SInfo>
            {memberCount === 1 ? translate('groups_member') : translate(`groups_members`)}:{' '}
            {memberCount}
          </SInfo>
        </SInfoContainer>
      </SRowContainers>
    </SColumnsContainers>
  );
};

export default GroupListItem;
