import { EmergencyType } from '../../containers/Alarm/AlarmSlice/types';

export const getAlarmTitle = (alarmTypeItem: EmergencyType, currentLang: string) => {
  if (!alarmTypeItem) {
    return '';
  }
  const langRes = alarmTypeItem.Names?.find(element => element.Language === currentLang);
  if (!langRes) {
    return alarmTypeItem?.Names?.length ? alarmTypeItem.Names[0]?.Name : '';
  }
  return langRes?.Name;
};
