import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { Backdrop } from '../Backdrop/Backdrop';
import FilterCheckboxItem from '../FilterItem/FilterCheckboxItem';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { FilterSection, SectionFilterItem } from '../SearchFilterBar/SearchFilterBar';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { toRem } from '../../utils/toRem';
import { ResetFiltersButton } from '../Button/ResetFiltersButton';

const SFlexDiv = styled.div`
  flex: 1;
`;

const SBackDrop = styled(Backdrop)`
  z-index: 9998;
`;

const SFilter = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  padding: 20px;
  padding-bottom: ${toRem(30)};
  transform: translate(-50%);
  background-color: ${props => palette.prussianBlue2};
  max-height: 75vh;
  max-width: 26rem;
  width: 100%;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const SHeader = styled.svg`
  width: 135px;
  height: 5px;
  place-self: center;
  margin-bottom: 30px;
  border-radius: 5px;
`;

const SLabel = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${palette.white};
  margin-bottom: 24px;
`;

const SLine = styled.hr`
  border: 1px solid ${palette.davysGrey10};
  margin-top: 18px;
  margin-bottom: 18px;
`;

const SButton = styled(Button)<{ $showReset?: boolean }>`
  width: 100%;
  margin-top: ${props => (props.$showReset ? '0' : '1.625rem')};

  button {
    margin: auto;
    width: 100%;
    max-width: 100rem;
    font-size: 1rem;
    padding: 0.8125rem 0;
    font-family: 'Roboto-Medium';
    color: ${palette.raisinBlack3};
  }
`;

interface MessageFilterProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filters: FilterSection[];
  onFilter: (value: FilterSection[]) => void;
  setCheckedState: (value: FilterSection[]) => void;
  label: string;
  setTabBar?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | undefined;
}

export const MessageFilter = (props: MessageFilterProps) => {
  const { isOpen, setIsOpen, onFilter, setCheckedState, filters, setTabBar, className } = props;
  const layout = useLayoutContext();
  const [keyReload, setKeyReload] = useState(0);
  const [localFilters, setLocalFilters] = useState<FilterSection[]>([...filters]);
  const displayResetButton = useMemo(() => {
    const selectionItems = localFilters?.length ? localFilters[0].content : null;
    if (!selectionItems) {
      return false;
    }
    const selectedItems = selectionItems.filter(item => item.checked);
    return selectedItems?.length !== 1 || selectedItems[0].id !== 0;
  }, [keyReload, localFilters]);

  useEffect(() => {
    if (isOpen) setLocalFilters(filters);
  }, [isOpen]);

  useLayoutEffect(() => {
    if (setTabBar) setTabBar(!isOpen);
    else layout.setTabsState(!isOpen);
  }, [isOpen, setTabBar]);

  const handleCheckbox = (option: SectionFilterItem, key: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newCheckedState = structuredClone(localFilters);
    newCheckedState[key].content[option.id].checked = !localFilters[key].content[option.id].checked;
    setLocalFilters(newCheckedState);
    setKeyReload(keyReload + 1);
  };

  const handleReset = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newCheckedState = structuredClone(localFilters);
    newCheckedState[0].content[0].checked = true;
    newCheckedState[0].content[1].checked = false;
    setCheckedState(newCheckedState);
    setLocalFilters(newCheckedState);
    setKeyReload(keyReload + 1);
    onFilter(newCheckedState);
  };

  const handleProceed = () => {
    setCheckedState(localFilters);
    onFilter(localFilters);
  };

  const { t } = useTranslation();
  return (
    <>
      {isOpen && (
        <>
          <SFilter className={className} key={keyReload} role="messageFilter">
            <SHeader role="header">
              <rect width="400" height="100" fill={palette.silver} />
            </SHeader>
            {localFilters.map((filter, key) => (
              <div key={key}>
                {key !== 0 && <SLine role="line" />}
                <SLabel role="title">{t(`${filter.title}`)}</SLabel>
                <ul role="filtersList">
                  {filter.content.map((option, key2) => (
                    <FilterCheckboxItem
                      checked={option.checked}
                      name={option.name}
                      setSelected={() => handleCheckbox(option, key)}
                      key={key2}
                      id={option.id}
                      type={filter.type === 'checkbox' ? 'box' : 'circle'}
                    />
                  ))}
                </ul>
              </div>
            ))}
            <SFlexDiv />
            <ResetFiltersButton
              isFullWidth
              onClick={handleReset}
              isDisplayed={displayResetButton}
              marginTop="1.625rem"
              marginBottom="0.8125rem"
            />
            <SButton
              id="messageFilterButton"
              key={props.label}
              onClick={handleProceed}
              tx={props.label}
              $showReset={displayResetButton}
            />
          </SFilter>
          <SBackDrop setModal={setIsOpen} />
        </>
      )}
    </>
  );
};
