import { Layout } from '../components/Layout/Layout';
import { News } from '../containers/News';

export const NewsPage = () => {
  return (
    <Layout isMessageLayout message="news_news" to="/dashboard" showBottomTabs>
      <News />
    </Layout>
  );
};
