import { batch } from 'react-redux';
import { getMeetingRooms } from '../../../apis/meetingRoomAPI';
import { AppThunk } from '../../../store';
import { setIsLoading, setMeetingRooms } from './index';

export const fetchMeetingRooms = (): AppThunk => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    const MeetingRooms = await getMeetingRooms();

    batch(() => {
      dispatch(setMeetingRooms(MeetingRooms));
      dispatch(setIsLoading(false));
    });
  } catch (error) {
    dispatch(setIsLoading(false));
    console.log('error log ', error);
  }
};
export const resetMeetingRoomStoreData = (): AppThunk => async dispatch => {
  dispatch(setMeetingRooms([]));
  dispatch(setIsLoading(false));
};
