import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SMap = styled.div`
  &.fullHeightMap {
    flex-grow: 1;
    height: 100%;
  }
  .gmnoprint {
    display: none;
  }
`;

export const SModalWrapper = styled.div`
  max-width: calc(26rem - 2.5rem);
  padding: 2rem 1.5rem;
  background-color: ${palette.prussianBlue2};
`;

export const SMapWrapper = styled.div<{ maxHeight: string }>`
  position: relative;
  max-height: ${props => props.maxHeight};
  overflow: hidden;
  border-radius: 10px;

  .searchWithButton {
    top: 1rem;
  }
`;

export const SInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    text-align: center;
    margin-bottom: 0.75rem;
  }
  .info {
    font-size: 12px;
    line-height: 1.5;
    white-space: pre-line;
    text-align: center;
  }
`;

export const SButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;

  > div {
    max-width: 135px;
  }
`;
