// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { AlarmStep } from '../../../utils/enums';
import { ActiveAlarmsModal, AlarmState, EmergencyType } from './types';

const initialState: AlarmState = {
  types: [],
  isLoading: false,
  selectedType: null,
  selectedGroups: [],
  alarmCreateModel: null,
  selectedGroupType: [],
  location: null,
  customText: '',
  allSteps: [AlarmStep.SelectType],
  activeAlarmsModalData: { isOpen: false, items: [] },
};

const alarmSlice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    setTypes: (state, action: PayloadAction<EmergencyType[]>) => {
      state.types = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedGroups: (state, action: PayloadAction<number[]>) => {
      state.selectedGroups = action.payload;
    },
    clearSelectedGroups: state => {
      state.selectedGroups = initialState.selectedGroups;
    },
    setAlarmCreateModel: (state, action: PayloadAction<EmergencyType>) => {
      state.alarmCreateModel = action.payload;
    },
    setAlarmAllSteps: (state, action: PayloadAction<number[]>) => {
      state.allSteps = action.payload;
    },
    setAlarmLocation: (state, action: PayloadAction<Location>) => {
      state.location = action.payload;
    },
    setAlarmCustomText: (state, action: PayloadAction<string>) => {
      state.customText = action.payload;
    },
    setSelectedGroupType: (state, action: PayloadAction<number[]>) => {
      state.selectedGroupType = action.payload;
    },
    setActiveAlarmsModalData: (state, action: PayloadAction<ActiveAlarmsModal>) => {
      state.activeAlarmsModalData = action.payload;
    },
    resetAlarmSlice: state => {
      state.types = [];
      state.isLoading = false;
      state.selectedType = null;
      state.selectedGroups = [];
      state.alarmCreateModel = null;
      state.selectedGroupType = [];
      state.location = null;
      state.customText = '';
      state.allSteps = [AlarmStep.SelectType];
      state.activeAlarmsModalData = { isOpen: false, items: [] };
    },
  },
});

// export store actions
export const {
  setTypes,
  setIsLoading,
  setSelectedGroups,
  clearSelectedGroups,
  setAlarmCreateModel,
  setAlarmAllSteps,
  setSelectedGroupType,
  setAlarmLocation,
  setAlarmCustomText,
  setActiveAlarmsModalData,
  resetAlarmSlice,
} = alarmSlice.actions;

// data selecting could be seperated also
export const selectTypes = (state: RootState) => state.alarm.types;
export const selectTypeByID = (state: RootState, typeID: number) =>
  state.alarm.types?.find(type => type.ID === typeID);
export const selectAlarmGroups = (state: RootState) => state.alarm.selectedGroups;
export const isAlarmLoading = (state: RootState) => state.alarm.isLoading;
export const getSelectedGroupType = (state: RootState) => state.alarm.selectedGroupType;
export const getAlarmCreateModel = (state: RootState) => state.alarm.alarmCreateModel;
export const getAlarmLocation = (state: RootState) => state.alarm.location;
export const getAlarmCustomText = (state: RootState) => state.alarm.customText;
export const getAlarmAllSteps = (state: RootState) => state.alarm.allSteps;
export const getActiveAlarmsModalData = (state: RootState) => state.alarm.activeAlarmsModalData;

export default alarmSlice.reducer;
