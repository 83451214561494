import styled, { css } from 'styled-components';
import { TextField } from '../../components/TextField/TextField';
import { palette } from '../../theme/colors';
import { Button } from '../../components/Button/Button';

interface SButtonParams {
  $error: boolean;
}

interface STextFieldParams {
  error?: boolean;
}

export const SUserImg = styled.img`
  margin: 2.25rem auto;
  display: block;
  width: 28%;
  border-radius: 9999px;
  box-shadow: ${props => props.theme.shadow.primary};
`;

export const STitle = styled.h1`
  font-size: 1.125rem;
  font-family: 'Roboto-Medium';
  color: ${palette.silver};
  text-align: center;
  margin: 1rem auto 0 auto;
`;

export const SSubTitle = styled.h2`
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  color: ${palette.silver};
  width: 70%;
  line-height: 1.3;
  opacity: 0.5;
  text-align: center;
  margin: 1.25rem auto;
`;

export const SError = styled.p`
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  color: ${palette.tartOrange};
`;

export const SForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  gap: 0.815rem;
`;

export const SButton = styled(Button)<SButtonParams>`
  width: 100%;
  margin-top: 2.875rem;
  button {
    max-width: 200rem;

    width: 100%;
    font-size: 1rem;
    padding: 0.8125rem 0;
    font-family: 'Roboto-Medium';
    color: ${palette.raisinBlack3};
    ${props =>
      props.$error &&
      css`
        opacity: 0.5;
        cursor: default;
      `}
  }
`;

export const STextField = styled(TextField)<STextFieldParams>`
  input {
    color: ${palette.white};
    font-family: 'Roboto-Regular';
    font-size: 0.9375rem;
    font-weight: 400;
    ${props =>
      props.error &&
      css`
        border-color: ${palette.tartOrange};
      `}
  }
  input::placeholder {
    font-size: 0.9375rem;
    font-weight: 400;
    font-family: 'Roboto-Regular';
    color: ${palette.grayx11gray};
  }
`;
