import { Layout } from '../components/Layout/Layout';
import { MeetingRoomList } from '../containers/MeetingRoom/MeetingRoomList';

export const MeetingRoomPage = () => {
  return (
    <Layout isMessageLayout message="home_meetingRoom" to="/dashboard" showBottomTabs>
      <MeetingRoomList />
    </Layout>
  );
};
