import { Chat, Reply } from '../ChatsList/chatListSlice/types';
import { dateFormats, getDateFormat } from '../../utils/date';
import {
  fetchCurrentChat,
  fetchCurrentChatRepliesWithAttachments,
} from '../ChatsList/chatListSlice/actionCreators';
import {
  selectAreAllRepliesLoaded,
  selectAreRepliesLoading,
  selectCurrentChat,
  selectCurrentChatReplies,
  setAreAllRepliesLoaded,
  setCurrentChatReplies,
} from '../ChatsList/chatListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ChatIcon from '../../assets/imgs/chats/chat.svg';
import { ImagesByMonthList } from './ImagesByMonthList';
import Loader from '../../components/Loader/Loader';
import Options, { OptionItemProps } from '../../components/Options/Options';
import { SPage } from './ChatMedia.styles';
import { getImage } from '../../apis/mediaAPI';
import { t } from 'i18next';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import MediaImagesModal from './MediaImagesModal';

interface ChatMediaImage {
  messageId?: number;
  replyId?: number;
  imageBase64: string;
  senderName: string;
  sent: string;
}

export interface MediaByMonth {
  monthKey: string;
  images: ChatMediaImage[];
}

interface ChatMediaProps {
  optionsOpen: boolean;
  setOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickOptions: React.Dispatch<React.SetStateAction<boolean>>;
}

const REPLIES_PER_PAGE = 15;
export const ChatMedia = (props: ChatMediaProps) => {
  const { optionsOpen, setOptionsOpen, onClickOptions } = props;
  const { id: messageId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const layout = useLayoutContext();
  const currentChat = useSelector(selectCurrentChat);
  const replies = useSelector(selectCurrentChatReplies);
  const areRepliesLoading = useSelector(selectAreRepliesLoading);
  const areAllRepliesLoaded = useSelector(selectAreAllRepliesLoaded);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isLightBoxOpen, setIsLightBoxOpen] = useState<string | null>(null);
  const [imagesToShow, setImagesToShow] = useState<ChatMediaImage[]>([]);
  const [imgKey, setImgKey] = useState(-1);
  const imagesEndRef = useRef<HTMLDivElement>(null);

  const loadImage = async (photoFileName: string, message?: Chat, reply?: Reply) => {
    const messageOrReply = message || reply;
    const res = await getImage({ imageName: photoFileName, size: 'large', svg: false });
    if (!res || !messageOrReply) {
      return null;
    }
    const image: ChatMediaImage = {
      replyId: reply?.id ?? undefined,
      messageId: message?.id ?? undefined,
      imageBase64: res,
      senderName: messageOrReply.senderName,
      sent: messageOrReply.sent,
    };
    return image;
  };

  const getImages = async () => {
    if (currentChat) {
      setIsLoading(true);
      const promises: Promise<ChatMediaImage | null>[] = [];
      currentChat.photoFileNames?.forEach(photoFileName => {
        promises.push(loadImage(photoFileName, currentChat));
      });
      replies
        .filter(reply => reply.photoFileNames && reply.photoFileNames.length)
        .forEach(reply => {
          if (reply.photoFileNames) {
            reply.photoFileNames.forEach(photoFileName => {
              promises.push(loadImage(photoFileName, undefined, reply));
            });
          }
        });
      const response = await Promise.all(promises);
      const filteredResponse = response.filter(Boolean) as ChatMediaImage[];
      filteredResponse.sort((a, b) => new Date(b.sent).getTime() - new Date(a.sent).getTime());
      setImagesToShow(filteredResponse);
      setIsLoading(false);
    }
  };

  const mediaByMonth = useMemo<MediaByMonth[]>(() => {
    if (imagesToShow) {
      const sortedImages = [...imagesToShow];
      sortedImages.sort((a, b) => new Date(b.sent).getTime() - new Date(a.sent).getTime());
      const mediaByMonthObject: { [key: string]: ChatMediaImage[] } = {};
      sortedImages.forEach(reply => {
        const monthKey = reply.sent.slice(0, 7);
        if (!mediaByMonthObject[monthKey]) {
          mediaByMonthObject[monthKey] = [];
        }
        mediaByMonthObject[monthKey].push(reply);
      });
      const mediaByMonthItems: MediaByMonth[] = Object.keys(mediaByMonthObject).map(monthKey => {
        return { monthKey, images: mediaByMonthObject[monthKey] };
      });
      return mediaByMonthItems;
    }
    return [];
  }, [imagesToShow]);

  useEffect(() => {
    dispatch(fetchCurrentChat(messageId || '', undefined, undefined, REPLIES_PER_PAGE, 1));
  }, []);

  useEffect(() => {
    layout.setSubTitle(t('files_count', { count: imagesToShow?.length }));
  }, [imagesToShow]);

  useEffect(() => {
    if (imgKey > -1) {
      const imageData: ChatMediaImage = imagesToShow[imgKey];
      layout.setMessage(t('count_of_total', { count: imgKey + 1, total: imagesToShow.length }));
      layout.setSubTitle(
        `${imageData.senderName}, ${getDateFormat(
          imageData.sent,
          dateFormats.monthNameShortDateTime
        )}`
      );
      layout.setDoShowDots(true);
    } else {
      layout.setMessage(t('chat_media'));
      layout.setSubTitle(t('files_count', { count: imagesToShow?.length }));
      layout.setDoShowDots(false);
      setOptionsOpen(false);
    }
  }, [imgKey]);

  const changeRepliesPage = async () => {
    if (
      imagesEndRef.current &&
      imagesEndRef.current.getBoundingClientRect().y - window.innerHeight < 400 &&
      !areRepliesLoading &&
      !areAllRepliesLoaded
    ) {
      dispatch(fetchCurrentChatRepliesWithAttachments(replies.length, REPLIES_PER_PAGE, 1));
    }
  };

  useEffect(() => {
    getImages();
  }, [replies]);

  const openImageModal = (imageBase64: string, sent: string) => {
    const foundImageIndex = imagesToShow.findIndex(
      img => img.imageBase64 === imageBase64 && img.sent === sent
    );
    if (foundImageIndex > -1) {
      setIsLightBoxOpen(imagesToShow[foundImageIndex].imageBase64);
      setImgKey(foundImageIndex);
    }
  };

  const closeImageModal = () => {
    setImgKey(-1);
    setIsLightBoxOpen(null);
  };

  const goToChatReplyOrMessage = () => {
    const foundChatMediaImage: ChatMediaImage = imagesToShow[imgKey];

    if (foundChatMediaImage) {
      dispatch(setCurrentChatReplies([]));
      dispatch(setAreAllRepliesLoaded(false));
      navigate(`/message/${messageId}`, {
        state: {
          scrollToReplyId: foundChatMediaImage.replyId,
          scrollToMessage: !foundChatMediaImage.replyId,
        },
      });
    }
  };

  const getOptionsItems = () => {
    const itemsList: OptionItemProps[] = [
      {
        name: 'show_in_chat',
        icon: ChatIcon,
        callback: () => goToChatReplyOrMessage(),
      },
    ];
    return itemsList;
  };

  const handleImageChange = (index: number, next: boolean) => {
    if (index > -1) {
      if (next && imagesToShow.length !== index + 1) {
        setImgKey(index + 1);
        setIsLightBoxOpen(imagesToShow[index + 1]?.imageBase64);
      }
      if (!next && index > 0) {
        setImgKey(index - 1);
        setIsLightBoxOpen(imagesToShow[index - 1]?.imageBase64);
      }
    }
  };

  const onScroll = () => {
    changeRepliesPage();
  };

  if (isLoading && !imagesToShow.length) {
    return <Loader />;
  }
  return (
    <SPage>
      <div onScroll={onScroll} onWheel={onScroll}>
        {mediaByMonth.map(media => (
          <div key={media.monthKey}>
            <ImagesByMonthList mediaByMonth={media} onImageClick={openImageModal} />
          </div>
        ))}
        <div ref={imagesEndRef}></div>
        {isLoading && imagesToShow.length && <Loader />}
      </div>

      <Options isOpen={optionsOpen} setIsOpen={onClickOptions} items={getOptionsItems()} />
      <MediaImagesModal
        imgKey={imgKey}
        imagesList={imagesToShow.map(img => img.imageBase64)}
        isOpen={isLightBoxOpen !== null}
        closeModal={closeImageModal}
        handleImageChange={handleImageChange}
      />
    </SPage>
  );
};
