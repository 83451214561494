import { batch } from 'react-redux';
import type { NavigateFunction } from 'react-router-dom';
import { getSingleGroupsMembers } from '../../../apis/createMessageAPI';
import { getGroups } from '../../../apis/groupsAPI';
import { GetGroupsQuery } from '../../../apis/groupsAPI/types';
import { saveLocation } from '../../../apis/locationAPI';
import {
  cancelWorkAlonePersonalAlarm,
  createWorkAlonePersonalAlarm,
  getWorkAlonePersonalAlarmById,
  getWorkAlonePersonalAlarmStatus,
  setOffWorkAlonePersonalAlarm,
  updateWorkAlonePersonalAlarm,
} from '../../../apis/loneWorkerTimerAPI';
import { GetUserQuery, getAllUsers } from '../../../apis/userAPI';
import { AppThunk } from '../../../store';
import { fetchChats } from '../../ChatsList/chatListSlice/actionCreators';
import { setGroupMembers } from '../../CreateMessage/createMessageSlice';
import { GroupMember, Location } from '../../GroupDetail/groupDetailSlice/types';
import { Group } from '../../GroupsList/groupsSlice/types';
import {
  setAlarmData,
  setGroups,
  setGroupsLoading,
  setGroupsMembers,
  setIsDeactivated,
  setIsError,
  setIsLoading,
  setIsLocationDenied,
  setMessageText,
  setOneMinuteWarning,
  setSelectedGroups,
  setSelectedUsers,
  setTime,
  setUsers,
  setUsersLoading,
} from './index';
import { LoneWorkerPersonalAlarm, UpdateTimerData } from './types';
import { SelectListUser } from '../../CreateMessage/createMessageSlice/types';

export const fetchGroups =
  (query?: GetGroupsQuery): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setGroupsLoading(true));
      const currentGroups = getState().loneWorkerTimer.groups || [];
      let allGroups = [...currentGroups];
      const newGroups = await getGroups({
        ...query,
        menuitem: 'group_alarm_work_alone',
        minGroupMembersCount: 2,
        member: true,
      });
      const currentGroupsIdsList = [...currentGroups].map(group => group.id);
      if (query?.skip) {
        const filteredNewGroups = [...newGroups].filter(
          group => !currentGroupsIdsList.includes(group.id)
        );
        allGroups = [...allGroups, ...filteredNewGroups];
      } else {
        allGroups = [...newGroups];
      }
      batch(() => {
        dispatch(setGroups(allGroups));
        dispatch(setGroupsLoading(false));
      });
    } catch (error) {
      dispatch(setGroupsLoading(false));
    }
  };

export const fetchUsers =
  (query?: GetUserQuery): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setUsersLoading(true));
      const currentUsers = getState().loneWorkerTimer.users || [];
      let allUsers = [...currentUsers];
      const newUsers = await getAllUsers(query);
      if (query?.skip) {
        const filteredNewUsers = [...newUsers].filter(
          user => !allUsers.find(u => u.id === user.id)
        );
        allUsers = [...allUsers, ...filteredNewUsers];
      } else {
        allUsers = [...newUsers];
      }
      batch(() => {
        dispatch(setUsers(allUsers));
        dispatch(setUsersLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setUsersLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

const getGroupMembersList = async (selectedGroup: Group, currentUserId: number | undefined) => {
  try {
    const members: GroupMember[] = [];

    const usersInGroup = await getSingleGroupsMembers(selectedGroup.id);
    usersInGroup.forEach((member: GroupMember) => {
      if (member.userID !== currentUserId) {
        members.push({
          ...member,
          isSelected: true,
          groupID: member.groupID,
        });
      }
    });
    return members;
  } catch (error) {
    return [];
  }
};

export const fetchGroupMembers =
  (
    selectedGroup: Group,
    currentUserId: number | undefined,
    onMembersLoad?: (members: GroupMember[]) => void
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const members: GroupMember[] = await getGroupMembersList(selectedGroup, currentUserId);
      if (onMembersLoad) {
        onMembersLoad(members);
      }
      batch(() => {
        dispatch(setGroupsMembers(members));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const clearCreateData = (): AppThunk => async (dispatch, getState) => {
  try {
    const authUser = getState().user.user;
    dispatch(setIsLoading(true));
    if (authUser) {
      dispatch(
        setSelectedUsers([
          {
            id: authUser.id,
            displayName: authUser.name ?? '',
            groupIds: null,
            isSelected: true,
          } as SelectListUser,
        ])
      );
    }
    batch(() => {
      dispatch(setGroups([]));
      dispatch(setUsers([]));
      dispatch(setSelectedGroups([]));
      dispatch(setGroupMembers([]));
      dispatch(setTime({ hours: 0, minutes: 0 }));
      dispatch(setMessageText(''));
      dispatch(setOneMinuteWarning(false));
      dispatch(setIsLoading(false));
      dispatch(setIsLocationDenied(false));
    });
  } catch (error) {
    console.log(error);
    batch(() => {
      dispatch(setIsError(`${error}`));
      dispatch(setIsLoading(false));
    });
  }
};

export const createAlarm =
  (
    alarm: LoneWorkerPersonalAlarm,
    location: Location | null,
    navigate: NavigateFunction
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      if (location) {
        const locationResult = await saveLocation(location);
        alarm.locationId = locationResult?.id;
      }
      const alarmData = await createWorkAlonePersonalAlarm(alarm);
      batch(() => {
        dispatch(setAlarmData(alarmData));
        dispatch(setIsLoading(false));
      });
      clearCreateData();
      navigate(`/aloneWorker/timer`);
    } catch (error) {
      batch(() => {
        dispatch(setIsError(`${error}`));
        dispatch(setIsLoading(false));
      });
    }
  };

export const fetchAlarmData =
  (navigate?: NavigateFunction, fromMainMenu?: boolean): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const alarmData = await getWorkAlonePersonalAlarmStatus();

      if (fromMainMenu) {
        if (alarmData && alarmData.MessageId) {
          await cancelWorkAlonePersonalAlarm();
          // just in case
          localStorage.removeItem('isLoneWorkTimerActive');
        }
      }
      batch(() => {
        dispatch(setAlarmData(alarmData));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setAlarmData(null));
        dispatch(setIsError(`${error}`));
        dispatch(setIsLoading(false));
      });
      localStorage.removeItem('isLoneWorkTimerActive');
      if (navigate) navigate('dashboard');
    }
  };

export const fetchFinishedAlarmData =
  (navigate: NavigateFunction): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      const currentAlarmData = getState().loneWorkerTimer.alarmData;
      const alarmData = await getWorkAlonePersonalAlarmById(currentAlarmData?.Id);
      if (alarmData) {
        batch(() => {
          dispatch(setAlarmData(alarmData));
          dispatch(setIsLoading(false));
        });
      } else {
        batch(() => {
          dispatch(setAlarmData(null));
          dispatch(setIsError(`Alarm does not exist`));
          dispatch(setIsLoading(false));
        });
        navigate('dashboard');
      }
    } catch (error) {
      console.log('Meets error', error);
      batch(() => {
        dispatch(setAlarmData(null));
        dispatch(setIsError(`${error}`));
        dispatch(setIsLoading(false));
      });
      navigate('dashboard');
    }
  };

export const updateTimer =
  (updateTimerData: UpdateTimerData): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const updatedAlarm = await updateWorkAlonePersonalAlarm(updateTimerData);
      batch(() => {
        dispatch(setAlarmData(updatedAlarm));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsError(`${error}`));
        dispatch(setIsLoading(false));
      });
    }
  };

export const deactivateAlarm =
  (navigate: NavigateFunction, path: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await cancelWorkAlonePersonalAlarm();
      navigate(path);
      batch(() => {
        dispatch(setAlarmData(null));
        dispatch(setIsDeactivated(true));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsError(`${error}`));
        dispatch(setIsLoading(false));
      });
    }
  };

export const goToAlarmChat =
  (navigate: NavigateFunction): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      const alarmResponse = await getWorkAlonePersonalAlarmById(
        getState().loneWorkerTimer.alarmData?.Id
      );
      if (alarmResponse?.MessageId) {
        dispatch(fetchChats({}));
        navigate(`/message/${alarmResponse?.MessageId}`);

        // cancel
        cancelWorkAlonePersonalAlarm();
      } else {
        navigate('dashboard');
      }
      batch(() => {
        dispatch(setAlarmData(null));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsError(`${error}`));
        dispatch(setIsLoading(false));
      });
      navigate('dashboard');
    }
  };

export const setOffTimer =
  (navigate: NavigateFunction): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoading(true));
      await setOffWorkAlonePersonalAlarm();
      setTimeout(() => {
        localStorage.removeItem('isLoneWorkTimerActive');
        dispatch(goToAlarmChat(navigate));
      }, 5000);
    } catch (error) {
      batch(() => {
        dispatch(setIsError(`${error}`));
        dispatch(setIsLoading(false));
      });
    }
  };
