import { useTranslation } from 'react-i18next';
import { SDeletedMessageContainer, STextContainer, STimeText } from './DeletedMessage.styles';
import DeletedSign from '../../../assets/imgs/chats/trash-icon--grey.svg';
import { useMessageRefsContext } from '../../../utils/customHooks/MessageRefsContext';
import { useEffect, useRef } from 'react';

export interface DeletedMessageProps {
  sentDateTime?: string;
  isRepliedTo?: boolean;
  id?: number;
}

function DeletedMessage(props: DeletedMessageProps) {
  const { sentDateTime, isRepliedTo, id } = props;

  const { registerRef, unRegisterRef } = useMessageRefsContext();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (id) {
      registerRef(id, containerRef);
    }
    return () => {
      if (id) {
        unRegisterRef(id);
      }
    };
  }, []);

  const { t } = useTranslation();

  return (
    <SDeletedMessageContainer
      isRepliedTo={isRepliedTo}
      ref={containerRef}
      role="deletedMessageContainer"
    >
      <STextContainer>
        <img className="deletedSign" src={DeletedSign} alt="deleted" role="deletedIcon" />
        <i className="deleteText">{t('message_this_deleted')}</i>
      </STextContainer>
      {!!sentDateTime && <STimeText>{sentDateTime}</STimeText>}
    </SDeletedMessageContainer>
  );
}

export default DeletedMessage;
