import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Active from '../../components/Checklists/ActiveTab';
import Ended from '../../components/Checklists/EndedTab';
import Template from '../../components/Checklists/TemplateTab';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchChecklists, fetchChecklistSummary } from './checklistsSlice/actionCreators';
import {
  getActiveChecklist,
  getActiveTab,
  getChecklists,
  isChecklistsLoading,
  reset,
  resetCheckListSelections,
  selectCheckListSummary,
  setActiveTab,
} from './checklistsSlice';
import { ChecklistStatus } from '../../utils/enums';
import ChecklistDetails from '../../components/Checklists/ChecklistDetails/ChecklistDetails';
import Loader from '../../components/Loader/Loader';
import ChecklistFilter from '../../components/Checklists/ChecklistFilter';
import { STab, STabList, STabPanel, STabs } from '../CreateMessage/CreateMessageList';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';

import { debounce } from 'lodash';
import { batch } from 'react-redux';
import {
  checkIfFiltersApplied,
  LocalStorageFiltersKeys,
  useLocalStorageFilters,
} from '../../utils/customHooks/useLocalStorageFilters';
import { transformCheckListFilters } from './helpers';
import { useInterval } from '../../utils/customHooks/useInterval';
import {
  CheckListFilter,
  defaultCheckListFiltersByStatus,
  initialChecklistPagingValues,
  mappedChecklistType,
  filtersSectionsByStatus,
  ChecklistDateSortTypeByStatus,
} from './Checklists.models';
import { SSelect, SSearchFilterBar, SList, SListPage } from './Checklists.styles';

function Checklists() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const layout = useLayoutContext();

  const checklists = useAppSelector(getChecklists);
  const activeChecklist = useAppSelector(getActiveChecklist);
  const checklistSummary = useAppSelector(selectCheckListSummary);
  const isLoading = useAppSelector(isChecklistsLoading);
  const activeTab = useAppSelector(getActiveTab);

  const [searchText, setSearchText] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);

  const [filters, setFilters] = useLocalStorageFilters<CheckListFilter>(
    LocalStorageFiltersKeys.checklists,
    defaultCheckListFiltersByStatus(activeTab)
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [skip, setSkip] = useState<number>(initialChecklistPagingValues.skip);
  const resetSkip = () => setSkip(initialChecklistPagingValues.skip);

  const listContainerRef = useRef<HTMLDivElement>(null);

  const intervalHandler = useCallback(() => {
    batch(() => {
      dispatch(
        fetchChecklists(
          {
            ...initialChecklistPagingValues,
            ...transformCheckListFilters(filters),
            search: searchText,
            type: [mappedChecklistType[activeTab]],
          },
          { is: true, resetSkip }
        )
      );
      dispatch(
        fetchChecklistSummary(
          '' /* 'searchText' -- search should not affect count-numbers */,
          transformCheckListFilters(filters)
        )
      );
    });
  }, [filters, searchText, activeTab]);

  useInterval(intervalHandler, 15000);

  const getSelectedTab = () => {
    if (activeTab === ChecklistStatus.Started) {
      setTabIndex(0);
    }
    if (activeTab === ChecklistStatus.NotStarted) {
      setTabIndex(1);
    }
    if (activeTab === ChecklistStatus.Ended) {
      setTabIndex(2);
    }
  };

  const getCorrectInitialFilters = () => {
    let filtersCopy = { ...filters };
    const sort = filtersCopy.sort;
    const dateSortValues = Object.values(ChecklistDateSortTypeByStatus);
    const isSortedByDate = dateSortValues.includes(sort);
    const correctDateSortValue = ChecklistDateSortTypeByStatus[activeTab];
    const isNotSortedByProperDate = sort !== correctDateSortValue;
    if (isSortedByDate && isNotSortedByProperDate) {
      filtersCopy = { ...filters, sort: correctDateSortValue };
      setFilters(filtersCopy);
    }
    return filtersCopy;
  };

  useEffect(() => {
    const initialFilters = getCorrectInitialFilters();
    getSelectedTab();
    batch(() => {
      dispatch(resetCheckListSelections()); // we could use the one in the below useEffect with the filters as a changing dependency
      // to avoid duplicate requests in the initial , couldn't we?
      dispatch(
        fetchChecklistSummary(
          '' /* 'searchText' -- search should not affect count-numbers */,
          transformCheckListFilters(initialFilters)
        )
      );
      dispatch(
        fetchChecklists({
          ...initialChecklistPagingValues,
          ...transformCheckListFilters(initialFilters),
          type: [mappedChecklistType[activeTab]],
        })
      );
    });
  }, []);

  useEffect(() => {
    batch(() => {
      dispatch(
        fetchChecklistSummary(
          '' /* 'searchText' -- search should not affect count-numbers */,
          transformCheckListFilters(filters)
        )
      );
    });
  }, [filters]);

  useEffect(() => {
    dispatch(reset());
  }, []);

  const handleOpenFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const onSearch = (value: string) => {
    dispatch(
      fetchChecklists({
        ...initialChecklistPagingValues,
        ...transformCheckListFilters(filters),
        search: value,
        type: [mappedChecklistType[activeTab]],
      })
    );
    setSearchText(value);
    resetSkip();
  };

  const handleFilter = (_filters: CheckListFilter) => {
    dispatch(
      fetchChecklists({
        ...initialChecklistPagingValues,
        ...transformCheckListFilters(_filters),
        search: searchText,
        type: [mappedChecklistType[activeTab]],
      })
    );
    setFilters(_filters);
    resetSkip();
    setFilterOpen(false);
  };

  const handleChangeTab = (tab: ChecklistStatus) => () => {
    let filtersCopy = { ...filters };
    const dateSortValues = Object.values(ChecklistDateSortTypeByStatus);
    const isSortedByDate = dateSortValues.includes(filtersCopy.sort);

    if (isSortedByDate) {
      filtersCopy = {
        ...filters,
        sort: ChecklistDateSortTypeByStatus[tab],
      };
      setFilters(filtersCopy);
    }
    dispatch(
      fetchChecklists({
        ...initialChecklistPagingValues,
        ...transformCheckListFilters(filtersCopy),
        search: searchText,
        type: [mappedChecklistType[tab]],
      })
    );

    resetSkip();
    dispatch(setActiveTab(tab));
  };

  const handleScroll = useCallback(
    debounce(
      () => {
        if (listContainerRef.current) {
          listContainerRef.current.scrollBy(0, -200);
        }
        dispatch(
          fetchChecklists(
            {
              ...transformCheckListFilters(filters),
              search: searchText,
              type: [mappedChecklistType[activeTab]],
              limit: initialChecklistPagingValues.limit,
              skip: skip + initialChecklistPagingValues.limit,
            },
            undefined,
            true
          )
        );
        setSkip(prev => prev + initialChecklistPagingValues.limit);
      },
      4000,
      { trailing: false, leading: true }
    ),
    [skip, activeTab, searchText, filters]
  );

  const scrolled = () => {
    if (!listContainerRef.current) {
      return;
    }
    if (skip >= checklists.length) {
      return;
    }
    if (
      listContainerRef.current.offsetHeight + listContainerRef.current.scrollTop + 50 >=
      listContainerRef.current.scrollHeight
    ) {
      handleScroll();
    }
  };

  return (
    <SListPage noBottomPadding>
      <SSelect role="selectionContainer">
        <STabs
          role="tabs"
          defaultFocus={false}
          selectedTabClassName="is-selected"
          selectedTabPanelClassName="is-selected"
          selectedIndex={tabIndex}
          onSelect={index => {
            setTabIndex(index);
          }}
        >
          <STabList>
            <STab className="activeTab" onClick={handleChangeTab(ChecklistStatus.Started)}>
              {t('checklist_active')} {`(${checklistSummary.active})`}
            </STab>
            <STab className="templateTab" onClick={handleChangeTab(ChecklistStatus.NotStarted)}>
              {t('checklist_template')} {`(${checklistSummary.template})`}
            </STab>
            <STab className="endedTab" onClick={handleChangeTab(ChecklistStatus.Ended)}>
              {t('checklist_ended')} {`(${checklistSummary.ended})`}
            </STab>
          </STabList>
          <div>
            <SSearchFilterBar
              onSearch={onSearch}
              handleOpenFilter={handleOpenFilter}
              value={searchText}
              isFilterApplied={checkIfFiltersApplied({
                defaultFilters: defaultCheckListFiltersByStatus(activeTab),
                filters,
              })}
            />
          </div>

          <STabPanel>
            {activeTab === ChecklistStatus.Started && (
              <SList onScroll={scrolled} ref={listContainerRef} role="checklistsItemsList">
                {isLoading ? (
                  <Loader />
                ) : (
                  <Active
                    checklists={checklists}
                    onDotsClick={layout.toggleHeaderMenuVisability}
                    searchText={searchText}
                  />
                )}
              </SList>
            )}
          </STabPanel>
          <STabPanel>
            {activeTab === ChecklistStatus.NotStarted && (
              <SList onScroll={scrolled} ref={listContainerRef}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Template
                    checklists={checklists}
                    onDotsClick={layout.toggleHeaderMenuVisability}
                    searchText={searchText}
                  />
                )}
              </SList>
            )}
          </STabPanel>
          <STabPanel>
            {activeTab === ChecklistStatus.Ended && (
              <SList onScroll={scrolled} ref={listContainerRef}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Ended
                    checklists={checklists}
                    onDotsClick={layout.toggleHeaderMenuVisability}
                    searchText={searchText}
                  />
                )}
              </SList>
            )}
          </STabPanel>
        </STabs>
      </SSelect>
      <ChecklistDetails
        isOpen={layout.isMenuOpen}
        setIsOpen={layout.toggleHeaderMenuVisability}
        data={activeChecklist}
      />
      <ChecklistFilter
        isOpen={filterOpen}
        setIsOpen={setFilterOpen}
        filtersSections={filtersSectionsByStatus(activeTab)}
        onFilter={handleFilter}
        initialSelected={filters}
        checklistsStatus={activeTab}
      />
    </SListPage>
  );
}

export default Checklists;
