import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { checkAndConvertSetsInObject, checkAndRevertObjectToSet } from '../convertSetsInObject';

import { getItem, saveItem } from '../storage';
import { deepEqualUnordered } from '../checkIfIsEqual';

export const APP_FILTERS_KEY = 'APP_FILTERS';
const SET_TRANSFORMED_KEY = '__SET_TRANSFORMED__';
export enum LocalStorageFiltersKeys {
  documents = 'Documents',
  // [TODO] try to find a way to have both filters and sorting saved under the same key "documents"
  documentsSorting = 'DocumentsSorting',
  checklists = 'Checklists',
  groups = 'Groups',
  news = 'News',
  alarmsLis = 'alarms',
}

const getStorageFilters = <T,>(key: string, defaultValue: T): T => {
  // getting stored value
  const savedAppFilters = getItem(APP_FILTERS_KEY);
  if (!savedAppFilters) return defaultValue;
  const savedFilters = savedAppFilters[key];
  if (!savedFilters) return defaultValue;
  const initial = JSON.parse(savedFilters);
  return checkAndRevertObjectToSet(initial, SET_TRANSFORMED_KEY) || defaultValue;
};

export const useLocalStorageFilters = <T extends { [index: string]: any }>(
  key: LocalStorageFiltersKeys,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [filters, setFilter] = useState<T>(() => {
    return getStorageFilters(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    saveItem(APP_FILTERS_KEY, {
      ...getItem(APP_FILTERS_KEY),
      [key]: JSON.stringify(checkAndConvertSetsInObject(filters, SET_TRANSFORMED_KEY)),
    });
  }, [key, filters]);

  return [filters, setFilter];
};

interface CheckIfFiltersAppliedParams<T> {
  defaultFilters: T;
  filters: T;
}
export const checkIfFiltersApplied = <T extends { [index: string]: any }>(
  params: CheckIfFiltersAppliedParams<T>
): boolean => {
  const { defaultFilters, filters } = params;

  return !deepEqualUnordered(defaultFilters, filters);
};
