import React from 'react';
import {
  BottomRow,
  LeftContainer,
  MessagesTextContainer,
  OnCallAlertContainer,
  Row,
  SGroupsTitle,
  SimpleText,
  Subject,
  WhiteSpan,
} from './OnCallAlertItem.styles';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { useAppSelector } from '../../hooks';
import { selectGroups } from '../../containers/GroupsList/groupsSlice';
import _ from 'lodash';
import { checkIfDateIsToday, dateFormats, getDateFormatCustom } from '../../utils/date';
import { OnCallAlertDocument } from '../../containers/OnCallAlertList/onCallAlertSlice/types';
import { translate } from '../../utils/translate';

export interface OnCallAlertProps {
  onCallAlert: OnCallAlertDocument;
}

export const OnCallAlertItem = (props: OnCallAlertProps) => {
  const { onCallAlert } = props;
  const {
    subject,
    ended,
    groupName,
    started,
    groupId,
    text,
    groupImage,
    id,
    senderName,
    totalRecipients,
    totalAnswered,
  } = onCallAlert;
  const groupsList = useAppSelector(selectGroups);
  const group = _.find(groupsList, g => g.id === groupId);
  const dateFormat = checkIfDateIsToday(started || '')
    ? dateFormats.simpleTime24
    : dateFormats.monthNameShortDateTimeNoComma24;

  return (
    <OnCallAlertContainer to={`/oncall/${id}`} id={`onCallAlertCard-${id}`}>
      <Row role="topRow">
        <Subject ended={ended} role="subject">
          {subject || translate('onCallAlert_screen')}
        </Subject>
        <SimpleText fontSize={'0.7rem'} role="responsesText">{`${totalAnswered || 0}/${
          totalRecipients || 0
        } ${translate('imOk_okay')}`}</SimpleText>
      </Row>
      <BottomRow role="infoRow">
        <LeftContainer role="imageContainer">
          <ProfilePicture
            profilePictureFileName={group?.imageFileName || groupImage || ''}
            isGroup
          />
        </LeftContainer>
        <MessagesTextContainer role="infoContainer">
          <SGroupsTitle role="title">{groupName}</SGroupsTitle>
          <Row role="bottomRow">
            <SimpleText role="messageTextContainer" fontSize={'0.7rem'} gray>
              {senderName}: <WhiteSpan role="messageText">{text}</WhiteSpan>
            </SimpleText>
            <SimpleText fontSize={'0.6rem'} role="dateContainer">
              <WhiteSpan role="dateText">
                {getDateFormatCustom(started || '', dateFormat)}
              </WhiteSpan>
            </SimpleText>
          </Row>
        </MessagesTextContainer>
      </BottomRow>
    </OnCallAlertContainer>
  );
};
