/* eslint-disable import/named */

import { AxiosError, AxiosResponse } from 'axios';
import { checkNetworkAvailability } from '../../containers/CheckAppAvailable/helpers';
import { deleteItem } from '../../utils/storage';

export const handleResponse = (response: AxiosResponse) => {
  if (response.data) {
    return response.data;
  }
  return response;
};

/**
 * Handle errors by type
 *
 * @param {AxiosError} error - some error
 * @param {boolean} returnErrorResponseData - tells function to return error response data object
 * @return {void}
 */
export const handleError = (error: AxiosError, returnErrorResponseData?: boolean) => {
  const response = error.response;
  if (response) {
    // Request made and server responded
    //can't be read because of missing security headers
    if (response.status === 401 && response.data === 'Invalid CSRF') {
      checkNetworkAvailability({ errorType: 401 });
    }
    // response code 500 or 404
    if (response.status === 500 || response.status === 404) {
      checkNetworkAvailability({ errorType: response.status });
    }
  } else if (error.request) {
    // no response at all
    checkNetworkAvailability({ errorType: 'MISSING_NETWORK' });
  }
  // check if status is 401 and remove user so we are able to go to login page
  if (
    response?.status === 401 &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ['unauthorized-access', 'invalid-session'].includes(response?.data?.Code)
  ) {
    deleteItem('user');
  }
  // all the difrrent type of request fail message that could come from the backend
  if (returnErrorResponseData && response?.data) {
    throw response?.data;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (response?.data.Message || error.message) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw response?.data.Message || error.message;
  }
  throw error;
};
