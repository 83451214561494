import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { Link } from 'react-router-dom';
import { toRem } from '../../utils/toRem';

export const SFlexDiv = styled.div`
  flex: 1;
`;

export const SSidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 19px 0 0;
  height: 100%;
`;

export const SRowContainers = styled.div`
  color: ${palette.white};
  padding: 8px 4px 8px 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${toRem(24)};
`;

export const SName = styled.div`
  font-size: 16px;
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: 7px;
`;

export const SInfoContainer = styled.div`
  display: flex;
`;

export const SInfo = styled(Link)`
  font-size: 12px;
  font-family: 'Roboto-Regular';
  color: ${palette.honeyYellow};
  line-height: 14px;
  text-transform: uppercase;
  text-decoration: none;
`;

export const SElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  * + * {
    margin-top: 10px;
  }
`;

export const SSidebarAvailabilityContainer = styled.div`
  margin-bottom: ${toRem(12)};
`;
