import { Backdrop } from '../../components/Backdrop/Backdrop';
import FilterRadioItem from '../../components/FilterItem/FilterRadioItem';
import { FilterSection, SectionFilterItem } from '../../components/SearchFilterBar/SearchFilterBar';
import { palette } from '../../theme/colors';
import { translate } from '../../utils/translate';
import { SFilter, SHeader, SLabel } from './MessageTypeFilter.styles';

interface MessageFilterProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setCheckedState: (value: FilterSection) => void;
  list: FilterSection;
}

const MessageTypeFilter = (props: MessageFilterProps) => {
  const { isOpen, setIsOpen, setCheckedState, list, setType } = props;

  const handleRadio = (option: SectionFilterItem) => {
    const newCheckedState = list;
    newCheckedState.content.forEach(item => (item.checked = false));

    newCheckedState.content[option.id].checked = true;
    setCheckedState(newCheckedState);
    setIsOpen(!isOpen);
    setType(newCheckedState.content[option.id].name);
  };

  if (!isOpen) return <></>;

  return (
    <>
      <SFilter role="messageTypeFilter">
        <SHeader role="header">
          <rect width="400" height="100" fill={palette.silver} />
        </SHeader>
        <SLabel role="title">{translate(`messages_selectReplyType`)}:</SLabel>
        <ul>
          {list.content.map((option, key2) => (
            <FilterRadioItem
              checked={option.checked}
              name={option.name}
              setSelected={() => {
                handleRadio(option);
              }}
              key={key2}
              id={option.id}
            />
          ))}
        </ul>
      </SFilter>
      <Backdrop setModal={setIsOpen} />
    </>
  );
};

export default MessageTypeFilter;
