/* eslint-disable @typescript-eslint/naming-convention */
import { SetAvailability } from '../containers/SetAvailability/SetAvailability';
import Language from '../components/SidebarOptions/Language/Language';
import Settings from '../components/SidebarOptions/Settings/Settings';
import TestAlarm from '../components/SidebarOptions/TestAlarm/TestAlarm';
import Sidebar from '../containers/Sidebar/Sidebar';
import { Support } from '../containers/Support/Support';
import { CreateMessage } from './CreateMessage';
import { CreateMessageNew } from './CreateMessageNew';
import { EditDocumentsPage } from './EditDocuments';
import ImOk from './ImOk';
import ImOkPage from './ImOkPage';
import { LogNotesPage } from './LogNotes';
import { Login2faPage } from './Login2fa';
import LoginSkolonPage from './LoginSkolonPage';
import { LoneWorkerTimerCreateAlarmPage } from './LoneWorkerTimerCreateAlarmPage';
import { LoneWorkerTimerPage } from './LoneWorkerTimerPage';
import { LoneWorkerTimerSelectGroupsPage } from './LoneWorkerTimerSelectGroupsPage';
import { MeetingRoomPage } from './MeetingRoomPage';
import OnCallAlert from './OnCallAlert';
import { OnCallAlertMemberSettingsPage } from './OnCallAlertMemberSettings';
import OnCallAlertPage from './OnCallAlertPage';
import OverviewPage from './OverviewPage';
import AlarmPage from './alarm/alarm';
import AlarmCustomTextPage from './alarm/alarmCustomText';
import AlarmMapPage from './alarm/alarmMap';
import AlarmSelectGroupPage from './alarm/alarmSelectGroup';
import { BroadcastPage } from './broadcast/broadcast';
import { BroadcastNewPage } from './broadcast/broadcastNew';
import { ChangePasswordPage } from './changePassword';
import { ChangeSecondNumberPage } from './changeSecondNumber';
import { ChatMediaPage } from './chat/ChatMediaPage';
import { ChatMessages } from './chat/ChatMessages';
import { ChatMessagesFromHoldingStatement } from './chat/ChatMessagesFromHoldingStatement';
import { ReadReceiptsPage } from './chat/ReadReceipts';
import { ChatPage } from './chat/chat';
import ChatAddGroupsPage from './chat/chatAddGroups';
import ChatDetailsFromHoldingStatementPage from './chat/chatDetailFromHoldingStatment';
import ChatDetailsPage from './chat/chatDetails';
import ForwardPage from './chat/forward';
import { ChatMemberSettings } from './chatMemberSettings';
import ChecklistPage from './checklists/checklist';
import ChecklistEditAccess from './checklists/checklistEditAccess';
import ChecklistsPage from './checklists/checklistsPage';
import CreateChecklistPage from './checklists/createChecklistPage';
import EditChecklistPage from './checklists/editChecklistPage';
import NewChecklistPage from './checklists/newChecklistPage';
import StartChecklistPage from './checklists/startChecklistPage';
import { CreatCecMessagePage } from './creatCecMessage';
import { CreateHoldingStatementPage } from './createHoldingStatement';
import CreateLogNotePage from './createLogNote';
import { DashBoardPage } from './dashBoard';
import { DocumentsPage } from './documents';
import { ExternalContactMessagePage } from './externalContactMessage';
import { ExternalContactsPage } from './externalContacts';
import { ForgetPasswordPage } from './forgetPassword';
import { GroupsPage } from './groups/groupDetails';
import { GroupsListPage } from './groups/groupsList';
import { GroupMemberPage } from './groups/memberSettings';
import { HoldingStatementPage } from './holdingStatement';
import { IAmOkMemberSettingsPage } from './iAmOkMemberSettings';
import { InternalSupportPage } from './internalSupport';
import { LogNotePage } from './logNote';
import { LoginPage } from './login';
import { LoginIntroPage } from './loginIntro';
import { NewsPage } from './news';
import { NotFoundPage } from './notFound';
import PanicAlarmCreatePage from './panicButton/PanicAlarmCreatePage';
import PanicAlarmTimerPage from './panicButton/PanicAlarmTimerPage';
import { PanicSelectRecipientsPage } from './panicButton/PanicSelectRecipientsPage';
import { ProfilePage } from './profile';
import { ResetPasswordPage } from './resetPassword';
import { StartIamOkMessagePage } from './startIamOkMessage';
import { StartOnCallAlertMessagePage } from './startOnCallAlertMessage';

export const public_routes = [
  // intor page was hidden  for more info --> loginIntro.tsx
  { path: '/intro/*', element: <LoginIntroPage /> },
  { path: '/', element: <LoginIntroPage /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/forgetPassword', element: <ForgetPasswordPage /> },
  {
    path: '/recover_password/:userId/:resetToken',
    element: <ResetPasswordPage />,
  },
  {
    path: '/initial_password/:userId/:resetToken',
    element: <ResetPasswordPage />,
  },
  { path: '/sso', element: <LoginPage /> },
  { path: '/skolon_login', element: <LoginSkolonPage /> },
  { path: '*', element: <NotFoundPage /> },
];

export const auth_routes = [
  { path: '/2fa', element: <Login2faPage /> },
  {
    path: 'dashboard',
    element: <DashBoardPage />,
  },
  {
    path: '/change_password/*',
    element: <ChangePasswordPage />,
  },
  { path: '/sidebar', element: <Sidebar /> },
  { path: '/change-second-number', element: <ChangeSecondNumberPage /> },
  { path: '/chat', element: <ChatPage /> },
  { path: 'message/:id/forward', element: <ForwardPage /> },
  { path: 'message/:id/read-receipts/:replyId', element: <ReadReceiptsPage /> },
  { path: 'message/:id/media', element: <ChatMediaPage /> },
  { path: '/profile', element: <ProfilePage /> },
  { path: '/groups', element: <GroupsListPage /> },
  { path: '/aloneWorker/create/select-recipients', element: <LoneWorkerTimerSelectGroupsPage /> },
  { path: '/aloneWorker/create', element: <LoneWorkerTimerCreateAlarmPage /> },
  { path: '/aloneWorker/timer', element: <LoneWorkerTimerPage /> },
  { path: '/meeting_room', element: <MeetingRoomPage /> },
  {
    path: '/groups/:id',
    element: <GroupsPage />,
  },
  {
    path: 'groups/:id/memberSettings/:memberID',
    element: <GroupMemberPage />,
  },
  {
    path: 'documents/*',
    element: <DocumentsPage />,
  },
  {
    path: 'documents/:groupDocumentId/:folderId/:type/:selectedFileId',
    element: <EditDocumentsPage />,
  },
  {
    path: 'documents/:groupDocumentId/:type/:selectedFileId',
    element: <EditDocumentsPage />,
  },
  {
    path: 'documents/edit/:type',
    element: <EditDocumentsPage />,
  },
  {
    path: 'documents/edit/:type/:value',
    element: <EditDocumentsPage />,
  },
  { path: '/internal-support', element: <InternalSupportPage /> },

  { path: 'imOk', element: <ImOkPage /> },
  { path: 'muster/:id/*', element: <ImOk /> },
  { path: 'startIamOk/*', element: <StartIamOkMessagePage /> },
  {
    // id here is GroupID
    path: 'muster/:imOkId/memberSettings/:id/:memberID',
    element: <IAmOkMemberSettingsPage />,
  },
  { path: 'onCallAlert', element: <OnCallAlertPage /> },
  { path: 'oncall/:id/*', element: <OnCallAlert /> },
  { path: 'startOnCallAlert/*', element: <StartOnCallAlertMessagePage /> },
  {
    // id here is GroupID
    path: 'oncall/:onCallAlertId/memberSettings/:id/:memberID',
    element: <OnCallAlertMemberSettingsPage />,
  },
  { path: '/createMessage', element: <CreateMessage /> },
  { path: '/sidebar', element: <Sidebar /> },
  { path: 'sidebar/settings', element: <Settings /> },
  { path: 'sidebar/support', element: <Support /> },
  { path: 'sidebar/language', element: <Language /> },
  { path: 'sidebar/test-alarm', element: <TestAlarm /> },
  { path: 'sidebar/enable-not-available-mode', element: <SetAvailability /> },
  { path: '/createMessage/new', element: <CreateMessageNew /> },
  { path: 'news', element: <NewsPage /> },
  { path: '/message/:id', element: <ChatMessages /> },
  {
    path: '/message/:id/fromHoldingStatement',
    element: <ChatMessagesFromHoldingStatement />,
  },
  {
    path: 'message/:id/fromHoldingStatement/read-receipts/:replyId',
    element: <ReadReceiptsPage />,
  },

  {
    path: '/message/:id/fromHoldingStatement/forward/',
    element: <ForwardPage fromHoldingStatement />,
  },
  {
    path: 'message-details/:chatID/memberSettings/:memberID',
    element: <ChatMemberSettings />,
  },
  {
    path: 'message-details/:chatID/fromHoldingStatement/memberSettings/:memberID',
    element: <ChatMemberSettings fromHoldingStatement />,
  },
  { path: '/message-details/:id', element: <ChatDetailsPage /> },
  {
    path: '/message-details/:id/fromHoldingStatement',
    element: <ChatDetailsFromHoldingStatementPage />,
  },
  { path: '/message-details/:id/addGroups', element: <ChatAddGroupsPage /> },
  { path: 'checklists', element: <ChecklistsPage /> },
  { path: 'checklists/create/:checklistName?', element: <CreateChecklistPage /> },
  { path: 'checklists/new/:checklistName?', element: <NewChecklistPage /> },
  { path: 'checklist/:id/edit', element: <EditChecklistPage /> },
  { path: 'checklist/:id', element: <ChecklistPage /> },
  { path: 'checklist/:id/start/:checklistName?', element: <StartChecklistPage /> },
  { path: 'checklist/:id/edit-access', element: <ChecklistEditAccess /> },
  { path: '/holding-statement', element: <HoldingStatementPage /> },
  {
    path: '/createHoldingStatement/*',
    element: <CreateHoldingStatementPage />,
  },
  { path: '/log-notes', element: <LogNotesPage /> },
  { path: '/log-note/:id', element: <LogNotePage /> },
  { path: 'log-note/:id/forward', element: <ForwardPage fromLogNote /> },
  { path: '/log-note/new', element: <CreateLogNotePage /> },
  { path: 'broadcast', element: <BroadcastPage /> },
  { path: 'broadcast/new', element: <BroadcastNewPage /> },
  { path: '/message/:id', element: <ChatMessages /> },
  { path: '/message-details/:id', element: <ChatDetailsPage /> },
  {
    path: '/message-details/:id/addRecipients',
    element: <ChatAddGroupsPage />,
  },
  { path: 'alarm', element: <AlarmPage /> },
  { path: 'alarm/selectGroup', element: <AlarmSelectGroupPage /> },
  { path: 'alarm/alarmCustomText', element: <AlarmCustomTextPage /> },
  { path: 'alarm/map', element: <AlarmMapPage /> },
  { path: 'cec', element: <ExternalContactsPage /> },
  { path: 'cec/:id/*', element: <ExternalContactMessagePage /> },
  { path: 'createCecMessage/*', element: <CreatCecMessagePage /> },
  { path: 'overview', element: <OverviewPage /> },
  { path: 'panic/create', element: <PanicAlarmCreatePage /> },
  { path: 'panic/create/select-recipients', element: <PanicSelectRecipientsPage /> },
  { path: 'panic/timer', element: <PanicAlarmTimerPage /> },
];
