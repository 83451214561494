export const palette = {
  navyBlue: '#001932',
  earthYellow: '#ffbe00',
  honeyYellow: '#ffbe00',
  gold: '#ffbe00',
  tartOrange: '#FF3B30',
  danger: '#FF3B30',
  ashesGray: '#C2C2C2',
  appleGreen: '#43B243',
  stormGray: '#6D748D',
  transparent: 'rgba(0, 0, 0, 0)',

  black: '#333333',
  darkblack: '#000000',
  raisinBlack: '#1B222F',
  raisinBlack2: '#232832',
  raisinBlack3: '#1A202C',
  nightSkyBlue: '#273449',
  prussianBlue: '#232D3F',
  prussianBlue2: '#212B3D',
  prussianBlue3: '#273449',
  prussianBlue4: '#253145',
  prussianBlue5: '#2B3649',
  queenBlue: '#516380',
  loaderDark: '#0E1622',
  vividSkyBlue: '#39C3F2',
  wildBlue: '#899DC8',
  gunmetal: '#242E3F',
  fadedDarkBlue: '#232D3F',
  siamiBlue: '#516380',
  unitedNationsBlue: '#6490DB',
  oxfordBlue: '#001932',
  darkElecticBlue: '#626E85',
  ebony: '#0A121F',
  pickedBluewood: '#323B56',
  cloudBurst: '#1F2F48',
  BlueBayoux: '#4B5B75',
  dimGrey: '#B3B3B4',
  darkSlateLightGrey: '#182233',
  checkedBorder: '#2e3d56',
  mirage: '#18202D',

  lightGrey: '#D1D1D1',
  darkGrey: '#979797',
  darkBlue: '#171E2C',
  grayx11gray: '#B8B8BB',
  lavendarGray: '#c7c7d2',
  platinum: '#E5E5E5',
  spaceCadet: '#3b405c',
  ashGray: '#B3B3B3',

  backDrop: 'rgba(14, 14, 15, 0.77)',

  charcoal: '#334056',
  charcoal2: '#343C4E',
  policeBlue: '#3A4B67',

  davysGrey10: 'rgba(90,90,90,0.2)',
  davysGrey1: 'rgba(90,90,90,0.1)',
  dustyGray: '#989898',
  davysGray: '#4A4C52',
  silverChalice: '#A9A9A9',
  cultured: '#EEEEEE',

  silver: '#C2C2C2',
  silver2: '#c4c4c4',
  silverSand: '#BCBEC3',
  gainsBoro: '#DCDCDC',
  gainsBoro2: '#D6D9E4',
  gainsBoro3: '#DBE0E4',
  gainsBoro4: '#D9D9D9',

  slimyGreen: '#149510',
  appleGreeen: '#43B243',

  blue: '#14213d',
  grey: '#e5e5e5',
  Gray88: '#E0E0E0',
  white: '#FFFFFF',

  shadowColor: 'rgb(90 90 90 / 0.1)',
  applGreen: 'rgba(67, 178, 67, 1)',

  doveGray: '#616161',
  tinyBorder: '#253145',
  bluishGrey: '#607193',
  whisperGray: '#EEEEEE',
  background: 'rgba(71, 71, 71, 0.7)',
  navalNight: '#011932',

  offWhite: '#F3F3F3',
  spanishGray: '#90959E',

  /////////Replaced colors/////////
  // navyBlue: '#18202D',
  // earthYellow: '#D0A360',
  // honeyYellow: '#FFB100',
  // gold: '#fca311',
  // tartOrange: '#FF3B30',
  // danger: '#FF625D',

  notificationModalBoxShadow: 'rgba(90, 90, 90, 0.1)',
  navy20: '#CCD1D6',
  navy40: '#99A3AD',
  navyLighter: '#CCD6DA',
  navyLight: '#678391',
  timeBackground: '#00001466',
};
