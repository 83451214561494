import styled from 'styled-components';
import { palette } from '../../../theme/colors';

const HighLightStyle = styled.span`
  font-weight: bold;
  color: ${palette.honeyYellow};
`;

export const HighlightSearchTerm = (text: any, searchTerm: any) => {
  try {
    const startIndex = text.toString().toLowerCase().indexOf(searchTerm.toLowerCase());
    if (startIndex < 0) {
      return text;
    }
    const endIndex = startIndex + searchTerm.length;
    const highlightedText = text.slice(startIndex, endIndex);
    return (
      <>
        {text.slice(0, startIndex)}
        <HighLightStyle>{highlightedText}</HighLightStyle>
        {text.slice(endIndex)}
      </>
    );
  } catch (err) {
    return text;
  }
};
