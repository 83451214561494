import Country from '../../components/CountryCodeDropDown/resources/country';
import { CountryCode } from '../../components/CountryCodeDropDown/types';

export interface PhoneNumberShorten {
  country?: CountryCode;
  nationalNumber?: string;
  countryCallingCode?: string;
}

export const isNumbersOnly = (text: string): boolean => {
  const numRegex = /^\d+$/;

  return numRegex.test(text);
};

export const getPhoneNumber = (phone: string): PhoneNumberShorten | undefined => {
  const country = Country.getCountryByNumber(phone);
  if (country) {
    const { dial_code: dialCode, code } = country;
    const phoneNumber = dialCode ? phone.slice(dialCode.length) : phone;
    return { country: code, countryCallingCode: dialCode, nationalNumber: phoneNumber };
  }
  return { country: '' as CountryCode, nationalNumber: phone };
};

export const getSecondaryPhoneNumber = (
  phoneNumbersString?: string | null
): PhoneNumberShorten | undefined => {
  if (!phoneNumbersString) {
    return undefined;
  }
  const phoneNumbers = phoneNumbersString.split(',');
  const secPhoneNumber = phoneNumbers?.length > 1 && phoneNumbers[1].substring(1);
  if (secPhoneNumber) {
    const defaultPhone = {
      country: 'SE',
      countryCallingCode: '+46',
      nationalNumber: secPhoneNumber,
    } as PhoneNumberShorten;
    const phoneNumber = getPhoneNumber(secPhoneNumber);
    return phoneNumber?.country?.length ? phoneNumber : defaultPhone;
  }
  return undefined;
};

export const isPhoneNumberChanged = (
  currentNumber: PhoneNumberShorten,
  previousNumber: PhoneNumberShorten
): boolean => {
  const { country: currentCountry, nationalNumber: currentNationalNumber } = currentNumber;
  const { country: prevCountry, nationalNumber: prevNationalNumber } = previousNumber;
  const areCountriesEqual = currentCountry === prevCountry;
  const areNumbersEqual = currentNationalNumber === prevNationalNumber;
  return !areCountriesEqual || !areNumbersEqual;
};

export const getPhoneNumberStringByPhoneNumberObject = (
  phoneNumber: PhoneNumberShorten | undefined
) => {
  if (!phoneNumber) {
    return '-';
  }
  const { countryCallingCode, nationalNumber } = phoneNumber;
  return countryCallingCode && nationalNumber ? `${countryCallingCode} ${nationalNumber}` : '-';
};
