import { Layout } from '../../components/Layout/Layout';
import { GroupDetail } from '../../containers/GroupDetail';

export const GroupsPage = () => {
  return (
    <Layout isMessageLayout message="group_details_header" to="/groups" showBottomTabs>
      <GroupDetail />
    </Layout>
  );
};
