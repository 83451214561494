import * as React from 'react';
import Trash from '../../assets/imgs/chats/trash.svg';
import Loader from '../../components/Loader/Loader';
import { TitlesWithTime } from '../../components/TitlesWithTimeCard/TitlesWithTime';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { isChatsLoading, selectLogNotes } from '../ChatsList/chatListSlice';
import { deleteAMessage, fetchLogNotes } from '../ChatsList/chatListSlice/actionCreators';

import NoMessages from '../../assets/imgs/NotFound/no-result.svg';

import { useNavigate } from 'react-router-dom';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import Options from '../../components/Options/Options';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { getMessageSubject, handleDate, messageShowGenerator } from '../HoldingStatement/helpers';
import { selectUser } from '../Login/LoginSlice';
import { EmptyListFallbackContainer, SList, SPage, SSearchBarBase } from './LogNotes.styles';

export const LogNotes = () => {
  const dispatch = useAppDispatch();
  const confirm = useConfirmation();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const logNotes = useAppSelector(selectLogNotes);
  const isLoading = useAppSelector(isChatsLoading);

  const [searchTerm, setSearchTerm] = React.useState<string | undefined>();
  const [optionsOpen, setOptionsOpen] = React.useState<boolean>(false);
  const [selectedChatId, setSelectedChatId] = React.useState<number>();

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleHide = (id: number) => () => {
    confirm({
      title: 'messages_delete_title',
      description: 'messages_logNotes_deleteConfirmDesc',
      onSubmit: () => {
        dispatch(deleteAMessage(id, navigate, true));
        setOptionsOpen(false);
      },
      onCancel: () => {
        setOptionsOpen(false);
      },
      confirmText: 'logNote_delete_button',
      cancelText: 'cancel',
    });
  };

  const onclickDots = (id: number) => {
    setSelectedChatId(id);
    setOptionsOpen(!optionsOpen);
  };

  React.useEffect(() => {
    try {
      dispatch(fetchLogNotes({ search: searchTerm }));
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, searchTerm]);

  const handleClick = (id: number) => {
    navigate(`/log-note/${id}`);
  };

  return (
    <SPage>
      <SSearchBarBase
        placeholderTx="documents_search"
        placeholder="Search..."
        fallback={onSearch}
        value={searchTerm}
      />

      {isLoading && <Loader />}
      {!logNotes.length ? (
        <EmptyListFallbackContainer role="emptyFallbackContainer">
          <EmptyListFallback
            src={NoMessages}
            listLength={logNotes.length}
            isLoading={isLoading}
            searchTerm={''}
            emptyListTx={'empty_log_notes'}
            noSearchTx={'messages_not_found_search'}
          />
        </EmptyListFallbackContainer>
      ) : null}
      {logNotes.length > 0 && !isLoading && (
        <>
          <Options
            isOpen={optionsOpen}
            setIsOpen={setOptionsOpen}
            setTabBar
            items={[
              {
                name: 'logNote_delete_button',
                icon: Trash,
                callback: handleHide(selectedChatId || 0),
              },
            ]}
          />

          <SList role="logNotesList">
            {logNotes.map(logNote => (
              <TitlesWithTime
                onClick={() => handleClick(logNote.id)}
                itemId={logNote.id}
                key={`${logNote.id}`}
                title={getMessageSubject(logNote)}
                subTitle={messageShowGenerator(logNote, user?.id ?? 0)}
                date={handleDate(logNote, 'sent') || ''}
                time={handleDate(logNote, 'lastReplySent') ?? (handleDate(logNote, 'sent') || '')}
                onMoreOptionClick={onclickDots}
              />
            ))}
          </SList>
        </>
      )}
      <BigFloatButton link={'/log-note/new'} tx={'logNotes_create'} />
    </SPage>
  );
};
