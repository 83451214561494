import { Group } from '../GroupsList/groupsSlice/types';
import { RefObject } from 'react';
import { AlarmFilterTypeObject } from '../../components/AlarmFilter/AlarmFilter';
import { AlarmsAccountTitle } from './Alarm';
import {
  EmergencyType,
  GroupsArr,
  LinkedSubOrganizationsWithGroupsType,
  NamesArr,
} from './AlarmSlice/types';
import { AlertBannerRef } from '../../components/AlertBanner/AlertBanner';
import { translate } from '../../utils/translate';
import { flatten } from 'lodash';
import { ELanguages } from '../../i18n';
import { ShortenedSubOrganization } from './types';
import Geocoder from '../../utils/geocoder';

export const filterAlarmsByAccounts = (
  accounts: AlarmsAccountTitle[],
  filters: AlarmFilterTypeObject
): AlarmsAccountTitle[] => {
  const { selectedIds } = filters;
  if (selectedIds === 'SELECTED_ALL') return accounts;
  if (selectedIds === 'UNSELECTED_ALL') return [];
  return accounts.filter(account => selectedIds.has(account.id));
};

export const handleFiltersChange = (
  isFilterApplied: boolean,
  filters: AlarmFilterTypeObject,
  alertRef: RefObject<AlertBannerRef>
) => {
  if (isFilterApplied) {
    // since the default filter is "SELECTED_ALL", so if there is a filter applied it could
    // only be "UNSELECTED_ALL" or another number
    const selectedAccounts =
      filters.selectedIds === 'UNSELECTED_ALL' ? 0 : (filters.selectedIds as Set<number>).size;

    const title =
      selectedAccounts === 1
        ? translate(`alarm_filter_accounts_active-one`)
        : translate('alarm_filter_accounts_active-other', { x: selectedAccounts });

    if (alertRef.current?.isOpen) {
      if (alertRef.current?.title !== title) alertRef.current?.alert(title);
    } else {
      alertRef.current?.alert(title);
    }
  } else {
    if (alertRef.current?.isOpen) {
      alertRef.current?.close();
    }
  }
};

export const generateBothLangNames = (alarmName: NamesArr[]) => {
  const newAlarmName: NamesArr[] = [];
  const name = alarmName?.length ? alarmName[0]?.Name : '';
  const doesSwedishAlarmNameExist = alarmName?.find(n => n.Language === ELanguages.se);
  if (!doesSwedishAlarmNameExist) {
    newAlarmName?.push({ Language: ELanguages.se, Name: name, ShortName: null });
  }
  const doesEnglishAlarmNameExist = alarmName?.find(n => n.Language === ELanguages.en);
  if (!doesEnglishAlarmNameExist) {
    newAlarmName?.push({ Language: ELanguages.en, Name: name, ShortName: null });
  }
  return newAlarmName.length ? [...alarmName, ...newAlarmName] : [...alarmName];
};

export const getGroupsFromAlarmAndSubAccountAlarm = (
  alarm: EmergencyType,
  __groups: Group[]
): GroupsArr[] => {
  const mainGroups = alarm.Groups ? alarm.Groups : [];
  const subOrgGroups = __groups ? __groups : [];
  const allGroups: (Group | GroupsArr)[] = [...mainGroups, ...subOrgGroups];
  const seen = new Set();

  const filteredArr = allGroups.filter(el => {
    const duplicate = seen.has(el.id);
    seen.add(el.id);
    return !duplicate;
  });
  return filteredArr;
};

export const buildForcedDownAlarm = (
  linkedSubOrg: LinkedSubOrganizationsWithGroupsType,
  originalAlarm: EmergencyType
): EmergencyType => ({
  ...originalAlarm,
  Names: generateBothLangNames(originalAlarm.Names ?? []),
  Groups: getGroupsFromAlarmAndSubAccountAlarm(originalAlarm, linkedSubOrg.groups),
  OrganizationID: null,
  OrganizationName: null,
  SkipMap: linkedSubOrg.skipMap,
  NoGroup: linkedSubOrg.noGroup,
  SuborganizationID: linkedSubOrg.id,
  SuborganizationName: linkedSubOrg.name,
  subOrganisationIDForEmergencyMessage: linkedSubOrg.id,
  warningStep: linkedSubOrg.warningStep,
  allowCustomizedText: linkedSubOrg.allowCustomizedText,
});

export const getMainAccountAlarmsList = (alarmTypes: EmergencyType[]): EmergencyType[] =>
  // Account alarms: Those are alarms that don't have SuborganizationID
  // Main account alarms should not be shown if the user is not in any main account groups.
  alarmTypes
    .slice()
    .filter(alarm => !alarm.SuborganizationID)
    .sort(
      // sorted by order field
      // if the order is same it should compare alarm names
      // but since that couldn't be secured for every alarm type will make it same as before for now
      // normally the user should belong to only one main account (Main Organization)
      (a, b) => a.Order! - b.Order! || a.OrganizationName!.localeCompare(b.OrganizationName!)
    )
    // hideFromMainAccountUsers: true should be excluded
    .filter(alarm => !alarm.hideFromMainAccountUsers);

//subAccounts alarms + alarms that are forced down to the subAccount from main account
export const getSubAccountsAlarmsList = (alarmTypes: EmergencyType[]): EmergencyType[] => {
  // extract the sub organisation ids from subAccount alarms
  // Those are alarms that have SuborganizationID.
  const mainSubOrgsId: ShortenedSubOrganization[] = alarmTypes
    .slice()
    .filter(alarm => !!alarm.SuborganizationID)
    .map(alarm => ({
      subOrgId: alarm.SuborganizationID!,
      subOrgName: alarm.SuborganizationName!,
    }));

  const forcedSubOrgsId: ShortenedSubOrganization[] = flatten(
    alarmTypes
      .slice()
      .filter(mainAlarm => !!mainAlarm.linkedSubOrganizationsWithGroups)
      .map(withLinkedAlarm =>
        withLinkedAlarm.linkedSubOrganizationsWithGroups!.map(linked => ({
          subOrgId: linked.id,
          subOrgName: linked.name,
        }))
      )
  );

  const sortedSubOrgs = Array.from(
    new Set(
      // ordered by subAccount name.
      [...mainSubOrgsId, ...forcedSubOrgsId]
        .sort((a, b) => a.subOrgName!.localeCompare(b.subOrgName!))
        .map(subOrg => subOrg.subOrgId)
    )
  );
  // Those are main account alarms (SuborganizationID: null) that have the
  // subAccount listed in linkedSubOrganizationsWithGroups array.
  // note that the id and name in the linkedSubOrganizationsWithGroups item is the subAccount id and name
  const getForcedDownAlarmsForSubOrg = (subOrgId: number) => {
    return flatten(
      alarmTypes
        .filter(mainAlarm => !!mainAlarm.linkedSubOrganizationsWithGroups)
        .map(withLinkedAlarm =>
          withLinkedAlarm
            .linkedSubOrganizationsWithGroups!.filter(linked => linked.id === subOrgId)
            .map(a => buildForcedDownAlarm(a, withLinkedAlarm))
        )
    ).sort((a, b) => a.Order! - b.Order!);
  };

  // SubAccountsAlarms: This includes both alarms that belong to the
  // subAccount itself ( Those are alarms that have SuborganizationID) and
  // alarms that are forced down to the subAccount from main account
  return flatten(
    // alarms grouped by subAccount
    // for every subAccount we get its forced down alarms (FIRST) and
    // the alarms of the subAccount itself (Second)
    sortedSubOrgs.map(subOrgId => [
      ...getForcedDownAlarmsForSubOrg(subOrgId),
      ...alarmTypes
        .filter(alarm => alarm.SuborganizationID === subOrgId)
        // the alarms of the subAccount ordered by Order field
        .sort((a, b) => a.Order! - b.Order!),
    ])
  );
};
export const groupAlarmsByAccountTitle = (
  mainAccountAlarms: EmergencyType[],
  subAccountsAlarms: EmergencyType[]
): AlarmsAccountTitle[] => {
  // organization
  const accountsById: any = {};

  if (mainAccountAlarms.length > 0) {
    const firstAlarm = mainAccountAlarms[0];
    accountsById[firstAlarm.OrganizationID!] = {
      id: firstAlarm.OrganizationID,
      name: firstAlarm.OrganizationName,
      alarms: [...mainAccountAlarms],
    };
  }

  subAccountsAlarms.forEach(item => {
    if (accountsById[item.SuborganizationID!]) {
      accountsById[item.SuborganizationID!].alarms.push(item);
    } else {
      accountsById[item.SuborganizationID!] = {
        id: item.SuborganizationID!,
        name: item.SuborganizationName,
        alarms: [item],
      };
    }
  });

  return Object.values(accountsById);
};

export const getUserAlarmList = (
  groups: Group[],
  alarmTypes: EmergencyType[],
  onFinishCallback: () => void
): { accountTitlesList: AlarmsAccountTitle[]; alarmList: EmergencyType[] } => {
  if (alarmTypes.length === 0) {
    return { accountTitlesList: [], alarmList: [] };
  }
  const isUserBelongToMainAccountGroup =
    groups.filter(group => !!group.member && !group.subOrganizationID).length !== 0;

  const mainAccountAlarms = isUserBelongToMainAccountGroup
    ? getMainAccountAlarmsList(alarmTypes)
    : ([] as EmergencyType[]);

  const subAccountsAlarms = getSubAccountsAlarmsList(alarmTypes);

  const alarmsAccountTitles: AlarmsAccountTitle[] = groupAlarmsByAccountTitle(
    mainAccountAlarms,
    subAccountsAlarms
  );

  const FullAlarmList = [...mainAccountAlarms, ...subAccountsAlarms];

  onFinishCallback();
  return {
    accountTitlesList: alarmsAccountTitles,
    alarmList: FullAlarmList.slice().map(item => ({
      ...item,
      Name: generateBothLangNames(item.Names ?? []),
    })),
  };
};

export const getLocationName = async (_location: google.maps.LatLngLiteral) => {
  if (_location?.lat != undefined) {
    try {
      const res = await Geocoder({
        latitude: _location.lat,
        longitude: _location.lng,
      });
      return res?.addressName ? res?.addressName : '';
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
};
