import React, { useLayoutEffect } from 'react';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { translate } from '../../utils/translate';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import { Modal } from '../Modal/Modal';
import SearchableMap from '../SearchableMap/SearchableMap';
import { SButtonWrapper, SInfoWrapper, SMapWrapper, SModalWrapper } from './Map.styles';
import { MapTypeToggleButton } from '../MapTypeToggleButton/MapTypeToggleButton';
import styled from 'styled-components';

interface MapAlertDisplayProps {
  location: google.maps.LatLngLiteral | undefined;
  setLocation: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | undefined>>;
  setLocName: React.Dispatch<React.SetStateAction<string>>;
  onClickAccept: () => void;
  onClickDecline?: () => void;
}

const SMapTypeWrapper = styled.div`
  position: absolute;
  bottom: 7.75rem;
  right: 3rem;
`;

function MapAlert(props: MapAlertDisplayProps) {
  const { location, setLocation, setLocName, onClickAccept, onClickDecline } = props;

  const layout = useLayoutContext();

  useLayoutEffect(() => {
    layout.setMessage(translate('messages_location'));
    layout.setSubTitle('');
    layout.setDoShowDots(false);
  }, []);

  return (
    <Modal isOpen={true} setIsOpen={() => {}}>
      <SModalWrapper role="mapAlertContainer">
        <SInfoWrapper role="infoContainer">
          <h3 className="title" role="title">
            {translate('use_location_modal_title')}
          </h3>
          <p className="info" role="info">
            {translate('use_location_modal_intro')}
          </p>
        </SInfoWrapper>
        <SMapWrapper maxHeight="336px" role="mapContainer">
          <SearchableMap setLocName={setLocName} location={location} setLocation={setLocation} />
        </SMapWrapper>
        <SMapTypeWrapper>
          <MapTypeToggleButton />
        </SMapTypeWrapper>
        <SButtonWrapper role="actionButtons">
          <ActionButton
            type="button"
            color="red"
            tx="decline"
            onClick={onClickDecline}
            role="declineButton"
          />
          <ActionButton
            type="button"
            color="green"
            tx="accept"
            onClick={onClickAccept}
            role="acceptButton"
          />
        </SButtonWrapper>
      </SModalWrapper>
    </Modal>
  );
}

export default MapAlert;
