import { useState } from 'react';
import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';
import Arrow from '../../assets/imgs/cec/summary-right-arrow.svg';
import { CheckBoxWithSubTitle } from '../CheckBoxWithSubTitle/CheckBoxWithSubTitle';

const SContainer = styled.div<{ $needExpand?: boolean }>`
  width: 100%;

  .leftContainer {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 90%;
  }

  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${palette.prussianBlue5};
    margin-bottom: 1rem;
  }
`;

const SNamTitle = styled.p`
  font-family: 'Roboto-Regular';
  color: ${palette.white};

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5625rem;
  letter-spacing: 0px;
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SMainWrapper = styled.div<{ $expanded?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .image {
    width: 1.375rem;
    height: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;

    img {
      transform: rotate(90deg);
      transition: transform 0.3s;
      ${props =>
        props.$expanded &&
        css`
          transform: rotate(-90deg);
        `};
    }
  }
`;
const SExpandedBody = styled.div<{ $expanded?: boolean }>`
  padding-left: 2.125rem;
  /* transition  ============= start */
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  ${props =>
    props.$expanded &&
    css`
      max-height: 1000px;
      opacity: 1;
    `};
  transition: all 0.3s ease;
  /* transition  ============= end */
`;

export const SCheckBoxWithSubTitle = styled(CheckBoxWithSubTitle)`
  width: auto;
  padding: 0rem;
  .SLeftTextContainer {
    max-width: 80%;
  }
  .STitle {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 0.875rem;
  }
`;
export interface IsAllPartialSelection {
  is: boolean;
  all: boolean;
}
export interface ICollapsibleCheckBoxProps {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
  selected: IsAllPartialSelection;
  handleSelect: () => void;
  className?: string | undefined;
}

export const CollapsibleCheckBox = (props: ICollapsibleCheckBoxProps) => {
  const { title, children, selected, handleSelect, className } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpand = () => {
    setExpanded(prev => !prev);
  };

  const renderContent = () => <SExpandedBody $expanded={expanded}> {children}</SExpandedBody>;
  return (
    <SContainer className={className}>
      <SMainWrapper $expanded={expanded}>
        <div onClick={toggleExpand} className="leftContainer">
          <div className="image">
            <img src={Arrow} alt="right-arrow" />
          </div>
          <SNamTitle>{title}</SNamTitle>
        </div>
        <SCheckBoxWithSubTitle
          className="SFilterCheckboxItem"
          selected={selected.is}
          onToggleCheck={handleSelect}
          key={`${title}`}
          valueId={0}
          checkBoxType={selected.all ? 'box' : 'filledBox'}
          withoutSeparator
        />
      </SMainWrapper>
      {renderContent()}
    </SContainer>
  );
};
