/* eslint-disable @typescript-eslint/naming-convention */

/** use duck-typing to check if the object is a Set,
 *  since Using the instanceof operator is risky.
 *  The instanceof test might not work when performed in a different window context,
 *  especially on some older browsers.
 */
export function isSet(set: any): boolean {
  if (
    set &&
    typeof set.add === 'function' &&
    typeof set.clear === 'function' &&
    typeof set.delete === 'function' &&
    typeof set.has === 'function'
  ) {
    return true;
  }

  return false;
}
// convert sets to array
export const setsToArray = (set: Set<any>): any[] => {
  return Array.from(set);
};
/**
 * a function to detect set on the first level of an object and transform it to object since sets cannot be stringified,
 * it will transform it to to an array assigned to props with SET_TRANSFORMED key for retrieving detection
 * the saving transformation be like for the next object
 *   {
 *     ...restOf Filters,
 *     selectedIDs : {1,2,3}
 *   }
 *  will be
 *   {
 *     ...restOf Filters,
 *     selectedIDs : { __SET_TRANSFORMED__ : [1,2,3] }
 *   }
 */
export const checkAndConvertSetsInObject = <T extends { [index: string]: any }>(
  _filters: T,
  setTransformKey: string
): T => {
  const clonedFilters = { ..._filters };
  Object.entries(clonedFilters).reduce((transformed, [key, value]) => {
    const _key = key as keyof T;
    if (isSet(value)) {
      transformed[_key] = { [setTransformKey]: Array.from(value) } as T[typeof _key];
    }
    return transformed;
  }, clonedFilters);
  return clonedFilters;
};

// a function to detect set on the first level of an object and transform it to object since sets cannot be stringified
export const checkAndRevertObjectToSet = <T extends { [index: string]: any }>(
  _filters: T,
  setTransformKey: string
): T => {
  const clonedFilters = { ..._filters };
  Object.entries(clonedFilters).reduce((transformed, [key, value]) => {
    const _key = key as keyof T;
    if (value[setTransformKey]) {
      transformed[_key] = new Set(value[setTransformKey]) as T[typeof _key];
    }
    return transformed;
  }, clonedFilters);
  return clonedFilters;
};
