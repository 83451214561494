import axios from 'axios';
import type { AxiosError } from 'axios';
import { BASE_URL } from '../../apis/utils/provider';
import { store } from '../../store';
import { getItem, saveItem } from '../../utils/storage';
import { updateUser } from '../Login/LoginSlice/actionCreators';
import { setIsOnline } from './checkAppAvailableSlice';

export const recheckTimeOutKey = 'recheckTimeOut';

const addRandomTimeoutAndSave = (callback: () => void) => {
  const delayTimesArray = [0, 1000, 2000, 3000, 4000, 5000, 6000];
  const randomDelayTimeIndex = Math.floor(Math.random() * delayTimesArray.length);
  const delayTime = delayTimesArray[randomDelayTimeIndex];
  const recheckTimeout = setTimeout(callback, delayTime);
  saveItem(recheckTimeOutKey, recheckTimeout);
};

interface CheckNetworkAvailabiltyProps {
  errorType?: 'MISSING_NETWORK' | number;
  checkIfNetworkBack?: boolean;
}

export const checkNetworkAvailability = async (props: CheckNetworkAvailabiltyProps) => {
  const { checkIfNetworkBack } = props;

  try {
    await axios.request({
      baseURL: BASE_URL,
      method: 'GET',
      url: `/account/authstatus`,
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-XSRF-Token': getItem('csrf'),
      },
    });
    addRandomTimeoutAndSave(() => {
      store.dispatch(setIsOnline(true));
      store.dispatch(updateUser(false, true));
    });
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      if (err.response.status !== 401 && !checkIfNetworkBack) {
        store.dispatch(setIsOnline(false));
      }
      if (err.response.status === 401) {
        addRandomTimeoutAndSave(() => {
          store.dispatch(setIsOnline(true));
        });
      }
    } else if (err.request) {
      // The client never received a response, and the request was never left
      if (!checkIfNetworkBack) {
        store.dispatch(setIsOnline(false));
      }
    }
  }
};
