import { palette } from '../../theme/colors';
import styled from 'styled-components';
import FilterCheckboxItem from '../FilterItem/FilterCheckboxItem';
import { SearchBarBase } from '../SearchBarBase/SearchBarBase';
import { toRem } from '../../utils/toRem';

export const SFilterCheckboxItem = styled(FilterCheckboxItem)`
  margin-bottom: 0.75rem;
`;

export const SFlexDiv = styled.div`
  flex: 1;
`;

export const SFilter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 20px;
  padding-bottom: ${toRem(30)};
  background-color: ${palette.prussianBlue2};
  max-height: 75vh;
  max-width: 26rem;
  width: 100%;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

export const SHeader = styled.svg`
  width: 135px;
  max-height: 5px;
  flex: 1;
  place-self: center;
  margin-bottom: 3.75rem;
  border-radius: 5px;
`;

export const SLabelContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const SLabel = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${palette.white};
  margin: 1.5rem 0 0.8rem 0.1rem;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  input {
    ::placeholder {
      color: ${palette.silver};
      font-size: 1rem;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: ${palette.silver};
      font-size: 1rem;
    }

    ::-ms-input-placeholder {
      color: ${palette.silver};
      font-size: 1rem;
    }
  }
`;
