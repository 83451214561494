import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { RequireRole } from '../components/RequireRole/RequireRole';
import { selectCanStartOnCallAlert } from '../containers/Login/LoginSlice';
import { StartOnCallAlertMessageContainer } from '../containers/StartOnCallAlertMessage/StartOnCallALertMessageContainer';
import { useAppSelector } from '../hooks';
import { useLocation } from 'react-router-dom';

export const StartOnCallAlertMessagePage = () => {
  const { pathname } = useLocation();
  const canStartOnCallALert = useAppSelector(selectCanStartOnCallAlert);
  const backBtnPath =
    pathname === '/startOnCallAlert/summary' ? '/startOnCallAlert' : '/onCallAlert';

  return (
    <Layout isMessageLayout message="onCallAlert_screen" to={backBtnPath} showBottomTabs>
      <RequireRole roleCondition={!!canStartOnCallALert} backTo="/onCallAlert">
        <StartOnCallAlertMessageContainer />
      </RequireRole>
    </Layout>
  );
};
