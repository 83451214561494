/**
 * areAllConditionsTruth
 *
 * This function takes an array of conditions and checks if all conditions are truthy.
 * It returns `true` if every condition in the array evaluates to true, and `false` otherwise.
 *
 * @param {Array} conditions - An array of conditions to be evaluated.
 * @returns {boolean} - Returns `true` if all conditions are truthy, `false` otherwise.
 *
 * Example usage:
 * const conditions = [
 *   haveAddRecipientsToAnyMessageRole || isNonAlarmSender,
 *   !hasNotNormalGroupTypes,
 *   !isOnCallAlertMessage,
 *   !isIamOkMessage,
 *   !isBroadCastMessage
 * ];
 * const showButton = areAllConditionsTruth(conditions);
 */
export function areAllConditionsTruth(conditions: any[]): boolean {
  return conditions.every(condition => condition);
}
