import i18n from 'i18next';
/**
 * Translates text.
 *
 * @param key The i18n key.
 */
// added type any for now
export function translate(key: string, params?: any): string {
  return key ? i18n.t(key, params) : '';
}
