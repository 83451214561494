import React from 'react';
import { ReactComponent as CoSafeLogo } from '../../assets/imgs/general/cosafe-logo.svg';
import { SplashWrapper, SplashContainer } from './Splash.styles';

interface SplashProps {
  className?: string | undefined;
}
const Splash = (props: SplashProps) => {
  const { className } = props;
  return (
    <SplashWrapper className={className}>
      <SplashContainer>
        <CoSafeLogo />
      </SplashContainer>
    </SplashWrapper>
  );
};

export default Splash;
