import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { GroupMember, Location } from '../../GroupDetail/groupDetailSlice/types';
import { Group } from '../../GroupsList/groupsSlice/types';
import { LoneWorkerPersonalAlarmResponse, LoneWorkerTimerState, Time } from './types';
import { SelectListUser } from '../../CreateMessage/createMessageSlice/types';

const initialState: LoneWorkerTimerState = {
  groups: [],
  users: [],
  selectedGroups: [],
  selectedUsers: [],
  locationCoords: null,
  groupsMembers: [],
  alarmData: null,
  isLoading: false,
  usersLoading: false,
  groupsLoading: false,
  error: null,
  time: { hours: 0, minutes: 0 },
  messageText: '',
  oneMinuteWarning: false,
  isDeactivated: false,
  isLocationDenied: false,
};

export const loneWorkerTimerSlice = createSlice({
  name: 'loneWorkerTimer',
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
    setSelectedGroups: (state, action: PayloadAction<Group[]>) => {
      state.selectedGroups = action.payload;
    },
    setUsers: (state, action: PayloadAction<SelectListUser[]>) => {
      state.users = action.payload;
    },
    setMessageText: (state, action: PayloadAction<string>) => {
      state.messageText = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<SelectListUser[]>) => {
      state.selectedUsers = action.payload;
    },
    deleteGroup: (state, action: PayloadAction<number>) => {
      //remove group
      state.selectedGroups = state.selectedGroups.filter(
        (group: Group) => group.id !== action.payload
      );
      //remove group members
      state.groupsMembers = state.groupsMembers.filter(
        (member: GroupMember) => member.groupID !== action.payload
      );
    },
    setLocationCoords: (state, action: PayloadAction<Location>) => {
      state.locationCoords = action.payload;
    },
    setGroupsMembers: (state, action: PayloadAction<GroupMember[]>) => {
      state.groupsMembers = action.payload;
    },
    setTime: (state, action: PayloadAction<Time>) => {
      state.time = action.payload;
    },
    setOneMinuteWarning: (state, action: PayloadAction<boolean>) => {
      state.oneMinuteWarning = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUsersLoading: (state, action: PayloadAction<boolean>) => {
      state.usersLoading = action.payload;
    },
    setGroupsLoading: (state, action: PayloadAction<boolean>) => {
      state.groupsLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setAlarmData: (state, action: PayloadAction<LoneWorkerPersonalAlarmResponse | null>) => {
      state.alarmData = action.payload;
    },
    setIsDeactivated: (state, action: PayloadAction<boolean>) => {
      state.isDeactivated = action.payload;
    },
    setIsLocationDenied: (state, action: PayloadAction<boolean>) => {
      state.isLocationDenied = action.payload;
    },
  },
});

export const {
  setGroups,
  setUsers,
  setSelectedGroups,
  setSelectedUsers,
  deleteGroup,
  setLocationCoords,
  setGroupsMembers,
  setTime,
  setMessageText,
  setOneMinuteWarning,
  setAlarmData,
  setIsDeactivated,
  setIsLocationDenied,
  setIsLoading,
  setUsersLoading,
  setGroupsLoading,
  setIsError,
} = loneWorkerTimerSlice.actions;

export const getGroups = (state: RootState) => state.loneWorkerTimer.groups;
export const getUsers = (state: RootState) => state.loneWorkerTimer.users;
export const getSelectedGroups = (state: RootState) => state.loneWorkerTimer.selectedGroups;
export const getSelectedUsers = (state: RootState) => state.loneWorkerTimer.selectedUsers;
export const getLocation = (state: RootState) => state.loneWorkerTimer.locationCoords;
export const getGroupsMembers = (state: RootState) => state.loneWorkerTimer.groupsMembers;
export const getTime = (state: RootState) => state.loneWorkerTimer.time;
export const getMessageText = (state: RootState) => state.loneWorkerTimer.messageText;
export const getOneMinuteWarning = (state: RootState) => state.loneWorkerTimer.oneMinuteWarning;
export const getAlarmData = (state: RootState) => state.loneWorkerTimer.alarmData;
export const getIsDeactivated = (state: RootState) => state.loneWorkerTimer.isDeactivated;
export const getIsLocationDenied = (state: RootState) => state.loneWorkerTimer.isLocationDenied;
export const getIsLoading = (state: RootState) => state.loneWorkerTimer.isLoading;
export const getUsersLoading = (state: RootState) => state.loneWorkerTimer.usersLoading;
export const getGroupsLoading = (state: RootState) => state.loneWorkerTimer.groupsLoading;

export default loneWorkerTimerSlice.reducer;
