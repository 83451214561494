import { BASE_URL } from '../apis/utils/provider';

export interface GetImageLinkProps {
  imageName: string | null;
  size?: 'small' | 'medium' | 'large';
}
export const getImageLink = (props: GetImageLinkProps): string => {
  const { imageName, size } = props;
  if (!imageName) return '';

  const imageWithoutExtension = imageName.replace(/\.[^/.]+$/, '');
  const imageExtension = imageName.split('.').pop();

  if (!imageWithoutExtension && !imageExtension) return '';

  return `${BASE_URL}/media/file/${imageWithoutExtension}${
    size ? '_' + size : ''
  }.${imageExtension}`;
};

export const getAudioLink = (audioName: string): string => {
  if (!audioName) return '';
  return `${BASE_URL}/media/file/${audioName}`;
};
