import isElectron from 'is-electron';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import message from '../../assets/imgs/general/contact-im-ok.svg';
import end from '../../assets/imgs/general/end-im-ok.svg';
import Delete from '../../assets/imgs/iamokay/iamok-delete.svg';
import Edit from '../../assets/imgs/iamokay/iamok-edit.svg';
import ImOkMessage from '../../components/ImOk/ImOkMessage';
import ImOkResponseButtonGroup from '../../components/ImOk/ImOkResponseButtonGroup';
import { ImOkResponseList } from '../../components/ImOk/ImOkResponseList';
import ImOkStatusMessage from '../../components/ImOk/ImOkStatusMessage';
import Loader from '../../components/Loader/Loader';
import MapModal from '../../components/MapModal/MapModal';
import { Modal } from '../../components/Modal/Modal';
import { OptionItemProps } from '../../components/Options/Options';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IamOkMessageMembersType } from '../../routes/ImOk';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { ImOkStatusType } from '../../utils/enums';
import { translate } from '../../utils/translate';
import { checkIfNotZeroMembers } from '../ImOkList/createMessageIamOkSummary.helpers';
import { SOptions, SPage } from '../ImOkList/im-ok.styles';
import {
  isImOkBottomModalOpen,
  selectImOkDocument,
  selectImOkIsLoading,
  setShowImOkBottomModal,
} from '../ImOkList/imOkSlice';
import {
  deleteIamOkMessage,
  fetchImOkDocument,
  setEndImOkResponse,
  toggleImOkBottomModal,
} from '../ImOkList/imOkSlice/actionCreators';
import { selectUser } from '../Login/LoginSlice';

export interface IamOkLocationDataType {
  locationName: string;
  latitude: number;
  longitude: number;
  userName: string;
  lastupdated: string;
}

interface ModalStateType {
  isOpen: boolean;
  locationData?: IamOkLocationDataType;
}

const ImOkDocument = (props: { setShowDots: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { setShowDots } = props;
  const confirm = useConfirmation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    setTabsState,
    setMessage: setHeaderTitle,
    setSubTitle: setHeaderSubTitle,
  } = useLayoutContext();

  const [activeButton, setActiveButton] = useState(ImOkStatusType.NotOk);
  const MINUTE_MS = 10000;

  const [createMessagesModalOpen, setCreateMessagesModalOpen] = useState(false);
  const imOkDocument = useAppSelector(selectImOkDocument);
  const isLoading = useAppSelector(selectImOkIsLoading);
  const user = useAppSelector(selectUser);
  const hasIamOkayRole = user?.roles?.includes('ManageMuster');
  const isEnded = imOkDocument?.ended;
  const isSameSender = imOkDocument?.creatorid === user?.id;
  const everyOnCanStartIamok = user?.organizationMusterCreateSetting === 0;

  const shouldHideTheList = hasIamOkayRole
    ? false // don't hide if the user has the role
    : everyOnCanStartIamok // if the user has not the role  , check if  everyone can start iamok
    ? !isSameSender // if yes,  hide if the user is not the one who started the iaml
    : true; // if no, hide

  const showSettingsModal = useAppSelector(isImOkBottomModalOpen);

  const [messageHeight, setMessageHeight] = useState<number | undefined>();
  const [buttonsGroupHeight, setButtonsGroupRef] = useState<number | undefined>();

  const [messagToggled, setMessagToggled] = useState<boolean>(false);

  const measuredButtonGroupsRef = useCallback(node => {
    if (node !== null) {
      setButtonsGroupRef(node.getBoundingClientRect().height);
    }
  }, []);
  const measuredMessageRef = useCallback(
    node => {
      if (node !== null) {
        setMessageHeight(node.getBoundingClientRect().height);
      }
    },
    [messagToggled]
  );

  const handleEnd = () => {
    confirm({
      title: 'imOk_end_modal_title',
      description: 'imOk_end_modal_text',
      onSubmit: () => {
        if (imOkDocument) dispatch(setEndImOkResponse(`${imOkDocument.id}`));
        dispatch(toggleImOkBottomModal(false));
      },
      onCancel: () => {
        dispatch(toggleImOkBottomModal(false));
      },
      confirmText: 'imOk_end_modal',
      cancelText: 'cancel',
    });
  };
  const handleDelete = () => {
    confirm({
      title: 'messages_confirmation',
      description: 'imOk_delete_modal_description',
      onSubmit: () => {
        if (imOkDocument) {
          dispatch(deleteIamOkMessage(imOkDocument.id, navigate));
        }
        dispatch(toggleImOkBottomModal(false));
      },
      onCancel: () => {
        dispatch(toggleImOkBottomModal(false));
      },
      confirmText: 'delete',
      cancelText: 'cancel',
    });
  };

  const fetchImOk = async () => {
    let id = window.location.pathname.split('/').pop();
    if (isElectron()) {
      id = window.location.hash.split('/').pop();
    }
    dispatch(fetchImOkDocument(id || ''));
  };

  const [mapModalState, setMapModalState] = useState<ModalStateType>({
    isOpen: false,
  });

  const settingsModal: (OptionItemProps | { hidden: boolean })[] = [
    {
      name: 'imOk_end',
      callback: () => handleEnd(),
      icon: end,
      hidden: shouldHideTheList || isEnded,
    },
    {
      name: 'imOk_contact',
      callback: () => setCreateMessagesModalOpen(true),
      icon: message,
      hidden: shouldHideTheList || isEnded,
    },
    {
      name: 'messages_edit',
      callback: () => {
        navigate('edit');
        dispatch(toggleImOkBottomModal(false));
      },
      icon: Edit,
      hidden: shouldHideTheList || isEnded,
    },
    {
      name: 'messages_delete',
      callback: () => handleDelete(),
      icon: Delete,
      hidden: shouldHideTheList,
      disabled: !isEnded,
      disabledTx: 'iamOK_block_delete_decription',
    },
  ];

  useEffect(() => {
    if (!isEnded) {
      const interval = setInterval(() => {
        fetchImOk();
      }, MINUTE_MS);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    fetchImOk();
    return () => {
      setMapModalState({ isOpen: false });
    };
  }, []);

  useEffect(() => {
    setHeaderTitle(imOkDocument?.subject || translate(`imOk_title`));
    setHeaderSubTitle(
      imOkDocument?.groups.length ? imOkDocument?.groups[0]?.name || undefined : undefined
    );
    return () => {
      setHeaderTitle(undefined);
      setHeaderSubTitle(undefined);
    };
  }, [imOkDocument?.subject, imOkDocument?.groups]);

  const reloadData = () => {
    fetchImOk();
  };

  const openCreateMessagesModal = () => {
    dispatch(toggleImOkBottomModal(false));
    setCreateMessagesModalOpen(false);
  };

  const usersNotOk = imOkDocument?.users?.filter(imOkUser => imOkUser.imok === false) || [];
  const usersOk = imOkDocument?.users?.filter(imOkUser => imOkUser.imok) || [];
  const usersNoStatus = imOkDocument?.users?.filter(imOkUser => imOkUser.imok === null) || [];

  const contactMemberByType = (type: IamOkMessageMembersType) => {
    dispatch(setShowImOkBottomModal(false));
    setCreateMessagesModalOpen(false);

    if (imOkDocument && !checkIfNotZeroMembers(type, imOkDocument)) {
      setTabsState(false);
      navigate(`createMessageSummary/${type}`);
    } else {
      confirm({
        title: 'cec_noMembers',
        description: 'cec_noMembers',
        onSubmit: () => {},
        confirmText: 'done',
        cancelText: 'cancel',
      });
    }
  };

  const sendMessagesModalButtons = [
    {
      name: 'imOk_contact_all',
      callback: () => contactMemberByType('ALL'),
      hidden: false,
    },
    {
      name: 'imOk_contact_isNotOk',
      callback: () => contactMemberByType('NOT_OK'),
      hidden: usersNotOk.length === 1 ? usersNotOk[0].userid === user?.id : usersNotOk.length === 0,
    },
    {
      name: 'imOk_contact_isOk',
      callback: () => contactMemberByType('OK'),
      hidden: usersOk.length === 1 ? usersOk[0].userid === user?.id : usersOk.length === 0,
    },
    {
      name: 'imOk_contact_noStatus',
      callback: () => contactMemberByType('NO_STATUS'),
      hidden:
        usersNoStatus.length === 1
          ? usersNoStatus[0].userid === user?.id
          : usersNoStatus.length === 0,
    },
  ];
  const userFromList = imOkDocument?.users.find(item => item.userid === user?.id);
  const hideResponseButtonsForUser = !userFromList;
  const userIsImOkCreator = imOkDocument?.creatorid === user?.id;

  const filteredShowSettingsModal = settingsModal.filter(item => !item.hidden);
  const filteredSendMessagesModalButtons = sendMessagesModalButtons.filter(item => !item.hidden);

  useLayoutEffect(() => {
    if (filteredShowSettingsModal.length === 0) {
      setShowDots(false);
    } else {
      setShowDots(true);
    }
  }, [filteredShowSettingsModal.length]);

  const setMapIsOpen = (is: boolean) => {
    setMapModalState(prev => ({ ...prev, isOpen: is }));
  };

  const handleMapClick = (data: IamOkLocationDataType) => {
    setMapModalState(prev => ({ ...prev, locationData: data }));
    setMapIsOpen(true);
  };

  const renderMapModal = () => {
    return (
      <>
        {mapModalState.isOpen && mapModalState.locationData && (
          <Modal isOpen={mapModalState.isOpen} setIsOpen={setMapIsOpen}>
            <MapModal
              address={mapModalState.locationData.locationName}
              latitude={mapModalState.locationData.latitude}
              longitude={mapModalState.locationData.longitude}
              type={mapModalState.locationData.userName}
              withoutRecalledLabel
              withDate
              recalled={true}
              sent={getNumbersBasedDateTextByLanguage({
                dateText: mapModalState.locationData.lastupdated,
              })}
            />
          </Modal>
        )}
      </>
    );
  };

  if (!imOkDocument && isLoading) return <Loader />;

  return (
    <SPage $paddingBottom={buttonsGroupHeight}>
      <SOptions
        isOpen={showSettingsModal || createMessagesModalOpen}
        setIsOpen={() => openCreateMessagesModal()}
        setTabBar
        items={
          createMessagesModalOpen ? filteredSendMessagesModalButtons : filteredShowSettingsModal
        }
      />
      {imOkDocument && (
        <ImOkMessage
          onMessagedToggled={setMessagToggled}
          ref={measuredMessageRef}
          imOkDocument={imOkDocument}
        />
      )}
      {imOkDocument && (
        <ImOkResponseList
          messageHeight={messageHeight}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          imOkDocument={imOkDocument}
          handleMapClick={handleMapClick}
        />
      )}
      {!isEnded && (userIsImOkCreator || !hideResponseButtonsForUser) && imOkDocument && (
        <ImOkResponseButtonGroup
          contactMemberByType={contactMemberByType}
          imOkDocument={imOkDocument}
          reloadData={() => reloadData()}
          hideResponseButtonsForUser={hideResponseButtonsForUser}
          userFromList={userFromList}
          userIsImOkCreator={userIsImOkCreator}
          setCreateMessagesModalOpen={setCreateMessagesModalOpen}
          ref={measuredButtonGroupsRef}
        />
      )}
      {isEnded && <ImOkStatusMessage ref={measuredButtonGroupsRef} />}
      {renderMapModal()}
    </SPage>
  );
};

export default ImOkDocument;
