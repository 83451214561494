import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { BottomRow, Item, SimpleText } from './SelectOrgList.styles';
import { translate } from '../../utils/translate';
import SCheckbox from '../../components/FilterItem/SBoxButton';
import { isNil } from 'lodash';

export interface IOtherProps {
  id: number;
  name?: string;
  title?: string;
  parentId?: number;
}

export interface IOrgProps {
  org: IOtherProps;
  onCardPress?: () => void;
  isSelected: boolean;
  textClassName?: string;
}

export const GroupItemContainer = styled.div`
  // border-bottom: 1px solid ${palette.queenBlue};
  padding-bottom: 5px;
  padding-top: 5px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const MessagesTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const SelectOrgItem = (props: IOrgProps) => {
  const { org, isSelected, textClassName, onCardPress } = props;
  const handleOrgType = (organization: IOtherProps) => {
    return !organization?.parentId
      ? `${translate('messages_broadcast_account')}`
      : `${translate('messages_broadcast_subaccount')}`;
  };

  return (
    <GroupItemContainer
      onClick={onCardPress}
      id={`orgItem-${org?.id}`}
      role={`orgItem-${org?.name}`}
    >
      <BottomRow role="contentRow">
        <MessagesTextContainer role="messagesTextContainer">
          <Row role="textRow">
            <SimpleText role="name" fontSize={'0.8rem'} className={textClassName}>
              {org?.name}
            </SimpleText>
            {isNil(org.title) && (
              <SimpleText role="type" fontSize={'0.7rem'} gray>
                {handleOrgType(org)}
              </SimpleText>
            )}
          </Row>
        </MessagesTextContainer>
        <Item role="checkboxContainer">
          <SCheckbox isChecked={isSelected} />
        </Item>
      </BottomRow>
    </GroupItemContainer>
  );
};
