/* eslint-disable @typescript-eslint/no-shadow */

import {
  useEffect,
  useRef,
  useState,
  cloneElement,
  isValidElement,
  Children,
  EffectCallback,
} from 'react';
import { createCustomEqual } from 'fast-equals';
import { SMap } from './Map.styles';
import { useMapTypeContext } from '../../utils/customHooks/MapTypeContext';

interface MapProps extends google.maps.MapOptions {
  className?: string;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onBoundsChange?: (map: google.maps.Map) => void;
  ref?: any;
  inputRef?: any;
  onSearch?: (lat: number, lng: number) => void;
}

export const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  onBoundsChange,
  children,
  inputRef,
  onSearch,
  className = '',
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const { mapType } = useMapTypeContext();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  const styledMapType = new google.maps.StyledMapType(
    [{ elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }],
    { name: 'Styled Map' }
  );

  if (map) {
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId(mapType);
    map.setOptions({
      styles: [{ elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }],
    });
  }

  if (map && onSearch) {
    const searchBox = new window.google.maps.places.SearchBox(inputRef.current);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (!places || places.length === 0) {
        return;
      }

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach(place => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry');
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        onSearch(lat, lng);
        map.setCenter({ lat: lat, lng: lng });
      });
      map.fitBounds(bounds);
    });
  }

  useEffect(() => {
    if (map) {
      google.maps.event.clearInstanceListeners(map);

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }

      if (onBoundsChange) {
        map.addListener('bounds_changed', () => onBoundsChange(map));
      }
    }

    return () => {
      if (map) google.maps.event.clearInstanceListeners(map);
    };
  }, [map]);

  function isLatLngLiteral(obj: any): obj is google.maps.LatLngLiteral {
    return typeof obj === 'object' && Number.isFinite(obj.lat) && Number.isFinite(obj.lng);
  }

  const deepCompareEqualsForMaps = createCustomEqual(deepEqual => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  });

  function useDeepCompareMemoize(value: any) {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  }

  function useDeepCompareEffectForMaps(callback: EffectCallback, dependencies: any[]) {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
  }

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <SMap ref={ref} className={className} />
      {Children.map(children, child => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};
