import { Layout } from '../components/Layout/Layout';
import { LoneWorkerTimerCreateAlarm } from '../containers/LoneWorkerTimer/LoneWorkerTimerCreateAlarm';

export const LoneWorkerTimerCreateAlarmPage = () => {
  return (
    <Layout isMessageLayout message="lone_worker_timer" to="/dashboard">
      <LoneWorkerTimerCreateAlarm />
    </Layout>
  );
};
