import Drawer from 'react-bottom-drawer';
import DisclaimerIcon from '../../../assets/imgs/chats/alarm-bottom-sheet-icon.svg';
import { translate } from '../../../utils/translate';
import { SButton, SDrawerWrapper, SLine } from './BlockedStateBottomSheet.styles';

export interface IBlockedStateBottomSheetProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BlockedStateBottomSheet = (props: IBlockedStateBottomSheetProps) => {
  const { isOpen, setIsOpen } = props;

  const onCloseHandle = () => {
    setIsOpen(false);
  };
  return (
    <SDrawerWrapper role="blockedStateBottomSheetWrapper">
      <Drawer className="profileDrawer" isVisible={isOpen} onClose={onCloseHandle} hideScrollbars>
        <img
          className="alarmIcon"
          src={DisclaimerIcon}
          alt="Disclaimer Icon"
          role="disclaimerIcon"
        />
        <p className="STitle" role="title">
          {translate('messages_alarm_notification_blocked_title')}
        </p>
        <p className="SDescription" role="description">
          {translate('messages_alarm_notification_blocked_desc')}
        </p>
        <p className="SSubtitle" role="subtitle">
          {translate('messages_alarm_blocked_how_to_end_title')}
        </p>
        <ol role="stepsList">
          <li className="SSteps" role="step1">
            {translate('messages_alarm_blocked_how_to_end_desc_1')}
          </li>
          <li className="SSteps" role="step2">
            {translate('messages_alarm_blocked_how_to_end_desc_2')}
          </li>
          <li className="SSteps" role="step3">
            {translate('messages_alarm_blocked_how_to_end_desc_3')}
          </li>
        </ol>
        <SLine role="line" />
        <SButton tx="close" onClick={onCloseHandle} />
      </Drawer>
    </SDrawerWrapper>
  );
};
