import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';
import { ReactComponent as Add } from '../../assets/imgs/chats/add.svg';

export const SFileInput = styled.input`
  display: none;
`;

export const SChatBoxWrapper = styled.div<any>`
  z-index: 15;
  width: 100%;
  // Anything affected from turning it to auto ??
  /* height: ${props => (props.map ? '17rem' : props.margin ? '8rem' : '5rem')}; */
  background-color: ${palette.prussianBlue2};
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: space-evenly;
  padding: 0.75rem 1.5rem 0.75rem 1.3rem;

  &.checklist-comment {
    height: fit-content;
    padding: 1rem 0 0;
  }
`;

export const SChatBox = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  max-width: 24rem;
  flex-direction: column;
  width: 100%;
`;

export const STop = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SBottom = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
`;

const SAdd = styled(Add)`
  cursor: pointer;
  min-width: 24px;
`;

export const YellowAdd = styled(SAdd)<any>`
  cursor: ${props => props.$disable && 'not-allowed'};
  path {
    stroke: ${palette.honeyYellow};
  }

  align-self: flex-end;
  margin-bottom: 0.55rem;
`;

export const SImageContainer = styled.div<any>`
  width: 100%;
  height: 4rem;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .scrollableImages {
    height: 100%;
    display: flex;
    align-items: center;
  }
  div + div {
    margin-left: ${props => (props.pdf ? '1.3rem' : '1rem')};
  }
`;

export const SImg = styled.div`
  display: flex;
  position: relative;
  height: 80%;

  .picture {
    border-radius: 4px;
    width: 4rem;
    object-fit: cover;
  }
  .del {
    cursor: pointer;
    position: absolute;
    top: -6px;
    right: -6px;
  }
`;

export const SPdf = styled.div`
  display: flex;
  position: relative;
  width: 4rem;
  flex-direction: column;
  align-items: center;
  height: 80%;

  svg {
    min-height: 35px;
  }

  p {
    text-align: center;
    line-break: anywhere;
    width: 100%;
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 10px;
  }

  .del {
    cursor: pointer;
    position: absolute;
    top: -6px;
    right: -12px;
  }
`;

export const SAddMoreImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  margin-left: 0.5rem;
  height: 50px;
  width: 5rem;
  border: 1px dashed ${palette.stormGray};
  border-radius: 3px;
`;

export const STextContainer = styled.form`
  margin: 0 1.25rem 0 0.875rem;
  padding: 0 1rem 0 1rem;
  width: 100%;
`;

export const STextInput = styled.textarea`
  border-radius: 20px;
  background-color: ${palette.ebony};
  color: ${palette.white};

  width: 100%;
  padding: 0.7rem 1rem;

  resize: none;
  border: none;
  font-family: 'Roboto-Regular';

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  :focus-visible {
    outline: none;
  }

  &::placeholder {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 0.875rem;
    color: ${palette.ashGray};
  }
`;

export const SIcon = styled.img`
  min-width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 0.55rem;
`;

export const SAttachedMap = styled.div`
  margin-top: 0.75rem;
  padding: 0;
  height: 12rem;
  width: 100%;
  border-radius: 10px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

export const EditContainer = styled.div`
  padding: 0.5rem 1.25rem;
  background-color: ${palette.charcoal2};
  height: 44px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  p {
    color: ${palette.silver};
    font-size: 12px;
    font-family: 'Roboto-Regular';
    margin-left: 1.25rem;
  }
  .close {
    cursor: pointer;
    margin-left: auto;
  }
`;

export const SReplyContainer = styled.div`
  background-color: ${palette.pickedBluewood};
  border-left: 3px solid ${palette.honeyYellow};
  padding: 0.75rem 1.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  .close {
    flex-shrink: 0;
    margin-left: 1.125rem;
    cursor: pointer;
  }
  .alarmMessageIcon {
    margin-right: 0.75rem;
    height: 1.25rem;
  }
  .photoMessageIcon {
    margin-right: 0.5rem;
    height: 2rem;
    width: 2rem;
    border-radius: 4px;
  }
`;

export const SClampLines = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const SReplyTextContainer = styled.div`
  flex-grow: 1;
  font-size: 0.75rem;
  .senderTitle {
    font-family: 'Roboto-Medium';
    color: ${palette.white};
    font-weight: 500;
    line-height: 0.875rem;
    margin-bottom: 0.375rem;
    ${SClampLines}
  }

  .messageBody {
    font-family: 'Roboto-Regular';
    opacity: 0.7;
    color: ${palette.silver};
    font-weight: 400;
    ${SClampLines}
  }
`;

export const SFakeFooter = styled.div<{ $height?: string }>`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${palette.prussianBlue2};
  height: ${props => (props.$height ? props.$height : undefined)};
`;
