import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { SelectListUser } from '../../containers/CreateMessage/createMessageSlice/types';
import { UserSelectItem } from '../Chat/UserSelectItem';
import {
  AdditionalUsersLoaderContainer,
  SList,
  SRecipientItemContainer,
  SLoaderContainer,
} from './RecipientsSelection.styles';
import { selectUser } from '../../containers/Login/LoginSlice';
import Loader from '../Loader/Loader';
import { isInViewport } from '../../utils/helperFunctions';
import { HighlightSearchTerm } from '../Checklists/HighlightSearchTerm/HighlightSearchTerm';

// page and setPage is not used locally, so it an be changed from parent component.
// For example when search text will change setPage(0) will be set from parent
interface UsersSelectionListProps {
  selectedUsersIdsList: number[];
  users: SelectListUser[];
  isLoading?: boolean;
  displayAuthUser?: boolean;
  searchText?: string;
  className?: string;
  page: number;
  emptyListFallbackComponent?: React.ReactElement;
  lockedUsersIds?: number[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onUserClick: (user: SelectListUser) => void;
  loadUsers: () => void;
}

export const UsersSelectionList = (props: UsersSelectionListProps) => {
  const {
    selectedUsersIdsList,
    users,
    isLoading,
    displayAuthUser = true,
    searchText,
    className,
    page,
    emptyListFallbackComponent,
    lockedUsersIds = [],
    setPage,
    onUserClick,
    loadUsers,
  } = props;
  const authUser = useAppSelector(selectUser);
  const usersListRef = useRef<HTMLDivElement>(null);
  const [lastReachedItemIndex, setLastReachedItemIndex] = useState<number>(0);
  const filteredItems = useMemo(() => {
    return users.filter(userItem => displayAuthUser || userItem.id !== authUser?.id);
  }, [users, displayAuthUser, authUser?.id]);

  const handleMouseWheel = () => {
    const lastItemIndex = users.length;
    const haveReachedEnd = isInViewport(document.getElementById(`user-${lastItemIndex}`));
    if (haveReachedEnd && !isLoading && lastReachedItemIndex !== lastItemIndex) {
      setLastReachedItemIndex(lastItemIndex);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (!users.length) loadUsers();
  }, []);

  useEffect(() => {
    handleMouseWheel();
  }, [users]);

  useEffect(() => {
    if (page > 0) {
      loadUsers();
    }
  }, [page]);

  useEffect(() => {
    if (!usersListRef?.current) return;

    usersListRef.current.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, [searchText]);

  const isUserSelected = (itemId: number) => {
    return [...selectedUsersIdsList].includes(itemId);
  };

  const isLocked = (itemId: number) => {
    return lockedUsersIds.includes(itemId);
  };

  if (!users.length && isLoading) {
    return (
      <SLoaderContainer>
        <Loader />
      </SLoaderContainer>
    );
  }

  return (
    <SList onScroll={handleMouseWheel} ref={usersListRef} className={className}>
      {!users.length && isLoading ? <Loader /> : null}
      {!users.length && !isLoading && emptyListFallbackComponent
        ? emptyListFallbackComponent
        : null}
      {filteredItems.map((user, index) => (
        <SRecipientItemContainer
          key={`user-${user.id}`}
          haveDivider={index < users.length - 1}
          role={`userItem-${user.displayName}`}
          id={`user-${index + 1}`}
        >
          <UserSelectItem
            user={user}
            name={HighlightSearchTerm(user.displayName, searchText)}
            isSelected={isUserSelected(user.id) || isLocked(user.id)}
            onCardPress={() => onUserClick(user)}
            photoFileName={user.photoFileName || undefined}
            isLocked={isLocked(user.id)}
          />
        </SRecipientItemContainer>
      ))}
      {users.length && isLoading ? (
        <AdditionalUsersLoaderContainer role="additionalUsersLoader">
          <Loader />
        </AdditionalUsersLoaderContainer>
      ) : null}
    </SList>
  );
};
