import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { translate } from '../../utils/translate';
import { ReactComponent as RightArrow } from '../../assets/imgs/general/right-arrow.svg';
import { ReactComponent as Qustion } from '../../assets/imgs/support/support-qustion.svg';
import { Layout } from '../../components/Layout/Layout';
import { Page } from '../../components/Page/Page';
import { STab } from './Support.styles';
import { SElementContainer } from '../Sidebar/Sidebar.styles';
import i18n, { SupportLanguageCodes } from '../../i18n';

export const Support = () => {
  const [technicalSupportUrl, setTechnicalSupportUrl] = useState(
    process.env.REACT_APP_HELP_CENTER_URL
  );

  const getTechnicalSupportUrl = () => {
    const language =
      SupportLanguageCodes[i18n.language] !== SupportLanguageCodes.se
        ? SupportLanguageCodes[i18n.language]
        : '';

    // #hs-chat-open - allows to open HubSpot chat right after the navigation
    setTechnicalSupportUrl(
      `${process.env.REACT_APP_HELP_CENTER_URL}/${language ?? ''}#hs-chat-open`
    );
  };

  useEffect(() => {
    getTechnicalSupportUrl();
  }, []);

  return (
    <Layout isMessageLayout message="support" to="/sidebar" showBottomTabs>
      <Page>
        <SElementContainer role="elementsContainer">
          <STab as={Link} to={'/internal-support'} role="contactsItem">
            <Qustion role="icon" />
            <p role="title">{translate('profile_support_internal_support')}</p>
            <RightArrow role="arrowIcon" />
          </STab>
          <STab
            as={'a'}
            target="_blank"
            href={technicalSupportUrl}
            rel="noreferrer noopener"
            role="technicalSupportItem"
          >
            <Qustion role="icon" />
            <p role="title">{translate('profile_support_general_support')}</p>
            <RightArrow role="arrowIcon" />
          </STab>
        </SElementContainer>
      </Page>
    </Layout>
  );
};
