import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import { SDescription } from '../FileMessage/FileMessage.styles';
import { toRem } from '../../../utils/toRem';

export const SMessageContent = styled.div<{ addMarginTop?: boolean }>``;

export const SLocationDescription = styled(SDescription)`
  padding: 0;
  padding-bottom: ${toRem(6)};
`;

export const SReplyContainer = styled.div`
  padding-left: ${toRem(10)};
`;

export const SMapMessageViewContainer = styled.div`
  position: relative;
  padding: ${toRem(4)};
  padding-top: 0px;
`;

export const STime = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: ${palette.background};
  height: ${toRem(18)};
  width: ${toRem(38)};
  border-radius: ${toRem(18)};
  font-size: ${toRem(10)};
  font-family: 'Roboto-Medium';
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SNoMapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  place-content: center;

  img {
    width: 100%;
    height: 100%;
    filter: blur(2px);
    object-fit: cover;
    opacity: 0.5;
  }
`;

export const STextContainer = styled.div`
  margin-top: ${toRem(2)};
  margin-left: ${toRem(10)};
  margin-right: ${toRem(10)};
`;

export const SLocationAndTime = styled.p`
  font-size: ${toRem(12)};
  padding-bottom: ${toRem(6)};
  color: ${palette.silver};
`;
