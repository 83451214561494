import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled from 'styled-components';
import BigFloatButton from '../../components/BigFloatButton/BigFloatButton';
import { MessageFilter } from '../../components/Chat/MessageFilter';
import { Page } from '../../components/Page/Page';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';
import { FilterSection, SectionFilterItem } from '../../components/SearchFilterBar/SearchFilterBar';
import { Chat as ChatType, Reply } from '../../containers/ChatsList/chatListSlice/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { palette } from '../../theme/colors';
import { ActiveTab, GroupType } from '../../utils/enums';
import { GroupsToShow } from '../../utils/global';
import { MessageFilterFilterBar } from '../ChatsList/ChatsList';
import { fetchGroups } from '../GroupsList/groupsSlice/actionCreators';
import { selectUser, selectUserRoles } from '../Login/LoginSlice';
import { SelectGroupsList } from './SelectGroupsList';
import { SelectUsersList } from './SelectUsersList';
import {
  getForwardedMessageID,
  getSelectedGroupType,
  getSelectedGroups,
  getSelectedUsers,
  selectActiveTab,
  setActiveTab,
  setSelectedGroupType,
  setSelectedUsers,
} from './createMessageSlice';
import {
  fetchUsers,
  loadAdditionalUsersByPage,
  preselectAuthUser,
  runForwardMessage,
  sendAMessage,
} from './createMessageSlice/actionCreators';
import { getCheckedSelectedUsers } from './helpers';

const SPage = styled(Page)`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;

export const CreateMessageButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CreateMessageListWrapper = styled.div`
  height: 100%;
  min-height: 0;
`;

export const NoMessagesWrapper = styled.div`
  margin-top: 10vh;
  text-align: center;

  p {
    margin-top: 1.2rem;
    font-family: 'Roboto-Regular';
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    mix-blend-mode: normal;
    color: ${palette.platinum};
  }
`;

export const STabs = styled(Tabs)`
  font-size: 12px;
  width: 100%;
  padding-top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const STabList = styled(TabList)<{ $disabled?: boolean }>`
  display: ${props => (props.$disabled ? 'none' : 'flex')};
  list-style-type: none;
  margin: 0;
`;

export const STab = styled(Tab)`
  cursor: pointer;
  color: ${props => props.disabled && palette.silver};
  width: 50%;
  text-align: center;
  user-select: none;
  padding: 30px 0 10px 0;
  border-bottom: 1px solid ${palette.queenBlue};
  font-family: 'Roboto-Regular';
  font-size: 14px;

  &.is-selected {
    border-bottom: 2px solid ${palette.honeyYellow};
    color: ${palette.honeyYellow};
    margin-bottom: -0.5px;
  }

  &:focus {
    outline: none;
  }
`;

export const STabPanel = styled(TabPanel)`
  &.is-selected {
    height: 100%;
    min-height: 0;
    display: block;
    margin-top: 1.25rem;
  }
`;

const GROUP_FILTER_SECTIONS: FilterSection = {
  title: 'messages_show_groups',
  type: 'checkbox',
  content: [
    {
      id: 0,
      name: GroupsToShow.MemberOfGroup,
      checked: true,
    },
    {
      id: 1,
      name: GroupsToShow.NotMemberOfGroup,
      checked: false,
    },
  ],
};
const itemsPerPage = 20;
interface CreateMessageProps {
  forward?: boolean;
  fromHoldingStatement?: boolean;
  selectedReply?: ChatType | Reply | null;
}

const CreateMessageList = (props: CreateMessageProps) => {
  const { forward, selectedReply } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedUsers = useAppSelector(getSelectedUsers);
  const selectedGroups = useAppSelector(getSelectedGroups);

  const selectedType = useAppSelector(getSelectedGroupType);
  const activeTab = useAppSelector(selectActiveTab);
  const roles = useAppSelector(selectUserRoles);
  const user = useAppSelector(selectUser);
  const forwardedMessageID = useAppSelector(getForwardedMessageID);

  const [searchText, setSearchText] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [types, setTypes] = useState<string[]>([GROUP_FILTER_SECTIONS.content[0].name || '']);
  const [groupFilters, setGroupFilters] = useState<FilterSection[]>([GROUP_FILTER_SECTIONS]);
  const [page, setPage] = useState(0);
  const isFilterApplied = types.length !== 1 || types[0] !== GroupsToShow.MemberOfGroup;

  const checkSelectedGroupType = () => {
    const groupType = selectedGroups?.length ? selectedGroups[0].groupType : GroupType.Normal;
    dispatch(setSelectedGroupType([groupType]));
  };
  const checkSelectedUsersListOnLoad = () => {
    const checkedUsers = getCheckedSelectedUsers(selectedUsers ?? []);
    dispatch(setSelectedUsers(checkedUsers));
  };

  const onSearchChange = () => {
    if (activeTab === ActiveTab.Users) {
      setPage(0);
      dispatch(fetchUsers({ sort: 'name', search: searchText, limit: itemsPerPage }));
      if (!searchText?.length) {
        dispatch(fetchGroups({ search: '' }));
      }
    }
    if (activeTab === ActiveTab.Groups) {
      dispatch(fetchGroups({ search: searchText }));
      if (!searchText?.length) {
        setPage(0);
        dispatch(fetchUsers({ sort: 'name', search: '', limit: itemsPerPage }));
      }
    }
  };

  useEffect(() => {
    if (!selectedUsers.length && !selectedGroups.length) {
      dispatch(preselectAuthUser());
    }
    dispatch(fetchUsers({ sort: 'name', limit: itemsPerPage }));
    dispatch(setActiveTab(ActiveTab.Groups));
    checkSelectedUsersListOnLoad();
    checkSelectedGroupType();
  }, [dispatch]);

  useEffect(() => {
    setSearchText('');
  }, [activeTab]);

  useEffect(() => {
    onSearchChange();
  }, [searchText]);

  useEffect(() => {
    if (page > 0) {
      dispatch(
        loadAdditionalUsersByPage({
          sort: 'name',
          search: searchText,
          limit: itemsPerPage,
          skip: page * itemsPerPage || 0,
        })
      );
    }
  }, [page]);

  const onLastUserReached = () => {
    setPage(page + 1);
  };

  const handleOpenFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleForward = () => {
    const groupIDs = selectedGroups.map(grp => grp.id);
    const recipientIDs = selectedUsers.map(usr => usr.id);
    if (selectedReply)
      dispatch(
        sendAMessage(
          {
            text: selectedReply.text ?? '',
            senderId: user?.id,
            groupIds: groupIDs,
            recipientIds: recipientIDs.length > 0 ? recipientIDs : null,
            type: 12,
            locationId: selectedReply.locationID ?? undefined,
            photoFileNames: selectedReply.photoFileNames ?? [],
            documentFileNames: selectedReply.documentFileNames ?? [],
            audioFileNames: selectedReply.audioFileNames ?? [],
          },
          navigate
        )
      );
    else if (forwardedMessageID)
      dispatch(runForwardMessage(forwardedMessageID, navigate, groupIDs, recipientIDs));
  };

  const handleFilter = (filters: FilterSection[]) => {
    setFilterOpen(!filterOpen);
    const newTypes: string[] = filters[0]?.content.reduce(
      (acc: string[], item: SectionFilterItem) => {
        if (item.checked) acc.push(item.name);
        return acc;
      },
      []
    );
    setGroupFilters(filters);
    setTypes(newTypes);
  };

  const canSeeFilter = roles?.includes('SeeOrgGroups');
  const disabled = !selectedType.length ? false : !selectedType.includes(GroupType.Normal);

  const renderButton = () => (
    <CreateMessageButtonWrapper role="createMessageButtonWrapper">
      {forward ? (
        <BigFloatButton onClick={handleForward} tx="messages_proceed" />
      ) : (
        <BigFloatButton link={'/createMessage/new'} tx={'messages_proceed'} />
      )}
    </CreateMessageButtonWrapper>
  );

  return (
    <SPage>
      {canSeeFilter ? (
        <MessageFilterFilterBar
          onSearch={setSearchText}
          handleOpenFilter={handleOpenFilter}
          withoutFilterButton={activeTab === ActiveTab.Users}
          value={searchText}
          isFilterApplied={isFilterApplied}
        />
      ) : (
        <SearchBarBase fallback={setSearchText} value={searchText} />
      )}
      <CreateMessageListWrapper role="recipientsSelectionContainer">
        <STabs selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected">
          <STabList $disabled={disabled}>
            <STab onClick={() => dispatch(setActiveTab(ActiveTab.Groups))}>
              {t('home_groups')} ({selectedGroups.length})
            </STab>
            <STab onClick={() => dispatch(setActiveTab(ActiveTab.Users))}>
              {t('messages_users')} ({selectedUsers.length})
            </STab>
          </STabList>
          <STabPanel>
            <SelectGroupsList
              searchText={searchText}
              types={types}
              emptyListTx={forward ? 'groups_not_found_by_search' : undefined}
            />
            {selectedGroups.length && renderButton()}
          </STabPanel>
          <STabPanel>
            <SelectUsersList searchText={searchText} onEndReached={onLastUserReached} />
            {selectedUsers.length && renderButton()}
          </STabPanel>
        </STabs>
      </CreateMessageListWrapper>
      <MessageFilter
        label={'messages_proceed'}
        isOpen={filterOpen}
        filters={groupFilters}
        setIsOpen={handleOpenFilter}
        setCheckedState={setGroupFilters}
        onFilter={handleFilter}
      />
    </SPage>
  );
};

export default CreateMessageList;
