import React, { useState } from 'react';
import { MapMessageView } from '../MapMessageView/MapMessageView';
import { ReactComponent as LocationPin } from '../../assets/imgs/chats/location-pin-shadow.svg';
import { ReactComponent as GeoYellow } from '../../assets/imgs/chats/geo-yellow.svg';
import { ReactComponent as CheckMark } from '../../assets/imgs/general/check-mark.svg';
import { useTranslation } from 'react-i18next';
import { MapTypeToggleButton } from '../MapTypeToggleButton/MapTypeToggleButton';
import {
  SMapTypeWrapper,
  SCopyCoordinatesButton,
  SMapModal,
  SLocationContainer,
  SLocationRow,
  SIconContainer,
  SBottomInfoWrapper,
  SInfoContainer,
} from './MapModal.styles';

interface MapModalProps {
  isAlarm?: boolean;
  address: string;
  latitude: number;
  longitude: number;
  type: string;
  sent: string;
  recalled: boolean;
  withoutRecalledLabel?: boolean;
  withDate?: boolean;
}

function MapModal(props: MapModalProps) {
  const {
    isAlarm = true,
    latitude,
    longitude,
    address,
    type,
    sent,
    recalled,
    withoutRecalledLabel = false,
    withDate = false,
  } = props;
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const copyCoordinates = () => {
    navigator.clipboard.writeText(`${latitude}, ${longitude}`);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <SMapModal>
      <MapMessageView longitude={longitude} latitude={latitude} modal={true} />
      <SLocationContainer>
        <SLocationRow>
          <SIconContainer>
            <LocationPin width={20} />
          </SIconContainer>
          <p>{address}</p>
        </SLocationRow>
        <SLocationRow>
          <SIconContainer>
            <GeoYellow width={20} />
          </SIconContainer>
          <p>
            {latitude},{longitude}
          </p>
        </SLocationRow>
        <SCopyCoordinatesButton onClick={copyCoordinates}>
          <p>{t(isCopied ? 'copied' : 'copy_coordinates')}</p>
          {isCopied && <CheckMark />}
        </SCopyCoordinatesButton>
      </SLocationContainer>
      <SBottomInfoWrapper>
        <SMapTypeWrapper>
          <MapTypeToggleButton />
        </SMapTypeWrapper>
        <SInfoContainer $withDate={withDate} $withoutRecalledLabel={withoutRecalledLabel} recalled>
          {isAlarm && <p className="title">{type}</p>}
          <p className="date">{`${t('imOk_updated')} ${sent}`}</p>
          {isAlarm && !withoutRecalledLabel && (
            <p className="subText">{t(recalled ? `recalled` : 'messages_active')}</p>
          )}
        </SInfoContainer>
      </SBottomInfoWrapper>
    </SMapModal>
  );
}

export default MapModal;
