import { UserById } from '../../apis/userAPI/types';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';

export function mapUserByIdToSelectUser(
  user: UserById,
  partial?: Partial<SelectListUser>
): SelectListUser {
  return {
    id: user.id,
    displayName: user.displayName,
    title: user.title,
    creatorId: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber,
    photoFileName: user.photoFileName,
    available: user.available,
    unavailable: user.unavailable,
    unavailableDateTo: user.unavailableDateTo || null,

    ...partial,
  };
}
