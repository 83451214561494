// input  2024-11-15T23:00:00

import { isEqual } from 'lodash';
import { User } from '../../apis/authApi/types';
import { dateFormats, getDateFormatCustom, getTimeFromDate } from '../../utils/date';
import { Time } from './SetAvailability';

export const parseUnavailableDate = (date: string | null | undefined): string | undefined => {
  if (!date) return undefined;
  return getDateFormatCustom(date, dateFormats.yearMonthDay);
};

export const parseUnavailableTime = (date: string | null | undefined): Time => {
  if (!date) return { hours: 0, minutes: 0 };
  const time = getTimeFromDate(date);
  const [hours, minutes] = time.split(':');
  return { hours: +hours, minutes: +minutes };
};

export const canDisplayConfirm = (
  isAvailable: boolean,
  date: string | undefined,
  time: Time,
  user: User | null
): boolean => {
  const isAvailableChanged = isAvailable !== user?.available;
  if (isAvailableChanged && isAvailable !== false) {
    return true;
  } else {
    if (!date) return false;
    const dateTime = new Date(`${date}T00:00:00`);
    dateTime.setHours(time.hours, time.minutes, 0, 0);
    const isFutureDate = dateTime.getTime() > Date.now();
    const isDateChanged = date !== parseUnavailableDate(user?.unavailableDateTo);
    const isTimeChanged = !isEqual(time, parseUnavailableTime(user?.unavailableDateTo));
    return isFutureDate && (isDateChanged || isTimeChanged);
  }
};
