/* eslint-disable import/no-extraneous-dependencies */

import { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { Chat } from '../../containers/Chat/Chat';

export const ChatMessages = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isAlarmActive, setIsAlarmActive] = useState(false);

  const data: any = location.state;

  const handleDots = () => {
    navigate(`/message-details/${id}`);
  };

  return (
    <Layout
      isMessageLayout
      to={data?.fromOverview ? '/overview' : '/chat'}
      noTranslate
      showDots
      isAlarmActive={isAlarmActive}
      dotsCallBack={handleDots}
      handleHeaderContentClick={handleDots}
    >
      <Chat id={id!} setIsAlarmActive={setIsAlarmActive} setSubMessage />
    </Layout>
  );
};
