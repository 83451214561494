import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${toRem(1)} solid ${palette.tinyBorder};
  padding-bottom: ${toRem(12)};
  padding-right: ${toRem(9)};
`;

export const SInfoRow = styled.div`
  display: flex;
  align-items: center;
`;

export const SInfoText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'Roboto-Regular';
  color: ${palette.white};
  padding-right: ${toRem(8)};
  .status {
    font-size: ${toRem(15)};
    line-height: ${toRem(18)};
  }
  .endDate {
    margin-top: ${toRem(4)};
    font-size: ${toRem(13)};
    line-height: ${toRem(15)};
    color: ${palette.ashesGray};
  }
`;
