import { Layout } from '../components/Layout/Layout';
import { LoneWorkerTimerRecipientsSelection } from '../containers/LoneWorkerTimer/LoneWorkerTimerRecipientsSelection';

export const LoneWorkerTimerSelectGroupsPage = () => {
  return (
    <Layout isMessageLayout message="send_alarm_to" to="/aloneWorker/create">
      <LoneWorkerTimerRecipientsSelection />
    </Layout>
  );
};
