import { MeetingRoom } from '../../containers/MeetingRoom/meetingRoomSlice/types';
import { ApiCore } from '../utils/core';

const ApiMeetingRoom = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: true,
  singleExtra: true,
  url: '',
});

export const getMeetingRooms = async () => {
  return ApiMeetingRoom.performExtra<MeetingRoom[]>({
    extraResource: `MeetingRooms`,
    method: 'GET',
  });
};
