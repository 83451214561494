import React from 'react';
import styled from 'styled-components';
import {
  SRowContainers,
  SName,
  SInfo,
  SInfoContainer,
} from '../../containers/GroupDetail/GroupDetail.styles';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { getImageLink } from '../../utils/formatImageLink';
import { translate } from '../../utils/translate';

const SGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 3.75rem;
  margin-bottom: 1.25rem;
`;

interface IGroupInfoCardProps {
  name: string;
  membersLength: number;
  imageFileName: string;
  type: string;
}

const GroupInfoCard = (props: IGroupInfoCardProps) => {
  const { membersLength, name, imageFileName, type } = props;

  return (
    <SGroupContainer role={`groupInfo-${name}`}>
      <div>
        <ProfilePicture
          profilePictureFileName={''}
          readyPhotoSource={getImageLink({
            imageName: imageFileName,
            size: 'small',
          })}
          isGroup
          diameter={60}
        />
      </div>
      <SRowContainers role="groupInfoContainer">
        <SName role="groupName"> {name}</SName>
        <SInfoContainer role="groupType" marginTop={true}>
          <SInfo>
            {translate(`groups_type`)}: {type}
          </SInfo>
        </SInfoContainer>
        <SInfoContainer marginTop role="groupMembers">
          <SInfo>
            {membersLength === 1 ? translate('groups_member') : translate(`groups_members`)}:{' '}
            {membersLength}
          </SInfo>
        </SInfoContainer>
      </SRowContainers>
    </SGroupContainer>
  );
};

export default GroupInfoCard;
