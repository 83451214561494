import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SContainer = styled.li`
  padding: 0.68rem 1.25rem;
  width: 100%;
  background-color: ${palette.prussianBlue};
  border-radius: 0.625rem;
  font-family: 'Roboto-Regular';
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${palette.silver};

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  cursor: pointer;

  .date,
  .time {
    align-self: flex-end;
    max-width: 30%;
    min-width: 22%;
    font-size: 0.7125rem;
    text-align: end;
  }
  .date {
    color: ${palette.white};
  }

  .subTitle {
    margin-right: 0.1rem;
  }
  .subTitleWithSender {
    color: ${palette.white};
  }
  .subTitleSender {
    color: ${palette.silver};
  }

  display: flex;
  flex-direction: row;
`;

export const SGrowDiv = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

export const STitle = styled.p`
  color: ${palette.white};
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 1rem;
  margin-right: 0.1rem;
`;

export const SRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  min-width: 0;
  &.top {
    margin-bottom: 0.625rem;
  }
`;

export const SMoreOptionBtn = styled.button`
  background: none;
  border: none;
  padding: 0 0.6rem;
  margin-left: 0.6rem;
  cursor: pointer;
`;
