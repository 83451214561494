import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SDrawerWrapper = styled.div<{ $moreMaxHeight?: boolean }>`
  .reactionsDrawer {
    position: fixed;
    bottom: 0;
    left: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1001;
    overflow-y: hidden;
    @media (min-width: 450px) {
      max-width: 26rem;
      margin: auto;
    }
    background-color: ${palette.prussianBlue2};
    max-height: ${toRem(390)};
  }
  .reactionsDrawer__backdrop {
    z-index: 1000;
  }
  .reactionsDrawer__handle-wrapper {
  }
  .reactionsDrawer__handle {
    width: 36%;
    margin-top: 1.3125rem;
  }
  .content {
    padding: 0.5rem 1.25rem 1.25rem;
    padding-bottom: ${toRem(20)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${toRem(340)};
  }

  p.STitle {
    margin-left: 0rem;
  }
`;

export const SReactionsEmojisRow = styled.div`
  background-color: ${palette.prussianBlue2};
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${palette.tinyBorder};
`;

export const STabHeader = styled.div<{ isSelected?: boolean; itemsCount: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${toRem(4)};
  cursor: pointer;
  width: calc(100% / ${props => props.itemsCount});
  border-bottom: ${props => props.isSelected && `2px solid ${palette.honeyYellow}`};
  padding-bottom: ${props => toRem(props.isSelected ? 12 : 14)};

  p.emoji {
    font-size: ${toRem(16)};
    font-family: 'AppleColorEmoji', serif;
  }
  p.count {
    font-size: ${toRem(16)};
    line-height: ${toRem(22)};
    padding-bottom: ${toRem(2)};
  }
`;

export const SUserContainer = styled.div<{ isAuthUser: boolean; isLastItem: boolean }>`
  cursor: ${props => props.isAuthUser && 'pointer'};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toRem(12)};
  padding: ${toRem(12)} 0;
  border-bottom: 1px solid ${props => (props.isLastItem ? palette.transparent : palette.tinyBorder)};
`;

export const SUserInfoContainer = styled.div`
  .name {
    font-size: ${toRem(16)};
    line-height: ${toRem(22)};
  }
  .removeReaction {
    font-size: ${toRem(12)};
    line-height: ${toRem(14)};
    color: ${palette.doveGray};
  }
`;

export const SUsersListContainer = styled.div`
  height: 100%;
  padding-bottom: 0.5rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SLoaderContainer = styled.div`
  display: flex;
`;
