import { decode } from 'html-entities';
import { compact } from 'lodash';
import { checkIfDateIsToday, dateFormats, getDateFormatCustom } from '../../utils/date';
import { MessageReplyType, ReceivedMessageType, SentMessageType } from '../../utils/enums';
import { translate } from '../../utils/translate';
import { messageReplyTextGenerator } from '../ChatsList/chatListSlice/actionCreators';
import { Chat, MessageInList } from '../ChatsList/chatListSlice/types';

export const getHoldingStatement = (messages: MessageInList[]) => {
  return messages.filter(message => message?.type === SentMessageType.HoldingStatement);
};

export const getMessageSender = (message: MessageInList, userId: number): string => {
  const { lastReplySenderId, senderID, lastReplySender, senderName } = message;
  const lastSenderId = lastReplySenderId || senderID;
  if (userId === lastSenderId) {
    const sender = translate('messages_you');
    return sender;
  } else {
    return lastReplySender || senderName;
  }
};

export const getMessageSubject = (message: MessageInList): string => {
  const alert = message.type === ReceivedMessageType.Alarm && !message.recalled;
  if (alert) return translate('alert');
  if (message.recalled) return translate('messages_alert_canceled');
  return message.subject || translate('home_logNotes');
};

export const handleDate = (
  message: MessageInList,
  timeKey: keyof Pick<Chat, 'lastReplySent' | 'sent'>
): string | undefined => {
  const dateText = message[timeKey];
  if (!dateText) {
    return undefined;
  } else {
    if (checkIfDateIsToday(dateText)) {
      return getDateFormatCustom(dateText, dateFormats.simpleTime24);
    } else {
      return getDateFormatCustom(dateText, dateFormats.monthNameShortDateTimeNoComma24);
    }
  }
};

export const messageShowGenerator = (message: MessageInList, userId: number): string => {
  return decode(
    messageReplyTextGenerator(MessageReplyType.Reply, message, userId) ||
      messageReplyTextGenerator(MessageReplyType.Message, message, userId) ||
      ''
  );
};

export const getStatementTitle = (message: MessageInList): string => {
  return message.groupName || translate('messages_you');
};

export const getRecipientsMedia = (message: MessageInList): string[] => {
  if (!message.messageRecipients) return [];
  const usersPhotoNameList = compact(
    message.messageRecipients.map(recipient => {
      if (recipient.photoFileName && recipient.photoFileName.trim() !== '') {
        return recipient.photoFileName;
      }
      return null;
    })
  );
  return usersPhotoNameList;
};
