import { useLocation, useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import CreateChecklist from '../../containers/Checklists/CreateChecklist';

function CreateChecklistPage() {
  const location = useLocation();
  const data = location.state;
  const { checklistName } = useParams();

  return (
    <Layout
      isMessageLayout
      message={checklistName ?? 'checklist_create_checklist'}
      to={data?.fromNew ? '/checklists/new' : '/checklists'}
      showBottomTabs
    >
      <CreateChecklist />
    </Layout>
  );
}

export default CreateChecklistPage;
