import { User } from '../../apis/authApi/types';
import { GroupType } from '../../utils/enums';
import { translate } from '../../utils/translate';
import { GroupMember } from '../GroupDetail/groupDetailSlice/types';
import { Group } from '../GroupsList/groupsSlice/types';
import { mapGroupMemberToSelectUser } from '../MemberSettings/helpers';
import { SelectedGroup } from './SelectGroupsList';
import { SelectListUser } from './createMessageSlice/types';

export const isSameUser = (a: SelectListUser, b: GroupMember) => a.id === b.userID;

export const onlyInSelectListUser = (
  selectedList: SelectListUser[],
  groupMembers: GroupMember[],
  compareFunction: (a: SelectListUser, b: GroupMember) => boolean
) =>
  selectedList.filter(
    leftValue => !groupMembers.some(rightValue => compareFunction(leftValue, rightValue))
  );

export const intersicetedWithGroupMembers = (
  selectedList: SelectListUser[],
  groupMembers: GroupMember[],
  compareFunction: (a: SelectListUser, b: GroupMember) => boolean
) =>
  selectedList.filter(leftValue =>
    groupMembers.some(rightValue => compareFunction(leftValue, rightValue))
  );

export const isHiddenCrossOrgOrCoAlert = (group: Group): boolean => {
  return [GroupType.CoAlert, GroupType.Hidden, GroupType.CrossOrg].includes(group.groupType);
};

export const isAddGroupHidden = (
  selectedGroups: SelectedGroup[],
  disableRecipientsChange = false
): boolean => {
  const selectedGroupsType = selectedGroups.map(g => g.groupType);
  const areGroupsSingular = [GroupType.CoAlert, GroupType.Hidden, GroupType.CrossOrg].some(v =>
    selectedGroupsType.includes(v)
  );

  return areGroupsSingular || disableRecipientsChange;
};

export const checkGroupsType = (groupType: number) => {
  if (groupType === GroupType.Hidden) {
    return translate('messages_hidden');
  }
  if (groupType === GroupType.CrossOrg) {
    return translate('groups_cossOrg');
  }
  if (groupType === GroupType.CoAlert) {
    return translate('CoAlert');
  }
  return null;
};

export const isGroupActive = (selectedGroups: SelectedGroup[], groupId: number) => {
  const foundIndex = selectedGroups.findIndex(group => group.id === groupId);
  return foundIndex > -1;
};

export const addGroupMembersToSelectedAndGetList = (
  members: GroupMember[],
  selectedUsersList: SelectListUser[]
) => {
  const selectedUsers = [...selectedUsersList];
  members.forEach(member => {
    const userIndex = selectedUsers.findIndex(user => user.id === member.userID);
    if (!member.groupID) {
      return;
    }
    if (userIndex > -1) {
      const newUser = { ...selectedUsers[userIndex] };
      newUser.groupIds = newUser.groupIds
        ? [...newUser.groupIds, member.groupID]
        : [member.groupID];
      selectedUsers[userIndex] = newUser;
    } else {
      const selectedUser: SelectListUser = mapGroupMemberToSelectUser(member, {
        isSelected: true,
        groupIds: [member.groupID],
      });
      selectedUsers.push(selectedUser);
    }
  });
  return selectedUsers;
};

export const getSelectedUsersAfterGroupUnselection = (
  selectedUsersList: SelectListUser[],
  groupId: number
) => {
  const usersInSelectedGroup = selectedUsersList.filter(user => user.groupIds?.includes(groupId));
  const usersNotInSelectedGroup = selectedUsersList.filter(
    user => !user.groupIds?.includes(groupId)
  );
  const newSelectedUsers = [...usersNotInSelectedGroup];
  usersInSelectedGroup.forEach(user => {
    const newUser = { ...user };
    newUser.groupIds = user.groupIds?.filter(id => id !== groupId);
    if (newUser.groupIds?.length) {
      newSelectedUsers.push(newUser);
    }
  });
  return newSelectedUsers;
};

export const getActiveGroupsAfterUserUnselection = (
  selectedUsers: SelectListUser[],
  selectedGroups: SelectedGroup[],
  authUserId: number
) => {
  let activeGroupsIds: number[] = [];
  selectedUsers.forEach(user => {
    if (user.groupIds && user.id !== authUserId) {
      activeGroupsIds = [...activeGroupsIds, ...user.groupIds];
    }
  });
  const uniqueActiveGroupsIds = Array.from(new Set(activeGroupsIds));
  return selectedGroups.filter(group => uniqueActiveGroupsIds.includes(group.id));
};

export const getSelectedUsersText = (
  selectedUsers: SelectListUser[],
  allUsers: SelectListUser[],
  authUserId?: number
) => {
  const filteredSelectedUsers = selectedUsers.filter(user => user.id !== authUserId);
  const filteredAllUsers = allUsers.filter(user => user.id !== authUserId);
  if (filteredSelectedUsers.length === filteredAllUsers.length) {
    return translate(`messages_allMembers`);
  } else if (!!filteredSelectedUsers.length) {
    return filteredSelectedUsers
      .filter(user => !!user.displayName?.length)
      .map(user => user.displayName)
      .join(', ');
  }
  return translate(`messages_selectRecipients`);
};

export const getSelectedGroupsAndSelectedUsersAfterFilter = (
  filteredUsers: SelectListUser[],
  selectedGroups: SelectedGroup[],
  authUser?: User | null
) => {
  let newSelectedUsers = [...filteredUsers];
  const foundAuthUser = newSelectedUsers.find(userItem => userItem.id === authUser?.id);
  if (!foundAuthUser) {
    newSelectedUsers.push({
      id: authUser?.id,
      isSelected: true,
      groupIds: null,
      displayName: authUser?.name,
    } as SelectListUser);
  }

  const newSelectedGroups = getActiveGroupsAfterUserUnselection(
    newSelectedUsers.filter(userItem => userItem.isSelected),
    selectedGroups,
    authUser?.id ?? 0
  );

  const newSelectedGroupsIds = newSelectedGroups.map(group => group.id);
  const unselectedGroups = selectedGroups.filter(group => !newSelectedGroupsIds.includes(group.id));

  unselectedGroups.forEach(group => {
    newSelectedUsers = getSelectedUsersAfterGroupUnselection(newSelectedUsers, group.id);
  });
  return { newSelectedUsers, newSelectedGroups };
};

export const getCheckedSelectedUsers = (selectedUsers: SelectListUser[]) => {
  return selectedUsers.filter(user => user.isSelected);
};
