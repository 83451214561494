import _ from 'lodash';
import userImg from '../../assets/imgs/general/single-place-holder.svg';
import avatar from '../../assets/imgs/general/groups-place-holder.svg';
import { ProfilePicture } from './ProfilePicture';
import { PlaceHolderWrapper } from './ProfilePicture.styles';
import styled from 'styled-components';
import { getImageLink } from '../../utils/formatImageLink';

interface ProfilePictureRowProps {
  profilePictureFileNames: string[];
  isGroup?: boolean;
  diameter?: number;
}

const SRowContainer = styled.div<any>`
  display: flex;
  position: relative;
  height: ${props => `${props.diameter}px`};
  min-width: ${props => `${props.diameter}px`};
  * + * {
    margin-left: -2.35rem;
  }
`;

export const ProfilePictureRow = (props: ProfilePictureRowProps) => {
  const { profilePictureFileNames, isGroup, diameter = 45 } = props;

  const noImage = isGroup ? avatar : userImg;
  if (!_.isEmpty(profilePictureFileNames)) {
    return (
      <SRowContainer
        diameter={diameter}
        length={profilePictureFileNames.length}
        role="profilePicturesRow"
      >
        {profilePictureFileNames.map((img, key) => (
          <ProfilePicture
            key={key}
            profilePictureFileName={''}
            diameter={diameter}
            isGroup={isGroup}
            readyPhotoSource={getImageLink({
              imageName: img,
              size: 'small',
            })}
            zIndex={profilePictureFileNames.length - key}
          />
        ))}
      </SRowContainer>
    );
  } else {
    return <PlaceHolderWrapper role="placeholderImage" src={noImage} alt="" diameter={diameter} />;
  }
};
