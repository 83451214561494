import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect, useState } from 'react';
import { GroupsSelectionList } from '../../components/RecipientsSelection/GroupsSelectionList';
import { Group } from '../GroupsList/groupsSlice/types';
import { getGroups, getGroupsLoading } from './PanicButtonSlice.tsx';
import { fetchGroups } from './PanicButtonSlice.tsx/actionCreators';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import emptyListIcon from '../../assets/imgs/NotFound/no-result.svg';

interface PanicGroupsListProps {
  showActionButtons: boolean;
  selectedGroupsIdsList: number[];
  isActive?: boolean;
  searchText?: string;
  onGroupClick: (group: Group) => void;
}

const GROUPS_PER_PAGE = 20;
export const PanicGroupsList = (props: PanicGroupsListProps) => {
  const {
    showActionButtons,
    selectedGroupsIdsList,
    isActive = true,
    searchText,
    onGroupClick,
  } = props;
  const dispatch = useAppDispatch();
  const groups = useAppSelector(getGroups);
  const groupsLoading = useAppSelector(getGroupsLoading);
  const [page, setPage] = useState(0);

  const loadGroupsList = (pageNumber?: number, search?: string) => {
    const activePage = pageNumber ?? page ?? 0;
    const activeSearch = search ?? searchText ?? '';
    dispatch(
      fetchGroups({
        limit: GROUPS_PER_PAGE,
        skip: activePage * GROUPS_PER_PAGE,
        search: activeSearch,
      })
    );
  };

  useEffect(() => {
    if (isActive) {
      setPage(0);
      loadGroupsList(0);
    }
  }, [searchText]);

  useEffect(() => {
    if (!isActive) {
      setPage(0);
      loadGroupsList(0, '');
    }
  }, [isActive]);

  return (
    <>
      {isActive && (
        <GroupsSelectionList
          selectedGroupsIdsList={selectedGroupsIdsList}
          groups={groups}
          isLoading={groupsLoading}
          className={showActionButtons && groups.length > 5 ? 'lwtBottomPadding' : undefined}
          page={page}
          setPage={setPage}
          onGroupClick={onGroupClick}
          loadGroups={loadGroupsList}
          searchText={searchText}
          emptyListFallbackComponent={
            <EmptyListFallback
              src={emptyListIcon}
              listLength={groups?.length ?? 0}
              searchTerm={searchText}
              emptyListTx={'no_groups_found'}
              noSearchTx={'groups_not_found_by_search'}
            />
          }
        />
      )}
    </>
  );
};
