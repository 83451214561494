/* eslint-disable import/named */

import axios, { Method } from 'axios';
import { getItem } from '../../utils/storage';
import { handleResponse, handleError } from './response';
import isElectron from 'is-electron';

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
export const getApiBaseUrl = (customUrl?: string) => {
  if (customUrl) {
    return `${customUrl}/api`;
  }
  if (isElectron() || process.env.REACT_APP_API_URL_COM == null) {
    return `${process.env.REACT_APP_API_URL}/api`;
  } else {
    return `${
      window.location.host.includes('cosafe.com')
        ? process.env.REACT_APP_API_URL_COM
        : process.env.REACT_APP_API_URL
    }/api`;
  }
};
export let BASE_URL = getApiBaseUrl();
export const changeBaseUrl = (_url: string) => {
  BASE_URL = getApiBaseUrl(_url);
};

const csrf = getItem('csrf');
const addVersion = (version?: string): string => {
  return version ? version + '/' : '';
};

const getAll = <T>(resource: string, version?: string): Promise<T> => {
  return axios
    .get(`${BASE_URL}/${addVersion(version)}${resource}`, {
      withCredentials: true,
    })
    .then(handleResponse)
    .catch(handleError);
};

const getSingle = <T>(resource: string, id: number, version?: string): Promise<T> => {
  return axios
    .get(`${BASE_URL}/${addVersion(version)}${resource}/${id}`, {
      withCredentials: true,
    })
    .then(handleResponse)
    .catch(handleError);
};

const post = <T>(resource: string, model: object, version?: string): Promise<T> => {
  return axios
    .post(`${BASE_URL}/${addVersion(version)}${resource}`, model, {
      withCredentials: true,
      headers: {
        'X-XSRF-Token': csrf,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

const put = <T>(resource: string, model: object, version?: string): Promise<T> => {
  return axios
    .put(`${BASE_URL}/${addVersion(version)}${resource}`, model, {
      withCredentials: true,
      headers: {
        'X-XSRF-Token': csrf,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

const patch = <T>(resource: string, model: object, version?: string): Promise<T> => {
  return axios
    .patch(`${BASE_URL}/${addVersion(version)}${resource}`, model, {
      withCredentials: true,
      headers: {
        'X-XSRF-Token': csrf,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

const remove = <T>(resource: string, id: number, version?: string): Promise<T> => {
  return axios
    .delete(`${BASE_URL}/${addVersion(version)}${resource}`, {
      data: {
        id,
      },
      withCredentials: true,
      headers: {
        'X-XSRF-Token': csrf,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

const performExtra = <T>(
  method: Method,
  resource: string,
  extraResource: string,
  returnErrorResponseData?: boolean,
  id?: number,
  modal?: object,
  params?: object,
  buffer?: boolean,
  headers?: any,
  version?: string
): Promise<T> => {
  return axios
    .request({
      baseURL: BASE_URL,
      method,
      data: modal,
      params: params,
      url: `${addVersion(version)}${resource}/${id ? id + '/' : ''}${extraResource}`,
      withCredentials: true,
      responseType: buffer ? 'arraybuffer' : 'json',
      headers: {
        'cosafe-api-version': process.env.REACT_APP_COSAFE_API_VERSION,
        ...headers,
      },
    })
    .then(res => {
      return handleResponse(res);
    })
    .catch(error => handleError(error, returnErrorResponseData));
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
  performExtra,
};
