import { SSpace, SName } from './TitledAlarmTypeGroup.styles';
import { AlarmsAccountTitle } from '../../containers/Alarm/Alarm';
import AlarmType from './AlarmType';

interface TitledAlarmTypeGroupProps {
  titledAlarmsGroup: AlarmsAccountTitle;
  currentLang?: string;
}

export function TitledAlarmTypeGroup(props: TitledAlarmTypeGroupProps) {
  const { titledAlarmsGroup, currentLang } = props;

  return (
    <div>
      <SName>{titledAlarmsGroup.name}</SName>
      {titledAlarmsGroup.alarms.map((item, key) => (
        <AlarmType
          key={`${titledAlarmsGroup.name}-${key}`}
          alarmTypeItem={item}
          currentLang={currentLang!}
        />
      ))}
      <SSpace />
    </div>
  );
}
