import { ReactComponent as CoSafeLogo } from '../../../assets/imgs/general/cosafe-logo.svg';
import { ReactComponent as BackBtn } from '../../../assets/imgs/general/back-arrow-yellow.svg';
import { ReactComponent as BackBtnWhite } from '../../../assets/imgs/chats/back-btn-white.svg';
import { useAppSelector } from '../../../hooks';
import { selectUser } from '../../../containers/Login/LoginSlice';
import DotsHorizontal from '../../../assets/imgs/navigation/header-dots-horizontal.svg';
import { translate } from '../../../utils/translate';
import { getImageLink } from '../../../utils/formatImageLink';
import _ from 'lodash';
import { ReactNode } from 'react';
import {
  SHeaderWrapper,
  SHeader,
  Logo,
  SBalanceSpace,
  SLink,
  SSideMenu,
  SDots,
  SHeaderMessageWrapper,
  HeaderMessageText,
  SSubMessageText,
} from './Header.styles';

export interface IHeaderProps {
  isMessageLayout: boolean;
  message: string;
  noTranslate: boolean;
  to?: string;
  backBtn?: boolean;
  showCoSafeLogo?: boolean;
  subMessageText?: string | null;
  showDots?: boolean;
  dotsCallBack?: () => void;
  handleHeaderContentClick?: () => void;
  isAlarmActive: boolean;
  backBtnCallBack?: (e: any) => void;
  renderCustomRightButton?: () => ReactNode;
}

export const Header = (props: IHeaderProps) => {
  const {
    isMessageLayout,
    message,
    noTranslate,
    to,
    backBtn,
    showCoSafeLogo,
    subMessageText,
    showDots,
    dotsCallBack,
    handleHeaderContentClick,
    isAlarmActive,
    backBtnCallBack,
    renderCustomRightButton,
  } = props;
  const user = useAppSelector(selectUser);

  return (
    <SHeaderWrapper isAlarmActive={isAlarmActive} id="headerWrapper">
      <SHeader id="header" extra={!isMessageLayout && !to} isAlarmActive={isAlarmActive}>
        {!isMessageLayout && (
          <>
            {backBtn && to ? (
              <SLink to={to} role="backButton">
                <BackBtn />
              </SLink>
            ) : (
              <SBalanceSpace role="whiteSpace" />
            )}
            {showCoSafeLogo || !user?.organizationLogo ? (
              <CoSafeLogo id="cosafeLogo" />
            ) : (
              <Logo
                src={getImageLink({
                  imageName: user?.organizationLogo,
                })}
                alt=""
                id="logo"
              />
            )}
            {!backBtn ? (
              <SLink to="/sidebar" id="sidemenuIcon">
                <SSideMenu />
              </SLink>
            ) : (
              <SBalanceSpace role="whiteSpace" />
            )}
          </>
        )}
        {isMessageLayout && to && (
          <>
            <SLink to={to} onClick={e => backBtnCallBack && backBtnCallBack(e)} role="backButton">
              {isAlarmActive ? <BackBtnWhite /> : <BackBtn />}
            </SLink>
            <SHeaderMessageWrapper
              id="headerMessageWrapper"
              pointer={!!handleHeaderContentClick}
              onClick={handleHeaderContentClick}
            >
              <HeaderMessageText id="headerMessage">
                {noTranslate
                  ? _.truncate(message, { length: 52 })
                  : translate(_.truncate(message, { length: 52 }))}
              </HeaderMessageText>
              {subMessageText && (
                <SSubMessageText id="headerSubmessage">{subMessageText}</SSubMessageText>
              )}
            </SHeaderMessageWrapper>
            {/* improve the implementation if more than 2 buttons variant needed  */}
            {showDots ? (
              <SDots
                id="headerMoreDots"
                role="menu"
                width={32}
                src={DotsHorizontal}
                alt=""
                onClick={dotsCallBack}
              />
            ) : !!renderCustomRightButton ? (
              renderCustomRightButton()
            ) : (
              <SBalanceSpace role="whiteSpace" />
            )}
          </>
        )}
      </SHeader>
    </SHeaderWrapper>
  );
};
