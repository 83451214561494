import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';

export const SPage = styled(Page)`
  padding-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  * + * {
    margin-top: 0.5rem;
  }
  margin-top: 1.25rem;
  overflow-y: auto;
  height: 100%;
  min-height: 0;
  padding-bottom: 5rem;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  width: 100%;
`;

export const EmptyListFallbackContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 3rem;
`;
