import ChecklistItem from './ChecklistItem';
import emptyList from '../../assets/imgs/NotFound/no-result.svg';
import { EmptyListFallback } from '../EmptyListFallback/EmptyListFallback';
import { TabProps } from '../../containers/Checklists/Checklists.models';

interface ActiveProps extends TabProps {
  overview?: boolean;
}

function Active(props: ActiveProps) {
  const { checklists, onDotsClick, overview, searchText } = props;
  return (
    <div role="activeTab">
      {checklists.map(item => (
        <ChecklistItem
          checklist={item}
          key={`Active-${item.id}`}
          onDotsClick={onDotsClick}
          overview={overview}
          searchText={searchText}
        />
      ))}
      {checklists.length === 0 && (
        <EmptyListFallback
          src={emptyList}
          listLength={checklists.length}
          isLoading={false}
          searchTerm={searchText}
          emptyListTx={'empty_checklists'}
          noSearchTx={'messages_not_found_search'}
        />
      )}
    </div>
  );
}

export default Active;
