import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { CreateHoldingStatement } from './CreateHoldingStatement';
import { CreateHoldingStatementCtxProvider } from './CreateHoldingStatementContext';
import { CreateHoldingStatmentSummary } from './CreateHoldingStatmentSummary';

const SPage = styled(Page)`
  padding: 1.25rem 0 0 0;
  /* to make only the list overflow */
  position: relative;
  height: 100%;
`;

export const CreateHoldingStatementContainer = () => {
  const { pathname } = useLocation();
  const { setTabsState, toggleTabBarVisibility } = useLayoutContext();

  React.useEffect(() => {
    if (pathname === '/createHoldingStatement/summary') {
      toggleTabBarVisibility();
    } else {
      setTabsState(true);
    }
  }, [pathname]);

  return (
    <SPage>
      <CreateHoldingStatementCtxProvider>
        <Routes>
          <Route path="/" element={<CreateHoldingStatement />} />
          <Route path="summary" element={<CreateHoldingStatmentSummary />} />
        </Routes>
      </CreateHoldingStatementCtxProvider>
    </SPage>
  );
};
