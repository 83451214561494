import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import { toRem } from '../../../utils/toRem';

export const SFilesMessageContainer = styled.div<{ haveText?: boolean }>`
  margin-bottom: ${props => !props.haveText && toRem(30)};
`;

export const SPdfInfoContainer = styled.div<{ marginBottom?: boolean; isSent?: boolean }>`
  margin-bottom: ${props => props.marginBottom && '0.25rem'};
  background-color: #ffffff0d;
  border-radius: ${toRem(8)};
  padding: ${toRem(6)} ${toRem(9)};
`;

export const SFileName = styled.p`
  font-family: 'Roboto-Medium';
  color: ${palette.navyLighter};
  fonts-size: ${toRem(16)};
  line-height: ${toRem(20.8)};
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
`;

export const SDescription = styled.p`
  font-family: 'Roboto-Regular';
  font-size: ${toRem(16)};
  line-height: ${toRem(20.8)};
  padding: ${toRem(4)} ${toRem(6)} 0;
  white-space: pre-line;
  .editedReplacer {
    font-size: ${toRem(11)};
    color: ${palette.transparent};
  }
`;

export const SPdfSizeText = styled.p<{ marginBottom?: boolean }>`
  font-weight: 400;
  font-size: ${toRem(11)};
  line-height: ${toRem(14.3)};
  color: ${palette.navy20};
`;

export const SPdfContainer = styled.a`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 0.75rem;
  color: ${palette.white};
  text-decoration: none;
`;
