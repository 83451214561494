import styled, { css } from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';

const basicTextStyle = css`
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 140%;
`;

const textInputStyles = css`
  ${basicTextStyle};
  color: ${palette.silver};
  font-family: 'Roboto-Thin';
  font-size: 13px;
  resize: none;
`;

export const SPage = styled(Page)`
  padding: 1.25rem 0 0;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
`;

export const SNewAlarmText = styled.p`
  margin: 1rem 0.25rem;
  ${basicTextStyle}
  color: ${palette.white};
`;

export const SInputContainer = styled.p`
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`;

export const STextInput = styled.textarea`
  width: calc(100% - 3rem);
  padding: 0.9375rem 1rem 0.9375rem 1rem;
  min-height: 100%;
  background: ${palette.prussianBlue};
  border: 1px solid ${palette.queenBlue};
  box-shadow: 0px 11px 15px rgba(90, 90, 90, 0.1);
  backdrop-filter: blur(10.8731px);
  border-radius: 12px;
  ${textInputStyles};
  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    min-height: 100%;
    ${textInputStyles};
  }
  :-ms-input-placeholder {
    min-height: 100%;
    ${textInputStyles};
  }
`;

export const SDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${palette.cloudBurst};
  padding: 1rem;
  border-radius: 10px;
  max-width: calc(100% - 3rem);
  min-height: 3rem;
`;

export const SDescription = styled.p`
  padding-left: 6px;
  ${basicTextStyle}
  color: ${palette.white};
`;
