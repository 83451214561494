import { palette } from '../../theme/colors';
import styled from 'styled-components';

export const STitle = styled.p`
  color: ${palette.white};
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 1rem;
`;

export const SSubTitle = styled.p`
  color: ${palette.white};
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 1rem;
  margin: 1.25rem 0 1rem;
`;

export const SAddComment = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  width: fit-content;
  color: ${palette.honeyYellow};
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
`;

export const SCommentsList = styled.div`
  max-height: 20rem;
  display: flex;
  flex-direction: column;

  .comment + * {
    margin-top: 0.5rem;
  }
  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
