import React from 'react';
import { Checklist } from '../../../containers/Checklists/checklistsSlice/types';
import { SName } from '../../../containers/GroupDetail/GroupDetail.styles';
import { GroupDetail } from '../../../containers/GroupDetail/groupDetailSlice/types';
import { getNumbersBasedDateTextByLanguage } from '../../../utils/date';
import { ChecklistStatus } from '../../../utils/enums';
import { translate } from '../../../utils/translate';
import { SHeader } from '../../FilterOrSelectBottomSheet/styles';
import {
  SBottomWrapper,
  SChecklistSettings,
  SChecklistSettingsWrapper,
  SRow,
  SSharedContainer,
  SValue,
  SWrapper,
  SLine,
} from './ChecklistDetails.styles';

interface ChecklistInfoProps {
  data: Checklist;
  foundSharedGroups: GroupDetail[];
}

function ChecklistInfo(props: ChecklistInfoProps) {
  const { foundSharedGroups, data } = props;
  const { name, status, created, owner, totalTasks, lastEdited, lastEditor, ended, userNames } =
    data;
  return (
    <SChecklistSettingsWrapper className="wrapper" role="checklistInfo">
      <SChecklistSettings role="settingsContainer">
        <>
          <SHeader role="name">{name}</SHeader>
          <SWrapper role="statsContainer">
            {status === ChecklistStatus.Started && (
              <SRow role="templateContainer">
                <SName role="label">{translate(`modal_template_name`)}</SName>
                <SValue role="value">{name}</SValue>
              </SRow>
            )}
            <SRow role="countContainer">
              <SName role="label">{translate(`modal_item_count`)}</SName>
              <SValue role="value">{totalTasks}</SValue>
            </SRow>
            <SRow role="modifiedContainer">
              <SName role="label">{translate(`modal_modified`)}</SName>
              {lastEdited ? (
                <SValue role="value">
                  <p role="dateText">
                    {getNumbersBasedDateTextByLanguage({ dateText: lastEdited })}
                    {` ${translate(`groups_sortBy`).substring(5, 7)} ${lastEditor?.displayName}`}
                  </p>
                </SValue>
              ) : (
                <SValue role="value">{translate('checklist_not_edited')}</SValue>
              )}
            </SRow>
            <SRow role="creatorContainer">
              <SName role="label">{translate(`modal_creator`)}</SName>
              <SValue role="value">{owner?.displayName ?? translate(`deleted_user`)}</SValue>
            </SRow>
            {ended && (
              <SRow role="endedContainer">
                <SName role="label">{translate(`modal_ended`)}</SName>
                <SValue role="value">
                  {getNumbersBasedDateTextByLanguage({ dateText: ended })}
                </SValue>
              </SRow>
            )}
            <SRow role="createdContainer">
              <SName role="label">{translate(`modal_created`)}</SName>
              <SValue role="value">
                {getNumbersBasedDateTextByLanguage({ dateText: created })}
              </SValue>
            </SRow>
          </SWrapper>
        </>
        <SLine />
        <SBottomWrapper role="bottomWrapper">
          <SRow role="sharedWithContainer">
            <SName role="label">{translate(`modal_shared_with`)}</SName>
            <SSharedContainer role="sharedWithRow">
              {foundSharedGroups.map((e, i) => (
                <SValue role="value" key={i}>
                  {e.name}
                </SValue>
              ))}
              {!foundSharedGroups.length &&
                userNames.map(userName => (
                  <SValue role="value" key={`user-${userName}`}>
                    {userName}
                  </SValue>
                ))}
            </SSharedContainer>
          </SRow>
        </SBottomWrapper>
      </SChecklistSettings>
    </SChecklistSettingsWrapper>
  );
}

export default ChecklistInfo;
