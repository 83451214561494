import { formatBytes } from '../utils/formatBytes';
import { getAntiForgeryToken } from './authApi/authAPI';
import { mediaAPI } from './utils/core';

export interface GetImageProps {
  imageName: string | null;
  size?: 'small' | 'medium' | 'large';
  svg?: boolean;
  getSize?: boolean;
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const getImage = async (props: GetImageProps) => {
  const { imageName, size, svg } = props;
  if (!imageName) return '';
  const imageWithoutExtention = imageName.replace(/\.[^/.]+$/, '');
  const imageExtention = imageName.split('.').pop();
  const response = await mediaAPI.performExtra<ArrayBuffer>({
    method: 'GET',
    buffer: true,
    extraResource: `file/${imageWithoutExtention}${size ? '_' + size : ''}.${imageExtention}`,
  });
  if (!response) return '';
  return `data:image/${svg ? 'svg+xml' : 'png'};base64, ${arrayBufferToBase64(response)}`;
};

export const getImageWithSize = async (props: GetImageProps) => {
  const { imageName, size, svg } = props;
  if (!imageName) return { image: '', size: '0' };
  const imageWithoutExtention = imageName.replace(/\.[^/.]+$/, '');
  const imageExtention = imageName.split('.').pop();
  const response = await mediaAPI.performExtra<ArrayBuffer>({
    method: 'GET',
    buffer: true,
    extraResource: `file/${imageWithoutExtention}${size ? '_' + size : ''}.${imageExtention}`,
  });
  if (!response) return { image: '', size: '0' };
  return {
    image: `data:image/${svg ? 'svg+xml' : 'png'};base64, ${arrayBufferToBase64(response)}`,
    size: formatBytes(response.byteLength),
  };
};

export const getFile = async (name: string, getSize = false) => {
  const response = await mediaAPI.performExtra<any>({
    method: 'GET',
    buffer: true,
    extraResource: `file/${name}`,
  });

  if (!response) return { file: '' };
  if (getSize) {
    return {
      file: `data:application/pdf;base64, ${arrayBufferToBase64(response)}`,
      size: formatBytes(response.byteLength),
    };
  } else {
    return {
      file: `data:application/pdf;base64, ${arrayBufferToBase64(response)}`,
    };
  }
};

export const getDocument = async (id: number) => {
  const response = await mediaAPI.performExtra<any>({
    method: 'GET',
    buffer: true,
    extraResource: `GroupDocument/${id}`,
  });

  if (!response) return { file: '' };

  return {
    file: `data:application/pdf;base64, ${arrayBufferToBase64(response)}`,
  };
};

export const getAudioFile = async (name: string) => {
  const response = await mediaAPI.performExtra<any>({
    method: 'GET',
    buffer: true,
    extraResource: `file/${name}`,
  });

  if (!response) return '';

  return `data:audio/aac;base64, ${arrayBufferToBase64(response)}`;
};

export const toggleFavoriteStatus = async (
  id: number,
  currentStatus: boolean,
  isFolder = false
) => {
  const csrfToken = await getAntiForgeryToken();
  const response = await mediaAPI.performExtra<any>({
    method: 'POST',
    extraResource: `${isFolder ? 'folders' : 'document'}/${id}/${
      currentStatus ? 'unfavorite' : 'favorite'
    }`,
    headers: { 'X-XSRF-Token': csrfToken },
  });
  return response;
};

interface ToggleFavoriteStatusWithFallbackParams {
  id: number;
  newStatus: boolean;
  onFallback: (id: number, revertedValue: boolean, isFolder?: boolean) => void;
  isFolder?: boolean;
  onSuccess?: () => void;
}
export const toggleFavoriteStatusWithFallback = async (
  params: ToggleFavoriteStatusWithFallbackParams
) => {
  const { id, newStatus, onFallback, isFolder = false, onSuccess } = params;
  try {
    await toggleFavoriteStatus(id, !newStatus, isFolder);
    onSuccess?.();
  } catch (e) {
    onFallback(id, !newStatus, isFolder);
  }
};

interface PutImageRes {
  URL: string;
}

export const uploadImage = async (image: FormData) => {
  const csrfToken = await getAntiForgeryToken();

  return mediaAPI.performExtra<PutImageRes>({
    method: 'PUT',
    extraResource: `Image/Cropped`,
    model: image,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-XSRF-Token': csrfToken,
    },
  });
};

export const saveImageToServer = async (formData: FormData) => {
  try {
    const csrfToken = await getAntiForgeryToken();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-XSRF-Token': csrfToken,
    };
    const response = await mediaAPI.performExtra<any>({
      method: 'put',
      extraResource: `Image`,
      model: formData,
      headers: headers,
    });

    return response?.URL;
  } catch (error) {
    console.error(error);
  }
};

export const saveDocumentToServer = async (document: FormData) => {
  const csrfToken = await getAntiForgeryToken();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'X-XSRF-Token': csrfToken,
  };

  const response = await mediaAPI.performExtra<any>({
    method: 'put',
    extraResource: `Document`,
    model: document,
    headers: headers,
  });

  return response?.URL;
};

export const uploadImageCropped = async (image: FormData) => {
  const csrfToken = await getAntiForgeryToken();

  return mediaAPI.performExtra<PutImageRes>({
    method: 'PUT',
    extraResource: `Image/Cropped`,
    model: image,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-XSRF-Token': csrfToken,
    },
  });
};
