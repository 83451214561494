import {
  GetChecklistSort,
  ChecklistCreatedByFilters,
  GetChecklistType,
  GetChecklistProps,
} from '../../apis/checklistsAPI/types';
import { FilterSection } from '../../components/SearchFilterBar/SearchFilterBar';
import { ChecklistStatus } from '../../utils/enums';
import { Checklist } from './checklistsSlice/types';

export const ChecklistDatePropertyNamesByStatus: Record<
  ChecklistStatus,
  'started' | 'ended' | 'created'
> = {
  [ChecklistStatus.NotStarted]: 'created',
  [ChecklistStatus.Started]: 'started',
  [ChecklistStatus.Ended]: 'ended',
};

export const ChecklistDateSortTypeByStatus = {
  [ChecklistStatus.NotStarted]: GetChecklistSort.createdDateDesc,
  [ChecklistStatus.Started]: GetChecklistSort.startedDateDesc,
  [ChecklistStatus.Ended]: GetChecklistSort.endedDateDesc,
};

export const filtersSectionsByStatus = (status: ChecklistStatus): FilterSection[] => {
  return [
    {
      title: 'checklist_filter_by_sort',
      type: 'radio',
      content: [
        {
          id: 0,
          name: GetChecklistSort.name,
          nameTx: 'alphabet',
          checked: false,
        },
        {
          id: 1,
          name: ChecklistDateSortTypeByStatus[status],
          nameTx: 'groups_filter_doc',
          checked: true,
        },
      ],
    },
    {
      title: 'checklists_checklistType',
      type: 'checkbox',
      content: [
        {
          id: 0,
          name: ChecklistCreatedByFilters.my,
          nameTx: 'checklists_filter_created_me',
          checked: true,
        },
        {
          id: 1,
          name: ChecklistCreatedByFilters.others,
          nameTx: 'checklists_filter_created_other',
          checked: true,
        },
        {
          id: 2,
          name: ChecklistCreatedByFilters.shared,
          nameTx: 'checklists_filter_shared',
          checked: true,
        },
      ],
    },
  ];
};

export interface TabProps {
  checklists: Checklist[];
  onDotsClick: () => void;
  searchText?: string;
}

export const mappedChecklistType: Record<ChecklistStatus, GetChecklistType> = {
  Started: GetChecklistType.active,
  NotStarted: GetChecklistType.templates,
  Ended: GetChecklistType.ended,
};

export const initialChecklistPagingValues: Required<Pick<GetChecklistProps, 'skip' | 'limit'>> = {
  limit: 15,
  skip: 0,
};

export interface CheckListFilter extends Required<Pick<GetChecklistProps, 'sort'>> {
  checklistsType: Set<ChecklistCreatedByFilters>;
}

export const defaultCheckListFiltersByStatus = (status: ChecklistStatus): CheckListFilter => {
  return {
    sort: ChecklistDateSortTypeByStatus[status],
    checklistsType: new Set(Object.values(ChecklistCreatedByFilters)),
  };
};
