/* eslint-disable @typescript-eslint/naming-convention */

import * as React from 'react';
import styled from 'styled-components';
import { palette } from '../../theme/colors';
import Drawer from 'react-bottom-drawer';
import { useSelectlist } from '../../utils/customHooks/useSelectList';
import { translate } from '../../utils/translate';
import { DataSelectableListType, DocumentFilters, MemberFilterType } from './DocumentFilterV2';
import {
  SButton,
  SCheckBoxWithSubTitle,
  SDrawerWrapper as SDrawerWrapper_,
  SList,
  STitle,
} from './DocumentFilter.styles';
import { getSelectedDataValue } from './helpers';

const SDrawerWrapper = styled(SDrawerWrapper_)`
  .profileDrawer__content {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export interface IDocumentFilterV1Props<T> {
  data?: T[];

  initialShowEmptyFolders: boolean;
  initialMemberFilter?: Set<'MEMBER' | 'NOT_MEMBER'>;
  initialSelectedData?: Set<number>;

  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  onFilter: (filters: DocumentFilters) => void;
  resetStaged: () => void;
  onChange?: (filters: DocumentFilters) => void;
}

export const DocumentFilterV1 = <T extends DataSelectableListType>(
  props: IDocumentFilterV1Props<T>
) => {
  const {
    isOpen,
    setIsOpen,

    onFilter,
    onChange,
    resetStaged,

    data,

    initialShowEmptyFolders: initialShowEmptyFolders,
    initialMemberFilter,
    initialSelectedData,
  } = props;

  const [showEmptyFolders, setShowEmptyFolders] = React.useState<boolean>(initialShowEmptyFolders);
  const [stagedShowEmptyFolders, setStagedShowEmptyFolders] =
    React.useState<boolean>(initialShowEmptyFolders);

  const {
    handleSelect: handleMemberSelect,
    stagedSelectedItems: stagedMemberSelectedItems,
    onFinishSelecting: onFinishMemberSelecting,
  } = useSelectlist({
    data: [{ id: 'MEMBER' }, { id: 'NOT_MEMBER' }],
    multiStage: true,
    initialSelected: initialMemberFilter,
  });

  interface HandleChangeParams {
    _stagedShowEmptyFolders?: boolean;
    _stagedMemberSelectedItems?: Set<string | number>;
  }

  const handleChange = (params: HandleChangeParams) => {
    const { _stagedMemberSelectedItems, _stagedShowEmptyFolders } = params;
    if (onChange)
      onChange({
        showEmptyFolders: _stagedShowEmptyFolders ?? stagedShowEmptyFolders,
        memberFilter: Array.from(
          _stagedMemberSelectedItems ?? stagedMemberSelectedItems
        ) as MemberFilterType,
      });
  };

  const toggleEmptyFolder = () => {
    setStagedShowEmptyFolders(prev => {
      handleChange({ _stagedShowEmptyFolders: !prev });
      return !prev;
    });
  };

  const applyEmptyFolderChange = (apply: boolean, _stagedShowEmptyFolders?: boolean) => {
    if (apply) {
      if (_stagedShowEmptyFolders !== undefined) setShowEmptyFolders(_stagedShowEmptyFolders);
    } else {
      setStagedShowEmptyFolders(showEmptyFolders);
    }
  };

  const {
    handleSelect,
    isStagedSelectedAll,
    isNonStagedSelected,
    stagedSelectedItems,
    onFinishSelecting,
  } = useSelectlist({
    data: data ?? [],
    multiStage: true,
    initialSelected: initialSelectedData,
  });

  const onCloseHandle = () => {
    onFinishSelecting(false);
    onFinishMemberSelecting(false);

    applyEmptyFolderChange(false);

    resetStaged();
    setIsOpen(false);
  };

  const handleFilter = () => {
    onFilter({
      selectedData: getSelectedDataValue({
        isAllDataSelected: isStagedSelectedAll,
        isNoneOfDataSelected: isNonStagedSelected,
        selectedData: stagedSelectedItems,
      }),
      showEmptyFolders: stagedShowEmptyFolders,
      memberFilter: Array.from(stagedMemberSelectedItems) as MemberFilterType,
    });

    onFinishSelecting(true);
    onFinishMemberSelecting(true);

    applyEmptyFolderChange(true, stagedShowEmptyFolders);
  };

  return (
    <SDrawerWrapper role="documentsFilterContainer">
      <Drawer className="profileDrawer" isVisible={isOpen} onClose={onCloseHandle} hideScrollbars>
        <STitle role="title">{translate('checklist_filter_groups_title')}</STitle>
        {initialMemberFilter && (
          <>
            <SCheckBoxWithSubTitle
              className="SFilterCheckboxItem-header"
              selected={stagedMemberSelectedItems.has('MEMBER')}
              title={translate('groups_filter_member')}
              onToggleCheck={() => {
                handleMemberSelect('MEMBER', changed =>
                  handleChange({ _stagedMemberSelectedItems: changed })
                );
              }}
              valueId={0}
              checkBoxType={'box'}
              withoutSeparator
              clickAll
            />
            <SCheckBoxWithSubTitle
              className="SFilterCheckboxItem-header"
              selected={stagedMemberSelectedItems.has('NOT_MEMBER')}
              title={translate('group_not_member')}
              onToggleCheck={() => {
                handleMemberSelect('NOT_MEMBER', changed =>
                  handleChange({ _stagedMemberSelectedItems: changed })
                );
              }}
              valueId={0}
              checkBoxType={'box'}
              separatorColor={palette.prussianBlue5}
              clickAll
            />
          </>
        )}
        <SCheckBoxWithSubTitle
          className="SFilterCheckboxItem-header"
          selected={stagedShowEmptyFolders}
          title={translate('documents_show_empty_folders')}
          onToggleCheck={toggleEmptyFolder}
          valueId={0}
          checkBoxType={'box'}
          withoutSeparator={!data}
          separatorColor={palette.prussianBlue5}
          clickAll
        />
        {data && (
          <>
            <STitle role="accountsTitle">{translate('checklist_filter_accounts_title')}</STitle>
            <SList className="SList" role="accountsList">
              <SCheckBoxWithSubTitle
                className="SFilterCheckboxItem-header"
                selected={isStagedSelectedAll}
                title={translate('cec_selectAccounts')}
                onToggleCheck={() => handleSelect()}
                valueId={0}
                checkBoxType={'box'}
                withoutSeparator
                clickAll
              />
              {data.map((item, index) => (
                <SCheckBoxWithSubTitle
                  className="SFilterCheckboxItem"
                  selected={stagedSelectedItems.has(item.id)}
                  title={item.name}
                  onToggleCheck={handleSelect}
                  key={`${item.id}-${index}`}
                  valueId={item.id}
                  checkBoxType={'box'}
                  withoutSeparator
                  clickAll
                />
              ))}
            </SList>
          </>
        )}

        <SButton $valid={!true} disabled={!true} tx={'messages_filter'} onClick={handleFilter} />
      </Drawer>
    </SDrawerWrapper>
  );
};
