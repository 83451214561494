import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
  align-items: center;
  margin: 0 33px;
`;

export const SOperationBtn = styled.button`
  border: 2px solid ${palette.earthYellow};
  border-radius: 10px;
  height: 38px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: 200ms;
  cursor: pointer;

  &:active {
    transition: 200ms;
    opacity: 30%;
  }
`;

export const SOperationBtnIcon = styled.img`
  pointer-events: none;
`;

export const SNumbersColumnBox = styled.div`
  width: 100%;
  height: 174px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 16px 0;
  background-color: ${palette.loaderDark};
  border-radius: 10px;
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  border: none;
  outline: none;
  padding: 17px 0;
  position: relative;
  cursor: ns-resize;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .scrollDesc {
    position: absolute;
    font-size: 30px;
    right: 0;
    top: 70px;
    display: none;
    transform: scale(1);
    animation: pulse 1s infinite;
  }
  :hover {
    .scrollDesc {
      display: block;
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    70% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(0.9);
    }
  }
  p {
    line-height: 36px;
    font-size: 36px;
  }
  .inactive-number {
    color: ${palette.grayx11gray};
  }

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
`;

export const STitle = styled.p`
  font-size: 14px;
  font-family: 'Roboto-Regular';
  color: ${palette.white};
  margin-bottom: 16px;
`;
