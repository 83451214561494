import { FilterSection } from '../../components/SearchFilterBar/SearchFilterBar';
import { GroupType } from '../../utils/enums';
import { Group } from './groupsSlice/types';

// types & enums START ========================================================================
export enum FiltersByGroupStatus {
  Member = 'groups_filter_member',
  NotAMember = 'groups_notAMember',
}

export enum FiltersByGroupType {
  Normal = 'groups_filter_normal',
  Hidden = 'groups_filter_hidden',
  CoAlert = 'groups_coAlert',
  CrossOrg = 'groups_cossOrg',
}

export interface GroupsFilters {
  byGroupStatus: Set<FiltersByGroupStatus>;
  byGroupType: Set<FiltersByGroupType>;
}

// types & enums END ========================================================================
// filters configuration START ========================================================================

export const GROUP_FILTER_BY_STATUS_SECTION: FilterSection = {
  title: 'groups_filter_by_status',
  type: 'checkbox',
  content: [
    {
      id: 0,
      name: FiltersByGroupStatus.Member,
      checked: true,
    },

    {
      id: 1,
      name: FiltersByGroupStatus.NotAMember,
      checked: false,
    },
  ],
};
// filters configuration END ========================================================================
// helpers functions START ========================================================================

export const generateGroupsListDefaultFiltersSections = (
  haveSeeOrgGroupsRole: boolean,
  groups: Group[]
) => {
  const filters: FilterSection[] = [];

  if (haveSeeOrgGroupsRole) {
    filters.push(GROUP_FILTER_BY_STATUS_SECTION);
  }

  const filtersByGroupType = {
    [GroupType.Normal]: FiltersByGroupType.Normal,
    [GroupType.Hidden]: FiltersByGroupType.Hidden,
    [GroupType.CoAlert]: FiltersByGroupType.CoAlert,
    [GroupType.CrossOrg]: FiltersByGroupType.CrossOrg,
  };

  const filtersByGroupTypeSection: FilterSection = {
    title: 'groups_groupType',
    type: 'checkbox',
    content: [],
  };

  const groupTypes = Object.keys(filtersByGroupType) as unknown as GroupType[];
  groupTypes.forEach((type, index: number) => {
    const foundGroupByType = groups.find(group => group.groupType === Number(type));
    if (foundGroupByType) {
      filtersByGroupTypeSection.content.push({
        id: index,
        name: filtersByGroupType[type],
        checked: true,
      });
    }
  });

  if (filtersByGroupTypeSection.content.length > 1) {
    filters.push(filtersByGroupTypeSection);
  }

  return JSON.parse(JSON.stringify(filters));
};

export const generateGroupsListDefaultFilters = (): GroupsFilters => ({
  byGroupStatus: new Set([FiltersByGroupStatus.Member]),
  byGroupType: new Set(Object.values(FiltersByGroupType)),
});
// helpers functions END ========================================================================
