import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Support } from '../../../apis/authApi/types';
import { RootState } from '../../../store';
import { SupportState } from './types';

const initialState: SupportState = {
  supports: null,
  isLoading: {
    internalSupport: false,
  },
  error: null,
};

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setSupports: (state, action: PayloadAction<Support[]>) => {
      state.supports = action.payload;
    },
    setIsInternalLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading.internalSupport = action.payload;
    },
    setIsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

// export the reducers
export const { setSupports, setIsInternalLoading, setIsError } = supportSlice.actions;

export const selectSupports = (state: RootState) => state.support.supports;

export const selectIsInternalSupportLoading = (state: RootState) =>
  state.support.isLoading.internalSupport;

export default supportSlice.reducer;
