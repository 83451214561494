import styled from 'styled-components';
import { toRem } from '../../../utils/toRem';
import { palette } from '../../../theme/colors';

export const STime = styled.div`
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  background-color: ${palette.background};
  height: ${toRem(18)};
  width: ${toRem(38)};
  border-radius: ${toRem(18)};
  font-size: ${toRem(10)};
  font-family: 'Roboto-Medium';
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SGrid = styled.div<{ $isExpanded: boolean }>`
  position: relative;
  display: grid;
  gap: 6px;
  grid-template-columns: minmax(auto, 50%) minmax(auto, 50%);
  height: ${props => !props.$isExpanded && '200px'};
`;

export const SImageLoadingReplacer = styled.div`
  justify-content: center;
  align-items: center;
  height: 200px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
