// For changing favicon type based on browser color mode
export const faviconHandler = () => {
  const faviconTag = document.getElementById('faviconTag') as HTMLLinkElement;
  if (!faviconTag) {
    return;
  }

  const isDark = window.matchMedia('(prefers-color-scheme: dark)');
  const changeFavicon = function () {
    faviconTag.href = isDark.matches ? '/favicon_light.ico' : '/favicon_dark.ico';
  };
  isDark.addEventListener('change', changeFavicon);
  setTimeout(changeFavicon, 100);
};
