import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';
import styled from 'styled-components';

export const SPage = styled(Page)`
  height: fit-content;
  background: ${palette.navyBlue};
`;

export const SImagesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -4px;
  margin-bottom: 16px;
  padding-bottom: 16px;
`;

export const SImage = styled.img`
  width: calc(33.33% - 8px);
  height: auto;
  margin: 4px;
  aspect-ratio: 1/1;
  object-fit: cover;
`;

export const SMonthText = styled.p`
  font-family: 'Roboto-Regular';
  font-size: 16px;
  color: ${palette.white};
  line-height: 24px;
  margin-bottom: 8px;
`;
