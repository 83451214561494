import React, { useEffect } from 'react';
import {
  SNameChecklistModalContainer,
  SHeader,
  STitle,
  STextArea,
  SButtonContainer,
} from './NameChecklistModal.styles';
import { palette } from '../../theme/colors';
import { Backdrop } from '../Backdrop/Backdrop';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { useTranslation } from 'react-i18next';
import { SCancelButton, SConfirmButton } from '../ConfirmDialog/style';

interface NameChecklistModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  onAdd: () => void;
}

function NameChecklistModal(props: NameChecklistModalProps) {
  const { isOpen, setIsOpen, onAdd, text, setText } = props;

  const { t } = useTranslation();
  const layout = useLayoutContext();

  useEffect(() => {
    layout.setTabsState(!isOpen);
  }, [isOpen]);

  if (!isOpen) return <></>;

  return (
    <>
      <SNameChecklistModalContainer role="checklistItemModalContainer">
        <SHeader role="header">
          <rect width="400" height="100" fill={palette.silver} />
        </SHeader>
        <STitle role="title">{t(`checklist_template_new_task`)}</STitle>
        <STextArea value={text} onChange={e => setText(e.target.value)} role="inputField" />
        <SButtonContainer role="buttonsRow">
          <SCancelButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            role="cancelButton"
          >
            {t(`cancel`)}
          </SCancelButton>
          <SConfirmButton
            onClick={() => {
              if (text) {
                onAdd();
              }
              setText('');
            }}
          >
            {t(`add`)}
          </SConfirmButton>
        </SButtonContainer>
      </SNameChecklistModalContainer>
      <Backdrop setModal={setIsOpen} />
    </>
  );
}

export default NameChecklistModal;
