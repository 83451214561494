import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { RequireRole } from '../components/RequireRole/RequireRole';
import { selectCanStartIamok } from '../containers/Login/LoginSlice';
import { StartIamOkMessageContainer } from '../containers/StartIamOkMessage/StartIamOkMessageContainer';
import { useAppSelector } from '../hooks';
import { useLocation } from 'react-router-dom';

export const StartIamOkMessagePage = () => {
  const { pathname } = useLocation();
  const canStartIamok = useAppSelector(selectCanStartIamok);

  const backBtnPath = pathname === '/startIamOk/summary' ? '/startIamOk' : '/imOk';

  return (
    <Layout isMessageLayout message="home_muster" to={backBtnPath} showBottomTabs>
      <RequireRole roleCondition={!!canStartIamok} backTo="/imOk">
        <StartIamOkMessageContainer />
      </RequireRole>
    </Layout>
  );
};
