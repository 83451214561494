import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SLoaderContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

export const SFileInput = styled.input`
  display: none;
`;

export const SChatBoxWrapper = styled.div<{ map?: boolean; margin?: boolean }>`
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  z-index: 15;
  width: 100%;
  height: ${props => (props.map ? '17rem' : props.margin ? '8rem' : '5rem')};
  background-color: ${palette.prussianBlue2};
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: space-evenly;
  height: fit-content;
  padding: 1rem 0 0;
`;

export const SChatBox = styled.div`
  display: flex;
  align-items: center;
  max-width: 24rem;
  flex-direction: column;
  width: 100%;
`;

export const STop = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
`;

export const SImageContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
`;

export const SImg = styled.div`
  display: flex;
  position: relative;
  height: 100%;

  .picture {
    border-radius: 4px;
    width: 4rem;
    object-fit: cover;
  }
`;

export const STextContainer = styled.form`
  margin-right: 2.25rem;
  width: 100%;
`;

export const STextInput = styled.input`
  resize: none;
  border-radius: 73px;
  border: none;
  background-color: ${palette.ebony};
  color: ${palette.white};
  height: 2.5rem;
  width: 100%;
  padding: 0 0.75rem;

  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  :focus-visible {
    outline: none;
  }

  &::placeholder {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 0.875rem;
    color: ${palette.ashGray};
  }
`;

export const SIcon = styled.img`
  min-width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;

  &.left {
    margin-right: 1rem;
  }
`;

export const SBalanceSpace = styled.div`
  min-width: 1.5rem;
`;
