/* eslint-disable @typescript-eslint/no-inferrable-types */

import { createContext, RefObject, useContext, useRef } from 'react';

interface MessageRefsContextProps {
  registerRef: (id: number, ref: RefObject<HTMLDivElement>) => void;
  unRegisterRef: (id: number) => void;
  scrollToRef: (id: number) => void;
  messageRefs: React.MutableRefObject<Record<number, RefObject<HTMLDivElement>>>;
}

const initialMessageRefs = {} as React.MutableRefObject<Record<number, RefObject<HTMLDivElement>>>;
const messageRefsContextDefaultValue: MessageRefsContextProps = {
  registerRef: () => {},
  unRegisterRef: () => {},
  scrollToRef: (
    id: number,
    isAnimationEnabled: boolean = true,
    scrollBehavior: ScrollBehavior = 'smooth'
  ) => {
    const element = document.getElementById(`messageTop-${id}`);
    const containerElement = document.getElementById(`messageContainer-${id}`);
    if (element) {
      element?.scrollIntoView({ behavior: scrollBehavior });
      if (containerElement && isAnimationEnabled) {
        const className = containerElement.className;
        containerElement.className += ' flash';
        containerElement.onanimationend = () => {
          containerElement.className = className;
        };
      }
    }
  },
  messageRefs: initialMessageRefs,
};

const MessageRefsContext = createContext<MessageRefsContextProps>(messageRefsContextDefaultValue);

export const useMessageRefsContext = () => useContext(MessageRefsContext);

interface MessageRefsContextProviderProps {
  children: React.ReactNode;
}
export const MessageRefsContextProvider = (props: MessageRefsContextProviderProps) => {
  const { children } = props;

  const messageRefs = useRef<Record<number, RefObject<HTMLDivElement>>>({});

  const registerRef = (id: number | string, ref: RefObject<HTMLDivElement>) => {
    if (!messageRefs.current[id as any]) {
      messageRefs.current[id as any] = ref;
    }
  };
  const unRegisterRef = (id: number | string) => {
    if (!!messageRefs.current[id as any]) delete messageRefs.current[id as any];
  };

  const scrollToRef = (
    id: number,
    isAnimationEnabled: boolean = true,
    scrollBehavior: ScrollBehavior = 'smooth'
  ) => {
    if (messageRefs.current[id].current && isAnimationEnabled) {
      messageRefs.current[id].current!.className =
        messageRefs.current[id].current!.className + ' flash';

      messageRefs.current[id].current!.onanimationend = () => {
        messageRefs.current[id].current!.className = messageRefs.current[id]
          .current!.className.slice()
          .split(' ')
          .filter(i => i !== 'flash')
          .join(' ');
      };
    }
    const index: any = `messageTop-${id}`;
    messageRefs.current[index].current?.scrollIntoView({
      behavior: scrollBehavior,
      block: 'start',
      inline: 'start',
    });
  };

  return (
    <MessageRefsContext.Provider value={{ registerRef, unRegisterRef, scrollToRef, messageRefs }}>
      {children}
    </MessageRefsContext.Provider>
  );
};
