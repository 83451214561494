import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SNewsItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  color: ${palette.white};
  background-color: ${palette.prussianBlue2};
  border: 1px solid ${palette.queenBlue};
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
`;

export const SNewsItemTitle = styled.a`
  font-size: 0.9rem;
  color: ${palette.white};
  margin-bottom: 0.5rem;
  text-decoration: none;
`;

export const SNewsItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const SNewsItemInfoSource = styled.span`
  font-size: 0.85rem;
  color: ${palette.silver};
`;

export const SNewsItemInfoDate = styled.span`
  display: flex;
  color: ${palette.silver};

  svg {
    margin-right: 0.25rem;
  }
  p {
    font-size: 0.85rem;
  }
`;

export const SNewsItemContent = styled.p`
  font-size: 0.9rem;
  color: ${palette.white};
`;

export const SNewsItemRead = styled.span`
  font-size: 0.9rem;
  color: ${palette.gold};
  margin-left: 0.25rem;
  cursor: pointer;
`;
