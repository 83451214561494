import { palette } from '../../theme/colors';
import { Backdrop } from '../Backdrop/Backdrop';
import FilterCheckboxItem from '../FilterItem/FilterCheckboxItem';
import { useMemo, useState } from 'react';
import { FilterSection } from '../SearchFilterBar/SearchFilterBar';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import {
  FiltersByGroupStatus,
  FiltersByGroupType,
  GroupsFilters,
} from '../../containers/GroupsList/helpers';
import { useSelectlist } from '../../utils/customHooks/useSelectList';
import { SFilter, SHeader, SLabel, SLine } from './GroupFilter.styles';
import { ResetFiltersButton } from '../Button/ResetFiltersButton';

interface GroupFilterProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filterSections: FilterSection[];
  initialSelected: GroupsFilters;
  onFilter: (filters: GroupsFilters) => void;

  label: string;
}

export const GroupFilter = (props: GroupFilterProps) => {
  const { isOpen, setIsOpen, onFilter, initialSelected, filterSections } = props;

  const [keyReload] = useState(0);

  const {
    handleSelect: handleByStatusSelect,
    stagedSelectedItems: stagedByStatusSelectedItems,
    onFinishSelecting: onFinishByStatusSelecting,
  } = useSelectlist({
    data: Object.values(FiltersByGroupStatus).map(value => ({ id: value })),
    multiStage: true,
    initialSelected: initialSelected.byGroupStatus,
  });
  const {
    handleSelect: handleByGroupTypeSelect,
    stagedSelectedItems: stagedByGroupTypeSelectedItems,
    onFinishSelecting: onFinishByGroupTypeSelecting,
  } = useSelectlist({
    data: Object.values(FiltersByGroupType).map(value => ({ id: value })),
    multiStage: true,
    initialSelected: initialSelected.byGroupType,
  });

  const displayResetButton = useMemo(() => {
    const statusesArray = Array.from(stagedByStatusSelectedItems);
    const groupTypesArray = Array.from(stagedByGroupTypeSelectedItems);
    const isStatusChanged =
      statusesArray?.length !== 1 ||
      (statusesArray.length && statusesArray[0] !== FiltersByGroupStatus.Member);
    const isGroupTypesChanged =
      Object.values(FiltersByGroupType)?.length !== groupTypesArray.length;

    return isStatusChanged || isGroupTypesChanged;
  }, [stagedByGroupTypeSelectedItems, stagedByStatusSelectedItems]);

  const handleFilter = () => {
    onFilter({
      byGroupStatus: stagedByStatusSelectedItems as Set<FiltersByGroupStatus>,
      byGroupType: stagedByGroupTypeSelectedItems as Set<FiltersByGroupType>,
    });

    onFinishByStatusSelecting(true);
    onFinishByGroupTypeSelecting(true);
  };

  const onCloseHandle = () => {
    onFinishByStatusSelecting(false);
    onFinishByGroupTypeSelecting(false);
  };

  const handleReset = () => {
    onFilter({
      byGroupStatus: new Set([FiltersByGroupStatus.Member]) as Set<FiltersByGroupStatus>,
      byGroupType: new Set(Object.values(FiltersByGroupType)) as Set<FiltersByGroupType>,
    });
  };

  const { t } = useTranslation();

  if (!isOpen) return <></>;

  return (
    <>
      <SFilter key={keyReload}>
        <SHeader role="filterHeader">
          <rect width="400" height="100" fill={palette.silver} />
        </SHeader>
        {filterSections.map((filter, key) => (
          <div key={key}>
            {key !== 0 && <SLine />}
            <SLabel role="filterTitle">{t(`${filter.title}`)}</SLabel>
            <ul className={`list-${filterSections.length > 1 ? key : 1}`} role={``}>
              {filter.content.map((option, key2) => (
                <FilterCheckboxItem
                  type="box"
                  checked={
                    filter.title === 'groups_filter_by_status'
                      ? stagedByStatusSelectedItems.has(option.name!)
                      : stagedByGroupTypeSelectedItems.has(option.name!)
                  }
                  name={option.name}
                  setSelected={() =>
                    filter.title === 'groups_filter_by_status'
                      ? handleByStatusSelect(option.name)
                      : handleByGroupTypeSelect(option.name)
                  }
                  key={key2}
                  id={option.id}
                />
              ))}
            </ul>
          </div>
        ))}
        <ResetFiltersButton
          onClick={handleReset}
          isDisplayed={displayResetButton}
          marginBottom="0.8125rem"
        />
        <ActionButton type="button" tx={props.label} onClick={handleFilter} />
      </SFilter>
      <Backdrop onClick={onCloseHandle} setModal={setIsOpen} />
    </>
  );
};
