import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CountryCodeDropDown } from '../../components/CountryCodeDropDown/CountryCodeDropDown';
import Country from '../../components/CountryCodeDropDown/resources/country';
import { CountryCode, OnCountryCodeChangeParams } from '../../components/CountryCodeDropDown/types';
import { CountryCodeTextField } from '../../components/CountryCodeTextField/CountryCodeTextField';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import i18n, { EPhoneCodes } from '../../i18n';
import { translate } from '../../utils/translate';
import { selectUserById, selectUserByIdLoading } from '../Login/LoginSlice';
import { editSecondaryPhoneNumber, getCurrentUserById } from '../Login/LoginSlice/actionCreators';
import {
  SButton,
  SContainer,
  SErroText,
  SStritchedSpace,
  SSubTitle,
  STitle,
} from './ChangeSecondaryNumber.styles';
import {
  PhoneNumberShorten,
  getSecondaryPhoneNumber,
  isNumbersOnly,
  isPhoneNumberChanged,
} from './helpers';

export const ChangeSecondaryNumber = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const user = useAppSelector(selectUserById);
  const isGettingDataLoading = useAppSelector(selectUserByIdLoading);

  const [countryCode, setCountryCode] = useState<CountryCode>(
    (EPhoneCodes[i18n.language] as CountryCode) || 'SE'
  );
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState<
    PhoneNumberShorten | undefined
  >();
  const [isLoading, setIsloading] = useState(false);
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);

  const getPhoneNumber = () => {
    const secondPhoneNumber = getSecondaryPhoneNumber(user?.phoneNumber);
    if (secondPhoneNumber) {
      setSecondaryPhoneNumber(secondPhoneNumber);
      const { country, nationalNumber } = secondPhoneNumber;
      if (country) setCountryCode(country);
      if (nationalNumber) setPhoneNumber(nationalNumber);
    }
  };

  // backend sends two pohne number seperated with , and a space
  useEffect(() => {
    if (!user) {
      dispatch(getCurrentUserById());
    }
    getPhoneNumber();
  }, [dispatch, user]);

  const onCountryChanged = (params: OnCountryCodeChangeParams) => {
    setCountryCode(params.countryCode);
  };
  const onNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    if (isNumbersOnly(value) || !value.length) {
      setPhoneNumber(value);
    }
  };

  const handleButtonCliked = async () => {
    const dialCode = Country.countryCodeToDialCode(countryCode);
    const number = `${dialCode}${phoneNumber}`;
    if (number || phoneNumber === '') {
      try {
        setIsloading(true);
        dispatch(editSecondaryPhoneNumber(number));
        setIsInvalidPhoneNumber(false);
        setIsloading(false);
        navigation(-1);
      } catch (e) {
        setIsloading(false);
      }
    } else {
      setIsInvalidPhoneNumber(true);
    }
  };

  const canShowSaveButton = () => {
    if (countryCode) {
      if (secondaryPhoneNumber) {
        const currentPhone: PhoneNumberShorten = {
          country: countryCode,
          nationalNumber: phoneNumber ?? '',
        };
        const secondaryPhoneNumberShorten: PhoneNumberShorten = {
          country: secondaryPhoneNumber?.country,
          nationalNumber: secondaryPhoneNumber?.nationalNumber,
        };
        return isPhoneNumberChanged(currentPhone, secondaryPhoneNumberShorten);
      } else {
        return !!phoneNumber?.length;
      }
    }
    return false;
  };

  if (isLoading || isGettingDataLoading) return <Loader />;

  return (
    <SContainer role="changePhoneContainer">
      <STitle role="title">{translate('profile_changeSecNumber')}</STitle>
      <SSubTitle role="subtitle">{translate('profile_phone_intro')}</SSubTitle>
      <CountryCodeTextField
        StartAdornment={
          !isGettingDataLoading && (
            <CountryCodeDropDown initiallValue={countryCode} onChange={onCountryChanged} />
          )
        }
        type="tel"
        name="number"
        placeHolderTx="profile_yourPhoneNumber"
        value={phoneNumber}
        onChange={onNumberChange}
        hasError={isInvalidPhoneNumber}
        role="phoneInput"
      />
      {isInvalidPhoneNumber && <SErroText>{translate('invalid_phone_number')}</SErroText>}
      <SStritchedSpace />
      {canShowSaveButton() && <SButton onClick={handleButtonCliked} tx="save" />}
    </SContainer>
  );
};
