import React, { useEffect, useRef } from 'react';
import { MessageType } from '../../utils/enums';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import Dots from '../../assets/imgs/general/option-dots-horizontal.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Forwarded } from '../../assets/imgs/chats/forward-icon--grey.svg';
import { translate } from '../../utils/translate';
import { useMessageRefsContext } from '../../utils/customHooks/MessageRefsContext';
import {
  SContentAboveMessage,
  SAdditionalInfo,
  SDots,
  SEditMessageButtonContainer,
  SForwarded,
  SImageReplacer,
  SMainMessageContainer,
  SProfilePictureWrapper,
  SMessageContainer,
  SMessageTop,
  SSenderName,
  SSenderNameContainer,
  SSentMessageDateContainer,
  SReactionsRowContainer,
  Time,
  SReactionsRowPlaceholder,
} from './MessageContainer.styles';
import MessageContainerReactionsRow from '../MessageContainerReactionsRow/MessageContainerReactionsRow';
import { useAppSelector } from '../../hooks';
import { selectReactionsListByMessageOrReply } from '../../containers/ChatsList/chatListSlice';
import { MessageReactionsListItem } from '../../containers/ChatsList/chatListSlice/types';

const HOLD_TIMEOUT = 250;

export interface MessageContainerProps {
  messageType: MessageType;
  children?: React.ReactNode;
  senderImage: string | null;
  isSameSender?: boolean;
  sentDateTime?: string;
  senderName?: string;
  emergencyRecall?: boolean;
  messageTo: string | null;
  toggleModal?: () => void;
  alert?: boolean;
  showOnlyAttachment?: boolean;
  forwarded?: boolean;
  edited?: boolean;
  hasReply?: boolean;
  id?: number;
  hasAlarmInfo?: boolean;
}

function MessageContainer(props: MessageContainerProps) {
  const {
    messageType,
    sentDateTime,
    senderImage,
    isSameSender = false,
    children,
    senderName,
    emergencyRecall = false,
    messageTo,
    toggleModal,
    alert,
    showOnlyAttachment = false,
    forwarded,
    edited,
    hasReply,
    id,
    hasAlarmInfo = false,
  } = props;
  const { t } = useTranslation();
  const { registerRef, unRegisterRef } = useMessageRefsContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerTopRef = useRef<HTMLDivElement>(null);
  const holdTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [isReactionTouched, setIsReactionTouched] = React.useState(false);
  const reactions = useAppSelector(
    selectReactionsListByMessageOrReply(id!)
  ) as MessageReactionsListItem[];
  const haveReactions = !!reactions?.length;

  useEffect(() => {
    if (id) {
      registerRef(id, containerRef);
      registerRef(`messageTop-${id}` as any, containerTopRef);
    }
    return () => {
      if (id) {
        unRegisterRef(id);
        unRegisterRef(`messageTop-${id}` as any);
      }
    };
  }, []);

  const messagesSentList = [
    MessageType.SentAudioMessage,
    MessageType.SentTextMessage,
    MessageType.SentImageMessage,
    MessageType.SentFileMessage,
    MessageType.SentAlarmMessage,
    MessageType.SentAlarmRecalledMessage,
    MessageType.SentLocationMessage,
  ];
  const imageMessagesList = [MessageType.SentImageMessage, MessageType.ReceivedImageMessage];
  const fileMessagesList = [MessageType.SentFileMessage, MessageType.ReceivedFileMessage];
  const alarmMessagesList = [
    MessageType.SentAlarmMessage,
    MessageType.ReceivedAlarmMessage,
    MessageType.SentAlarmRecalledMessage,
    MessageType.ReceivedRecalledAlarmMessage,
  ];
  const locationMessagesList = [
    MessageType.SentLocationMessage,
    MessageType.ReceivedLocationMessage,
  ];
  const audioMessagesList = [MessageType.SentAudioMessage, MessageType.ReceivedAudioMessage];
  const textMessagesList = [MessageType.SentTextMessage, MessageType.ReceivedTextMessage];

  const isTextMessage = textMessagesList.includes(messageType);
  const isMessageSent = messagesSentList.includes(messageType);
  const isImageMessage = imageMessagesList.includes(messageType);
  const isFileMessage = fileMessagesList.includes(messageType);
  const isAlarmMessage = alarmMessagesList.includes(messageType);
  const isLocationMessage = locationMessagesList.includes(messageType);
  const isAudioMessage = audioMessagesList.includes(messageType);
  const showProfileImage = (!isSameSender && !isMessageSent) || (emergencyRecall && !isMessageSent);
  const showImageReplacer = isSameSender && !isMessageSent && !emergencyRecall;
  const showSenderName = !isMessageSent && !isSameSender;
  const showTimeOnMap = isAlarmMessage && !hasAlarmInfo;

  const scaleContainer = (scale = 1) => {
    if (containerRef.current && !isImageMessage)
      containerRef.current.style.transform = `scale(${scale})`;
  };

  const onTouchStart = () => {
    if (showOnlyAttachment || !toggleModal) return;
    scaleContainer(0.95);
    holdTimeoutRef.current = setTimeout(() => {
      scaleContainer();
      toggleModal();
    }, HOLD_TIMEOUT);
  };

  const onTouchEnd = () => {
    if (holdTimeoutRef.current) {
      clearTimeout(holdTimeoutRef.current);
      scaleContainer();
    }
  };

  useEffect(() => {
    if (isReactionTouched) {
      onTouchEnd();
    }
  }, [isReactionTouched]);

  return (
    <SMainMessageContainer
      isMessageSent={isMessageSent}
      role="mainMessageContainer"
      haveCursorPointer={!showOnlyAttachment && !!toggleModal}
      haveReactions={haveReactions}
    >
      <SMessageTop ref={containerTopRef} id={`messageTop-${id}`} />
      {showProfileImage && (
        <SProfilePictureWrapper role="profilePictureWrapper">
          <ProfilePicture profilePictureFileName={senderImage} diameter={24} />
        </SProfilePictureWrapper>
      )}
      {showImageReplacer && <SImageReplacer />}
      <SMessageContainer
        sentMessage={isMessageSent}
        audioContainer={isAudioMessage}
        messageContainerRounded={isSameSender}
        imageContainer={isImageMessage}
        fileContainer={isFileMessage}
        alarmContainer={isAlarmMessage}
        locationContainer={isLocationMessage}
        recalledContainer={emergencyRecall}
        activeAlertContainer={alert}
        canceledAlertContainer={isAlarmMessage && !alert}
        hasAlarmInfo={hasAlarmInfo}
        showSenderName={showSenderName}
        role="messageContainer"
        id={`messageContainer-${id}`}
        haveCursorPointer={!showOnlyAttachment && !!toggleModal}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchEnd}
        onMouseDown={onTouchStart}
        onMouseUp={onTouchEnd}
        onMouseMove={onTouchEnd}
        ref={containerRef}
        haveReactions={haveReactions}
      >
        {toggleModal && !showOnlyAttachment && (
          <SEditMessageButtonContainer
            margin={showSenderName}
            role="editMessageButton"
            onClick={() => toggleModal()}
          >
            <SDots src={Dots} alt="" role="dotsIcon" />
          </SEditMessageButtonContainer>
        )}
        <SContentAboveMessage
          needMinHeight={!isAlarmMessage}
          isTextMessage={isTextMessage}
          role="contentAboveMessage"
        >
          {showSenderName && (
            <SSenderNameContainer
              toContainerAlarm={isAlarmMessage || isLocationMessage}
              haveMarginLeft={
                isAlarmMessage || isLocationMessage || isImageMessage || isFileMessage
              }
              role="senderContainer"
            >
              <SSenderName role="senderText">{senderName}</SSenderName>
            </SSenderNameContainer>
          )}
          {forwarded && !hasReply && (
            <SForwarded
              extraForwardPadding={
                isAlarmMessage || isLocationMessage || isImageMessage || isFileMessage
              }
              haveName={showSenderName}
              role="forwardedContainer"
            >
              <Forwarded width={12} role="forwardedIcon" />
              <p role="text">{translate('messages_forward')}</p>
            </SForwarded>
          )}
          {messageTo && (
            <SSenderNameContainer
              toContainerAlarm={isAlarmMessage}
              haveMarginLeft={
                isAlarmMessage || isLocationMessage || isImageMessage || isFileMessage
              }
              role="messageToContainer"
            >
              <SAdditionalInfo isAlarmMessage={isAlarmMessage} role="messageToText">
                {t(`messages_to`)} {messageTo}
              </SAdditionalInfo>
            </SSenderNameContainer>
          )}
        </SContentAboveMessage>
        {children}
        {sentDateTime && (
          <SSentMessageDateContainer
            isAlarmMessage={isAlarmMessage}
            showTimeOnMap={showTimeOnMap}
            haveReactions={haveReactions}
            role="sentTimeContainer"
          >
            <Time showTimeOnMap={showTimeOnMap} role="sentTimeText">
              {edited && `${t('messages_edited').toLowerCase()} `} {sentDateTime}
            </Time>
          </SSentMessageDateContainer>
        )}
        {haveReactions && id && (
          <div>
            <SReactionsRowPlaceholder />
            <SReactionsRowContainer>
              <MessageContainerReactionsRow handleReactionTouched={setIsReactionTouched} id={id} />
            </SReactionsRowContainer>
          </div>
        )}
      </SMessageContainer>
    </SMainMessageContainer>
  );
}

export default MessageContainer;
