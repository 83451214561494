import React, { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import BigFloatButton from '../BigFloatButton/BigFloatButton';
import SearchableMap from '../SearchableMap/SearchableMap';
import { t } from 'i18next';
import { MapTypeToggleButton } from '../MapTypeToggleButton/MapTypeToggleButton';
import { SMapTypeWrapper, SMapContainer } from './MapDisplay.styles';

interface MapDisplayProps {
  location?: google.maps.LatLngLiteral;
  setLocation: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | undefined>>;
  onButtonClick: () => void;
  buttonColor: 'red' | 'yellow';
  buttonTx: string;
  handleClickBack?: () => void;
  backLink?: string;
}

function MapDisplay(props: MapDisplayProps) {
  const { location, setLocation, onButtonClick, buttonColor, buttonTx, handleClickBack, backLink } =
    props;

  const layout = useLayoutContext();
  const locationState = useLocation();
  const navigate = useNavigate();

  const [mounted, setMounted] = useState(false);

  useLayoutEffect(() => {
    if (backLink) {
      layout.setBackLink(backLink);
    }
    layout.setMessage(t('messages_location'));
    layout.setSubTitle('');
    layout.setDoShowDots(false);
  }, []);

  useLayoutEffect(() => {
    if (mounted) {
      if (handleClickBack) {
        handleClickBack();
        layout.setBackLink(undefined);
        layout.setDoShowDots(undefined);
      }
      layout.setMessage(undefined);
    } else {
      setMounted(true);
    }
  }, [locationState]);

  const handleButtonClick = () => {
    layout.setBackLink(undefined);
    layout.setDoShowDots(undefined);
    navigate('#');
    onButtonClick();
  };

  return (
    <SMapContainer role="mapContainer">
      <SearchableMap location={location} setLocation={setLocation} />
      <SMapTypeWrapper>
        <MapTypeToggleButton />
      </SMapTypeWrapper>
      <BigFloatButton
        extraPadding={false}
        tx={buttonTx}
        onClick={handleButtonClick}
        color={buttonColor}
      />
    </SMapContainer>
  );
}

export default MapDisplay;
