import React from 'react';
import { LoaderWrapper, LoaderContainer } from './Loader.styles';

interface LoaderProps {
  className?: string | undefined;
  size?: number;
}
const Loader = (props: LoaderProps) => {
  const { className, size = 80 } = props;
  return (
    <LoaderWrapper className={className} role="loaderWrapper">
      <LoaderContainer role="loaderContainer" $size={size}>
        <div></div>
      </LoaderContainer>
    </LoaderWrapper>
  );
};

export default Loader;
