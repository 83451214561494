import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { I2FAInitiol } from './types';

// initail state
const initialState: I2FAInitiol = {
  email: '',
  password: '',
  error: false,
};

// group detail slice
const login2FASlice = createSlice({
  name: 'login2fa',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
});

// export the reducers
export const { setEmail, setPassword, setIsError } = login2FASlice.actions;

export const selectEmail = (state: RootState) => state.auth2f.email;
export const selectPassword = (state: RootState) => state.auth2f.password;
export const select2faError = (state: RootState) => state.auth2f.error;

export default login2FASlice.reducer;
