import { useLocation, useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { ReadReceipts } from '../../containers/ReadReceipts/ReadReceipts';

export interface NavigatedPageState {
  prevPath?: string;
}
export const ReadReceiptsPage = () => {
  const location = useLocation();
  const locationState = location.state as NavigatedPageState;
  const { id } = useParams();
  return (
    <Layout
      to={locationState?.prevPath || `/message/${id}`}
      isMessageLayout
      message="message_read_receipts_page_title"
    >
      <ReadReceipts />
    </Layout>
  );
};
