import styled, { css } from 'styled-components';
import { Button } from '../Button/Button';
import { CheckBoxWithSubTitle } from '../CheckBoxWithSubTitle/CheckBoxWithSubTitle';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';
interface SCheckBoxWithSubTitleParams {
  $lessPadding?: boolean;
  $biggerFont?: boolean;
}

export const SCheckBoxWithSubTitle = styled(CheckBoxWithSubTitle)<SCheckBoxWithSubTitleParams>`
  ${props =>
    props.$lessPadding &&
    css`
      padding: 1rem 0rem 0rem 0rem;
    `}
  .STitle {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 0.875rem;
    ${props =>
      props.$biggerFont &&
      css`
        font-size: 1rem;
      `}
  }

  .SImgPlaceHolderContainer {
    height: 1.25rem;
    width: 1.25rem;

    svg {
      width: 52%;
      height: 48%;
    }
  }
`;

export const SDrawerWrapper = styled.div<{ $moreMaxHeight?: boolean }>`
  .profileDrawer {
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1001;
    @media (min-width: 450px) {
      max-width: 26rem;
      margin: auto;
    }
    background-color: ${palette.prussianBlue2};
    max-height: 75vh;
    ${props =>
      props.$moreMaxHeight &&
      css`
        max-height: 90vh;
      `}
  }
  .profileDrawer__backdrop {
    z-index: 1000;
  }
  .profileDrawer__handle-wrapper {
  }
  .profileDrawer__handle {
    width: 36%;
    margin-top: 1.3125rem;
  }
  .profileDrawer__content {
    padding: 1.25rem;
    padding-bottom: ${toRem(30)};
    ${props =>
      props.$moreMaxHeight &&
      css`
        max-height: calc(90vh - 45px);
      `}

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  p.STitle {
    margin-left: 0rem;
    font-weight: 400;
  }
`;

export const SList = styled.ul`
  padding-bottom: 0.5rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SCustomList = styled(SList)`
  padding-bottom: 0;
  height: calc(100% - 56px);
`;

export const SMainTitle = styled.h1`
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 1.125rem;
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  line-height: 1.18rem;
`;

export const STitle = styled.h1`
  margin-bottom: 0.25rem;
  &:not(:first-child) {
    margin-top: 1.25rem;
  }

  font-weight: 500;
  font-size: 1rem;
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  line-height: 1.18rem;
`;
export const SOverriddenTitle = styled(STitle)`
  font-size: 0.875rem;
`;

interface SButtonParams {
  $valid?: boolean;
  $resetButton?: boolean;
}

export const SButton = styled(Button)<SButtonParams>`
  width: 100%;
  margin-top: 0.8125rem;
  button {
    max-width: 100rem;
    width: 100%;
    font-size: 1rem;
    padding: 0.8125rem 0;
    font-family: 'Roboto-Medium';
    color: ${palette.raisinBlack3};
    ${props =>
      props.$valid &&
      css`
        opacity: 0.5;
        cursor: not-allowed;
      `}
  }
  ${props =>
    props.$resetButton &&
    css`
      margin-top: 1.5rem;
      button {
        background-color: ${palette.transparent};
        color: ${palette.white};
        border: 1px solid ${palette.honeyYellow};
      }
    `}
`;
