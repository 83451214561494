/* react-hooks/exhaustive-deps */
import { useState } from 'react';
import { translate } from '../../utils/translate';
import Drawer from 'react-bottom-drawer';
import {
  OptionsCurrentTypeText,
  OptionsTitleText,
  SButton,
  SCheckBoxWithSubTitle,
  SDrawerWrapper,
  SortOptionsButtonWrapper,
  STitle,
  VectorIcon,
} from './DocumentsSortingOptions.styles';
import Vector from '../../assets/imgs/SortingOptionsContainer/Vector.svg';

export enum DocumentSortingType {
  AtoZ = 'AtoZ',
  ZtoA = 'ZtoA',
  NewToOld = 'NewToOld',
  OldToNew = 'OldToNew',
}

export interface SortingOption {
  titleTx: string;
  id: DocumentSortingType;
}

export const mappedDocumentSortingOptions: Record<DocumentSortingType, SortingOption> = {
  AtoZ: { titleTx: 'sorting_option_AtoZ', id: DocumentSortingType.AtoZ },
  ZtoA: { titleTx: 'sorting_option_ZtoA', id: DocumentSortingType.ZtoA },
  NewToOld: { titleTx: 'sorting_option_NewToOld', id: DocumentSortingType.NewToOld },
  OldToNew: { titleTx: 'sorting_option_OldToNew', id: DocumentSortingType.OldToNew },
};

export interface DocumentsSortingOptionsProps {
  initialSort: DocumentSortingType;
  onSort: (sorting: DocumentSortingType) => void;
  className?: string | undefined;
}

export const DocumentsSortingOptions = (props: DocumentsSortingOptionsProps) => {
  const { initialSort, onSort, className } = props;
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);

  const toggleFilterOpened = () => setIsFilterOpened(prev => !prev);

  const [selectedOption, setSelectedOption] = useState<DocumentSortingType>(initialSort);
  const [stagedOption, setStagedOption] = useState<DocumentSortingType>(initialSort);
  const handleSort = () => {
    setSelectedOption(stagedOption);
    onSort(stagedOption);
    toggleFilterOpened();
  };
  const onCloseHandle = () => {
    toggleFilterOpened();
    setStagedOption(selectedOption);
  };

  const handleSelect = (_selected: DocumentSortingType) => () => {
    setStagedOption(_selected);
  };

  return (
    <>
      <SortOptionsButtonWrapper
        className={className}
        onClick={toggleFilterOpened}
        role="sortingOptionsContainer"
      >
        <OptionsTitleText role="sortByLabel">{translate('sorting_option_sortBy')}</OptionsTitleText>
        <OptionsCurrentTypeText role="currentOptionText">
          {translate(mappedDocumentSortingOptions[stagedOption].titleTx)}
          <VectorIcon src={Vector} alt="drop down arrow" role="dropdownIcon" />
        </OptionsCurrentTypeText>
      </SortOptionsButtonWrapper>
      <SDrawerWrapper role="drawerWrapper">
        <Drawer
          className="profileDrawer"
          isVisible={isFilterOpened}
          onClose={onCloseHandle}
          hideScrollbars
        >
          <div className="content-wrapper" role="contentWrapper">
            <STitle className="list-gap-item" role="title">
              {translate('groups_memberStatus')}
            </STitle>
            {Object.values(mappedDocumentSortingOptions).map((value, index) => (
              <SCheckBoxWithSubTitle
                key={`${value}-${index}`}
                className="SFilterCheckboxItem-header list-gap-item"
                selected={value.id === stagedOption}
                title={translate(value.titleTx) as string}
                onToggleCheck={handleSelect(value.id)}
                valueId={value.id}
                withoutSeparator
                clickAll
              />
            ))}
          </div>
          <SButton tx={'sort'} onClick={handleSort} />
        </Drawer>
      </SDrawerWrapper>
    </>
  );
};
