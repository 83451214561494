import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';

export const SExpandedBody = styled.div<{ $expanded?: boolean }>`
  /* transition  ============= start */
  max-height: 0;
  opacity: 0;
  ${props =>
    props.$expanded &&
    css`
      max-height: calc(0.37 * calc(90vh - 45px));
      opacity: 1;
    `};
  transition: all 0.3s ease;
  /* transition  ============= end */

  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SContainer = styled.div<{ $needExpand?: boolean }>`
  padding: 1rem 0;
  cursor: pointer;
  border-bottom: 1px solid ${palette.prussianBlue5};
`;

export const SMainWrapper = styled.div<{ $expanded?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    transform: rotate(90deg);
    transition: transform 0.3s;
    ${props =>
      props.$expanded &&
      css`
        transform: rotate(-90deg);
      `};
  }

  .image {
    width: 1.375rem;
    height: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SNamTitle = styled.p`
  font-family: 'Roboto-Bold';
  color: ${palette.white};

  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;
