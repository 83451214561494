/* eslint-disable @typescript-eslint/no-unused-expressions */

import { debounce } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../assets/imgs/general/search-icon.svg';
import ClearButton from '../../assets/imgs/general/clear-x-button.svg';
import { SSearch, SLabel, SContainer, SClear } from './SearchBarBase.styles';

declare const InstallTrigger: any;

export interface ISearchBarBaseProps extends React.HTMLAttributes<HTMLInputElement> {
  placeholderTx?: string;
  placeholder?: string;
  forwardedRef?: React.RefObject<any> | null;
  onChangeText?: (text: string) => void;
  fallback: (value: string) => void;
  debounced?: boolean;
  value?: string;
  label?: string;
}

export const SearchBarBase = (props: ISearchBarBaseProps) => {
  const {
    placeholderTx,
    placeholder,
    forwardedRef,
    onChangeText,
    debounced = true,
    value,
    className,
    fallback,
    label,
  } = props;
  const { t } = useTranslation();
  const actualPlaceholder = placeholderTx ? t(`${placeholderTx}`) : placeholder;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const debouncedOnChange = React.useCallback(
    debounce(onChangeText || fallback, 300, {
      leading: false,
      trailing: true,
    }),
    [onChangeText, fallback]
  );

  const agent = navigator.userAgent;

  const isFirefox = typeof InstallTrigger !== 'undefined' || agent.includes('Firefox');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounced
      ? debouncedOnChange(e.target.value)
      : onChangeText
      ? onChangeText(e.target.value)
      : fallback(e.target.value);
  };

  const renderSearch = () => (
    <>
      {label && <SLabel role="label">{t(`${label}`)}</SLabel>}
      <SearchIcon role="searchIcon" />
      <SSearch
        type={isFirefox ? 'text' : 'search'}
        size={10}
        placeholder={actualPlaceholder}
        ref={forwardedRef ?? inputRef}
        onChange={handleSearch}
        role="searchInput"
      />
    </>
  );

  const formRef = React.useRef<HTMLFormElement>(null);

  const handleFireFoxClearButton = () => {
    formRef.current?.reset();
    if (onChangeText) {
      onChangeText('');
    }
    if (fallback) {
      fallback('');
    }
  };

  React.useEffect(() => {
    if (!value?.length) {
      if (inputRef?.current) {
        inputRef.current.value = '';
      }
      if (forwardedRef?.current) {
        forwardedRef.current.value = '';
      }
    }
  }, [value]);

  return (
    <SContainer className={className} role="searchBarContainer">
      {isFirefox ? (
        <form ref={formRef} role="form">
          {renderSearch()}
          {value && value?.length > 0 && (
            <SClear onClick={handleFireFoxClearButton} role="clearButton">
              <img src={ClearButton} alt="clear button" role="clearIcon" />
            </SClear>
          )}
        </form>
      ) : (
        renderSearch()
      )}
    </SContainer>
  );
};
