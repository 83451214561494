import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SContainer = styled.li`
  padding: 0.5rem 0 0.5rem 0;

  display: flex;
  align-items: center;

  text-decoration: none;
  border-bottom: 1px solid ${palette.prussianBlue4};

  font-family: 'Roboto-Regular';
  .title {
    font-weight: 500;
    font-size: 0.875rem;
  }
  .title,
  .time {
    color: ${palette.white};
  }
  .subTitle {
    margin-top: 0.25rem;
  }
  .subTitle,
  .date {
    color: ${palette.silver};
    font-size: 0.75rem;
    font-weight: 400;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const STextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.75rem 0 0.75rem;
  flex-grow: 1;
  .availabilityChip {
    margin-top: 0.25rem;
  }
`;
