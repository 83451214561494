import { GetChecklistProps, GetChecklistSort } from '../apis/checklistsAPI/types';
import { fetchCurrentChat } from '../containers/ChatsList/chatListSlice/actionCreators';
import {
  fetchChecklists,
  fetchChecklistSummary,
  fetchOneChecklist,
  handleChecklistDataNotification,
} from '../containers/Checklists/checklistsSlice/actionCreators';
import { fetchImOkDocument, fetchImOkList } from '../containers/ImOkList/imOkSlice/actionCreators';
import {
  fetchOnCallAlertDocument,
  fetchOnCallAlertList,
} from '../containers/OnCallAlertList/onCallAlertSlice/actionCreators';
import { AppDispatch } from '../store';
import { EAlertType, Notification, NotificationPayload } from './types';

export class AppOpenedNotificationHandler {
  readonly id: number | null;

  readonly setNotification: React.Dispatch<React.SetStateAction<Notification>>;

  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  readonly notificationPayload: NotificationPayload;

  readonly showNotification: boolean;

  constructor(
    id: number | null,
    notificationPayload: NotificationPayload,
    setNotification: React.Dispatch<React.SetStateAction<Notification>>,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    showNotification: boolean
  ) {
    this.id = id;
    this.notificationPayload = notificationPayload;
    this.setIsModalOpen = setIsModalOpen;
    this.setNotification = setNotification;
    this.showNotification = showNotification;
  }

  openNotification(notificationParams: Partial<Notification>) {
    this.setNotification({
      messageId: this.id,
      title: this.notificationPayload.title,
      description: this.notificationPayload.message,
      alert: EAlertType.none,
      ...notificationParams,
    });
    this.setIsModalOpen(
      this.showNotification || notificationParams.alert === EAlertType.selfAlarmClosedApp
    );
  }
}

export class DataOnlyHandler {
  readonly id: number | null;

  readonly activeRoute: string;

  readonly dispatch: AppDispatch;

  constructor(id: number | null, activeRoute: string, disp: AppDispatch) {
    this.id = id;
    this.activeRoute = activeRoute;
    this.dispatch = disp;
  }

  async handleMessages() {
    if (this.activeRoute.includes(`message/${this.id}`)) {
      // update current chat
      this.dispatch(fetchCurrentChat(`${this.id}`, true, undefined, 15, undefined, true));
    }
  }

  async handleIamOk() {
    if (this.id && this.activeRoute.includes(`muster/${this.id}`)) {
      // update imOk document
      // currently push notification on IamOk status changes not comes
      this.dispatch(fetchImOkDocument(`${this.id}`));
      return;
    } else {
      // update imOk list
      this.dispatch(fetchImOkList());
    }
  }

  async handleOnCallAlert() {
    if (this.id && this.activeRoute.includes(`oncall/${this.id}`)) {
      // update onCallAlert
      // currently push notification on onCallAlert status changes not comes
      this.dispatch(fetchOnCallAlertDocument(this.id));
      return;
    } else if (this.activeRoute.includes('onCallAlert')) {
      // update onCallAlert list
      this.dispatch(fetchOnCallAlertList());
    }
  }

  async handleCheckList() {
    if (this.id && this.activeRoute.includes(`checklist/${this.id}`)) {
      // update checklist
      // currently push notification on checklist changes not comes
      this.dispatch(fetchOneChecklist(this.id));
      return;
    } else if (this.activeRoute.includes(`checklists`)) {
      // update checklist list
      const checklistProps: GetChecklistProps = {
        limit: 15,
        skip: 0,
        sort: GetChecklistSort.startedDateDesc,
        my: true,
        others: true,
        shared: true,
        type: [0, 1, 2],
      };
      const filters = {
        sort: GetChecklistSort.startedDateDesc,
        my: true,
        others: true,
        shared: true,
      };
      this.dispatch(fetchChecklistSummary('', filters));
      this.dispatch(fetchChecklists({ ...checklistProps }));
    }
  }

  async handleChecklistModified(checklistId: number) {
    if (checklistId && this.activeRoute.includes(`checklist/${checklistId}`)) {
      this.dispatch(handleChecklistDataNotification(checklistId));
    }
  }
}
