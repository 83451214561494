import { Layout } from '../../components/Layout/Layout';
import AlarmMap from '../../containers/Alarm/AlarmMap';
import { translate } from '../../utils/translate';
import { useAlarmStep } from '../../utils/customHooks/useAlarmStep';
import { AlarmStep } from '../../utils/enums';
function AlarmMapPage() {
  const { currentStep, link, stepsAmount } = useAlarmStep(AlarmStep.SelectLocation);
  return (
    <Layout
      isMessageLayout
      to={link}
      backBtn
      isAlarmCreateLayout
      message={`alarm_send_alarm`}
      subMessageText={`${translate(`alarm_step`)} ${currentStep} ${translate(
        `alarm_of`
      )} ${stepsAmount}`}
    >
      <AlarmMap />
    </Layout>
  );
}

export default AlarmMapPage;
