import { useLocation, useParams, Link } from 'react-router-dom';
import { ReactComponent as Home } from '../../../assets/imgs/navigation/homeTab.svg';
import { ReactComponent as Chat } from '../../../assets/imgs/navigation/envelopeTab.svg';
import { ReactComponent as Alarm } from '../../../assets/imgs/navigation/alarmTab.svg';
import { useAppSelector } from '../../../hooks';
import {
  selectMessagesActiveEmergancy,
  selectMessagesTotalUnread,
} from '../../../containers/ChatsList/chatListSlice';
import { NotificationBubble } from '../../NotificationBubble/NotificationBubble';
import { selectUserMenuItems } from '../../../containers/Login/LoginSlice';
import { Sbackground, FooterWrapper, SFooter, STabs, STabList, STab } from './Footer.styles';

function Footer(props: { showBottomTabs: boolean }) {
  const { id } = useParams();
  const location = useLocation();
  const totalUnread = useAppSelector(selectMessagesTotalUnread);
  const activeEmergancy = useAppSelector(selectMessagesActiveEmergancy);
  const menuItems = useAppSelector(selectUserMenuItems);

  let notificationNum: number | null | '!' = null;
  if (totalUnread !== 0) {
    notificationNum = totalUnread;
  } else {
    notificationNum = activeEmergancy ? '!' : null;
  }

  if (!props.showBottomTabs) {
    return <></>;
  }

  const haveAlarm = menuItems && menuItems?.findIndex(item => item.technicalName === 'alarm') > -1;

  return (
    <FooterWrapper role="footer">
      <SFooter role="footerContainer">
        <STabs role="tabsContainer">
          <STabList role="tabsRow">
            <STab selected={location.pathname === '/dashboard'} role="dashboardTab">
              <Link to="/dashboard" role="link">
                <Home />
              </Link>
            </STab>
            <STab
              selected={['/chat', '/createMessage', `/message-details/${id}`].includes(
                location.pathname
              )}
              role="chatTab"
            >
              <Link to="/chat" role="link">
                <Chat />
                {notificationNum && (
                  <NotificationBubble
                    notification={notificationNum}
                    isDanger={true}
                    className="footerNotificationBubble"
                  />
                )}
              </Link>
            </STab>
            {!haveAlarm ? (
              <STab />
            ) : (
              <STab selected={location.pathname === '/alarm'} isAlarm role="alarmsTab">
                <Link to="/alarm" role="link">
                  <Sbackground role="alarmIconContainer">
                    <Alarm />
                  </Sbackground>
                </Link>
              </STab>
            )}
          </STabList>
        </STabs>
      </SFooter>
    </FooterWrapper>
  );
}

export default Footer;
