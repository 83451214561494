import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SComment = styled.div`
  background-color: ${palette.charcoal};
  border-radius: 6px;
  width: 100%;
  max-height: 10rem;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .wrapper {
    max-width: 80%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .author {
    font-family: 'Roboto-Medium';
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }

  .text {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 12px;
    color: ${palette.silver};
    .message-link {
      color: ${palette.honeyYellow};
    }

    &:child a {
      text-decoration: none;
    }
  }

  .time {
    min-width: 5rem;
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 12px;
    text-align: end;
  }
`;

export const SAttachementContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  img {
    border-radius: 6px;
    width: 3.5rem;
  }
`;

export const SImageContainer = styled.div`
  margin-right: 0.75rem;
  min-width: 3.5rem;
  height: 3.5rem;

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
`;
