import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SplashWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${palette.darkblack};
`;

export const SplashContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 26rem;
  width: 100%;
  background-color: ${palette.navyBlue};
`;
