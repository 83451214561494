import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import Member from '../containers/ChatDetails/Member';

export const ChatMemberSettings = (props: { fromHoldingStatement?: boolean }) => {
  const { fromHoldingStatement } = props;
  const { chatID } = useParams();
  const location = useLocation();
  const prev = location.state?.prevPath;

  return (
    <Layout
      isMessageLayout
      message={`groups_groupMember`}
      to={
        prev ??
        (fromHoldingStatement
          ? `/message-details/${chatID}/fromHoldingStatement`
          : `/message-details/${chatID}`)
      }
      showBottomTabs
    >
      <Member />
    </Layout>
  );
};
