import { compact } from 'lodash';
import { Group } from '../containers/GroupsList/groupsSlice/types';
import { IsAllPartialSelection } from '../components/CollapsibleCheckBox/CollapsibleCheckBox';

/* ------------------------------ Helper Types ------------------------------ */
export type OrgAndSubOrgInGroupType = Pick<
  Group,
  'organizationID' | 'subOrganizationID' | 'organizationName' | 'subOrganizationname'
>;

export interface AccountFromGroup {
  id: number;
  name: string;
  type: 'ACCOUNT' | 'SUB_ACCOUNT';
}

/* -------------------------------- Functions ------------------------------- */
export const searchGroups = (searchTerm: string, groups: Group[]): Group[] => {
  return searchTerm
    ? groups.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : groups;
};

export const hasMultipleAccounts = <T extends OrgAndSubOrgInGroupType>(groups: T[]): boolean => {
  const groupOrganizationIDs = groups.map(g => g.organizationID);
  const groupSubOrganizationIDs = groups.map(g => g.subOrganizationID);

  const toFindAccounts = Array.from(new Set(groupOrganizationIDs));
  const toFindSubAccounts = Array.from(new Set(compact(groupSubOrganizationIDs)));
  return toFindAccounts.length > 1 || toFindSubAccounts.length > 1;
};

export const extractAccountsFromGroups = <T extends OrgAndSubOrgInGroupType>(
  groups: T[]
): AccountFromGroup[] => {
  let groupAccount: AccountFromGroup | undefined;
  const accounts = groups.map(group => {
    groupAccount = group.subOrganizationID
      ? {
          id: group.subOrganizationID!,
          name: group.subOrganizationname!,
          type: 'SUB_ACCOUNT',
        }
      : group.organizationID
      ? {
          id: group.organizationID!,
          name: group.organizationName!,
          type: 'ACCOUNT',
        }
      : undefined;
    return groupAccount;
  });
  const uniqueIds: number[] = [];

  return compact(accounts).filter(element => {
    const isDuplicate = uniqueIds.includes(element.id);

    if (!isDuplicate) {
      uniqueIds.push(element.id);
      return true;
    }
    return false;
  });
};

export const isAccountSelectedAllOrPartial = (
  groupsInAccountIDs: number[],
  selectedGroups: Set<number>
): IsAllPartialSelection => {
  const selectedGroupsIDs = Array.from(selectedGroups);
  const selectedIDs = groupsInAccountIDs.filter(id => selectedGroupsIDs.includes(id));
  const atLeastOneSelected = selectedIDs.length !== 0;
  const allSelected = selectedIDs.length === groupsInAccountIDs.length;
  return { all: allSelected, is: atLeastOneSelected };
};
