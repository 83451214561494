import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const AdditionalUsersLoaderContainer = styled.div`
  display: flex;
  margin: 10px auto;
`;

export const SList = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.lwtBottomPadding {
    padding-bottom: 160px !important;
  }
  &.lastItemBottomPadding {
    padding-bottom: 80px !important;
  }
`;

export const SRecipientItemContainer = styled.div<{
  haveDivider?: boolean;
  addExtraBottomPadding?: boolean;
}>`
  border-bottom: 1px solid ${props => (props.haveDivider ? palette.queenBlue : palette.transparent)};
  padding-bottom: 6px;
  margin-bottom: 16px;
`;

export const SLoaderContainer = styled.div`
  width: 100%;
  height: 90vh;
  justify-content: center;
  align-items: center;
`;
