/* eslint-disable import/named */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export enum PushyBannerState {
  NOT_CONNECTED = 'NOT_CONNECTED',
  CONNECTED = 'CONNECTED',
}

// initail state
const initialState: any = {
  isAppLoading: true,
  pushyConnectionBanner: null,
};

// group detail slice
const appSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setIsAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppLoading = action.payload;
    },
    setPushyConnectionBanner: (state, action: PayloadAction<PushyBannerState | null>) => {
      state.pushyConnectionBanner = action.payload;
    },
  },
});

// export the reducers
export const { setIsAppLoading, setPushyConnectionBanner } = appSlice.actions;

export const selectIsAppLoading = (state: RootState) => state.app.isAppLoading;
export const selectPushyConnectionBanner = (state: RootState) => state.app.pushyConnectionBanner;

export default appSlice.reducer;
