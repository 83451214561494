import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';

export const SMapTypeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
`;

export const SCopyCoordinatesButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.white};
  font-family: 'Roboto-Regular';
  font-weight: 500;
  font-size: 14px;
  border: 1px solid ${palette.honeyYellow};
  background-color: ${palette.transparent};
  padding: 0.5rem 0;
  border-radius: 16px;
  line-height: 1rem;
  cursor: pointer;
  gap: 10px;
`;

export const SMapModal = styled.div`
  width: 21rem;
  height: 70vh;
  margin: 0 auto;
  position: relative;
`;

export const SLocationContainer = styled.div`
  position: absolute;
  top: 3%;
  left: 1.5rem;
  padding: 0.75rem 1rem;
  background-color: ${palette.prussianBlue2};
  border-radius: 10px;
  width: 18rem;
  align-items: center;
  gap: 0.75rem;

  p {
    font-family: 'Roboto-Regular';
    font-weight: 500;
    font-size: 14px;
  }
`;

export const SLocationRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
`;

export const SIconContainer = styled.div`
  display: flex;
  flex-basis: 1.75rem;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;
  margin-right: 0.5rem;
`;

export const SBottomInfoWrapper = styled.div`
  position: absolute;
  bottom: 3%;
  left: 50%;
  max-width: 18rem;
  transform: translate(-50%, 0);
  width: 100%;
`;

export const SInfoContainer = styled.div<any>`
  padding: 0.75rem 1rem;
  background-color: ${palette.prussianBlue2};
  border-radius: 10px;
  max-width: 18rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.1rem;
  .title {
    font-family: 'Roboto-Regular';
    font-weight: 700;
    font-size: 16px;
  }
  .date {
    font-family: 'Roboto-Regular';
    font-weight: 500;
    font-size: 10px;
    color: ${palette.silver};
  }
  .subText {
    font-family: 'Roboto-Regular';
    font-weight: 500;
    font-size: 10px;
    color: ${props => props.recalled && palette.tartOrange};
  }
  ${props =>
    props.$withDate &&
    props.$withoutRecalledLabel &&
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}
`;
