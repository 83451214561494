import { Tabs, Tab, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { SearchBarBase } from '../../components/SearchBarBase/SearchBarBase';
import { SearchFilterBar } from '../../components/SearchFilterBar/SearchFilterBar';
import { palette } from '../../theme/colors';

export const SPage = styled(Page)`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`;

export const SListPage = styled(Page)`
  padding-top: 0rem !important;
  display: flex;
  flex-direction: column;
`;

export const SSelect = styled.div`
  min-height: 0;
  height: 100%;
`;

export const STabs = styled(Tabs)`
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
`;
export const STab = styled(Tab)<any>`
  cursor: pointer;
  color: ${props => props.disabled && palette.silver};
  width: 50%;
  text-align: center;
  user-select: none;
  padding: 0 0 10px 0;
  border-bottom: 1px solid ${palette.queenBlue};
  font-family: 'Roboto-Regular';
  font-size: 14px;

  &.is-selected {
    border-bottom: 2px solid ${palette.honeyYellow};
    color: ${palette.honeyYellow};
    margin-bottom: -0.5px;
  }

  &:focus {
    outline: none;
  }
`;

export const STabPanel = styled(TabPanel)`
  &.is-selected {
    /* vertical scrolling + hide scroller bar  */
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    height: 100%;
    display: block;
  }
`;

export const SSearchFilterBar = styled(SearchFilterBar)`
  margin: 1.25rem 0;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  margin: 0 0 1.25rem 0;
`;

export const SList = styled.div`
  overflow-y: auto;
  padding-bottom: 1rem;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
`;
