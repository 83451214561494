import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Arrow from '../../assets/imgs/chats/arrow.svg';
import { RecipientButton } from '../../components/RecipientButton/RecipientButton';
import { sendAMessage } from '../CreateMessage/createMessageSlice/actionCreators';

import { useTranslation } from 'react-i18next';
import { saveDocumentToServer } from '../../apis/mediaAPI';
import ChatBox from '../../components/Chat/ChatBox';
import { SimpleText } from '../../components/Chat/ChatListItem.styles';
import { FilterOrSelectBottomSheet } from '../../components/FilterOrSelectBottomSheet/FilterOrSelectBottomSheet';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { OnCallAlertkMessageMembersType } from '../../routes/OnCallAlert';
import { CreateMessageModel } from '../Chat/models';
import { selectGroupById } from '../GroupsList/groupsSlice';
import { fetchGroups } from '../GroupsList/groupsSlice/actionCreators';
import { selectUser } from '../Login/LoginSlice';
import { getRecipientsText, useGetMembers } from '../StartIamOkMessage/helpers';
import { getOnCallAlertInitiallSelected } from './createMessageOnCallAlertSummary.helpers';
import {
  GroupContainer,
  SFlexDiv,
  SIcon,
  SItem,
  SLine,
  SPage,
  SSummaryContainer,
} from './createMessageOnCallAlertSummary.styles';
import { selectOnCallAlertDocument, selectOnCallAlertIsLoading } from './onCallAlertSlice';
import { fetchOnCallAlertDocument } from './onCallAlertSlice/actionCreators';
import { SelectedAllType } from '../../components/FilterOrSelectBottomSheet/types';

export enum GroupsToShow {
  All = 'labelAll',
  MemberOfGroup = 'messages_groups_where_member',
  NotMemberOfGroup = 'messages_groups_where_not_member',
}

export const CreateMessageOnCallAlertSummary = () => {
  const onCallAlertDocument = useAppSelector(selectOnCallAlertDocument);
  const isLoading = useAppSelector(selectOnCallAlertIsLoading);

  const { membersType, id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [userIds, setUserIds]: [Set<number>, (set: Set<number>) => void] = useState(new Set());
  const [recipientsFilter, setRecipientsFilter] = useState<boolean>(false);
  const [tabBar, setTabBar] = useState<boolean>(true);

  const fetchOnCallAlert = async () => {
    dispatch(fetchOnCallAlertDocument(+(id || 0)));
  };

  const groupId = onCallAlertDocument?.groupId;
  const user = useAppSelector(selectUser);

  const selectedGroup = useAppSelector(selectGroupById(groupId || 0));

  const { members: membersForFilter, isGettingMembers } = useGetMembers({
    id: groupId || 0,
  });

  const onFilter = (selected: Set<number> | SelectedAllType) => {
    switch (selected) {
      case 'SELECTED_ALL':
        const selectedMemberIds = new Set(membersForFilter.map(member => member.userID));
        setUserIds(selectedMemberIds);
        setRecipientsFilter(false);
        break;
      case 'UNSELECTED_ALL':
        break;
      default:
        setUserIds(selected);
        setRecipientsFilter(false);
        break;
    }
  };

  const selectedGroups = selectedGroup ? [selectedGroup] : [];

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  useEffect(() => {
    if (!onCallAlertDocument) {
      fetchOnCallAlert();
    } else {
      const selected = getOnCallAlertInitiallSelected(
        membersType as OnCallAlertkMessageMembersType,
        onCallAlertDocument
        // user?.id
      );
      setUserIds(selected);
    }
  }, [onCallAlertDocument]);

  const { t } = useTranslation();

  const getIDs = <T extends { id: number }>(list: T[]) => {
    return list.map(item => item.id);
  };

  const sendMessage = (
    text?: string,
    imageFileNames?: string[],
    documentFileNames?: string[],
    audioFileNames = [],
    locationId?: number
  ) => {
    const recipientsIDs: number[] = [...userIds];
    const groupsIDs: number[] = getIDs(selectedGroups);

    const messageModel: CreateMessageModel = {
      subject: null,
      senderId: user?.id,
      groupIds: groupsIDs.length === 0 ? undefined : groupsIDs,
      recipientIds: recipientsIDs.length === 0 ? null : recipientsIDs,
      documentFileNames: documentFileNames,
      audioFileNames: audioFileNames,
      photoFileNames: imageFileNames,
      text: text,
      locationId: locationId,
      // replyType: getReplyTypeId(replyType),
    };

    dispatch(sendAMessage(messageModel, navigate));
  };

  const onPhotosSend = async (imagesList: string[], messageText: string) => {
    if (imagesList.length > 0) {
      const text = messageText && messageText.length > 0 ? messageText : undefined;
      sendMessage(text, imagesList);
    }
  };

  const onDocumentsSend = async (documentsList: File[]) => {
    const documentsFilesNamesArray = [];
    let document: File;
    for await (document of documentsList) {
      const formData = new FormData();
      formData.append('document', document);
      const result = await saveDocumentToServer(formData);
      if (result) {
        documentsFilesNamesArray.push(result);
      }
    }
    if (documentsFilesNamesArray.length > 0) {
      sendMessage(undefined, [], documentsFilesNamesArray);
    }
  };

  const renderToInput = () => {
    return (
      <SimpleText fontSize="14px" role="membersText">
        {getRecipientsText(membersForFilter, userIds || new Set())}
      </SimpleText>
    );
  };

  if (!onCallAlertDocument && isLoading) return <Loader />;

  return (
    <SPage>
      <SSummaryContainer role="summaryContainer">
        <GroupContainer role="groupsContainer">
          {selectedGroups.length !== 0 && (
            <>
              {selectedGroups.map((group, key) => {
                return (
                  <RecipientButton
                    key={key}
                    name={group?.name}
                    onClick={() => navigate(`/oncall/${id}`)}
                  />
                );
              })}
            </>
          )}
        </GroupContainer>
        {!isGettingMembers && (
          <FilterOrSelectBottomSheet
            isOpen={recipientsFilter}
            setIsOpen={setRecipientsFilter}
            onFilter={onFilter}
            data={membersForFilter}
            initialSelected={userIds}
            withPhoto
            atLeastOneReq
            selectShapeType="box"
            titleTx="messages_selectRecepients"
            selectAllTx={'imOk_allRecepients'}
            hideCurrentUserId={user?.id}
          />
        )}

        <SLine className="moreMargin" />
        <SItem onClick={() => setRecipientsFilter(!recipientsFilter)} role="sendToContainer">
          <SimpleText className="left" fontSize="12px" role="sendToLabel">
            {t(`messages_to`)}
          </SimpleText>
          {renderToInput()}
          <SFlexDiv />
          <SIcon role="arrowIcon" src={Arrow} alt="" />
        </SItem>
        <SLine />
      </SSummaryContainer>
      <ChatBox
        tabBar={tabBar}
        setTabBar={setTabBar}
        onTextSend={sendMessage}
        onPhotosSend={onPhotosSend}
        onDocumentsSend={onDocumentsSend}
        onLocationSend={(locationId, text) => sendMessage(text, [], [], [], locationId)}
      />
    </SPage>
  );
};
