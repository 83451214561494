import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const MeetingRoomItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.2rem;
  background-color: ${palette.fadedDarkBlue};
  border: 1px solid ${palette.queenBlue};
  border-radius: 0.75rem;
  cursor: pointer;
`;

export const SDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const STitle = styled.p`
  color: ${palette.white};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.17rem;
`;

export const SDescription = styled.p`
  color: ${palette.navy20};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  word-break: break-word;
  &[data-testid='description'] {
  }
`;

export const SLink = styled.p`
  color: ${palette.ashesGray};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  word-break: break-word;
`;

export const SLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
