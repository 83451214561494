/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  clearSelectedGroups,
  isAlarmLoading,
  selectTypes,
  setIsLoading,
  setSelectedGroupType,
} from './AlarmSlice';
import { getEmergencyTypesAction } from './AlarmSlice/actionCreators';
import { EmergencyType } from './AlarmSlice/types';

import NoAlarms from '../../assets/imgs/NotFound/no-result.svg';
import AlarmType from '../../components/AlarmItem/AlarmType';
import { getItem } from '../../utils/storage';
import { Page } from '../../components/Page/Page';
import { ReactComponent as Arrow } from '../../assets/imgs/alarms/arrow.svg';
import { selectGroups } from '../GroupsList/groupsSlice';

import {
  AlarmFilter,
  AlarmFilterType,
  AlarmFilterTypeObject,
} from '../../components/AlarmFilter/AlarmFilter';
import {
  LocalStorageFiltersKeys,
  checkIfFiltersApplied,
  useLocalStorageFilters,
} from '../../utils/customHooks/useLocalStorageFilters';
import { TitledAlarmTypeGroup } from '../../components/AlarmItem/TitledAlarmTypeGroup';
import { filterAlarmsByAccounts, getUserAlarmList, handleFiltersChange } from './helpers';
import { generateInitialSelectedData } from '../../components/DocumentFilter/helpers';
import {
  AlarmWrapper,
  NoAlarmWrapper,
  SAlertBanner,
  SPage,
  ScrollMoreBottom,
  ScrollMoreTop,
  TOP_PADDING_UNDER_FILTER_ALERT_BANNER,
} from './Alarm.styles';
import Loader from '../../components/Loader/Loader';
import { useTopBottomScrollLimit } from '../../utils/customHooks/useTopBottomScrollLimit';
import { AlertBannerRef } from '../../components/AlertBanner/AlertBanner';

export interface AlarmsAccountTitle {
  id: number;
  name: string;
  alarms: EmergencyType[];
}

interface IAlarmProps {
  filterButtonProps: {
    setFilterOpen: Dispatch<SetStateAction<boolean>>;
    filterOpen: boolean;
    renderFilterButton: boolean;
    setIsFilterApplied: Dispatch<SetStateAction<boolean>>;
    setRenderFilterButton: Dispatch<SetStateAction<boolean>>;
  };
}
const defaultFilters: AlarmFilterTypeObject = { selectedIds: 'SELECTED_ALL' };

function Alarm(props: IAlarmProps) {
  const {
    filterOpen,
    setFilterOpen,
    setIsFilterApplied,
    setRenderFilterButton,
    renderFilterButton,
  } = props.filterButtonProps;
  const dispatch = useAppDispatch();

  const alarmTypes = useAppSelector(selectTypes);
  const isLoading = useAppSelector(isAlarmLoading);
  const groups = useAppSelector(selectGroups);

  const [filters, setFilters] = useLocalStorageFilters<AlarmFilterTypeObject>(
    LocalStorageFiltersKeys.alarmsLis,
    { ...defaultFilters }
  );

  const isFilterApplied = checkIfFiltersApplied({
    defaultFilters: defaultFilters,
    filters,
  });

  const onFilter = (_filters: AlarmFilterType) => {
    if (_filters === 'UNSELECTED_ALL') {
      setFilters({ selectedIds: 'SELECTED_ALL' });
    } else {
      setFilters({ selectedIds: _filters });
    }
    setFilterOpen(false);
  };

  const alertRef = useRef<AlertBannerRef>(null);

  useLayoutEffect(() => {
    setIsFilterApplied(isFilterApplied);
  }, [isFilterApplied]);

  const { accountTitlesList, alarmList } = useMemo(
    () =>
      getUserAlarmList(groups, alarmTypes, () => {
        dispatch(setIsLoading(false));
      }),
    [groups, alarmTypes]
  );

  useEffect(() => {
    const shouldShowFilterButton = accountTitlesList.length > 1;
    if (shouldShowFilterButton !== renderFilterButton) {
      setRenderFilterButton(shouldShowFilterButton);
    }
  }, [accountTitlesList.length]);

  // we have to keep the useEffect in order for it to run when the alertRef.current to be defined
  useEffect(() => {
    handleFiltersChange(isFilterApplied, filters, alertRef);
  }, [isFilterApplied, filters.selectedIds, alertRef.current]);

  const { alarmRef, bottomArrowRef, checkScroll, handleScroll, topArrowRef, limit } =
    useTopBottomScrollLimit(alarmList);

  useEffect(() => {
    dispatch(getEmergencyTypesAction());
    // Clear selected groups and the type in store
    dispatch(clearSelectedGroups());
    dispatch(setSelectedGroupType([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // get the language so we know which alarm name to display
  const currentLang = getItem('language');

  if (isLoading) {
    return <Loader />;
  }

  if (alarmList.length === 0) {
    return (
      <Page>
        <NoAlarmWrapper>
          <img src={NoAlarms} alt="" />
          <p>You don’t have any alarms</p>
        </NoAlarmWrapper>
      </Page>
    );
  }

  return (
    <SPage>
      <SAlertBanner
        preset="fixed"
        ref={alertRef}
        placeHolderHeight={TOP_PADDING_UNDER_FILTER_ALERT_BANNER}
        alertTopToValue={16}
        onClick={() => {
          setFilterOpen(true);
        }}
      />
      <AlarmWrapper
        ref={alarmRef}
        onScroll={checkScroll}
        role="alarmContainer"
        haveAlarmTitles={accountTitlesList.length > 2}
        $isFilterApplied={isFilterApplied}
      >
        <AlarmFilter
          defaultFilters={defaultFilters.selectedIds}
          data={accountTitlesList}
          isOpen={filterOpen}
          setIsOpen={setFilterOpen}
          onFilter={onFilter}
          initialSelectedData={generateInitialSelectedData(filters.selectedIds, accountTitlesList)}
        />
        <ScrollMoreTop role="scrollMoreTop" onClick={() => handleScroll(true)} ref={topArrowRef}>
          <Arrow role="arrowUpIcon" />
        </ScrollMoreTop>

        {accountTitlesList.length < 2
          ? alarmList
              .slice(0, limit)
              .map((item, key) => (
                <AlarmType key={key} alarmTypeItem={item} currentLang={currentLang!} />
              ))
          : filterAlarmsByAccounts(accountTitlesList, filters).map(item => (
              <TitledAlarmTypeGroup
                key={`TitledAlarmTypeGroup-${item.id}`}
                titledAlarmsGroup={item}
                currentLang={currentLang!}
              />
            ))}
        <ScrollMoreBottom
          role="scrollMoreBottom"
          onClick={() => handleScroll(false)}
          ref={bottomArrowRef}
        >
          <Arrow role="arrowDownIcon" />
        </ScrollMoreBottom>
      </AlarmWrapper>
    </SPage>
  );
}

export default Alarm;
