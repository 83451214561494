import React from 'react';
import { ChecklistStatus } from '../../../utils/enums';
import { SChecklistButton, SChecklistSettingsWrapper } from './ChecklistDetails.styles';
import { ReactComponent as Play } from '../../../assets/imgs/checklists/play.svg';
import { ReactComponent as EditUser } from '../../../assets/imgs/checklists/edit.svg';
import { ReactComponent as EditTemplate } from '../../../assets/imgs/checklists/user-add.svg';
import { ReactComponent as Delete } from '../../../assets/imgs/checklists/delete.svg';
import { ReactComponent as Close } from '../../../assets/imgs/checklists/close.svg';
import { ReactComponent as AddSquare } from '../../../assets/imgs/checklists/add-square.svg';
import { ReactComponent as Rename } from '../../../assets/imgs/checklists/rename.svg';
import { useAppDispatch } from '../../../hooks';
import { useConfirmation } from '../../../utils/ConfirmationServiceContext/confirmationContext';
import {
  fetchContinueChecklist,
  fetchDeleteTemplate,
  fetchEndChecklist,
  renameAChecklist,
} from '../../../containers/Checklists/checklistsSlice/actionCreators';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../../utils/translate';
import { GroupDetail } from '../../../containers/GroupDetail/groupDetailSlice/types';

interface ChecklistDetailsButtonsProps {
  canEdit: boolean | undefined;
  status: ChecklistStatus;
  insideChecklist: boolean;
  id: number;
  foundSharedGroups: GroupDetail[];
  setIsOpen: () => void;
  name: string;
  setAddItemModalOpen?: () => void;
}

function ChecklistDetailsButtons(props: ChecklistDetailsButtonsProps) {
  const { canEdit, status, insideChecklist, id, setIsOpen, name, setAddItemModalOpen } = props;

  const dispatch = useAppDispatch();
  const confirm = useConfirmation();
  const navigate = useNavigate();

  const handleEditAccess = (checklistId: number) => {
    navigate(`/checklist/${checklistId}/edit-access`, {
      state: {
        fromList: !insideChecklist,
        fromItem: insideChecklist,
      },
    });
  };

  const handleDeleteChecklist = (checklistId: number) => {
    confirm({
      title: 'checklist_confirmation',
      description: 'checklist_delete_confirmation',
      onSubmit: () => {
        dispatch(fetchDeleteTemplate(checklistId));
        setIsOpen();
      },
      onCancel: () => {},
      confirmText: 'messages_delete',
      confirmStyle: 'red',
    });
  };

  const handleCheckListEnd = (checklistId: number) => {
    dispatch(fetchEndChecklist(checklistId, navigate));
    setIsOpen();
  };

  const handleRename = (checklistId: number, newName: string) => {
    confirm({
      title: 'checklist_rename',
      inputBox: true,
      inputBoxIntialValue: newName,
      onSubmit: text => {
        dispatch(renameAChecklist(checklistId, text!));
      },
      onCancel: () => {},
      confirmText: 'proceed',
    });

    setIsOpen();
  };

  const handleContinueChecklist = (checklistId: number) => {
    confirm({
      title: 'checklist_confirmation',
      description: 'checklist_continue_confirmation',
      onSubmit: () => {
        dispatch(
          fetchContinueChecklist(checklistId, () => {
            setIsOpen();
            if (insideChecklist) {
              navigate(0);
            } else {
              navigate(`/checklist/${checklistId}`);
            }
          })
        );
      },
      onCancel: () => {},
      confirmText: 'checklist_reactivate',
    });
  };

  const handleStartChecklist = (checklistId: number) => {
    confirm({
      description: 'checklist_name',
      onSubmit: (text?: string) => {
        if (text && text.length > 0) {
          navigate(`/checklist/${checklistId}/start/${encodeURIComponent(text)}`);
        }
      },
      onCancel: () => {},
      confirmText: 'proceed',
      inputBox: true,
      inputBoxIntialValue: name,
      placeholderTx: 'checklist_name',
    });
  };

  const renderStartedOptions = () => (
    <SChecklistSettingsWrapper button role="checklistDetailsButtons">
      {canEdit && (
        <>
          {insideChecklist && (
            <>
              <SChecklistButton
                role="addTaskButton"
                onClick={() => {
                  setAddItemModalOpen?.();
                }}
              >
                <p role="buttonText">{translate(`checkListInfo_add_task`)}</p>
                <AddSquare role="icon" />
              </SChecklistButton>
            </>
          )}
          <SChecklistButton
            role="editAccessButton"
            onClick={() => {
              handleEditAccess(id);
            }}
          >
            <p role="buttonText">{translate(`checklist_edit_acces`)}</p>
            <EditTemplate role="icon" />
          </SChecklistButton>
          <SChecklistButton
            role="renameButton"
            onClick={() => {
              handleRename(id, name);
            }}
          >
            <p role="buttonText">{translate(`checklist_rename`)}</p>
            <Rename role="icon" />
          </SChecklistButton>

          <SChecklistButton
            role="endButton"
            onClick={() => {
              handleCheckListEnd(id);
            }}
          >
            <p role="buttonText">{translate(`checklist_end_checklist`)}</p>
            <Close role="icon" />
          </SChecklistButton>
        </>
      )}
    </SChecklistSettingsWrapper>
  );

  const renderNotStartedOptions = () => (
    <SChecklistSettingsWrapper button role="settingsWrapper">
      <SChecklistButton role="startButton" onClick={() => handleStartChecklist(id)}>
        <p role="buttonText">{translate(`checkist_start_template`)}</p>
        <Play role="icon" />
      </SChecklistButton>

      {canEdit && (
        <>
          <SChecklistButton
            onClick={() => {
              handleEditAccess(id);
            }}
            role="editAccessButton"
          >
            <p role="buttonText">{translate(`checklist_edit_acces`)}</p>
            <EditTemplate role="icon" />
          </SChecklistButton>

          <SChecklistButton
            onClick={() =>
              navigate(`/checklist/${id}/edit`, {
                state: {
                  fromList: !insideChecklist,
                  fromItem: insideChecklist,
                },
              })
            }
            role="editTemplateButton"
          >
            <p role="buttonText">{translate(`checklist_edit_template`)}</p>
            <EditUser role="icon" />
          </SChecklistButton>

          <SChecklistButton role="deleteButton" onClick={() => handleDeleteChecklist(id)}>
            <p role="buttonText">{translate(`checklist_action_delete`)}</p>
            <Delete role="icon" />
          </SChecklistButton>
        </>
      )}
    </SChecklistSettingsWrapper>
  );

  const renderEndedOptions = () => (
    <SChecklistSettingsWrapper button role="settingsEndWrapper">
      <SChecklistButton onClick={() => handleContinueChecklist(id)} role="continueButton">
        <p role="buttonText">{translate(`checklist_action_continue`)}</p>
        <Play role="icon" />
      </SChecklistButton>
    </SChecklistSettingsWrapper>
  );

  if (!canEdit) return <></>;

  return (
    <div className="wrapper">
      {status === ChecklistStatus.Started && renderStartedOptions()}
      {status === ChecklistStatus.NotStarted && renderNotStartedOptions()}
      {status === ChecklistStatus.Ended && renderEndedOptions()}
    </div>
  );
}

export default ChecklistDetailsButtons;
