import { batch } from 'react-redux';
import { setIsError, setIsInternalLoading, setSupports } from './index';
import { AppThunk } from '../../../store';
import { getSupportInfo } from '../../../apis/authApi/authAPI';
import { setUser } from '../../Login/LoginSlice';
import { getItem } from '../../../utils/storage';

export const getSupports =
  (errorCallback?: (e: unknown) => void): AppThunk =>
  async (dispatch, getState) => {
    const organizationID = getState().user.user?.organizationID;
    if (!organizationID) {
      dispatch(setUser(getItem('user')));
      dispatch(getSupports());
      return;
    }
    try {
      dispatch(setIsInternalLoading(true));
      const supports = await getSupportInfo();
      const sortedSupports = supports.sort((a, b) => {
        return a.id === organizationID ? -1 : b.id === organizationID ? 1 : 0;
      });
      batch(() => {
        dispatch(setSupports(sortedSupports));
        dispatch(setIsInternalLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      if (errorCallback) errorCallback(error);
      batch(() => {
        dispatch(setIsInternalLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };
