import * as React from 'react';
import styled from 'styled-components';
import { translate } from '../../../utils/translate';
import Lock from '../../../assets/imgs/general/lock.svg';
import UnLock from '../../../assets/imgs/general/un-lock.svg';
import { palette } from '../../../theme/colors';

const SContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 0.9rem 0 0.3rem 0;
  p {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 16px;
    color: ${palette.silver};
    margin-left: 0.25rem;
  }
`;

export interface IBlockedStateSeparatorProps {
  isBlocked: boolean;
}

export const BlockedStateSeparator = (props: IBlockedStateSeparatorProps) => {
  const { isBlocked } = props;
  return (
    <SContainer className="block-separator">
      <img src={isBlocked ? Lock : UnLock} alt={isBlocked ? 'blocked' : ' unblocked'} />
      <p>{isBlocked ? translate('messages_blocked') : translate('messages_unblocked')}</p>
    </SContainer>
  );
};
