import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import { translate } from '../../../utils/translate';
import DisclaimerIcon from '../../../assets/imgs/chats/alarm-disclaimer.svg';
import { toRem } from '../../../utils/toRem';

const SContainer = styled.div<{ isSent?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${props => (props.isSent ? 'flex-end' : 'flex-start')};
`;
const SDisclaimer = styled.div<{ isSent?: boolean }>`
  margin-bottom: 0.5rem;
  margin-left: 2rem;
  padding: ${toRem(6)} ${toRem(10)};
  border-radius: ${toRem(12)};
  border-bottom-left-radius: ${props => (props.isSent ? toRem(12) : 0)};
  border-top-left-radius: ${props => (props.isSent ? toRem(12) : 0)};
  border-bottom-right-radius: ${props => (props.isSent ? 0 : toRem(12))};
  border-top-right-radius: ${props => (props.isSent ? 0 : toRem(12))};

  border-right: ${props => props.isSent && `2px solid ${palette.gainsBoro4}`};
  border-left: ${props => !props.isSent && `2px solid ${palette.gainsBoro4}`};
  background-color: ${props => (props.isSent ? palette.charcoal2 : palette.cloudBurst)};
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: 75%;
  .icon {
    height: 0.875rem;
    width: 0.875rem;
    margin-right: 0.5rem;
  }
  .textContainer {
    font-size: ${toRem(14)};
    line-height: ${toRem(18.2)};
    font-weight: 500;
    font-family: 'Roboto-Regular';
    color: ${palette.silver};
    vertical-align: middle;
  }
  .textContainer a {
    font-weight: 700;
    font-family: 'Roboto-Medium';
    color: ${palette.honeyYellow};
    text-decoration: none;
  }
`;

export interface IDisclaimerProps {
  subject: string;
  by: string;
  byLink: string;
  byLinkState?: { [index: string]: string };
  isSameUser?: boolean;
}

export const Disclaimer = (props: IDisclaimerProps) => {
  const { by, subject, byLink, isSameUser, byLinkState } = props;
  return (
    <SContainer isSent={isSameUser}>
      <SDisclaimer role="disclaimer" isSent={isSameUser}>
        <img className="icon" src={DisclaimerIcon} alt="alarm-icon" role="alarmIcon" />
        <p className="textContainer" role="textContainer">
          {subject} {translate('messages_alarm_ended_by')}
          <Link to={byLink} state={byLinkState} role="byLink">
            {' '}
            {isSameUser ? translate('messages_you') : by}
          </Link>
        </p>
      </SDisclaimer>
    </SContainer>
  );
};
