import { useEffect, useState } from 'react';

export interface SelectlistOptions {
  key?: string | undefined;
}

export interface UseSelectlistParams {
  // [TODO] refactor to enforce data on the custom hook level
  data: any[];
  multiStage?: boolean;
  options?: SelectlistOptions;
  initialSelected?: Set<number | string>;
}

interface UseSelectReturn {
  isSelectedAll: boolean;
  selectedItems: Set<string | number>;
  setSelectedItems: (set: Set<number | string>) => void;
  handleSelect: (key?: string | number, callback?: (changed: Set<string | number>) => void) => void;
  handleMultiSelect: (keys: number[], selectType: boolean) => void;
  isStagedSelectedAll: boolean;
  isNonStagedSelected: boolean;
  stagedSelectedItems: Set<string | number>;
  setStagedSelectedItems: (set: Set<number | string>) => void;
  onFinishSelecting: (applied: boolean) => void;
}

export const useSelectlist = (params: UseSelectlistParams): UseSelectReturn => {
  let {
    // [TODO] refactor to accept values without objects
    data,
    options = {
      key: 'id',
    },
  } = params;
  const { multiStage = false, initialSelected } = params;

  const [selectedItems, setSelectedItems]: [
    Set<number | string>,
    (set: Set<number | string>) => void
  ] = useState(initialSelected ? initialSelected : new Set());

  const [stagedSelectedItems, setStagedSelectedItems]: [
    Set<number | string>,
    (set: Set<number | string>) => void
  ] = useState(initialSelected ? initialSelected : new Set());

  useEffect(() => {
    if (initialSelected) {
      setSelectedItems(initialSelected);
      setStagedSelectedItems(initialSelected);
    }
  }, [initialSelected]);

  if (!(data instanceof Array)) {
    data = [];
  }
  if (options === null) {
    options = {};
  }
  if (!options.key) {
    options.key = 'id';
  }

  const handleSelect = (
    key?: string | number,
    callback?: (changed: Set<string | number>) => void
  ) => {
    if (multiStage) {
      setStagedSelectedItems(new Set(selectedItems));
      if (key) {
        const cloned = new Set(stagedSelectedItems);
        if (stagedSelectedItems.has(key)) {
          cloned.delete(key);
        } else {
          cloned.add(key);
        }
        setStagedSelectedItems(new Set(cloned));
        if (callback) callback(new Set(cloned));
        return;
      }
      if (stagedSelectedItems.size === data.length) {
        setStagedSelectedItems(new Set());
        if (callback) callback(new Set());
        return;
      }
      const allDataSet = new Set(data.map(item => item[options.key!]));
      setStagedSelectedItems(allDataSet);
      if (callback) callback(allDataSet);
      return;
    }
    if (key) {
      const cloned = new Set(selectedItems);
      if (selectedItems.has(key)) {
        cloned.delete(key);
      } else {
        cloned.add(key);
      }
      setSelectedItems(new Set(cloned));
      if (callback) callback(new Set(cloned));
      return;
    }
    if (selectedItems.size === data.length) {
      setSelectedItems(new Set());
      if (callback) callback(new Set());
      return;
    }
    const allDataSet = new Set(data.map(item => item[options.key!]));
    setSelectedItems(allDataSet);
    if (callback) callback(allDataSet);
  };

  const handleMultiSelect = (keys: number[], selectType: boolean) => {
    if (multiStage) {
      setStagedSelectedItems(new Set(selectedItems));

      if (selectType) {
        return setStagedSelectedItems(new Set([...Array.from(stagedSelectedItems), ...keys]));
      } else {
        return setStagedSelectedItems(
          new Set([
            ...Array.from(stagedSelectedItems as Set<number>).filter(item => !keys.includes(item)),
          ])
        );
      }
    }

    if (selectType) {
      return setSelectedItems(new Set([...Array.from(stagedSelectedItems), ...keys]));
    } else {
      return setSelectedItems(
        new Set([
          ...Array.from(stagedSelectedItems as Set<number>).filter(item => !keys.includes(item)),
        ])
      );
    }
  };

  return {
    isSelectedAll: data.length === 0 ? false : selectedItems.size === data.length,
    selectedItems,
    setSelectedItems,
    handleSelect,
    handleMultiSelect,

    isStagedSelectedAll: data.length === 0 ? false : stagedSelectedItems.size === data.length,
    isNonStagedSelected: data.length === 0 ? true : stagedSelectedItems.size === 0,
    stagedSelectedItems,
    setStagedSelectedItems,
    onFinishSelecting: (applied: boolean) => {
      if (applied) {
        setSelectedItems(new Set(stagedSelectedItems));
      } else {
        setStagedSelectedItems(new Set(selectedItems));
      }
    },
  };
};
