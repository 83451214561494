import isElectron from 'is-electron';
import { batch } from 'react-redux';
import {
  _changeProfileImage,
  editSecondaryNumber,
  fetchSsoUrl,
  getAccountInfo,
  getLightInfo,
  login as loginAPI,
  ssoLogin,
  ssoLogin as ssoLoginAPI,
  updateDeviceId,
} from '../../../apis/authApi/authAPI';
import { getImage } from '../../../apis/mediaAPI';
import { getUserById } from '../../../apis/userAPI';
import { UserById } from '../../../apis/userAPI/types';
import { getDeviceToken } from '../../../notifications/device.service';
import { AppThunk, RootState } from '../../../store';
import { deleteItem, getItem, saveItem } from '../../../utils/storage';
import { recheckTimeOutKey } from '../../CheckAppAvailable/helpers';
import { logoutUser } from '../../Sidebar/actionCreators';
import { getSsoRedirectUrl } from '../helpers';
import {
  setIsError,
  setIsLoginLoading,
  setIsUserByIdLoading,
  setIsUserVerified,
  setUser,
  setUserById,
  setUserByIdPhotoUrl,
} from './index';
import { LightUserMapped } from './types';

interface LoginProps {
  email: string;
  password: string;
}

export interface SsoLoginProps {
  email: string | null;
  code: string;
  provider?: string;
}

interface ErrorWithCode {
  Code: string;
}

export const refreshUserInfo = () => async (dispatch: any, getState: () => RootState) => {
  const currentUserId = getState().user.user?.id;
  if (currentUserId) {
    const user = await getAccountInfo();
    saveItem('user', user);
    batch(() => {
      dispatch(setUser(user));
    });
    return user;
  }
  return null;
};

export const extractErrorMessageFromResponse = (error: ErrorWithCode | unknown) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorCode = error && error.hasOwnProperty('Code') && (error as ErrorWithCode).Code;
  if (!errorCode) return;

  if (errorCode == 'INCORRECT_PASSWORD') {
    return 'incorrect_password';
  } else if (errorCode == 'TOO_MANY_ATTEMPTS') {
    return 'login_error_many_attempts';
  }

  return 'login_error';
};

export const login =
  (props: LoginProps): AppThunk =>
  async dispatch => {
    const { email, password } = props;
    try {
      dispatch(setIsLoginLoading(true));
      const user = await loginAPI({
        email,
        password,
      });
      saveItem('user', user);
      if (isElectron()) await updateDeviceId(getDeviceToken());
      batch(() => {
        dispatch(setUser(user));
        dispatch(setIsLoginLoading(false));
      });
    } catch (error) {
      const message = extractErrorMessageFromResponse(error);
      batch(() => {
        dispatch(setIsLoginLoading(false));
        dispatch(setIsError(`${message ? message : error}`));
      });
    }
  };

export const updateUser =
  (light = false, disableLoading = false): AppThunk =>
  async (dispatch, getState: () => RootState) => {
    if (!disableLoading) dispatch(setIsLoginLoading(true));
    try {
      const recheckTimeout = getItem(recheckTimeOutKey);
      if (recheckTimeout) {
        clearTimeout(recheckTimeout);
        deleteItem(recheckTimeOutKey);
      }
      const user = !light ? await getAccountInfo() : undefined;
      const lightUser = light ? await getLightInfo() : undefined;
      const mappedUser: LightUserMapped | undefined = lightUser && {
        id: lightUser.userId,
        roles: lightUser.roles,
      };

      saveItem(
        'user',
        user ? user : !!getItem('user') ? { ...getItem('user'), ...mappedUser } : mappedUser
      );
      batch(() => {
        dispatch(setUser(light ? mappedUser! : user!));
        dispatch(setIsLoginLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(getState().user?.user ? setIsError(`${error}`) : setIsError(null));
        dispatch(logoutUser());
        dispatch(setIsLoginLoading(false));
      });
    }
  };

export const SsoLogin =
  (props: SsoLoginProps): AppThunk =>
  async dispatch => {
    const { email, code, provider } = props;
    try {
      dispatch(setIsLoginLoading(true));
      const user = await ssoLoginAPI({
        email,
        code,
        url: getSsoRedirectUrl(email, provider),
        provider,
      });
      saveItem('user', user);
      if (isElectron()) await updateDeviceId(getDeviceToken());
      batch(() => {
        dispatch(setUser(user));
        dispatch(setIsLoginLoading(false));
      });
    } catch (error) {
      console.log('error log ', error);
      batch(() => {
        dispatch(setIsLoginLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const getSsoUrl =
  (email: string | null, provider?: string): AppThunk =>
  async dispatch => {
    try {
      let redirect = getSsoRedirectUrl(email, provider);
      if (email) {
        email = encodeURIComponent(email);
      }
      redirect = encodeURIComponent(redirect);

      let url = 'AuthorizationUrl';

      if (email) {
        url += '?email=' + email;
      } else {
        url += '?provider=' + provider;
      }

      url += '&redirectUrl=' + redirect;

      const res = await fetchSsoUrl(url);
      if (isElectron()) {
        window.open(res.url);
      } else {
        window.location.href = res.url;
      }
    } catch (error) {
      console.error('getSsoUrl.error', error);
      batch(() => {
        dispatch(setIsLoginLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const loginUsingSsoCode =
  (email: string, code: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoginLoading(true));
      const user = await ssoLogin({ email: email, code: code, url: getSsoRedirectUrl(email) });
      saveItem('user', user);
      if (isElectron()) await updateDeviceId(getDeviceToken());
      batch(() => {
        dispatch(setUser(user));
        dispatch(setIsLoginLoading(false));
      });
    } catch (error) {
      console.error('loginUsingSsoCode.error', error);
      batch(() => {
        dispatch(setIsLoginLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const getCurrentUserById = (): AppThunk => async (dispatch, getState) => {
  const currentUserId = getState().user.user?.id;
  if (!currentUserId) {
    dispatch(setUser(getItem('user')));
    dispatch(getCurrentUserById());
    return;
  }
  try {
    dispatch(setIsUserByIdLoading(true));
    const user = await getUserById(currentUserId);
    const imageName = user.photoFileName && (await getImage({ imageName: user.photoFileName }));
    batch(() => {
      dispatch(setUserById(user));
      dispatch(setUserByIdPhotoUrl(imageName));
      dispatch(setIsUserByIdLoading(false));
    });
  } catch (error) {
    console.log('error log ', error);
    batch(() => {
      dispatch(setIsError(`${error}`));
      dispatch(setIsUserByIdLoading(false));
    });
  }
};

export const updateUserFromUserById =
  (userById: UserById): AppThunk =>
  async dispatch => {
    const user = getItem('user');
    const updatedUser = {
      ...user,
      photoFileName: userById.photoFileName,
    };
    saveItem('user', updatedUser);
    dispatch(setUser(updatedUser));
  };

export const editSecondaryPhoneNumber =
  (phoneNumber: string): AppThunk =>
  async (dispatch, getState) => {
    const userById = getState().user.userById;
    if (!userById) {
      dispatch(getCurrentUserById());
      dispatch(editSecondaryPhoneNumber(phoneNumber));
      return;
    }
    const phoneNumbers = userById?.phoneNumber.split(',');
    const primPhoneNumber = phoneNumbers && phoneNumbers[0];
    try {
      const user = await editSecondaryNumber({
        phoneNumbers: `${primPhoneNumber}, ${phoneNumber}`,
        userById: userById,
      });
      batch(() => {
        dispatch(setUserById(user));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const setIsVerifyAction =
  (res: boolean): AppThunk =>
  async dispatch => {
    try {
      dispatch(setIsLoginLoading(true));
      batch(() => {
        dispatch(setIsUserVerified(res));
        dispatch(setIsLoginLoading(false));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsLoginLoading(false));
        dispatch(setIsError(`${error}`));
      });
    }
  };

export const changeProfileImage =
  (photoUrl: string | null): AppThunk =>
  async (dispatch, getState) => {
    const userById = getState().user.userById;
    if (!userById) {
      dispatch(getCurrentUserById());
      dispatch(changeProfileImage(photoUrl));
      return;
    }
    try {
      const user = await _changeProfileImage({
        photoUrl: photoUrl,
        userById: userById,
      });
      batch(() => {
        dispatch(setUserById(user));
        dispatch(updateUserFromUserById(user));
      });
    } catch (error) {
      batch(() => {
        dispatch(setIsError(`${error}`));
      });
    }
  };
