import { EmptyListFallback } from '../EmptyListFallback/EmptyListFallback';
import ChecklistItem from './ChecklistItem';
import emptyList from '../../assets/imgs/NotFound/no-result.svg';
import { TabProps } from '../../containers/Checklists/Checklists.models';

function Ended(props: TabProps) {
  const { checklists, onDotsClick, searchText } = props;
  return (
    <div role="endedChecklistsTab">
      {checklists.map(item => (
        <ChecklistItem
          checklist={item}
          key={`Ended-${item.id}`}
          onDotsClick={onDotsClick}
          searchText={searchText}
        />
      ))}
      {checklists.length === 0 && (
        <EmptyListFallback
          src={emptyList}
          listLength={checklists.length}
          isLoading={false}
          searchTerm={searchText}
          emptyListTx={'empty_checklists'}
          noSearchTx={'messages_not_found_search'}
        />
      )}
    </div>
  );
}

export default Ended;
