import styled from 'styled-components';

const SNext = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 3rem;
  top: calc(50% - 20px);
  cursor: pointer;
  .prevArrow {
    transform: rotate(-90deg);
  }
  .nextArrow {
    transform: rotate(90deg);
  }
  height: 40px;
`;

export const SImgContainer = styled.div<{ $maxHeight?: string | undefined | null }>`
  max-height: ${props => props.$maxHeight ?? `calc(100vh - 120px)`} !important;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const SImg = styled.img<{ $scaleY?: number }>`
  object-fit: contain;
  width: 100%;
  pointer-events: none;
  transform: ${props => (props.$scaleY ? `scaleY(${props.$scaleY})` : undefined)};
`;

export const SRight = styled(SNext)`
  right: 0;
`;

export const SLeft = styled(SNext)`
  left: 0;
`;
