import { translate } from '../../utils/translate';
import { Chip, InnerCircle, OuterCircle } from './AvailabilityChip.style';

interface IAvailabilityChipProps {
  isAvailable?: boolean;
  size?: 'small' | 'large';
}

const AvailabilityChip = (props: IAvailabilityChipProps) => {
  const { isAvailable = true, size = 'small' } = props;
  return (
    <Chip className="availabilityChip" role="availability">
      <OuterCircle $isLarge={size === 'large'} $isAvailable={isAvailable}>
        {isAvailable && <InnerCircle $isLarge={size === 'large'} />}
      </OuterCircle>
      <p>{translate(isAvailable ? 'onCallAlert_available' : 'profile_not_available')}</p>
    </Chip>
  );
};

export default AvailabilityChip;
