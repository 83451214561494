import styled from 'styled-components';
import { palette } from '../../theme/colors';

interface ImageProps {
  diameter?: number;
  zIndex?: number;
}

export const SImg = styled.img<ImageProps>`
  border-radius: 9.5rem;
  height: ${props => `${props.diameter}px`};
  width: ${props => `${props.diameter}px`};
  z-index: ${props => props.zIndex ?? 1};
  object-fit: cover;
`;

export const PlaceHolderWrapper = styled.img<ImageProps>`
  width: ${props => `${props.diameter}px`};
  height: ${props => `${props.diameter}px`};
  z-index: ${props => props.zIndex ?? 1};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.nightSkyBlue};
  border-radius: 999rem;
  padding: 0.5rem;
`;
