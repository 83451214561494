import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavDots } from '../../components/NavDots/NavDots';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { translate } from '../../utils/translate';
import { SButton, SGapFiller, SPage, SSkip, SVerticalContainer } from './LoginIntro.styles';
import { introTutorial } from './helpers';

export const LoginIntro = () => {
  const { index } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { setBackLink } = useLayoutContext();
  useEffect(() => {
    if (!!index) {
      if (isNaN(+index)) {
        navigate(`/intro`);
        return;
      }
      if (+index >= introTutorial.length) {
        navigate(`/intro/${introTutorial.length - 1}`);
        return;
      }
      if (+index < 1) {
        navigate(`/intro`);
        return;
      }
      setBackLink(introTutorial[+index].back);
    } else {
      setBackLink(undefined);
    }
  }, [pathname]);

  const tutorialIndex = index ? +index : 0;

  const handleNext = () => {
    navigate(introTutorial[tutorialIndex].next);
  };
  return (
    <SPage>
      <SGapFiller />
      <SVerticalContainer>
        <img
          className="intro-pic"
          src={introTutorial[tutorialIndex]?.pic}
          alt={translate(introTutorial[tutorialIndex]?.title)}
        />
        <NavDots count={introTutorial.length} currentIndex={tutorialIndex} />
      </SVerticalContainer>
      <SVerticalContainer $maxWidth $fixedSize className="middle">
        <h2>{translate(introTutorial[tutorialIndex]?.title)}</h2>
        <p className="top-margin">{translate(introTutorial[tutorialIndex]?.disc)}</p>
      </SVerticalContainer>
      <SVerticalContainer>
        <SButton tx="next" onClick={handleNext} />
        <SSkip className="top-margin" to={'/login'}>
          {translate('login_intro_skip')}
        </SSkip>
      </SVerticalContainer>
      <SGapFiller />
    </SPage>
  );
};
