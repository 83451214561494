import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SNameChecklistModalContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  max-width: 100%;
  height: 362px;
  background-color: ${palette.prussianBlue2};
  z-index: 999;
  width: 26rem;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SHeader = styled.svg`
  width: 135px;
  height: 5px;
  place-self: center;
  border-radius: 5px;
`;

export const STitle = styled.p`
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 16px;
  margin: 1.25rem auto 1.25rem 0;
`;

export const STextArea = styled.textarea`
  width: 100%;
  flex: 1;
  resize: none;
  border-radius: 12px;
  border: 1px solid ${palette.queenBlue};
  background-color: ${({ theme }) => theme.palette.background.searchBar};
  font-family: 'Roboto-Regular';
  font-size: 1.125rem;
  padding: 1rem;
  color: ${palette.white};

  :focus-visible {
    outline: none;
  }
`;

export const SButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1.25rem;

  button {
    max-width: 10rem;
    width: 100%;
  }
`;
