import styled from 'styled-components';
import { translate } from '../../utils/translate';

export const NoMessagesWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  min-width: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    font-family: 'Roboto-Medium';
    font-size: 1rem;
    margin-top: 1rem;
  }
`;

export interface IEmptyListFallbackProps {
  src: string;
  listLength: number;
  isLoading?: boolean;
  searchTerm?: string | undefined;
  emptyListTx: string;
  noSearchTx?: string;
  className?: string | undefined;
  ignoreSearchTermInFallBackText?: boolean;
}

export const EmptyListFallback = (props: IEmptyListFallbackProps) => {
  const {
    emptyListTx,
    isLoading = false,
    listLength,
    noSearchTx,
    searchTerm,
    src,
    className,
    ignoreSearchTermInFallBackText = false,
  } = props;

  const renderSearchTerm = () => <>{searchTerm && ' ' + searchTerm}</>;
  return (
    <>
      {listLength === 0 && !isLoading && (
        <NoMessagesWrapper className={className} role="emptyFallback">
          <img role="image" src={src} alt="" />
          <p role="emptyText">
            {translate(searchTerm && noSearchTx ? noSearchTx : emptyListTx)}
            {!ignoreSearchTermInFallBackText && renderSearchTerm()}
          </p>
        </NoMessagesWrapper>
      )}
    </>
  );
};
