import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SUserImgContainer = styled.div`
  margin: 2rem auto ${toRem(20)};
  display: block;
  width: 7rem;
  height: 7rem;
  aspect-ratio: 1;

  border-radius: 9999px;

  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

export const SUserImg = styled.img`
  border-radius: 9999px;
  width: 7rem;
  height: 7rem;
  aspect-ratio: 1;
  box-shadow: ${props => props.theme.shadow.primary};
  object-fit: cover;
  background-color: ${palette.black};
`;

export const SEditImage = styled.img`
  position: absolute;
  bottom: 1px;
  right: 1px;
`;

export const STextFieldEndPlaceHolder = styled.div<{ $content?: string }>`
  min-height: 3.125rem;
  position: relative;
  display: flex;
  align-items: center;
  &::after {
    content: '${props => props.$content}';
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 15px;
    transform: translateY(-50%);
    color: ${palette.darkElecticBlue};
  }
  label {
    z-index: 10;
    left: 1rem;
    color: #626e85;
    font-size: 15px;
  }
`;

export const STextContainer = styled.div`
  display: flex;
  flex-direction: column;

  .wrapper + * {
    margin-top: 0.625rem;
  }
`;

export const SPropertyDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${palette.white};
  font-family: 'Roboto-Regular';
  font-size: 0.9rem;
  min-height: 3.125rem;
  width: 100%;
  padding: 0.5rem 1rem;
  background: ${palette.fadedDarkBlue};
  background-color: ${palette.fadedDarkBlue};
  border: 1px solid ${palette.siamiBlue};
  border-radius: 0.93rem;
  text-decoration: none;

  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;

  label {
    z-index: 10;
    left: 1rem;
    color: #626e85;
    font-size: 15px;
  }
  .valueText {
    padding-left: 1rem;
  }
`;

export const SLink = styled(Link)<{
  $textFieldLink?: boolean;
  $nonPointer?: boolean;
  $placeHolder?: boolean;
}>`
  position: relative;

  color: ${palette.white};

  font-family: 'Roboto-Regular';
  font-size: 0.9rem;

  display: flex;
  align-items: center;
  justify-content: start;

  height: 3.125rem;
  width: 100%;
  padding: 0 1rem;
  background: ${palette.fadedDarkBlue};
  background-color: ${palette.fadedDarkBlue};
  border: 1px solid ${palette.siamiBlue};
  border-radius: 0.93rem;
  text-decoration: none;

  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;

  img {
    margin-right: 1rem;
  }

  label {
    z-index: 10;
    left: 1rem;
    color: #626e85;
    font-size: 15px;
  }

  .phone-number {
    padding-left: 1rem;
  }

  .to-edit {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  ${props =>
    props.$textFieldLink &&
    css`
      border-radius: 0 0.93rem 0.93rem 0;
    `}
  ${props =>
    props.$nonPointer &&
    css`
      &:hover {
        cursor: auto;
      }
    `}
`;

export const SLinkContainer = styled.div<{ linkMode?: boolean }>`
  width: 100%;
  height: 3.125rem;
  display: flex;
  ${props =>
    props.linkMode &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const SBottomText = styled.p`
  margin-top: 3.063rem;
  text-align: center;
  height: 1rem;
  color: ${palette.silver};
`;

export const SUserProfileAvailabilityContainer = styled.div`
  margin-bottom: ${toRem(20)};
`;
