/* eslint-disable import/named */

import React, {
  AnimationEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import InfoCircle from '../../assets/imgs/documents/Info-Circle.svg';
import DismissIcon from '../../assets/imgs/documents/dismiss-x.svg';
import { Keyframes, keyframes } from 'styled-components';
import { SContainer, SPlaceHolder, SSvgButton } from './AlertBanner.styles';

type AlertBannerPreset = 'fixed' | 'timedOut';

interface ALertSettings {
  clickable: boolean;
  showDismissButton: boolean;
  needTimeOutAnimation: boolean;
  showPlaceHolder: boolean;
  needSafetyTerminationOnUnmount: boolean;
}

const alertSettings: Record<AlertBannerPreset, ALertSettings> = {
  timedOut: {
    clickable: false,
    showDismissButton: true,
    needTimeOutAnimation: true,
    showPlaceHolder: true,
    needSafetyTerminationOnUnmount: true,
  },
  fixed: {
    clickable: true,
    showDismissButton: false,
    needTimeOutAnimation: false,
    showPlaceHolder: true,
    needSafetyTerminationOnUnmount: false,
  },
};

export interface AlertBannerProps {
  alertTopToValue?: number;
  placeHolderHeight?: number;

  className?: string | undefined;
  preset?: AlertBannerPreset;
  onClick?: () => void;
}

const DEFAULT_TO_VALUE = 60;
const DEFAULT_REPLACE_TO_VALUE = 40;
const DEFAULT_PLACEHOLDER_HEIGHT = 44;
const DEFAULT_TOP = 0;

export interface AlertBannerRef {
  alert: (title: string) => void;
  close: (withAnimation?: boolean) => void;
  isOpen: boolean;
  title: string | undefined;
}

export const AlertBanner = forwardRef<AlertBannerRef, AlertBannerProps>((props, ref) => {
  const { alertTopToValue, placeHolderHeight, className, preset = 'timedOut', onClick } = props;

  const settings = alertSettings[preset];

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string | undefined>();

  const placeHolderRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // customize the initial top value
  const isAlertTopValueExist = alertTopToValue !== undefined && alertTopToValue !== null;
  const needToRestTopValue = isAlertTopValueExist ?? (alertTopToValue || 0) <= 0;
  const restedTopValue = -10;

  const top = needToRestTopValue ? restedTopValue : DEFAULT_TOP;

  const animatePlaceHolderHeight = (start = true, callback?: () => void) => {
    if (start) {
      setTimeout(() => {
        placeHolderRef.current?.classList.add('placeholderExpanded');
      }, 1);
    } else {
      placeHolderRef.current?.classList.remove('placeholderExpanded');
    }
    setTimeout(() => {
      callback?.();
    }, 330);
  };

  const close = () => {
    animatePlaceHolderHeight(false, () => {
      setTitle(undefined);
      setIsOpen(false);
      containerRef.current?.classList.remove('containerExpanded');
    });
  };

  // to animation value calculations
  const toValue =
    alertTopToValue !== undefined && alertTopToValue !== null ? alertTopToValue : DEFAULT_TO_VALUE;
  const replaceToValue = isAlertTopValueExist
    ? needToRestTopValue
      ? -10
      : alertTopToValue! - 17
    : DEFAULT_REPLACE_TO_VALUE;
  const placeholderToValue = placeHolderHeight ?? DEFAULT_PLACEHOLDER_HEIGHT;
  // check this with the fixed
  useEffect(() => {
    return () => {
      if (settings.needSafetyTerminationOnUnmount) close();
    };
  }, []);

  const stopTimeOutDismiss = () => {
    containerRef.current?.classList.remove('containerExpandedTimeout');
  };

  const initialAnimate = (start = true, callback?: () => void) => {
    if (start) {
      setTimeout(() => {
        containerRef.current?.classList.add('containerExpanded');
      }, 1);
    } else {
      stopTimeOutDismiss();
      containerRef.current?.classList.add('containerExpandedReversed');
    }
    setTimeout(() => {
      callback?.();
    }, 310);
  };

  const replaceAnimate = (callback?: () => void) => {
    containerRef.current?.classList.add('containerBounce');
    setTimeout(() => {
      containerRef.current?.classList.remove('containerBounce');
      callback?.();
    }, 250);
  };

  const timeOutDismiss = () => {
    containerRef.current?.classList.add('containerExpandedTimeout');
  };

  const open = (_title: string) => {
    if (title) {
      // stop any delayed timeout
      stopTimeOutDismiss();
      replaceAnimate(settings.needTimeOutAnimation ? timeOutDismiss : undefined);
      setTitle(_title);
      return;
    }
    setTitle(_title);
    setIsOpen(true);
    if (settings.showPlaceHolder) animatePlaceHolderHeight(true);
    initialAnimate(true, settings.needTimeOutAnimation ? timeOutDismiss : undefined);
  };

  const onDismiss = () => {
    initialAnimate(false, () => {
      close();
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      alert: open,
      close: onDismiss,
      isOpen,
      title,
    }),
    [title]
  );

  const timeoutSlideKeyFrame = (params: Pick<SContainerParams, '$top'>) => {
    const { $top } = params;
    const tmpKeyframes = keyframes`
      100% {
        opacity: 0;
        top: ${$top}px;
       }
  `;
    return { timeoutSlideName: tmpKeyframes.getName(), timeoutSlide: tmpKeyframes };
  };

  const { timeoutSlideName, timeoutSlide } = timeoutSlideKeyFrame({ $top: top });

  const onAnimationEnd = (e: AnimationEvent<HTMLDivElement>) => {
    if (e.animationName === timeoutSlideName) {
      close();
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <SContainer
        onAnimationEnd={onAnimationEnd}
        ref={containerRef}
        $top={top}
        $topToValue={toValue}
        $replaceToValue={replaceToValue}
        $timeoutSlide={timeoutSlide}
        $clickable={settings.clickable}
        onClick={onClick}
        role="alarmBanner"
        className={className}
      >
        <div className="titleContainer" role="titleContainer">
          <img src={InfoCircle} alt="info circle" role="icon" />
          <p className="title" role="title">
            {title}
          </p>
        </div>
        {settings.showDismissButton && (
          <SSvgButton onClick={onDismiss} role="dismissButton">
            <img src={DismissIcon} alt="Dismiss button" role="icon" />
          </SSvgButton>
        )}
      </SContainer>
      {settings.showPlaceHolder && (
        <SPlaceHolder
          className="SPlaceHolder"
          placeholderToValue={placeholderToValue}
          ref={placeHolderRef}
          role="placeholder"
        />
      )}
    </>
  );
});

export interface SContainerParams {
  $top: number;
  $topToValue: number;
  $replaceToValue: number;
  $timeoutSlide?: Keyframes;
  $clickable?: boolean;
}
