import React, { useEffect } from 'react';
import Active from '../../components/Checklists/ActiveTab';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { SOverview, SPage, SContainer, SLine, STitle, SList } from './Overview.styles';
import { ChecklistStatus } from '../../utils/enums';
import { translate } from '../../utils/translate';
import { ChatsList } from '../ChatsList/ChatsList';
import { getChecklists, isChecklistsLoading } from '../Checklists/checklistsSlice';
import { fetchChecklists, fetchMoreChecklists } from '../Checklists/checklistsSlice/actionCreators';

function Overview() {
  const checklists = useAppSelector(getChecklists);
  const isLoading = useAppSelector(isChecklistsLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (checklists.length === 0) {
      dispatch(fetchChecklists());
    }

    const interval = setInterval(() => {
      dispatch(fetchMoreChecklists());
    }, 10000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const getStartedChecklists = () => {
    return checklists.filter(item => item.status === ChecklistStatus.Started);
  };

  return (
    <SPage>
      <SOverview role="overviewContainer">
        <SContainer role="messagesContainer">
          <STitle role="title">{translate(`home_messages`)}</STitle>
          <SList role="messagesListContainer">
            <ChatsList overview />
          </SList>
        </SContainer>
        <SLine />
        <SContainer role="checklistsContainer">
          <STitle role="title">{translate(`home_checklists`)}</STitle>
          <SList role="checklistsList">
            {isLoading ? (
              <Loader />
            ) : (
              <Active checklists={getStartedChecklists()} overview onDotsClick={() => {}} />
            )}
          </SList>
        </SContainer>
      </SOverview>
    </SPage>
  );
}

export default Overview;
