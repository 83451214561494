import ArrowRightIcon from '../../assets/imgs/meetingRoom/arrow-right-light-thin copy.svg';
import LinkIcon from '../../assets/imgs/meetingRoom/chain--yellow.svg';
import { MeetingRoom } from '../../containers/MeetingRoom/meetingRoomSlice/types';
import {
  MeetingRoomItemContainer,
  SDataContainer,
  SDescription,
  SLink,
  SLinkContainer,
  STitle,
} from './MeetingRoomItem.styles';
import { formatUrlAsDmainName } from './helpers';
export interface MeetingRoomItemProps {
  meetingRoom: MeetingRoom;
  onMeeatingRoomClick: (meetingRoomLink: string) => void;
}

export const MeetingRoomItem = (props: MeetingRoomItemProps) => {
  const { meetingRoom, onMeeatingRoomClick } = props;
  const { name, description, url } = meetingRoom;

  return (
    <MeetingRoomItemContainer onClick={() => onMeeatingRoomClick(meetingRoom.url)}>
      <SDataContainer>
        <STitle>{name}</STitle>
        {description && <SDescription>{description}</SDescription>}

        <SLinkContainer>
          <img src={LinkIcon} alt="link icon" />
          <SLink>{formatUrlAsDmainName(url) || url}</SLink>
        </SLinkContainer>
      </SDataContainer>

      <img src={ArrowRightIcon} alt="right arrow" />
    </MeetingRoomItemContainer>
  );
};
