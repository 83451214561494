import { useEffect, useMemo, useRef, useState } from 'react';
import {
  SDrawerWrapper,
  SLoaderContainer,
  SReactionsEmojisRow,
  STabHeader,
  SUsersListContainer,
} from './MessageReactionsBottomSheet.styles';
import Drawer from 'react-bottom-drawer';
import {
  selectedIsMessageReactionsLoading,
  selectedReactionsMessageId,
  selectedMessageReactions,
  selectReactionsListByMessageOrReply,
} from '../../containers/ChatsList/chatListSlice';
import { useAppSelector } from '../../hooks';
import { useDispatch } from 'react-redux';
import {
  closeMessageReactionsBottomSheet,
  fetchMessageReactions,
} from '../../containers/ChatsList/chatListSlice/actionCreators';
import {
  MessageReactionsItem,
  MessageReactionsListItem,
} from '../../containers/ChatsList/chatListSlice/types';
import { selectUser } from '../../containers/Login/LoginSlice';
import Loader from '../Loader/Loader';
import { isInViewport } from '../../utils/helperFunctions';
import { MessageReactionsBottomSheetItem } from './MessageReactionsBottomSheetItem';

export const MessageReactionsBottomSheet = () => {
  const dispatch = useDispatch();
  const reactionsMessageId = useAppSelector(selectedReactionsMessageId);
  const isLoading = useAppSelector(selectedIsMessageReactionsLoading);
  const reactions = useAppSelector(
    selectReactionsListByMessageOrReply(reactionsMessageId as number)
  ) as MessageReactionsListItem[];
  const authUser = useAppSelector(selectUser);
  const selectedReactions = useAppSelector(selectedMessageReactions);
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState<string | undefined>();
  const [lastReachedItemIndex, setLastReachedItemIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedTab(reactionsMessageId ? reactions[0]?.reactionTypeId : undefined);
  }, [reactionsMessageId]);

  const getSortedMessageReactionsItems = (items: MessageReactionsItem[]) => {
    const filteredItems = items.filter(item => item.reactionTypeId === selectedTab);
    const index = filteredItems.findIndex(item => item.userId === authUser?.id);
    if (index > -1) {
      const [authUserItem] = filteredItems.splice(index, 1);
      filteredItems.unshift(authUserItem);
    }
    return filteredItems as MessageReactionsItem[];
  };

  const displayedMessageReactions = useMemo(() => {
    const items = selectedReactions?.items ?? [];
    return getSortedMessageReactionsItems(items);
  }, [selectedReactions, selectedTab]);

  const onTabChange = async () => {
    if (!scrollViewRef?.current) return;
    scrollViewRef.current.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    dispatch(fetchMessageReactions(reactionsMessageId, selectedTab));
  };

  useEffect(() => {
    onTabChange();
  }, [selectedTab]);

  const isTabSelected = (reactionTypeId: string) => selectedTab === reactionTypeId;

  const handleMouseWheel = () => {
    const lastItemIndex = displayedMessageReactions.length;
    const haveReachedEnd = isInViewport(document.getElementById(`user-${lastItemIndex}`));
    if (haveReachedEnd && !isLoading && lastReachedItemIndex !== lastItemIndex) {
      setLastReachedItemIndex(lastItemIndex);
      dispatch(fetchMessageReactions(reactionsMessageId, selectedTab));
    }
  };

  if (!reactionsMessageId) return <></>;

  return (
    <>
      <SDrawerWrapper role="reactionsDrawerContainer">
        <Drawer
          className="reactionsDrawer"
          isVisible={!!reactionsMessageId}
          onClose={() => dispatch(closeMessageReactionsBottomSheet())}
          hideScrollbars
        >
          <div className="content">
            <SReactionsEmojisRow>
              {reactions.map(item => (
                <STabHeader
                  isSelected={isTabSelected(item.reactionTypeId)}
                  itemsCount={reactions.length}
                  key={item.reactionTypeUnicode}
                  onClick={() => setSelectedTab(item.reactionTypeId)}
                >
                  <p className="emoji">{item.reactionTypeUnicode}</p>
                  <p className="count">{item.count}</p>
                </STabHeader>
              ))}
            </SReactionsEmojisRow>
            <SUsersListContainer ref={scrollViewRef} onScroll={handleMouseWheel}>
              {displayedMessageReactions.map((item, index) => (
                <MessageReactionsBottomSheetItem
                  key={`reaction-${index}`}
                  reaction={item}
                  isLastItem={index === displayedMessageReactions.length - 1}
                  id={`reaction-${index + 1}`}
                />
              ))}
            </SUsersListContainer>
            {isLoading && (
              <SLoaderContainer>
                <Loader size={40} />
              </SLoaderContainer>
            )}
          </div>
        </Drawer>
      </SDrawerWrapper>
    </>
  );
};
