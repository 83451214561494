import * as React from 'react';
import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';

import { SBoxCheckBox } from './SBoxCheckBox';
import { SCircleCheckBox } from './SRoundCheckBox';

import { ReactComponent as GroupsPlaceHolder } from '../../assets/imgs/iamokay/img-placeholder.svg';
import { SFilledBoxCheckBox } from './SFilledBoxCheckBox';

interface SItemContainerProps {
  $disabled?: boolean;
  selected: boolean;
  $squareBox?: boolean;
  $withPhoto?: boolean;
  $withoutSeparator?: boolean;
  $clickAll?: boolean;
  $separatorColor?: string;
  $startWithCheckBox?: boolean;
}

const SItemContainer = styled.li<SItemContainerProps>`
  display: flex;
  place-content: space-between;
  align-items: center;
  width: 100%;

  ${props =>
    props.$startWithCheckBox &&
    css`
      place-content: start;
      .input::after {
        margin-right: 1rem;
      }
    `}

  ${props =>
    props.$clickAll &&
    css`
      cursor: pointer;
    `}
  ${props =>
    !props.$withoutSeparator &&
    css`
      border-bottom: 1px solid ${props.$separatorColor || palette.queenBlue};
      padding: 0.75rem 0rem;
    `}
  ${props =>
    props.$squareBox &&
    css`
      padding: 1rem 0rem;
    `}

  ${props =>
    props.$squareBox &&
    !props.$withPhoto &&
    css`
      .STitle {
        margin-left: 0.815rem;
      }
    `}

  ${props =>
    props.$disabled &&
    css`
      p {
        color: ${palette.darkGrey};
      }
      .checkBorder {
        border: 1px solid ${palette.darkGrey};
      }
    `}

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SImg = styled.img`
  width: 2.875rem;
  height: 2.875rem;
  aspect-ratio: 1;

  border-radius: 9999px;
`;
const SImgContainer = styled.div`
  width: 2.875rem;
  height: 2.875rem;
  aspect-ratio: 1;

  border-radius: 9999px;
  box-shadow: ${props => props.theme.shadow.primary};
  background-color: ${palette.nightSkyBlue};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const SLeftTextContainer = styled.div<{ $withPhoto?: boolean; $startWithCheckBox?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-width: 75%;
  ${props =>
    props.$withPhoto &&
    css`
      flex-grow: 1;
    `}
  ${props =>
    props.$startWithCheckBox &&
    css`
      margin-left: 1rem;
    `}
`;

const STitle = styled.p`
  color: ${palette.white};
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.18rem;
`;

const SSubTitle = styled.p`
  font-family: 'Roboto-Regular';
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${palette.silver};
`;

export interface ICheckBoxWithSubTitleProps {
  selected: boolean;
  title?: string;
  valueId: number | string;
  onToggleCheck: (valueId: number | string) => void;
  subTitle?: string;
  checkBoxType?: 'box' | 'circle' | 'filledBox';
  disabled?: boolean;
  className?: string | undefined;

  withPhoto?: boolean;
  photoUrl?: string;
  PlaceHolder?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;

  withoutSeparator?: boolean;
  separatorColor?: string;
  clickAll?: boolean;
  startWithCheckBox?: boolean;
  renderAdditionalInfo?: () => React.ReactNode;
}

export const CheckBoxWithSubTitle = (props: ICheckBoxWithSubTitleProps) => {
  const {
    onToggleCheck,
    selected,
    title,
    subTitle,
    valueId,
    checkBoxType = 'circle',
    disabled = false,
    className,
    withPhoto = false,
    withoutSeparator = false,
    clickAll = false,
    photoUrl,
    separatorColor,
    PlaceHolder,
    startWithCheckBox = false,
    renderAdditionalInfo,
  } = props;

  const handleClick = () => {
    onToggleCheck(valueId);
  };

  const renderCheckBox = () => {
    if (checkBoxType === 'box') {
      return (
        <SBoxCheckBox
          value={valueId}
          onChange={clickAll ? undefined : handleClick}
          readOnly={clickAll}
          checked={selected}
          id={valueId.toString()}
          disabled={disabled}
          role="checkbox"
        />
      );
    }
    if (checkBoxType === 'filledBox') {
      return (
        <SFilledBoxCheckBox
          value={valueId}
          onChange={clickAll ? undefined : handleClick}
          readOnly={clickAll ? true : false}
          checked={selected}
          borderActiveColor={palette.silver}
          id={valueId.toString()}
          disabled={disabled}
          role="checkbox-filled"
        />
      );
    }

    return (
      <SCircleCheckBox
        value={valueId}
        onChange={clickAll ? undefined : handleClick}
        readOnly={clickAll ? true : false}
        checked={selected}
        id={valueId.toString()}
        disabled={disabled}
        role="circleCheckbox"
      />
    );
  };
  return (
    <SItemContainer
      selected={selected}
      $disabled={disabled}
      $squareBox={checkBoxType === 'box'}
      $withPhoto={withPhoto}
      $withoutSeparator={withoutSeparator}
      $clickAll={clickAll}
      $separatorColor={separatorColor}
      $startWithCheckBox={startWithCheckBox}
      onClick={clickAll ? handleClick : undefined}
      className={className}
      role={`checkboxContainer-${title}`}
    >
      {withPhoto && (
        <>
          {photoUrl ? (
            <SImg role="imageContainer" className="SImg" src={photoUrl} alt={'photo'} />
          ) : PlaceHolder ? (
            <div className="externalPlaceHolderWrapper">
              <PlaceHolder role="placeholder" />
            </div>
          ) : (
            <SImgContainer role="imageContainer" className="SImgPlaceHolderContainer">
              <GroupsPlaceHolder role="placeholder" />
            </SImgContainer>
          )}
        </>
      )}
      {startWithCheckBox && renderCheckBox()}
      {(title || subTitle) && (
        <SLeftTextContainer
          role="leftTextContainer"
          className="SLeftTextContainer"
          $withPhoto={withPhoto}
          $startWithCheckBox={startWithCheckBox}
        >
          <STitle role="title" className="STitle">
            {title}
          </STitle>
          {subTitle && (
            <SSubTitle role="subtitle" className="SSubTitle">
              {subTitle}
            </SSubTitle>
          )}
          {renderAdditionalInfo && renderAdditionalInfo()}
        </SLeftTextContainer>
      )}
      {!startWithCheckBox && renderCheckBox()}
    </SItemContainer>
  );
};
