import { isNull } from 'lodash';
import { MemberFilterTypes } from '.';
import { GroupType } from '../../utils/enums';
import { GroupMember } from './groupDetailSlice/types';

export const sortGroupMembers = (
  members: GroupMember[] | null,
  sorting: MemberFilterTypes,
  searchTerm?: string
): GroupMember[] => {
  let orderedGroupMembers: GroupMember[] = [];
  if (members) {
    if (sorting === MemberFilterTypes.Name)
      orderedGroupMembers = members
        .slice()
        .sort((a: GroupMember, b: GroupMember) => a.userName.localeCompare(b.userName));
    if (sorting === MemberFilterTypes.Title)
      orderedGroupMembers = [
        ...members
          .slice()
          .filter((m: GroupMember) => !isNull(m.info) && m.info.length > 0)
          .sort((a: GroupMember, b: GroupMember) => a.info.localeCompare(b.info)),
        ...members.slice().filter((m: GroupMember) => !isNull(m.info) && m.info.length === 0),
      ];
  }
  return searchTerm
    ? orderedGroupMembers.filter((m: GroupMember) =>
        m.userName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : orderedGroupMembers;
};

export const checkGroupsType = (groupType: GroupType) => {
  if (groupType === GroupType.Normal) {
    return 'groups_filter_normal';
  } else if (groupType === GroupType.Hidden) {
    return 'groups_filter_hidden';
  } else if (groupType === GroupType.CrossOrg) {
    return 'groups_cossOrg';
  } else if (groupType === GroupType.CoAlert) {
    return 'groups_coAlert';
  }
  return '';
};
