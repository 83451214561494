import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { ReactComponent as ArrowRight } from '../../assets/imgs/checklists/arrow-right.svg';

export const SArrowRight = styled(ArrowRight)`
  filter: invert(1);
`;

export const SChecklistItem = styled.div`
  min-height: 4rem;
  color: ${palette.white};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  .timeContainer {
    margin-right: 0.2rem;
    align-items: center;
    align-self: flex-start;
    p {
      font-family: 'Roboto-Regular';
      font-weight: 400;
      font-size: 12px;
    }
  }

  .dotsContainer {
    cursor: pointer;
    display: flex;
    align-self: flex-start;
  }
`;

export const SRow = styled.div`
  display: flex;
  max-width: 69%;
  min-height: 2.375rem;
  align-items: center;

  .right {
    margin-bottom: auto;
  }
`;

export const SNameContainer = styled.div`
  max-width: 75%;
  overflow-wrap: break-word;
  margin-left: 1.5rem;

  .checklist {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 14px;
  }

  .groups {
    margin-top: 4px;
    display: flex;
    align-items: center;
  }

  .group {
    padding-left: 4px;
    font-family: 'Roboto-Regular';
    color: ${palette.silver};
    font-weight: 400;
    font-size: 12px;
  }
`;

export const SLine = styled.hr`
  margin: 0;
  width: 100%;
  border: 1px solid ${palette.tinyBorder};
`;
