import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import 'moment/locale/nl';
import 'moment/locale/pt-br';
import 'moment/locale/es-us';
import i18n, { ELanguages } from '../i18n';

export const adjustToTimezoneOffset = (jsDate: Date) => {
  const date = new Date(jsDate);
  const offset = date.getTimezoneOffset();
  const newDateObj = new Date(date.getTime() - offset * 60000);
  return newDateObj;
};

export const dateFormats = {
  monthNamePlusDate: 'MMMM D',
  monthNamePlusYear: 'MMMM YYYY',
  dayLongMonth: 'DD MMMM',
  monthNameDateTime: 'DD MMMM HH:mm',
  monthNameShortDateTime: 'DD MMM, HH:mm',
  monthNameShortDateTimeNoComma: 'DD MMM hh:mm',
  monthNameShortDateTimeNoComma24: 'DD MMM HH:mm',
  generalListingDateTimeFormat: 'D MMM HH:mm',
  simpleTime: 'HH:mm',
  simpleTimeSeconds: 'HH:mm:ss',
  simpleTime24: 'HH:mm',
  monthNameShort: 'DD MMM',
  dayMonthYear: 'DD.MM.YYYY',
  dayMonthNameYearSpace: 'DD MMMM YYYY',
  dayMonthNameShortYearSpace: 'DD MMM YYYY',
  yearMonthDay: 'YYYY-MM-DD',
  yearMonthDayTimeNoComma24: 'YYYY-MM-DD HH:mm',
  fullTime: 'mm:ss',
  messageSeenFormat: 'D MMM, H:MM',
};

export const dateLanguages: Record<string, string> = {
  se: 'sv',
  en: 'en-gb',
  pt: 'pt-br',
  es: 'es-us',
  'en-TT': 'en-gb',
  nl: 'nl',
};

export const datesFormatByLanguage: Record<ELanguages, string> = {
  se: `YYYY-MM-DD`,
  en: `DD/MM/YYYY`,
  pt: `DD/MM/YYYY`,
  es: `DD/MM/YYYY`,
  'en-TT': `DD/MM/YYYY`,
  nl: `DD-MM-YYYY`,
};

export const getDateFormatCustom = (
  jsDate?: string | null,
  dateFormat: string = dateFormats.simpleTime24
) => {
  if (!jsDate) return '';
  const newDateObj = adjustToTimezoneOffset(new Date(jsDate));
  return moment(newDateObj).locale(dateLanguages[`${i18n.language}`]).format(dateFormat);
};

export const getTimeFromDate = (jsDate?: string | null): string => {
  if (!jsDate) return '';
  const newDateObj = adjustToTimezoneOffset(new Date(jsDate));
  if (isNaN(newDateObj.getTime())) return '';
  return moment(newDateObj).locale(dateLanguages[`${i18n.language}`]).format('HH:mm:ss');
};

export const getDateFormat = (jsDate: string, dateFormat: string = dateFormats.simpleTime24) => {
  const newDateObj = new Date(jsDate);
  return moment(newDateObj).locale(dateLanguages[`${i18n.language}`]).format(dateFormat);
};

export const secondsToTimeFormat = (seconds: number) => {
  return moment.utc(seconds * 1000).format(dateFormats.fullTime);
};
export const checkIfDateIsToday = (date: string) => {
  const today = new Date();
  const inputDate = new Date(date);
  return today.toDateString() === inputDate.toDateString();
};
export const checkIfDateIsCurrentYear = (date: string) => {
  const today = new Date();
  const inputDate = new Date(date);
  return today.getFullYear() === inputDate.getFullYear();
};

export const sameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};
// return true if it's 1 is greater than 2
export const isDateSmallerThanOrEqual = (date1: string, date2: string): boolean => {
  return new Date(date1) <= new Date(date2);
};

// convert seconds to specific time format
export const convertSecondsToTime = (
  seconds: number,
  dateFormat: string = dateFormats.simpleTimeSeconds
) => {
  return moment.utc(seconds * 1000).format(dateFormat);
};

export const getHumanReadableTimeDifference = (date: Date) => {
  moment.relativeTimeThreshold('m', 60);
  return moment(date).locale(dateLanguages[`${i18n.language}`]).fromNow();
};

export const getNumbersBasedDateTextByLanguage = ({
  dateText,
  showOnlyTimeIfToday = false,
  excludeTime = false,
}: {
  dateText?: string | null;
  showOnlyTimeIfToday?: boolean;
  excludeTime?: boolean;
}) => {
  if (!dateText) return '';

  const language = i18n.language as ELanguages;
  const hoursFormat = excludeTime ? '' : ' HH:mm';
  let showOnlyTime = false;

  if (showOnlyTimeIfToday) {
    const today = new Date();
    const dateTime = new Date(dateText);
    today.setHours(12, 0, 0, 0);
    dateTime.setHours(12, 0, 0, 0);
    showOnlyTime = sameDay(today, dateTime);
  }

  const expectedDateFormat =
    datesFormatByLanguage[language as ELanguages] ?? datesFormatByLanguage[ELanguages.en];
  const dateFormat = showOnlyTime ? '' : expectedDateFormat;

  const dateTimeFormat =
    !dateFormat.length && !hoursFormat?.length
      ? `${expectedDateFormat} HH:mm`
      : `${dateFormat}${hoursFormat}`.trim();

  return getDateFormatCustom(dateText, dateTimeFormat);
};
