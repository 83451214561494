import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SDrawerWrapper = styled.div`
  .profileDrawer {
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1001;
    @media (min-width: 450px) {
      max-width: 26rem;
      margin: auto;
    }
    background-color: ${palette.prussianBlue2};
    max-height: 75vh;
  }
  .profileDrawer__backdrop {
    z-index: 1000;
  }
  .profileDrawer__handle-wrapper {
  }
  .profileDrawer__handle {
    width: 36%;
    margin-top: 1.3125rem;
  }
  .profileDrawer__content {
    padding: 1.25rem;
    padding-bottom: ${toRem(30)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
