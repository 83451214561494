import { getAlarmAllSteps } from '../../containers/Alarm/AlarmSlice';
import { useAppSelector } from '../../hooks';
import { AlarmStep, AlarmStepLinks } from '../enums';

export const useAlarmStep = (openedStep: AlarmStep) => {
  const allSteps = useAppSelector(getAlarmAllSteps);
  const currentStep = allSteps.indexOf(openedStep);
  const prevStepLink =
    AlarmStepLinks[AlarmStep[allSteps[currentStep - 1]] as keyof typeof AlarmStepLinks];
  return {
    link: prevStepLink,
    // +1 since it's a text and real step is a position in array, where first is 0
    currentStep: currentStep + 1,
    stepsAmount: allSteps.length,
  };
};
