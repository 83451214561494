import React, { createContext, useContext, useEffect, useState } from 'react';
import { MapType } from '../enums';
interface MapTypeContextProps {
  mapType: MapType;
  toggleMapType: () => void;
}
const mapTypeContextDefaultValue: MapTypeContextProps = {
  mapType: MapType.STYLED,
  toggleMapType: () => {
    console.error(
      'toggleMapType is not working because element is not wrapped with MapTypeContextProvider!'
    );
  },
};

const MapTypeContext = createContext<MapTypeContextProps>(mapTypeContextDefaultValue);

export const useMapTypeContext = () => useContext(MapTypeContext);

interface MapTypeContextProviderProps {
  children: React.ReactNode;
}
export const MapTypeContextProvider = (props: MapTypeContextProviderProps) => {
  const { children } = props;
  const [mapType, setMapType] = useState<MapType>(MapType.STYLED);

  const getStoredMapType = async () => {
    const storedMapType = localStorage.getItem('mapType')
      ? (localStorage.getItem('mapType') as MapType)
      : MapType.STYLED;
    setMapType(storedMapType);
  };

  useEffect(() => {
    getStoredMapType();
  }, []);

  const toggleMapType = () => {
    const newMapType = mapType === MapType.HYBRID ? MapType.STYLED : MapType.HYBRID;
    setMapType(newMapType);
    localStorage.setItem('mapType', newMapType);
  };

  return (
    <MapTypeContext.Provider
      value={{
        mapType,
        toggleMapType,
      }}
    >
      {children}
    </MapTypeContext.Provider>
  );
};
