import isElectron from 'is-electron';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteDevice, updateDeviceId } from '../apis/authApi/authAPI';
import { isDevRelease } from '../configurations';
import { setIncomingMessages } from '../containers/ChatsList/chatListSlice';
import { fetchTotalUnread } from '../containers/ChatsList/chatListSlice/actionCreators';
import { fetchOnCallAlertList } from '../containers/OnCallAlertList/onCallAlertSlice/actionCreators';
import { useAppDispatch } from '../hooks';
import { isDesktop } from '../utils/isDesktop';
import { getDeviceToken, getLoggedUser, saveDeviceToken } from './device.service';
import { AppNotification } from './types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Emergency1 from './../assets/sounds/emergency.mp3';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Emergency2 from './../assets/sounds/emergency2.mp3';

export const PushyService = (props: {
  onNotification: (notification: AppNotification) => void;
}) => {
  const { onNotification } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const initPushy = () => {
    const { ipcRenderer } = window.require('electron');

    // TODO: remove after fixing issue with Pushy
    console.log('initPushy');

    const soundByFileName: any = {
      'emergency.caf': Emergency1,
      'emergency2.caf': Emergency2,
    };

    const sound = new Audio();
    sound.loop = true;

    // Save device token in the database
    ipcRenderer.on('register-device', async (_, token) => {
      const deviceToken = getDeviceToken();

      // TODO: remove after fixing issue with Pushy
      console.log('token received from Pushy.register-device', token);
      console.log('previous localStorage pushyToken', deviceToken);

      if (!token) {
        console.error('No Pushy token detected!');
        return;
      }

      if (deviceToken !== token) {
        try {
          if (deviceToken) await deleteDevice(deviceToken);
        } catch (error) {
          console.error(error);
        }
      }
      saveDeviceToken(token);

      // TODO: remove after fixing issue with Pushy
      console.log('new localStorage pushyToken', getDeviceToken());

      //send token/user pair to server
      if (getLoggedUser()) await updateDeviceId(token);
    });

    // Display notification
    ipcRenderer.on('received_notification', (_, notificationPayload) => {
      if (isDesktop() && isDevRelease()) {
        console.log(
          '(-------- dev release only log --------)\n on received_notification [Will Display notification] \n -- notificationPayload: ',
          notificationPayload
        );
      }
      if (!!soundByFileName[notificationPayload.sound]) {
        const alarmName = notificationPayload.title.replace('ALARM - ', '');
        notificationPayload.alarmNotification = {
          alarmTitle: t('desktop_alarm_notification_title'),
          alarmName: alarmName,
          openBtnText: t('desktop_alarm_notification_open_btn_text'),
        };
        notificationPayload.title = t('desktop_alarm_notification_title');
        notificationPayload.message = alarmName;
        sound.src = soundByFileName[notificationPayload.sound];
        sound.play();
      }
      ipcRenderer.send('handle_notification', notificationPayload);
    });

    // Show app message
    ipcRenderer.on('main-message', (_, notification) => {
      if (isDesktop() && isDevRelease()) {
        console.log(
          '(-------- dev release only log --------)\n on main_message [Show app message] \n -- notification',
          notification
        );
      }
      dispatch(setIncomingMessages(true));
      dispatch(fetchTotalUnread());

      if (
        !notification?.notificationPayload ||
        notification.notificationPayload.sound === 'emergency.caf'
      ) {
        sound.pause();
      }
      if (!notification?.doNotOpenMessages) onNotification(notification);
      if (notification?.isOnCallAlertWindowClose && window.location.hash.includes('onCallAlert')) {
        dispatch(fetchOnCallAlertList());
      }
    });

    ipcRenderer.send('pushy-initialized', null);
    // TODO: remove after fixing issue with Pushy
    console.log('pushy-initialized event sent to main process');
  };

  useEffect(() => {
    if (isElectron()) initPushy();
  }, []);

  return null;
};
