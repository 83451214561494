import i18n, { ELanguages } from '../../i18n';
import { getDateFormatCustom } from '../../utils/date';

export const getDateTextByLanguage = (dateText: string | null) => {
  if (!dateText) return '';

  const language = i18n.language as ELanguages;
  const currentYear = getDateFormatCustom(new Date().toISOString().replace('Z', ''), 'YYYY');
  const dateYear = getDateFormatCustom(dateText, 'YYYY');
  const showYear = currentYear !== dateYear;

  if ([ELanguages['en-TT'], ELanguages.en].includes(language)) {
    const dateFormat = showYear ? 'MMMM D, YYYY' : 'MMMM D, HH:mm';
    return `${getDateFormatCustom(dateText, dateFormat)}`;
  } else {
    const dateFormat = showYear ? 'D MMMM, YYYY' : 'D MMMM, HH:mm';
    return `${getDateFormatCustom(dateText, dateFormat)}`;
  }
};
