import { GroupSelectItem } from '../../components/Chat/GroupSelectItem';
import { useRef } from 'react';
import { Group } from '../GroupsList/groupsSlice/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Loader from '../../components/Loader/Loader';
import { getGroupMembers } from '../../apis/groupsAPI';
import { GroupType } from '../../utils/enums';
import { handleSelectGroup, isGroupsLoading, selectGroupsSelection } from './checklistsSlice';
import { checkGroupsType } from '../CreateMessage/helpers';
import { SList } from '../CreateMessage/SelectGroupsList';
import NoMembers from '../../assets/imgs/NotFound/no-result.svg';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import styled from 'styled-components';

import { HighlightSearchTerm } from '../../components/Checklists/HighlightSearchTerm/HighlightSearchTerm';
import { GroupMember } from '../GroupDetail/groupDetailSlice/types';
export interface SelectedGroup {
  id: number;
  name: string | null;
  hidden: boolean;
  groupType: GroupType;
}

interface ChecklistSelectGroupsListProps {
  searchText?: string;
  groups: Group[];
}

const SEmptyListFallback = styled(EmptyListFallback)`
  p {
    font-family: 'Roboto-Regular';
    font-weight: 400;
  }
`;

export const SCustomList = styled(SList)`
  padding: 1.25rem 0 5rem 0;
`;

export const ChecklistSelectGroupsList = (props: ChecklistSelectGroupsListProps) => {
  const { searchText, groups } = props;
  const dispatch = useAppDispatch();

  const { isGroupsSelected } = useAppSelector(selectGroupsSelection);
  const isLoading = useAppSelector(isGroupsLoading);
  const usersListRef = useRef<HTMLDivElement>(null);

  const onGroupClick = async (group: Group) => {
    const { id, groupMembersCount } = group;

    let groupMembers = [] as GroupMember[];
    if (groupMembersCount > 1) {
      groupMembers = await getGroupMembers({ id });
    }

    dispatch(
      handleSelectGroup({ groupId: id, usersIds: groupMembers.map(member => member.userID) })
    );
  };

  if (isLoading && !groups.length) {
    return <Loader />;
  }
  return (
    <SCustomList ref={usersListRef}>
      {groups.map((group, index) => (
        <GroupSelectItem
          id={`group-${index + 1}`}
          key={group.id}
          name={HighlightSearchTerm(group.name, searchText)}
          membersCount={group.groupMembersCount}
          type={checkGroupsType(group.groupType)}
          isSelected={isGroupsSelected(group.id)}
          photoFileName={group.imageFileName || undefined}
          onCardPress={() => onGroupClick(group)}
        />
      ))}
      {!groups.length && (
        <SEmptyListFallback
          src={NoMembers}
          listLength={groups.length}
          isLoading={false}
          emptyListTx={'checklist_share_empty'}
        />
      )}
    </SCustomList>
  );
};
