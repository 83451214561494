import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SReactionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${toRem(12)};
  padding: ${toRem(12)} ${toRem(20)};
  border-bottom: 1px solid ${palette.prussianBlue5};
`;

export const SReactionContainer = styled.div<{ isSelected?: boolean }>`
  transition-duration: 300ms;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${toRem(48)};
  width: ${toRem(48)};
  background-color: ${props => (props.isSelected ? palette.policeBlue : palette.prussianBlue3)};
  border-radius: ${toRem(24)};
  border: ${props => props.isSelected && `2px solid ${palette.queenBlue}`};
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  p {
    transition-duration: 300ms;
    font-family: 'AppleColorEmoji', serif;
    font-size: ${toRem(18)};
  }
`;
