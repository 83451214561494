import { useEffect, useState } from 'react';
import { getGroupMembers } from '../../apis/groupsAPI';
import { GroupType } from '../../utils/enums';
import { translate } from '../../utils/translate';
import { GroupMember } from '../GroupDetail/groupDetailSlice/types';

import { Group } from '../GroupsList/groupsSlice/types';
import { FiltersByGroupType } from '../GroupsList/helpers';
import { SelectableGroupMember } from '../../components/FilterOrSelectBottomSheet/types';

export enum MemberNotMemberGroupsFilters {
  MemberOfGroup,
  NotMemberOfGroup,
}
export const MemberNotMemberFiltersTX: Record<MemberNotMemberGroupsFilters, string> = {
  0: 'messages_groups_where_member',
  1: 'messages_groups_where_not_member',
};

export const filterGroupsNoCoAlertNoCO = (groups: Group[]): Group[] => {
  return groups.filter(group => ![GroupType.CoAlert, GroupType.CrossOrg].includes(group.groupType));
};

export const getIamOkGroups = (groups: Group[]): Group[] => {
  return filterGroupsNoCoAlertNoCO(groups).filter(group => group.menuItems.includes('muster'));
};

export const filterGroupsByType = (filters: FiltersByGroupType[], groups: Group[]): Group[] => {
  if (filters.length === 0 || filters.length === Object.keys(FiltersByGroupType).length) {
    return groups;
  }

  // Create a set for quick lookup of filters
  const filterSet = new Set(filters);

  // Filter groups in a single iteration
  const filtersByGroupType = {
    [GroupType.CoAlert]: FiltersByGroupType.CoAlert,
    [GroupType.CrossOrg]: FiltersByGroupType.CrossOrg,
    [GroupType.Normal]: FiltersByGroupType.Normal,
    [GroupType.Hidden]: FiltersByGroupType.Hidden,
  };

  return groups.filter(group =>
    filtersByGroupType[group.groupType] ? filterSet.has(filtersByGroupType[group.groupType]) : false
  );
};

export const filterGroupsMemberNotMember = (
  filters: MemberNotMemberGroupsFilters[],
  groups: Group[]
): Group[] => {
  if (filters.length === 0) return groups;
  // number of enum filters divided by 2 because it's numeric enums.
  if (filters.length === Object.keys(MemberNotMemberGroupsFilters).length / 2) return groups;

  let filteredGroups = [...groups];

  if (filters.includes(MemberNotMemberGroupsFilters.MemberOfGroup)) {
    filteredGroups = filteredGroups.filter(group => group.member);
  }

  if (filters.includes(MemberNotMemberGroupsFilters.NotMemberOfGroup)) {
    filteredGroups = filteredGroups.filter(group => {
      return !group.member;
    });
  }
  return filteredGroups;
};

export const searchGroupsName = (searchTerm: string, groups: Group[]): Group[] => {
  return searchTerm
    ? groups.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : groups;
};

export const useGetMembers = (props: { id: number; setUserIds?: (set: Set<number>) => void }) => {
  const { id, setUserIds } = props;
  const [isGettingMembers, setIsLoading] = useState<boolean>(false);

  const [members, setMembers] = useState<SelectableGroupMember[]>([]);

  const init = async () => {
    if (!id) return;
    setIsLoading(true);
    const groupMembers = await getGroupMembers({ id });
    const transformedMembers = groupMembers.map(member => {
      return {
        ...member,
        name: member.userName || '',
        id: +member.userID || 0,
        imageName: member.photoFileName || '',
        showAvailability: !member.available,
      };
    });
    const selectedMembers = new Set(groupMembers.map(member => member.userID));
    if (setUserIds) {
      setUserIds(selectedMembers);
    }
    setMembers(transformedMembers);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, [id]);

  return {
    members,
    isGettingMembers,
  };
};

export const getRecipientsText = (members: GroupMember[], selected: Set<number>): string => {
  if (members.length === selected.size) {
    return translate('labelAll');
  }
  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const selectedUsers = members.reduce((accumulator: string[], member: GroupMember) => {
    if (selected.has(+member.userID)) {
      accumulator.push(capitalize(member.userName || ''));
    }
    return accumulator;
  }, []);

  return selectedUsers.join(', ');
};
