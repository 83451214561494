import styled from 'styled-components';
import { Layout } from '../components/Layout/Layout';
import { LoneWorkerTimer } from '../containers/LoneWorkerTimer/LoneWorkerTimer';
import { palette } from '../theme/colors';

const SLayout = styled(Layout)`
  background-color: ${palette.darkblack};
`;

export const LoneWorkerTimerPage = () => {
  return (
    <SLayout
      backgroundColor={palette.darkblack}
      isMessageLayout
      message="lone_worker_timer"
      to="/dashboard"
    >
      <LoneWorkerTimer />
    </SLayout>
  );
};
