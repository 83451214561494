//TODO: View the document in here after you finish the component
import styled from 'styled-components';
import { Backdrop } from './Backdrop/Backdrop';

const SModal = styled.div<{ maxHeight?: string; zIndex?: number }>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 32px;
  max-height: ${props => (props.maxHeight ? props.maxHeight : 'calc(100vh)')};
  width: 100%;
  max-width: 24rem;
  z-index: ${props => (props.zIndex ? props.zIndex : 999)};
`;

interface ModalProps {
  isOpen: boolean;
  backdropZIndex?: number;
  maxHeight?: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | ((arg0: boolean) => void);
  children: React.ReactNode;
  role?: string;
}
export const Modal = (props: ModalProps) => {
  const { isOpen, children, backdropZIndex, maxHeight, setIsOpen } = props;
  return (
    <>
      {isOpen && (
        <>
          <SModal maxHeight={maxHeight} zIndex={backdropZIndex ? backdropZIndex + 1 : undefined}>
            {children}
          </SModal>
          <Backdrop setModal={setIsOpen} zIndex={backdropZIndex} />
        </>
      )}
    </>
  );
};
