import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SBottomSheet = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  padding: 20px;
  padding-bottom: ${toRem(30)};
  transform: translate(-50%);
  background-color: ${palette.prussianBlue2};
  width: 100%;
  max-width: 26rem;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

export const SCurtainHandle = styled.svg`
  width: 135px;
  height: 5px;
  place-self: center;
  margin-bottom: 24px;
  border-radius: 5px;
`;

export const SContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
`;

export const STitleText = styled.p`
  font-size: 16px;
  color: ${palette.white};
  font-family: 'Roboto-Medium';
`;

export const SDateRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 24px 0 40px 0;
`;
