import { Layout } from '../../components/Layout/Layout';
import AlarmCustomText from '../../containers/Alarm/AlarmCustomText';
import { translate } from '../../utils/translate';
import { useAlarmStep } from '../../utils/customHooks/useAlarmStep';
import { AlarmStep } from '../../utils/enums';
function AlarmCustomTextPage() {
  const { currentStep, link, stepsAmount } = useAlarmStep(AlarmStep.AddCustomText);
  return (
    <Layout
      isMessageLayout
      to={link}
      backBtn
      isAlarmCreateLayout
      message={`alarm_send_alarm`}
      subMessageText={`${translate(`alarm_step`)} ${currentStep} ${translate(
        `alarm_of`
      )} ${stepsAmount}`}
    >
      <AlarmCustomText />
    </Layout>
  );
}

export default AlarmCustomTextPage;
