import React from 'react';
import { Base } from './BigFloatButton.styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface BigFloatButtonProps {
  tx: string;
  link?: string;
  onClick?: () => void;
  extraPadding?: boolean;
  color?: 'yellow' | 'red';
  className?: string | undefined;
  disabled?: boolean;
}

const BigFloatButton = (props: BigFloatButtonProps) => {
  const { tx, link, onClick, extraPadding = true, color, className, disabled } = props;
  const { t } = useTranslation();
  return (
    <>
      {link != null ? (
        <Base
          className={className}
          as={Link}
          to={link}
          $extraPadding={extraPadding}
          color={color}
          role="button"
          data-e2e={`${tx}_button`}
        >
          {t(tx)}
        </Base>
      ) : (
        <Base
          className={className}
          as="button"
          onClick={onClick}
          $extraPadding={extraPadding}
          color={color}
          disabled={disabled ?? false}
          role="button"
          data-e2e={`${tx}_button`}
        >
          {t(tx)}
        </Base>
      )}
    </>
  );
};

export default BigFloatButton;
