import * as React from 'react';
import _ from 'lodash';
import { OnCallAlertStatusType } from '../../utils/enums';
import {
  CountText,
  EmptyList,
  ResponseListContainer,
  ResponseRow,
  SwitchButton,
  SwitchButtonText,
  UserWrapper,
} from './OnCallAlert.styles';
import { translate } from '../../utils/translate';
import { palette } from '../../theme/colors';
import { OnCallAlertDocumentSimple } from '../../containers/OnCallAlertList/onCallAlertSlice/types';
import OnCallAlertUserItem from './OnCallAlertUserItem';

export interface OnCallAlertResponseListProps {
  activeButton: OnCallAlertStatusType;
  setActiveButton: (e: any) => void;
  onCallAlertDocument: OnCallAlertDocumentSimple;
  messageHeight?: number;
}

export const OnCallAlertResponseList = (props: OnCallAlertResponseListProps) => {
  const { activeButton, setActiveButton, onCallAlertDocument, messageHeight } = props;
  const { users, id: onCallAlertId, groupId } = onCallAlertDocument;

  const notAvailableUsers = _.filter(
    users,
    user => user.status === OnCallAlertStatusType.NotAvailable
  );
  const availableUsers = _.filter(users, user => user.status === OnCallAlertStatusType.Available);
  const noStatus = _.filter(users, user => user.status === OnCallAlertStatusType.NoStatus);

  const buttonRowItems = [
    {
      id: OnCallAlertStatusType.NotAvailable,
      text: 'onCallAlert_unavailable',
      count: notAvailableUsers?.length || 0,
      color: palette.tartOrange,
      align: 'flex-start',
    },
    {
      id: OnCallAlertStatusType.NoStatus,
      text: 'imOk_notStatus',
      count: noStatus?.length || 0,
      color: 'transparent',
      align: 'center',
    },
    {
      id: OnCallAlertStatusType.Available,
      text: 'onCallAlert_available',
      count: availableUsers?.length || 0,
      color: palette.applGreen,
      align: 'flex-end',
    },
  ];

  const selectedUserGroup = () => {
    if (activeButton === OnCallAlertStatusType.NotAvailable) {
      return notAvailableUsers;
    }
    if (activeButton === OnCallAlertStatusType.Available) {
      return availableUsers;
    }
    return noStatus;
  };
  const selectedGroup = _.find(buttonRowItems, button => button.id === activeButton);

  return (
    <ResponseListContainer $removedHeight={messageHeight} role="responsesListContainer">
      <ResponseRow role="statusButtonsRow">
        {_.map(buttonRowItems, (button, index) => {
          return (
            <SwitchButton
              align={button.align}
              active={button.id === activeButton}
              onClick={() => setActiveButton(button.id)}
              key={index}
              role="statusButton"
              data-e2e={`status_${button.text}_button`}
            >
              <SwitchButtonText
                isNoStatus={button.id == OnCallAlertStatusType.NoStatus}
                active={button.id === activeButton}
                color={button.color}
                role="status"
              >
                {translate(button.text)}
              </SwitchButtonText>
              <CountText
                active={button.id === activeButton}
                role="count"
                data-e2e={`status_${button.text}_count`}
              >
                {`(${button.count || 0})`}
              </CountText>
            </SwitchButton>
          );
        })}
      </ResponseRow>
      {selectedUserGroup().length ? (
        <UserWrapper role="usersContainer">
          {_.map(selectedUserGroup(), (userItem, index) => {
            return (
              <OnCallAlertUserItem
                key={index}
                user={userItem}
                onCallAlertId={onCallAlertId}
                groupId={groupId!}
                selectedGroup={{
                  id: selectedGroup!.id,
                  text: selectedGroup!.text,
                  color: selectedGroup!.color,
                }}
              />
            );
          })}
        </UserWrapper>
      ) : (
        <EmptyList role="emptyListContainer">
          <p role="emptyText">{translate('imOk_noMessages')}</p>
        </EmptyList>
      )}
    </ResponseListContainer>
  );
};
