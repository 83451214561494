import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { TextField } from '../TextField/TextField';

export const STextField = styled(TextField)`
  margin-top: 0.625rem;

  input {
    caret-color: black;
    background-color: ${palette.white};
    font-family: 'Roboto-Regular';
    font-size: 1rem;

    ::placeholder {
      color: ${palette.silver};
      font-weight: 400;
      font-size: 1rem;
      font-family: 'Roboto-Regular';
    }
  }
`;

export const STextContainer = styled.div`
  padding: 0 2rem;
`;
