import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SPage = styled(Page)`
  padding 0 1.25rem;
`;

export const SInternalSupport = styled.div`
  display: flex;
  flex-direction: column;
  * + * {
    margin-top: 10px;
  }
  height: 100%;

  padding: 1rem 0;

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SSupportAccountGroup = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 0;
  .groupName {
    font-family: 'Roboto-Bold';
    font-size: 1.5rem;
    margin-bottom: ${toRem(12)};
    margin-top: 0;
  }
`;

export const SContactCard = styled.div`
  margin-bottom: 0.5rem;
  background-color: ${palette.prussianBlue3};
  border-radius: ${toRem(12)};
  padding: 1rem;
  font-family: 'Roboto-Regular';
  .name {
    font-family: 'Roboto-Bold';
    font-size: ${toRem(18)};
    line-height: ${toRem(21)};
    margin-bottom: ${toRem(4)};
    color: ${palette.earthYellow};
  }
  .title {
    font-size: ${toRem(14)};
    line-height: ${toRem(16)};
    line-height: 1rem;
    color: ${palette.ashesGray};
    margin-bottom: 1rem;
  }
  .name,
  .title {
    text-transform: capitalize;
  }
  .phone,
  .email {
    font-size: ${toRem(16)};
    line-height: ${toRem(18)};
    color: ${palette.white};
  }
  .phone {
    margin-bottom: ${toRem(6)};
  }
  .name,
  .title,
  .phone,
  .email {
    margin-top: 0;
  }

  a {
    display: block;
    text-decoration: none;
    :hover {
      color: ${palette.wildBlue};
    }
  }
`;
