import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { SearchBarBase } from '../SearchBarBase/SearchBarBase';

export const SSearchWithButton = styled.div`
  position: absolute;
  top: 6.06rem;
  z-index: 99;
  width: 100%;
  padding: 0 1rem;
  display: flex;
`;

export const SSearchBarBase = styled(SearchBarBase)`
  width: calc(100% - 51px - 1rem);
  margin-right: 1rem;
`;

export const SCenterMarkerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-53%, -92%);
  z-index: 1;
  width: 32px;
  pointer-events: none;
`;

export const SLocationButton = styled.button`
  cursor: pointer;
  width: 51px;
  border: 1px solid ${palette.queenBlue};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.background.searchBar};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SMapView = styled.div`
  height: 100vh;
`;
