import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { setTempChecklistTasks } from '../../containers/Checklists/checklistsSlice';
import NewChecklist from '../../containers/Checklists/NewChecklist';
import { useAppDispatch } from '../../hooks';

function NewChecklistPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { checklistName } = useParams();

  useEffect(() => {
    if (!checklistName) navigate('/checklists');
  }, [navigate, checklistName]);

  return (
    <Layout
      isMessageLayout
      message={checklistName}
      to={`/checklists/create/${checklistName}`}
      showBottomTabs
      showDots
      dotsCallBack={() => setIsOpen(!isOpen)}
      backBtnCallBack={() => dispatch(setTempChecklistTasks(null))}
    >
      <NewChecklist isHeaderOptionsOpen={isOpen} setHeaderOptionsOpen={setIsOpen} />
    </Layout>
  );
}

export default NewChecklistPage;
