import { defaultLanguage } from '../../i18n';
import { isDesktop } from '../../utils/isDesktop';
import { getItem } from '../../utils/storage';

interface FragmentParams {
  [key: string]: string;
}

export const getSsoRedirectUrl = (email: string | null, provider?: string) => {
  if (isDesktop()) {
    const authLink = process.env.REACT_APP_SSO_REDIRECT_URL;
    const redirect = `${authLink}?email=${(email || '').trim()}&lang=${
      getItem('language') ?? defaultLanguage
    }`;
    return redirect;
  }

  let host = 'https://' + window.location.host;
  if (window.location.host.startsWith('localhost')) {
    const url = new URL(process.env.REACT_APP_SSO_REDIRECT_URL || window.location.href);
    host = `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`;
  }

  const uri = email
    ? '/login/?sso=' + encodeURIComponent(email)
    : '/login/?sso_provider=' + provider;

  return host + uri;
};

export const getFragmentParams = (fragment?: string) => {
  const params: FragmentParams = {};

  if (!fragment && window.location.hash) {
    fragment = window.location.hash.substring(1);
  }

  if (fragment) {
    fragment.split('&').forEach(function (part: string) {
      const values = part.split('=');
      const key = values[0];

      if (values.length >= 2) {
        values.shift();
        params[key] = values.join('=');
      }
    });
  }

  return params;
};
