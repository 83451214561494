import styled from 'styled-components';

export const SMapTypeWrapper = styled.div`
  position: absolute;
  bottom: 9.75rem;
  right: 2rem;
`;

export const SMapContainer = styled.div`
  position: fixed;
  max-width: 26rem;
  z-index: 9;
  top: 0;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
`;
