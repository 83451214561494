import { palette } from '../../../theme/colors';
import styled, { keyframes } from 'styled-components';
import { toRem } from '../../../utils/toRem';

interface SMessageProps {
  isRepliedTo?: boolean;
}

const flash = keyframes`
   25% {
     background-color: ${palette.BlueBayoux};
   } 
   75% {
     background-color: ${palette.BlueBayoux};
  }
`;

export const SDeletedMessageContainer = styled.div<SMessageProps>`
  margin-left: ${props => (!props.isRepliedTo ? toRem(67) : '')};
  margin-bottom: ${props => !props.isRepliedTo && toRem(8)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${props => (props.isRepliedTo ? 'transparent' : palette.navyLight)};
  padding: ${props => (props.isRepliedTo ? '0px' : `${toRem(8)}`)};
  border-radius: ${toRem(12)};

  &.flash {
    animation: ${flash} 0.8s linear;
    animation-delay: 0.5s;
  }
`;

export const STextContainer = styled.div`
  align-items: center;
  display: flex;
  .deletedSign {
    margin-right: ${toRem(9.5)};
    margin-left: ${toRem(3)};
  }
  .deleteText {
    font-size: ${toRem(14)};
    line-height: ${toRem(18.2)};
    font-weight: 400;
    font-style: italic;
    color: ${palette.white};
  }
`;

export const STimeText = styled.p`
  font-family: 'Roboto-Regular';
  font-size: ${toRem(11)};
  line-height: ${toRem(14.3)};
  font-weight: 500;
  color: ${palette.navy20};
`;
