import styled from 'styled-components';
import { Page } from '../../components/Page/Page';

export const SPage = styled(Page)`
  padding: 0;
`;

export const SList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.25rem 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
