//TODO: View the document in here after you finish the component
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { verifyPassword } from '../../apis/authApi/authAPI';
import { selectIsUserVerified, setIsLoginLoading } from '../../containers/Login/LoginSlice';
import { setIsVerifyAction } from '../../containers/Login/LoginSlice/actionCreators';
import useForm from '../../utils/customHooks/useForm';
import { translate } from '../../utils/translate';
import { passwordSchema } from '../../utils/validate';
import { SDialog, SModalWrapper, SOverLay } from '../ConfirmDialog/style';
import { TextField } from '../TextField/TextField';
import Loader from '../Loader/Loader';
import {
  SModalSButtonWrapper,
  SAlertError,
  SAlertForm,
  SAlertTitle,
  SFieldInput,
  SModalCancelButton,
  SModalConfirmButton,
} from './PWAlertModal.styles';

interface PWVerifyModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onProceed: any;
}

export interface VerifyForm {
  password: string;
}

export const PWVerifyModal = (props: PWVerifyModalProps) => {
  const { isOpen, setIsOpen, onProceed } = props;
  const dispatch = useDispatch();
  const isVerified = useSelector(selectIsUserVerified);
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { inputs, handleChange, errors, handleBlur, touched, isValid } = useForm<VerifyForm>({
    initial: {
      password: '',
    },
    validateSchema: {
      password: passwordSchema,
    },
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e as React.ChangeEvent<HTMLInputElement>);

  const handleClickOutside = (e: MouseEvent) => {
    if (dialogRef && !dialogRef.current?.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useLayoutEffect(() => {
    if (document.addEventListener) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (document.removeEventListener) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await verifyPassword({
        password: inputs.password,
      });
      batch(() => {
        dispatch(setIsVerifyAction(true));
        dispatch(setIsLoginLoading(false));
      });
      onProceed();
      setIsLoading(false);
    } catch (err) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      return handleSubmit(event);
    }
  };

  return (
    <>
      {isOpen && (
        <>
          <SOverLay role="overlay" />
          <SModalWrapper role="passwordVerifyDialog">
            <SDialog ref={dialogRef} role="dialogContent">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <SAlertTitle role="title">
                    {translate('messages_broadcast_password_confirmation_modal_intro')}
                  </SAlertTitle>
                  <SAlertForm role="form">
                    <SFieldInput role="inputContainer">
                      <TextField
                        role="inputField"
                        type="password"
                        name="password"
                        placeHolderTx="pw_verify_input_holder"
                        value={inputs.password}
                        onChange={onChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                      ></TextField>

                      {isError && (
                        <SAlertError role="errorText">{translate('pw_verify_error')}</SAlertError>
                      )}
                    </SFieldInput>

                    <SModalSButtonWrapper role="buttonsRow">
                      <SModalCancelButton onClick={() => setIsOpen(false)} role="cancelButton">
                        {translate('cancel')}
                      </SModalCancelButton>
                      <SModalConfirmButton
                        type="button"
                        onClick={handleSubmit}
                        role="confirmButton"
                      >
                        {translate('messages_proceed')}
                      </SModalConfirmButton>
                    </SModalSButtonWrapper>
                  </SAlertForm>
                </>
              )}
            </SDialog>
          </SModalWrapper>
        </>
      )}
    </>
  );
};
