import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword, validateResetToken } from '../../apis/authApi/authAPI';
import userImg from '../../assets/imgs/general/user-login.svg';
import PasswordImg from '../../assets/imgs/login/login-password.svg';
import { translate } from '../../utils/translate';
import { passwordSchema, validateConfirmPassword } from '../../utils/validate';
import Loader from '../../components/Loader/Loader';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import {
  SUserImg,
  SButton,
  SError,
  SForm,
  SSubTitle,
  STextField,
  STitle,
} from './ResetPassword.styles';

export interface IResetPasswordProps {
  userId?: string;
  resetToken?: string;
}

export interface ResetPasswordForm {
  password: string;
  repeatPssword: string;
}

export const ResetPassword = (props: IResetPasswordProps) => {
  const { userId, resetToken } = props;
  const confirm = useConfirmation();

  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarnings] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordConfirmationErrorMessage, setPasswordConfirmationErrorMessage] = useState('');

  const navigate = useNavigate();
  const init = async () => {
    if (userId && resetToken) {
      try {
        setIsLoading(true);
        await validateResetToken({
          userId: Number(userId),
          token: resetToken,
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        confirm({
          title: 'warning',
          description: 'login_resetLinkExpired',
          onSubmit: () => {
            navigate('/login', { replace: true });
          },
          confirmText: 'done',
          blockOutsideClick: true,
        });
      }
    } else {
      navigate('/login', { replace: true });
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const validate = () => {
    const passErr = passwordSchema(password);
    if (passErr) {
      setPasswordErrorMessage(passErr);
      setShowWarnings(true);
      return false;
    }

    const confirmPassErr = validateConfirmPassword(passwordConfirmation, password);
    if (confirmPassErr) {
      setPasswordConfirmationErrorMessage(confirmPassErr);
      setShowWarnings(true);
      return false;
    }
    return true;
  };

  const handleBlur = () => {
    setShowWarnings(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordErrorMessage('');
    setPasswordConfirmationErrorMessage('');
    const valid = validate();
    if (!valid) return;

    try {
      setIsLoading(true);

      await resetPassword({
        password: password,
        token: resetToken!,
        userId: Number(userId)!,
      });
      setIsLoading(false);
      confirm({
        title: 'login_success',
        description: 'profile_changed_password_info',
        onSubmit: () => {
          navigate('/login', { replace: true });
        },
        confirmText: 'done',
      });
    } catch (err) {
      setIsLoading(false);
      confirm({
        title: 'warning',
        description: 'general_network_error',
        onSubmit: () => {
          handleSubmit(err);
        },
        confirmText: 'retry',
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <SUserImg src={userImg} alt="" />
      <STitle>{translate('login_setUpPassword')}</STitle>
      <SSubTitle>{translate('login_passwordInstruction')}</SSubTitle>
      <SForm onSubmit={handleSubmit}>
        <STextField
          isShadow
          error={!!passwordErrorMessage && showWarning}
          StartAdornment={<img src={PasswordImg} alt="password icon" />}
          type="password"
          name="password"
          placeHolderTx="login_password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          onBlur={handleBlur}
        />
        {passwordErrorMessage && showWarning && <SError>{passwordErrorMessage}</SError>}
        <STextField
          isShadow
          error={!!passwordConfirmationErrorMessage && showWarning}
          StartAdornment={<img src={PasswordImg} alt="password icon" />}
          type="password"
          name="repeatPssword"
          placeHolderTx="login_renterPass"
          value={passwordConfirmation}
          onChange={e => setPasswordConfirmation(e.target.value)}
          onBlur={handleBlur}
        />
        {passwordConfirmationErrorMessage && showWarning && (
          <SError>{passwordConfirmationErrorMessage}</SError>
        )}
        <SButton
          $error={showWarning}
          disabled={showWarning}
          type="submit"
          tx={'create_password_button'}
        />
      </SForm>
    </div>
  );
};
