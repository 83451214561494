import { Layout } from '../../components/Layout/Layout';
import PanicAlarmCreate from '../../containers/PanicButton/PanicAlarmCreate';

function PanicAlarmCreatePage() {
  return (
    <Layout isMessageLayout message={'home_panic_button'} to="/dashboard">
      <PanicAlarmCreate />
    </Layout>
  );
}

export default PanicAlarmCreatePage;
