export enum UserPermision {
  admin = 'admin',
  member = 'member',
}

export enum GroupVisibality {
  hidden = 'hidden',
  normal = 'normal',
}

export enum AlarmStep {
  SelectType = 0,
  SelectGroups = 1,
  AddCustomText = 2,
  SelectLocation = 3,
}

export enum AlarmStepLinks {
  SelectType = '/alarm',
  SelectGroups = '/alarm/selectGroup',
  AddCustomText = '/alarm/alarmCustomText',
  SelectLocation = '/alarm/map',
}

export enum MessageGroupTypes {
  Hidden = 2,
  CrossOrganization = 3,
  CoAlert = 4,
}

export enum ImOkStatusType {
  NotOk,
  Ok,
  NoStatus,
  All,
}

export enum MessageType {
  ReceivedTextMessage,
  SentTextMessage,
  ReceivedAudioMessage,
  SentAudioMessage,
  ReceivedFileMessage,
  SentFileMessage,
  ReceivedImageMessage,
  SentImageMessage,
  ReceivedAlarmMessage,
  SentAlarmMessage,
  ReceivedRecalledAlarmMessage,
  SentAlarmRecalledMessage,
  SentLocationMessage,
  ReceivedLocationMessage,
  HoldingStatement,
  LogNotes,
  ImOk,
  OnCallAlerts,
}

export enum MessageSubType {
  personalAlarm = 3,
  startedChecklist = 10,
  sharedChecklist = 11,
  reactivatedChecklist = 12,
}
export enum SentMessageType {
  Chat = 1,
  LogNotes = 10,
  HoldingStatement = 9,
}

export enum ReceivedMessageType {
  Alarm = 2,
  AlarmReply = 12,
  Broadcast = 3,
  LogFile = 10,
}

export enum MessageReplyType {
  Message,
  Reply,
}

export enum OnCallAlertStatusType {
  Available = 1,
  NotAvailable = 2,
  NoStatus = 0,
  All = 3,
}
export enum AlarmTypes {
  Crime = 2,
  Scared = 3,
  FeelingIll = 4,
  Fire = 5,
  ImportantMessage = 6,
}
export enum ChecklistStatus {
  Started = 'Started',
  NotStarted = 'NotStarted',
  Ended = 'Ended',
}

export enum GroupType {
  Normal = 0,
  Hidden = 2,
  CrossOrg = 3,
  CoAlert = 4,
}

export enum ReplyToMessageType {
  All = 0,
  SenderOnly = 1,
  None = 2,
}
export enum ReplyPurpose {
  regular = 0,
  crisis = 1,
  blockedComment = 2,
  unBlockedComment = 3,

  ChecklistEnded = 4,
  ChecklistReactivated = 5,
  alarmCustomText = 6,
}

export const groupTypeNames: Record<GroupType, string> = {
  0: 'groups_normal',
  2: 'groups_hidden',
  3: 'groups_cossOrg',
  4: 'groups_coAlert',
};

export enum ActiveTab {
  Groups,
  Users,
}

export enum CreateMessageReplyType {
  replyAll,
  senderOnly,
  noReply,
}

export enum MapType {
  STYLED = 'styled_map',
  ROAD = 'roadmap',
  HYBRID = 'hybrid',
  SATELLITE = 'satellite',
  TERRAIN = 'terrain',
}

export enum RecipientsSelectionTabIndex {
  GROUPS = 0,
  USERS = 1,
}
