import isElectron from 'is-electron';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import { RequireAuth } from '../../routes/requireAuth';
import { useDarkMode } from '../../utils/customHooks/useDarkMode';
import { darkTheme } from '../../theme/theme';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../theme/globalStyle';

import { Suspense, useEffect, useLayoutEffect } from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n, { ELanguages, addTranslations, defaultLanguage, getResourceBundle } from '../../i18n';
import {
  PushyBannerState,
  selectIsAppLoading,
  setIsAppLoading,
  setPushyConnectionBanner,
} from './AppSlice';

import { RequireCookies } from '../../components/RequireCookies/RequireCookies';
import { OpenMobileApp } from '../OpenMobileApp/OpenMobileApp';
import { auth_routes, public_routes } from '../../routes/AllRoutes';
import Splash from '../../components/Splash/Splash';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUser } from '../Login/LoginSlice';
import { loginUsingSsoCode, updateUser } from '../Login/LoginSlice/actionCreators';
import { fetchTotalUnread } from '../ChatsList/chatListSlice/actionCreators';
import { CheckAppAvailable } from '../CheckAppAvailable/CheckAppAvailable';
import { selectIsOnLine } from '../CheckAppAvailable/checkAppAvailableSlice';
import { setLanguage } from '../../components/SidebarOptions/Settings/settingsSlice/actionCreators';
import { getItem, saveItem } from '../../utils/storage';
import { ErrorHandler } from '../../components/ErrorHandler/ErrorHandler';
import { ipcRenderOn, ipcRendererSend } from '../../utils/electronHooks';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { PushyConnectionResponse } from '../../components/MessageItem/BlockedStateBanner/PushyConnectionBanner';
import { MapTypeContextProvider } from '../../utils/customHooks/MapTypeContext';
import { updateTestApiUrl } from '../../configurations';
import { isDesktop } from '../../utils/isDesktop';

function App() {
  const confirm = useConfirmation();
  // UI State
  const isAppLoading = useAppSelector(selectIsAppLoading);
  const user = useAppSelector(selectUser);
  const isOnline = useAppSelector(selectIsOnLine);

  const dispatch = useAppDispatch();
  const { mountedComponent } = useDarkMode();
  // always force dark them for now
  const themeMode = darkTheme;
  // define router type
  const Router = isElectron() ? HashRouter : BrowserRouter;

  useLayoutEffect(() => {
    updateTestApiUrl();
  }, []);

  const handleLanguage = () => {
    const initialLang = sessionStorage.getItem('selectedLang') as ELanguages;
    if (initialLang) {
      saveItem('language', initialLang);
      i18n.changeLanguage(initialLang);
      dispatch(setLanguage(initialLang));
      sessionStorage.removeItem('selectedLang');
    } else if (
      user &&
      getItem('language') !== user.preferredLanguage &&
      user.preferredLanguage !== null &&
      user.preferredLanguage !== undefined
    ) {
      saveItem('language', user.preferredLanguage);
      i18n.changeLanguage(user.preferredLanguage);
    } else if (user && user.preferredLanguage === null) {
      dispatch(setLanguage(i18n.language as ELanguages));
    }
  };

  const switchToBrowserLanguage = () => {
    if (!isElectron() && !user && !getItem('language')) {
      const userLanguage = navigator.language;
      const allowedLanguages =
        defaultLanguage === ELanguages.se
          ? [ELanguages.se, ELanguages.en, ELanguages.nl]
          : [ELanguages.pt, ELanguages.es, ELanguages['en-TT']];
      if (userLanguage) {
        let languageKey = userLanguage.split('-')[0];
        if (languageKey === 'sv') {
          languageKey = ELanguages.se;
        } else if (!allowedLanguages.includes(languageKey as ELanguages)) {
          languageKey = ELanguages.en;
        }
        sessionStorage.setItem('selectedLang', languageKey);
        handleLanguage();
      }
    }
  };

  const reloadTranslationResources = async () => {
    const initialLang = getItem('language') as ELanguages;
    const languageResourceBundle = await getResourceBundle(initialLang);
    if (!languageResourceBundle) {
      dispatch(setIsAppLoading(true));
      await addTranslations();
      dispatch(setIsAppLoading(false));
    }
  };

  const handleSso = async (payload: any) => {
    dispatch(loginUsingSsoCode(payload.email, payload.code));
  };

  const handleConnectionChanges = (isConnected: boolean) => {
    dispatch(
      setPushyConnectionBanner(
        isConnected ? PushyBannerState.CONNECTED : PushyBannerState.NOT_CONNECTED
      )
    );
  };

  const compareVersionsAfter24Hours = () => {
    setTimeout(() => {
      ipcRendererSend('on-versions-compare');
    }, 86400000);
    // 86400000 = 24H in milliseconds
  };

  const showAppUpdatePopup = () => {
    confirm({
      description: 'update_app_text',
      onSubmit: () => ipcRendererSend('on-update-confirmation'),
      onCancel: () => compareVersionsAfter24Hours(),
      confirmText: 'ok',
      cancelText: 'later',
      blockOutsideClick: true,
    });
  };

  const onElectronAppStart = async () => {
    await reloadTranslationResources();
    if (isElectron()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.title = window.desktopAppName || 'Cosafe Desktop App';
      ipcRendererSend('on-versions-compare');
      ipcRenderOn('on-update-available', () => showAppUpdatePopup());
      ipcRenderOn('on-update-not-available', () => compareVersionsAfter24Hours());
      ipcRenderOn('sso-auth', (payload: any) => handleSso(payload));
      ipcRenderOn('on-pushy-connectivity-change', (payload: PushyConnectionResponse) =>
        handleConnectionChanges(payload.isConnected)
      );
    }
  };

  useEffect(() => {
    dispatch(updateUser(window.location.pathname.includes('message/')));
    onElectronAppStart();
    switchToBrowserLanguage();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    const getUnread = () => {
      dispatch(fetchTotalUnread());
    };
    if (user && isOnline) {
      reloadTranslationResources();
      handleLanguage();
      if (!isDesktop()) {
        interval = setInterval(getUnread, 15000);
      } else {
        getUnread();
      }
    }
    if (!isOnline) {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isOnline]);

  // Renders
  if (!mountedComponent) return <div />;
  if (isAppLoading) return <Splash />;

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      <I18nextProvider i18n={i18n}>
        <Suspense fallback="loading">
          <MapTypeContextProvider>
            <RequireCookies>
              <Router>
                <OpenMobileApp>
                  <CheckAppAvailable>
                    <Routes>
                      {public_routes.map((route, key) => (
                        <Route
                          key={`public-${key}`}
                          path={route.path}
                          element={<ErrorHandler>{route.element}</ErrorHandler>}
                        />
                      ))}
                      {auth_routes.map((route, key) => (
                        <Route
                          key={`auth-${key}`}
                          path={route.path}
                          element={
                            <ErrorHandler>
                              <RequireAuth>{route.element}</RequireAuth>
                            </ErrorHandler>
                          }
                        />
                      ))}
                    </Routes>
                  </CheckAppAvailable>
                </OpenMobileApp>
              </Router>
            </RequireCookies>
          </MapTypeContextProvider>
        </Suspense>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
