import isElectron from 'is-electron';
import styled from 'styled-components';

const SBackdrop = styled.div<{ customZIndex?: number }>`
  //TODO: replace rgba static color after polished
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: ${props => (props.customZIndex ? props.customZIndex : isElectron() ? 99 : 998)};
  left: 0;
  top: 0;
`;

interface BackdropProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>> | ((arg0: boolean) => void);
  zIndex?: number;
}

export const Backdrop = ({ setModal, zIndex }: BackdropProps) => {
  return <SBackdrop onClick={() => setModal(false)} customZIndex={zIndex} />;
};
