import { indexInterface } from '../groupsAPI/types';

export enum GetChecklistType {
  active,
  templates,
  ended,
}
export enum GetChecklistSort {
  name = 'name',
  nameDesc = 'nameDesc',
  createdDate = 'createdDate',
  createdDateDesc = 'createdDateDesc',
  startedDate = 'startedDate',
  startedDateDesc = 'startedDateDesc',
  endedDate = 'endedDate',
  endedDateDesc = 'endedDateDesc',
}
export enum ChecklistCreatedByFilters {
  my = 'my',
  others = 'others',
  shared = 'shared',
}

export interface GetChecklistProps extends indexInterface {
  search?: string;
  skip?: number;
  limit?: number;
  sort?: GetChecklistSort;

  type?: GetChecklistType[];

  my?: boolean;
  others?: boolean;
  shared?: boolean;
}
