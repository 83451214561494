import styled, { keyframes } from 'styled-components';
import { SCircleCheckBox } from './SRoundCheckBox';

const bounce = keyframes`
    50% {
        transform:   scale(1.2);
    }
    75% {
        transform:   scale(.9);
    }
    100% {
        transform:  scale(1);
    }
`;

export const SFilledBoxCheckBox = styled(SCircleCheckBox)`
  border-radius: 0.25rem;
  border: 1.5px solid var(--border);

  &::after {
    border-radius: 0.125rem;
  }

  &:not(:disabled):hover {
    --shadow-spread: 1px;
  }

  &:checked::after {
    animation: ${bounce} 0.4s linear forwards 0.2s;
  }
`;
