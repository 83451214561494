/* eslint-disable import/named */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';
import { TestAlarmState } from './types';

const initialState: TestAlarmState = {
  testId: null,
  isLoading: false,
};

const testAlarmSlice = createSlice({
  name: 'tesAlarm',
  initialState,
  reducers: {
    setTestId: (state, action: PayloadAction<number>) => {
      state.testId = action.payload;
    },
    setIsTestAlarmLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setTestId, setIsTestAlarmLoading } = testAlarmSlice.actions;

export const getTestId = (state: RootState) => state.testAlarm.testId;
export const getIsLoading = (state: RootState) => state.testAlarm.isLoading;

export default testAlarmSlice.reducer;
