import {
  addMessageReaction,
  addMessageReplyReaction,
  deleteMessageReaction,
  deleteMessageReplyReaction,
  editMessageReaction,
  editMessageReplyReaction,
  GetChatsProps,
} from '../../apis/chatAPI';
import { AppDispatch, AppThunk } from '../../store';
import { initialPagingValues } from './ChatsList';
import { setChats, setIncomingMessages, setIncomingUpdateMessageID } from './chatListSlice';
import {
  MessageInList,
  MessageReactionsListItem,
  MessageReactionType,
} from './chatListSlice/types';

export const updateOneMessageInList = (
  prevChats: MessageInList[],
  updateOneChatIndex: number,
  chats: MessageInList[],
  dispatch: AppDispatch,
  props: GetChatsProps,
  fetchChatsCallBack: (
    props: GetChatsProps,
    isPaging?: boolean,
    updateOneChatIndex?: number
  ) => AppThunk
): void => {
  const copyChats = prevChats.slice();
  // check if the chat item we fetched match the one we are targeting (using messageIndex in current list, skip and take properties)
  // if not (that should be an edge case)
  if (copyChats[updateOneChatIndex].id === chats[0].id) {
    copyChats[updateOneChatIndex] = chats[0];
    dispatch(setChats(copyChats));
    dispatch(setIncomingUpdateMessageID(undefined));
  } else {
    // the chat item we fetched match doesn't match the one we are targeting
    // if Not, it means that current message list we have is not updated and not synced with backend
    // so we will update it
    dispatch(setIncomingUpdateMessageID(undefined));
    dispatch(
      fetchChatsCallBack({
        ...props,
        ...initialPagingValues,
      })
    );
  }
};

export const dispatchUpdateMessageList = (
  willMessagesListIndexesChange: boolean,
  dispatch: AppDispatch,
  id?: number
) => {
  if (willMessagesListIndexesChange) {
    // update the whole list
    dispatch(setIncomingMessages(true));
  } else {
    // update a specific messageID
    dispatch(setIncomingUpdateMessageID(id));
  }
};

export const getReactionsAfterDeletingReaction = async (
  chatId: number,
  messageOrReplyId: number,
  reactionTypeId: string,
  currentReactionListItem: MessageReactionsListItem | null,
  currentReactionListItemIndex: number,
  messageReactions: MessageReactionsListItem[]
) => {
  const messageReactionsList = [...messageReactions];
  const isMessageReaction = chatId === messageOrReplyId;

  if (currentReactionListItem) {
    if (
      currentReactionListItem.reactionTypeId === reactionTypeId &&
      currentReactionListItem.currentUserReactionId
    ) {
      if (isMessageReaction) {
        await deleteMessageReaction(chatId, currentReactionListItem.currentUserReactionId);
      } else {
        await deleteMessageReplyReaction(
          chatId,
          messageOrReplyId,
          currentReactionListItem.currentUserReactionId
        );
      }
    }
    const currentReactionListItemCount =
      messageReactionsList[currentReactionListItemIndex]?.count - 1;
    messageReactionsList[currentReactionListItemIndex] = {
      ...messageReactionsList[currentReactionListItemIndex],
      count: currentReactionListItemCount > 0 ? currentReactionListItemCount : 0,
      currentUserReactionId: null,
    };
  }
  return messageReactionsList;
};

export const getReactionsAfterEditingReaction = async (
  chatId: number,
  messageOrReplyId: number,
  messageReactions: MessageReactionsListItem[],
  currentReactionListItem: MessageReactionsListItem | null,
  reactionType?: MessageReactionType
) => {
  const messageReactionsList = [...messageReactions];
  const isMessageReaction = chatId === messageOrReplyId;
  const reactionTypeId = reactionType?._Id;
  if (!reactionType || !reactionTypeId) return messageReactionsList as MessageReactionsListItem[];
  const plannedReactionListItemIndex = messageReactionsList.findIndex(
    item => item.reactionTypeId === reactionTypeId
  );

  if (currentReactionListItem?.reactionTypeId !== reactionTypeId) {
    let response;
    if (currentReactionListItem) {
      const currentUserReactionId = currentReactionListItem.currentUserReactionId;
      if (!currentUserReactionId) return;
      response = isMessageReaction
        ? await editMessageReaction(chatId, currentUserReactionId, reactionTypeId)
        : await editMessageReplyReaction(
            chatId,
            messageOrReplyId,
            currentUserReactionId,
            reactionTypeId
          );
    } else {
      response = isMessageReaction
        ? await addMessageReaction(chatId, reactionTypeId)
        : await addMessageReplyReaction(chatId, messageOrReplyId, reactionTypeId);
    }

    if (plannedReactionListItemIndex > -1) {
      const plannedReactionListItemCount =
        messageReactionsList[plannedReactionListItemIndex]?.count + 1;
      messageReactionsList[plannedReactionListItemIndex] = {
        ...messageReactionsList[plannedReactionListItemIndex],
        count: plannedReactionListItemCount > 0 ? plannedReactionListItemCount : 1,
        currentUserReactionId: response._Id,
      };
    } else {
      messageReactionsList.push({
        reactionTypeId: reactionTypeId,
        reactionTypeUnicode: reactionType.unicode,
        count: 1,
        currentUserReactionId: response._Id,
      });
    }
  }
  return messageReactionsList as MessageReactionsListItem[];
};
