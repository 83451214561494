import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { useMapTypeContext } from '../../utils/customHooks/MapTypeContext';
import { ReactComponent as MapLayer } from '../../assets/imgs/map/map-layer.svg';

const SMapTypeButton = styled.button`
  z-index: 99 !important;
  height: 2.75rem !important;
  width: 2.75rem !important;
  border-radius: 0.75rem;
  background: ${palette.prussianBlue2};
  cursor: pointer;
`;

export const MapTypeToggleButton = () => {
  const { toggleMapType } = useMapTypeContext();

  return (
    <SMapTypeButton onClick={toggleMapType} role="mapTypeButton">
      <MapLayer role="mapTypeIcon" />
    </SMapTypeButton>
  );
};
