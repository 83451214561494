import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SFilter = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  padding: 1.25rem 1.25rem 2rem;
  transform: translate(-50%);
  background-color: ${() => palette.prussianBlue2};
  max-height: 75vh;
  width: 100%;
  max-width: 26rem;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

export const SHeader = styled.svg`
  width: 135px;
  height: 5px;
  place-self: center;
  margin-bottom: 2rem;
  border-radius: 5px;
`;

export const SLabel = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${palette.silver};
  margin: 0 0 1.8rem 0.1rem;
`;
