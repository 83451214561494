import React from 'react';
import { ImOkDocument } from '../../containers/ImOkList/imOkSlice/types';
import {
  BottomRow,
  ImOkContainer,
  LeftContainer,
  MessagesTextContainer,
  Row,
  SGroupsTitle,
  SimpleText,
  Subject,
  WhiteSpan,
} from './ImOkItem.styles';
import { useTranslation } from 'react-i18next';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import _ from 'lodash';
import { checkIfDateIsToday, dateFormats, getDateFormatCustom } from '../../utils/date';
import { translate } from '../../utils/translate';

export interface ImOkProps {
  imOk: ImOkDocument;
}

export const ImOkItem = (props: ImOkProps) => {
  const { imOk } = props;
  const { subject, ended, groups, created, name, recipients, users, id, creatorname } = imOk;
  const group = _.find(groups, g => g.id === groups[0]?.id);

  const groupsName = groups?.length ? groups[0].name : '';

  const numberOfResponses = users?.filter(user => user.imok !== null).length;

  const { t } = useTranslation();
  const dateFormat = checkIfDateIsToday(created)
    ? dateFormats.simpleTime24
    : dateFormats.monthNameShortDateTimeNoComma24;

  return (
    <ImOkContainer to={`/muster/${id}`} role={`imOkItem-${name}`} id={`imOkItem-${id}`}>
      <Row role="imOkHeaderRow">
        <Subject role="imOkSubject" ended={ended}>
          {subject || translate(`imOk_title`)}
        </Subject>
        <SimpleText
          role="imOkRecipientsCount"
          fontSize={'0.7rem'}
        >{`${numberOfResponses}/${recipients} ${t('imOk_okay')}`}</SimpleText>
      </Row>
      <BottomRow role="imOkInfoRow">
        <LeftContainer role="imOkImageContainer">
          <ProfilePicture profilePictureFileName={group?.image || null} isGroup />
        </LeftContainer>
        <MessagesTextContainer role="imOkInfoTextContainer">
          <SGroupsTitle role="imOkGroupsList">{groupsName}</SGroupsTitle>
          <Row role="imOkCreatorRow">
            <SimpleText role="imOkCreator" fontSize={'0.7rem'} gray>
              {creatorname}: <WhiteSpan>{name}</WhiteSpan>
            </SimpleText>
            <SimpleText role="imOkDate" fontSize={'0.6rem'}>
              <WhiteSpan>{getDateFormatCustom(created, dateFormat)}</WhiteSpan>
            </SimpleText>
          </Row>
        </MessagesTextContainer>
      </BottomRow>
    </ImOkContainer>
  );
};
