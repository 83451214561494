import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SPage = styled(Page)`
  display: flex;
  flex-direction: column;
`;

export const SFilterButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 2.75rem;
  font-size: 1rem;
  color: ${palette.white};
  background-color: ${palette.prussianBlue2};
  border: 1px solid ${palette.queenBlue};
  border-radius: 0.75rem;
  margin-bottom: 1rem;

  svg {
    margin-left: 0.5rem;
  }
`;

export const SNewsList = styled.div`
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SDrawer = styled.div`
  .customDrawer {
    background-color: ${palette.prussianBlue2};
    padding: 1.25rem 2rem ${toRem(30)};
    left: 0;
    right: 0;
    bottom: 0;
    width: 40vw;
    max-height: 75vh;
    min-width: 26rem;
    max-width: 26rem;
    margin: auto;
    z-index: 9999;
    // margin-bottom: 76px;
  }
  .customDrawer__content {
    padding: 0;
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
    > div {
      height: 100%;
    }
  }
  .customDrawer__handle-wrapper {
    display: none;
  }
`;
