import { getDeviceToken } from '../../notifications/device.service';
import { SelectListUser } from '../CreateMessage/createMessageSlice/types';
import { GroupMember } from '../GroupDetail/groupDetailSlice/types';
import { Group } from '../GroupsList/groupsSlice/types';
import { mapGroupMemberToSelectUser } from '../MemberSettings/helpers';
import { LoneWorkerPersonalAlarm, Time } from './loneWorkerTimerSlice/types';

export interface LwtPreperationData {
  selectedGroups?: Group[];
  selectedUsers: SelectListUser[];
  timeSelected?: Time;
  messageText: string;
  oneMinuteWarning?: boolean;
}

export const calculateSecondsBetweenTwoDateTimes = (earliestDate: Date, latestDate: Date) => {
  return (+latestDate - +earliestDate) / 1000;
};

export const prepareLwtData = (data: LwtPreperationData) => {
  const { timeSelected, selectedGroups = [], messageText, oneMinuteWarning, selectedUsers } = data;
  const dispatchTime = new Date();
  const secondsToAdd = timeSelected ? (timeSelected?.hours * 60 + timeSelected?.minutes) * 60 : 0;
  dispatchTime.setSeconds(dispatchTime.getSeconds() + secondsToAdd);
  const alarmData: LoneWorkerPersonalAlarm = {
    groupIDs: selectedGroups.map((group: Group) => group.id),
    deviceId: getDeviceToken(),
    text: messageText,
    oneMinuteWarning: oneMinuteWarning ?? false,
    locationId: null,
    dispatchTime,
  };
  alarmData.recipientIds = selectedUsers.map(user => user.id);

  return alarmData;
};

export const getRecipientsText = (
  selectedGroups: Group[],
  selectedUsers: SelectListUser[],
  authUserId: number
) => {
  let text = '';
  if (selectedGroups.length || selectedUsers.length) {
    const groupsNames = selectedGroups.map(group => group.name);
    const usersNames = selectedUsers
      .filter(user => !user.groupIds?.length && authUserId !== user.id)
      .map(user => user.displayName);
    text = [...groupsNames, ...usersNames].join(', ');
    if (text?.length) {
      text = text.replace(/,\s*$/, '');
    }
  }
  return text;
};

export const getIfCanActivateLwt = (data: LwtPreperationData, authUserId: number) => {
  const { timeSelected, messageText, selectedUsers } = data;
  return Boolean(
    timeSelected &&
      (timeSelected.hours || timeSelected.minutes) &&
      messageText.length &&
      selectedUsers.filter(user => authUserId !== user.id).length
  );
};

export const addGroupMembersToSelectedAndGetList = (
  members: GroupMember[],
  selectedUsersList: SelectListUser[]
) => {
  const selectedUsers = [...selectedUsersList];
  members.forEach(member => {
    const userIndex = selectedUsers.findIndex(user => user.id === member.userID);
    if (!member.groupID) {
      return;
    }
    if (userIndex > -1) {
      const newUser = { ...selectedUsers[userIndex] };
      newUser.groupIds = newUser.groupIds
        ? [...newUser.groupIds, member.groupID]
        : [member.groupID];
      selectedUsers[userIndex] = newUser;
    } else {
      const selectedUser: SelectListUser = mapGroupMemberToSelectUser(member, {
        isSelected: true,
        groupIds: [member.groupID],
      });
      selectedUsers.push(selectedUser);
    }
  });
  return selectedUsers;
};

export const getSelectedUsersAfterGroupUnselection = (
  selectedUsersList: SelectListUser[],
  groupId: number
) => {
  const usersInSelectedGroup = selectedUsersList.filter(user => user.groupIds?.includes(groupId));
  const usersNotInSelectedGroup = selectedUsersList.filter(
    user => !user.groupIds?.includes(groupId)
  );
  const newSelectedUsers = [...usersNotInSelectedGroup];
  usersInSelectedGroup.forEach(user => {
    const newUser = { ...user };
    newUser.groupIds = user.groupIds?.filter(id => id !== groupId);
    if (newUser.groupIds?.length) {
      newSelectedUsers.push(newUser);
    }
  });
  return newSelectedUsers;
};

export const getActiveGroupsAfterUserUnselection = (
  selectedUsers: SelectListUser[],
  selectedGroups: Group[],
  authUserId: number
) => {
  let activeGroupsIds: number[] = [];
  selectedUsers.forEach(user => {
    if (user.groupIds && user.id !== authUserId) {
      activeGroupsIds = [...activeGroupsIds, ...user.groupIds];
    }
  });
  const uniqueActiveGroupsIds = Array.from(new Set(activeGroupsIds));
  return selectedGroups.filter(group => uniqueActiveGroupsIds.includes(group.id));
};
