import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const Chip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  p {
    color: ${palette.ashesGray};
    font-size: ${toRem(13)};
    font-weight: 400;
    line-height: ${toRem(18)};
    font-family: 'Roboto-Regular';
  }
`;

const circleStyles = css`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerCircle = styled.div<{ $isLarge: boolean }>`
  width: ${props => (props.$isLarge ? toRem(8) : toRem(6))};
  height: ${props => (props.$isLarge ? toRem(8) : toRem(6))};
  background-color: ${palette.white};
  ${circleStyles};
`;

export const OuterCircle = styled.div<{ $isAvailable?: boolean; $isLarge: boolean }>`
  height: ${props => (props.$isLarge ? toRem(16) : toRem(14))};
  width: ${props => (props.$isLarge ? toRem(16) : toRem(14))};
  margin-right: ${toRem(6)};
  ${props =>
    props.$isAvailable
      ? css`
          background-color: ${palette.appleGreen};
        `
      : css`
          border: 4px solid ${palette.queenBlue};
        `};
  ${circleStyles};
`;
