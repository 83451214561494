import { useNavigate } from 'react-router-dom';
import {
  calculateAllAlarmSteps,
  handleNavigate,
} from '../../containers/Alarm/AlarmSlice/actionCreators';
import { EmergencyType } from '../../containers/Alarm/AlarmSlice/types';
import { selectUser } from '../../containers/Login/LoginSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { AlarmContainer, SNameContainer, SName } from './AlarmType.styles';
import { BASE_URL } from '../../apis/utils/provider';
import { AlarmStep } from '../../utils/enums';
import { selectAlarmGroups } from '../../containers/Alarm/AlarmSlice';
import { useConfirmation } from '../../utils/ConfirmationServiceContext/confirmationContext';
import { getAlarmTitle } from './helpers';

interface AlarmTypeProps {
  alarmTypeItem: EmergencyType;
  currentLang: string;
}

function AlarmType(props: AlarmTypeProps) {
  const dispatch = useAppDispatch();
  const { alarmTypeItem, currentLang } = props;
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const selectedGroups = useAppSelector(selectAlarmGroups);
  const confirm = useConfirmation();
  const alarmTitle = getAlarmTitle(alarmTypeItem, currentLang);

  const handleTypeClick = () => {
    dispatch(calculateAllAlarmSteps(alarmTypeItem));
    dispatch(
      handleNavigate(
        user?.id,
        selectedGroups,
        alarmTypeItem,
        navigate,
        AlarmStep.SelectType,
        confirm
      )
    );
  };

  return (
    <div className="item">
      <AlarmContainer
        color={alarmTypeItem.Color}
        onClick={handleTypeClick}
        id={`alarm-${alarmTypeItem.ID}`}
        role={`alarm-${alarmTitle}`}
      >
        <SNameContainer id="infoContainer">
          <SName role="title">{alarmTitle}</SName>
        </SNameContainer>
        {alarmTypeItem.Icon && (
          <img role="alarmIcon" src={`${BASE_URL}/media/file/${alarmTypeItem.Icon}`} alt="" />
        )}
      </AlarmContainer>
    </div>
  );
}

export default AlarmType;
