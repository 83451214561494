import * as React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { STextField, STextContainer } from './ConfirmDialog.styles';
import {
  SDialog,
  SOverLay,
  SCancelButton,
  SConfirmButton,
  STitle,
  SButtonWrapper,
  SDescription,
  SModalWrapper,
} from './style';

interface IConfirmDialogProps {
  isOpen: boolean;
  title: string;
  description?: string;
  onSubmit?: (text?: string) => void;
  onCancel?: () => void;
  dismiss: () => void;
  confirmText?: string;
  cancelText?: string;
  isLoading?: boolean;
  inputBox?: boolean;
  initialInputValue?: string;
  placeholderTx?: string;
  confirmStyle?: 'standard' | 'green' | 'red' | 'fit-big-text';
  descriptionStyle?: 'fit-big-text';
  blockOutsideClick?: boolean;
}

const ConfirmDialog = (props: IConfirmDialogProps) => {
  const {
    isOpen,
    title,
    description,
    onSubmit,
    onCancel,
    confirmText,
    cancelText,
    dismiss,
    inputBox,
    placeholderTx,
    confirmStyle,
    descriptionStyle,
    initialInputValue,
    blockOutsideClick,
  } = props;

  const { t } = useTranslation();
  const titleTx = title ? t(`${title}`) : null;
  const confirmTx = confirmText ? t(`${confirmText}`) : t('ok');
  const cancelTx = cancelText ? t(`${cancelText}`) : t('cancel');
  const descTx = t(`${description}`);

  const dialogRef = React.useRef<HTMLDivElement>(null);
  const [text, setText] = React.useState<string | undefined>(initialInputValue);

  const handleClickOutside = (e: MouseEvent) => {
    if (dialogRef && !dialogRef.current?.contains(e.target as Node)) {
      dismiss();
    }
  };

  React.useEffect(() => {
    setText(initialInputValue);
  }, [initialInputValue]);

  React.useEffect(() => {
    if (document.addEventListener && !blockOutsideClick) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (document.removeEventListener) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockOutsideClick]);

  const handleSubmit = (submitText?: string) => {
    if (onSubmit) {
      if (inputBox) {
        if (submitText) {
          onSubmit(submitText);
          setText('');
        }
      } else {
        onSubmit();
      }
    }
  };

  return isOpen
    ? ReactDOM.createPortal(
        <React.Fragment>
          <SOverLay />
          <SModalWrapper role="confirmationDialog">
            <SDialog ref={dialogRef} role="dialogContent">
              <STextContainer role="textContainer">
                {titleTx && (
                  <STitle data-e2e={`${title ?? 'dialog'}_title`} role="title">
                    {titleTx}
                  </STitle>
                )}
                {description && (
                  <SDescription descriptionStyle={descriptionStyle} role="description">
                    {descTx}
                  </SDescription>
                )}
              </STextContainer>
              {inputBox && (
                <form
                  role="inputForm"
                  onSubmit={e => {
                    e.preventDefault();
                    handleSubmit(text);
                  }}
                >
                  <STextField
                    role="inputField"
                    type="text"
                    placeholder={t(`${placeholderTx}`)}
                    value={text}
                    onChange={e => {
                      setText(e.target.value);
                    }}
                  />
                </form>
              )}
              <SButtonWrapper role="buttonsWrapper">
                {onCancel && (
                  <SCancelButton
                    data-e2e={`${title ?? 'dialog'}_cancelButton`}
                    role="cancelButton"
                    onClick={onCancel}
                  >
                    {cancelTx}
                  </SCancelButton>
                )}
                {onSubmit && (
                  <SConfirmButton
                    data-e2e={`${title ?? 'dialog'}_confirmButton`}
                    role="confirmButton"
                    onClick={() => handleSubmit(text)}
                    confirmStyle={confirmStyle}
                  >
                    {confirmTx}
                  </SConfirmButton>
                )}
              </SButtonWrapper>
            </SDialog>
          </SModalWrapper>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default ConfirmDialog;
