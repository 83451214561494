import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const SDotsContainers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.625rem;

  .dot-active {
    min-width: 1.75rem;
    background-color: ${palette.honeyYellow};
  }
`;

export const SDot = styled.li`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 9990px;
  background-color: ${palette.gainsBoro4};

  list-style: none;
`;
