export const useImageSize = () => {
  interface ImageSize {
    naturalWidth: number;
    naturalHeight: number;
  }

  const getImageSizes = async (imageSource: string): Promise<ImageSize> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageSource;

      img.onload = function () {
        const naturalWidth = img.naturalWidth;
        const naturalHeight = img.naturalHeight;
        resolve({ naturalWidth, naturalHeight });
      };

      img.onerror = function () {
        reject(new Error('Failed to load image'));
      };
    });
  };

  const getImageHeightByWidth = async (imageSource: string, width: number) => {
    try {
      const { naturalWidth, naturalHeight } = await getImageSizes(imageSource);
      return (width * naturalHeight) / naturalWidth;
    } catch (error) {
      return 'auto';
    }
  };

  return { getImageSizes, getImageHeightByWidth };
};
