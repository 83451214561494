import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';

export const SPage = styled(Page)`
  padding-bottom: 0;
`;

export const SOverview = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

export const SLine = styled.hr`
  margin: 1.25rem 0;
  width: 100%;
  border: 1px solid ${palette.tinyBorder};
`;

export const STitle = styled.p`
  font-family: 'Roboto-Bold';
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const SList = styled.div`
  height: 100%;
  min-height: 0;

  /* vertical scrolling + hide scroller bar  */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
