import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setIsAppLoading } from './containers/App/AppSlice';
import { store } from './store';
import { getItem } from './utils/storage';

export const resources = {};

export enum ELanguages {
  'se' = 'se',
  'pt' = 'pt',
  'nl' = 'nl',
  'es' = 'es',
  'en' = 'en',
  'en-TT' = 'en-TT',
}
export const EPhoneCodes: Record<string, string> = {
  se: 'SE',
  nl: 'NL',
  pt: 'PT',
  es: 'ES',
};

export const SupportLanguageCodes: Record<string, string> = {
  se: 'sv',
  en: 'en',
  'en-TT': 'en',
  nl: 'nl',
  es: 'es',
  pt: 'pt-BR',
};

export const defaultLanguage =
  process.env.REACT_APP_DEFAULT_LANGUAGE! === 'SE' ? ELanguages.se : ELanguages.pt;

i18n.use(initReactI18next).init({
  lng: getItem('language') || defaultLanguage,
  defaultNS: 'translations',
  fallbackLng: defaultLanguage,
  resources,
  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

// there is an issue happening when we run the tests, this function depend on the env variables,
// for getting the translations links, and the env are not available when we run the tests
// even though we are mocking the env variables in the setupTests.js file but seems that this file is loading first
// before the mock took place/applied, so we are using a fallback url for the tests
const fallbackURL = 'https://translation-tool.cosafe.com/Cosafe/texts_export.php?lang=en';

const getTranslation = async (lang: ELanguages) => {
  if (process.env.REACT_APP_DEFAULT_LANGUAGE! === 'SE') {
    const translationsUrl =
      lang === ELanguages.en
        ? process.env.REACT_APP_TRANSLATION_URL_EN!
        : lang === ELanguages.nl
        ? process.env.REACT_APP_TRANSLATION_URL_NL!
        : process.env.REACT_APP_TRANSLATION_URL_SE!;
    const res = await axios.get(translationsUrl ?? fallbackURL);
    return res.data;
  } else {
    let translationUrl;

    if (lang === ELanguages.es) {
      translationUrl = process.env.REACT_APP_TRANSLATION_URL_ES!;
    } else if (lang === ELanguages['en-TT']) {
      translationUrl = process.env.REACT_APP_TRANSLATION_URL_EN!;
    } else {
      translationUrl = process.env.REACT_APP_TRANSLATION_URL_PT!;
    }

    const res = await axios.get(translationUrl ?? fallbackURL);
    return res.data;
  }
};

export const addTranslations = async () => {
  try {
    if (process.env.REACT_APP_DEFAULT_LANGUAGE! === 'SE') {
      const [enTranslations, seTranslations, nlTranslations] = await Promise.all([
        getTranslation(ELanguages.en),
        getTranslation(ELanguages.se),
        getTranslation(ELanguages.nl),
      ]);
      i18n.addResourceBundle(ELanguages.en, 'translations', enTranslations);
      i18n.addResourceBundle(ELanguages.se, 'translations', seTranslations);
      i18n.addResourceBundle(ELanguages.nl, 'translations', nlTranslations);
    } else {
      const [ptTranslations, esTranslations, enTranslations] = await Promise.all([
        getTranslation(ELanguages.pt),
        getTranslation(ELanguages.es),
        getTranslation(ELanguages['en-TT']),
      ]);
      i18n.addResourceBundle(ELanguages.pt, 'translations', ptTranslations);
      i18n.addResourceBundle(ELanguages.es, 'translations', esTranslations);
      i18n.addResourceBundle(ELanguages['en-TT'], 'translations', enTranslations);
    }
  } catch (error) {
    console.error(error);
  }
  store.dispatch(setIsAppLoading(false));
};

export const getResourceBundle = async (lang: ELanguages = ELanguages.en) => {
  const response = await i18n.getResourceBundle(lang, 'translations');
  return response;
};

addTranslations();

export default i18n;
