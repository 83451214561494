const httpRootRegex = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+\.+[^:/?\n]+)/g;

export const formatUrlAsDmainName = (fullUrl: string): string | undefined => {
  if (!fullUrl || typeof fullUrl !== 'string') return undefined;

  let hostname: string | undefined;
  try {
    hostname = fullUrl.match(httpRootRegex)?.[0];
    if (hostname) hostname = hostname.replace('www.', '').replace(/https?:\/\//g, '');
  } catch (error) {
    console.error(error);
  }

  return hostname || undefined;
};
