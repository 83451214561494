import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../assets/imgs/chats/arrow.svg';
import QuestionIcon from '../../assets/imgs/general/question.svg';
import Loader from '../../components/Loader/Loader';
import { LoneWorkerTimerDescriptionBottomSheet } from '../../components/LoneWorkerTimerDescriptionBottomSheet/LoneWorkerTimerDescriptionBottomSheet';
import MapAlert from '../../components/Map/MapAlert';
import { TimeSelectionBottomSheet } from '../../components/TimeSelectionBottomSheet/TimeSelectionBottomSheet';
import { SDrawerWrapper } from '../../components/cec/CecTextTemplatesBottomSheet/CecTextTemplatesBottomSheet';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useLayoutContext } from '../../utils/customHooks/LayoutContext';
import { defaultLat, defaultLng } from '../../utils/geocoder';
import { translate } from '../../utils/translate';
import { selectUser } from '../Login/LoginSlice';
import {
  SActivateButton,
  SActivateButtonContainer,
  SBannerText,
  SDivider,
  SFeatureButton,
  SFeatureButtonTextBox,
  SFeatureTitle,
  SIcon,
  SInfoBanner,
  SLearnMoreText,
  SMessageInput,
  SMessageInputWrapper,
  SNotifySwitch,
  SNotifySwitchContainer,
  SPage,
  SQuestionIcon,
  SRow,
  SScreenTitle,
} from './LoneWorkerTimer.styles';
import { getIfCanActivateLwt, getRecipientsText, prepareLwtData } from './helpers';
import {
  getIsLoading,
  getIsLocationDenied,
  getLocation,
  getMessageText,
  getOneMinuteWarning,
  getSelectedGroups,
  getSelectedUsers,
  getTime,
  setIsLocationDenied,
  setLocationCoords,
  setMessageText,
  setOneMinuteWarning,
  setTime,
} from './loneWorkerTimerSlice';
import { createAlarm } from './loneWorkerTimerSlice/actionCreator';
import { LoneWorkerPersonalAlarm } from './loneWorkerTimerSlice/types';

export const LoneWorkerTimerCreateAlarm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const layout = useLayoutContext();
  const authUser = useAppSelector(selectUser);
  const isLocationDenied = useAppSelector(getIsLocationDenied);
  const isLoading = useAppSelector(getIsLoading);
  const selectedLocation = useAppSelector(getLocation);
  const timeSelected = useAppSelector(getTime);
  const messageText = useAppSelector(getMessageText);
  const selectedGroups = useAppSelector(getSelectedGroups);
  const selectedUsers = useAppSelector(getSelectedUsers);
  const oneMinuteWarning = useAppSelector(getOneMinuteWarning);
  const [messageInputFocus, setMessageInputFocus] = useState(false);
  const [isTimeSelectionOpen, setIsTimeSelectionOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [locationAlarmModalOpen, setLocationAlarmModalOpen] = useState(false);
  const [location, setLocation] = useState<google.maps.LatLngLiteral | undefined>({
    lat: defaultLat,
    lng: defaultLng,
  });
  const [locName, setLocName] = useState('');
  const canActivate: boolean = useMemo(() => {
    return getIfCanActivateLwt({ timeSelected, messageText, selectedUsers }, authUser?.id ?? 0);
  }, [timeSelected, messageText, selectedUsers, authUser?.id]);

  const recipientsText = useMemo(() => {
    return getRecipientsText(selectedGroups, selectedUsers, authUser?.id ?? 0);
  }, [authUser?.id, selectedGroups, selectedUsers]);

  const askLocationPermission = () => {
    setLocationAlarmModalOpen(true);
  };

  useEffect(() => {
    askLocationPermission();
  }, []);

  const onLocationConfirm = async () => {
    layout.setMessage('lone_worker_timer');
    if (!location?.lat || !location?.lng) {
      return;
    }
    setTimeout(() => {
      dispatch(
        setLocationCoords({
          longitude: location?.lng,
          latitude: location?.lat,
          name: locName || `${location?.lat} | ${location?.lng}`,
        })
      );
      setLocationAlarmModalOpen(false);
    }, 50);
  };

  const onLocationDecline = () => {
    setLocationAlarmModalOpen(false);
    dispatch(setIsLocationDenied(true));
    layout.setMessage('lone_worker_timer');
  };

  const setHoursAndMinutes = (hours: number, minutes: number) => {
    setIsTimeSelectionOpen(!isTimeSelectionOpen);
    dispatch(setTime({ hours, minutes }));
  };

  const activateAlarm = () => {
    if (canActivate) {
      const alarmData: LoneWorkerPersonalAlarm = prepareLwtData({
        timeSelected,
        selectedGroups,
        messageText,
        oneMinuteWarning,
        selectedUsers,
      });
      dispatch(createAlarm(alarmData, selectedLocation, navigate));
    }
  };

  if (isLoading) {
    return (
      <SPage>
        <Loader />
      </SPage>
    );
  }

  if (locationAlarmModalOpen && !selectedLocation && !isLocationDenied) {
    return (
      <MapAlert
        location={location}
        setLocation={setLocation}
        setLocName={setLocName}
        onClickDecline={onLocationDecline}
        onClickAccept={onLocationConfirm}
      />
    );
  }

  return (
    <SPage>
      <div className="mainContainer" role="createLwtContainer">
        <SInfoBanner role="infoBanner">
          <SRow role="textRow">
            <SQuestionIcon src={QuestionIcon} role="questionIcon" />
            <SBannerText role="bannerText">{t('lone_worker_timer_header')}</SBannerText>
          </SRow>
          <SLearnMoreText onClick={() => setIsDescriptionOpen(true)} role="learnMoreButton">
            {t('learn_more')}
          </SLearnMoreText>
        </SInfoBanner>
        <SScreenTitle role="timerSettingsTitle">{t('timer_settings')}</SScreenTitle>
        <SFeatureTitle role="timerLabel">{t('alarm_timer_ends_in')}</SFeatureTitle>
        <SFeatureButton
          onClick={() => setIsTimeSelectionOpen(true)}
          isActive={isTimeSelectionOpen}
          role="timerFeatureButton"
        >
          <SFeatureButtonTextBox
            isFilled={timeSelected && (timeSelected?.hours > 0 || timeSelected?.minutes > 0)}
            role="timerFeatureText"
          >
            {timeSelected?.hours || '00'} {t('alarm_hours').toLowerCase()}{' '}
            {timeSelected?.minutes || '00'} {t('alarm_minutes').toLowerCase()}
          </SFeatureButtonTextBox>
          <SIcon src={Arrow} alt="" role="arrowIcon" />
        </SFeatureButton>
        <SFeatureTitle role="recipientsLabel">{t('send_emergency_alarm_to')}</SFeatureTitle>
        <SFeatureButton
          onClick={() => navigate('/aloneWorker/create/select-recipients')}
          role="recipientsFeatureButton"
        >
          <SFeatureButtonTextBox isFilled={recipientsText.length > 0} role="recipientsFeatureText">
            <p role="recipientsText">
              {recipientsText.length ? recipientsText : t('choose_groups_and_recipients')}
            </p>
          </SFeatureButtonTextBox>
          <SIcon src={Arrow} alt="" role="arrowIcon" />
        </SFeatureButton>
        <SFeatureTitle role="messageLabel">{t('emergency_alarm_message')}</SFeatureTitle>
        <SMessageInputWrapper haveFocus={messageInputFocus} role="messageInputContainer">
          <SMessageInput
            onFocus={() => setMessageInputFocus(true)}
            onBlur={() => setMessageInputFocus(false)}
            value={messageText}
            onChange={e => {
              dispatch(setMessageText(e.target.value));
            }}
            placeholder={translate('emergency_help_msg')}
            role="messageInput"
          ></SMessageInput>
        </SMessageInputWrapper>
        <SDivider role="divider"></SDivider>
        <SNotifySwitchContainer
          onClick={() => dispatch(setOneMinuteWarning(!oneMinuteWarning))}
          role="notifySwitchContainer"
        >
          <h4 role="label">{t('notify_timer')}</h4>
          <SNotifySwitch isEnabled={oneMinuteWarning} role="switch">
            <div className="switch-circle" role="switchCircle" />
          </SNotifySwitch>
        </SNotifySwitchContainer>
        <SDrawerWrapper role="drawerWrapper">
          <TimeSelectionBottomSheet
            isOpen={isTimeSelectionOpen}
            setIsOpen={setIsTimeSelectionOpen}
            activeCountdownSetup={setHoursAndMinutes}
            initialHours={timeSelected?.hours}
            initialMinutes={timeSelected?.minutes}
          />
          <LoneWorkerTimerDescriptionBottomSheet
            isOpen={isDescriptionOpen}
            setIsOpen={setIsDescriptionOpen}
          />
        </SDrawerWrapper>
      </div>
      <SActivateButtonContainer role="activateButtonContainer">
        <SActivateButton isActive={canActivate} onClick={activateAlarm} role="activateButton">
          {t('alarm_activate_personal_alarm')}
        </SActivateButton>
      </SActivateButtonContainer>
    </SPage>
  );
};
