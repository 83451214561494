/* eslint-disable @typescript-eslint/naming-convention */

import * as React from 'react';
import Dots from '../../assets/imgs/documents/documents_dots.svg';
import classNames from 'classnames';
import HyperLinkText from '../HyperlinkText/HyperLinkText';
import { SContainer, SGrowDiv, SRow, SMoreOptionBtn, STitle } from './TitlesWithTime.styles';

export interface ITitlesWithTimeProps {
  date: string;
  time?: string;
  title: string;
  subTitle: string;
  subTitleSender?: string;
  itemId: number;
  onMoreOptionClick?: (itemId: number) => void;
  onClick?: (itemId: number) => void;
  searchTerm?: string;
}

export const TitlesWithTime = (props: ITitlesWithTimeProps) => {
  const {
    date,
    itemId,
    subTitle,
    title,
    onMoreOptionClick,
    time,
    subTitleSender,
    onClick,
    searchTerm,
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onMoreOptionClick) {
      e.stopPropagation();
      onMoreOptionClick(itemId);
    }
  };

  const subtTitleClasses = classNames('subTitle', {
    subTitleWithSender: !!subTitleSender,
  });

  const _onClick = () => onClick && onClick(itemId);

  return (
    <SContainer
      onClick={onClick && _onClick}
      id={`titleWithTime-${itemId}`}
      role={`titleWithTime-${title}`}
    >
      <SGrowDiv role="contentContainer">
        <SRow className="top" role="topRow">
          <STitle role="title">{title}</STitle>
          <p className="date" role="date">
            {date}
          </p>
        </SRow>
        <SRow role="subtitleRow">
          <p className={subtTitleClasses} role="subTitleTextLine">
            {subTitleSender && (
              <span className="subTitleSender" role="subtitleSender">
                {subTitleSender}:{' '}
              </span>
            )}
            <HyperLinkText text={subTitle} searchTerm={searchTerm} />
          </p>
          {time && (
            <p className="time" role="time">
              {time}
            </p>
          )}
        </SRow>
      </SGrowDiv>
      {onMoreOptionClick && (
        <SMoreOptionBtn onClick={handleClick} role="moreButton">
          <img role="moreIcon" src={Dots} alt="" />
        </SMoreOptionBtn>
      )}
    </SContainer>
  );
};
