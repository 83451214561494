import styled, { css, keyframes } from 'styled-components';
import { palette } from '../../theme/colors';
import { SContainerParams } from './AlertBanner';

const slideKeyFrame = (
  params: Pick<SContainerParams, '$topToValue' | '$top'>,
  _reverse = false
) => {
  const { $topToValue, $top } = params;
  return keyframes`
    100% {
      opacity: ${_reverse ? 0 : 1};
      top: ${_reverse ? $top : $topToValue}px;
     }
`;
};

const replaceSlideKeyFrame = (
  params: Pick<SContainerParams, '$topToValue' | '$replaceToValue'>
) => {
  const { $topToValue, $replaceToValue } = params;
  return keyframes`
    50% {
      opacity: 0;
      top: ${$replaceToValue}px;
     }
    100% {
      opacity: 1;
       top: ${$topToValue}px;
     }
`;
};

export const SContainer = styled.div<SContainerParams>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 2rem);
  height: 2.25rem;

  border: 1px solid ${palette.prussianBlue2};
  border-radius: 12px;

  background-color: ${palette.prussianBlue2};

  position: absolute;

  top: ${props => `${props.$top}px`};
  opacity: 0;

  transition: all 300ms cubic-bezier(0.38, 0.8, 0.32, 1.07);

  &.containerExpanded {
    top: ${props => `${props.$topToValue}px`};
    opacity: 1;
  }

  &.containerExpandedReversed {
    animation: ${props => slideKeyFrame({ $topToValue: props.$topToValue, $top: props.$top }, true)}
      300ms cubic-bezier(0.38, 0.8, 0.32, 1.07) forwards 0.2s;
  }

  &.containerExpandedTimeout {
    animation: ${props => props.$timeoutSlide} 300ms cubic-bezier(0.38, 0.8, 0.32, 1.07) forwards 4s;
  }
  &.containerBounce {
    animation: ${props =>
        replaceSlideKeyFrame({
          $topToValue: props.$topToValue,
          $replaceToValue: props.$replaceToValue,
        })}
      200ms cubic-bezier(0.38, 0.8, 0.32, 1.07) forwards;
  }

  left: 50%;
  transform: translateX(-50%);

  z-index: 1000;
  padding: 0.5rem 0 0.5rem 0.5rem;

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-family: 'Roboto-Regular';
    font-size: 0.75rem;
    font-weight: 400;
    margin-left: 0.625rem;
    line-height: 1rem;
  }
  .dismissButton {
  }

  ${props =>
    props.$clickable &&
    css`
      cursor: pointer;
    `}
`;

export const SPlaceHolder = styled.div<{ placeholderToValue: number }>`
  height: 0;
  width: 20px;
  transition: height 200ms cubic-bezier(0.38, 0.8, 0.32, 1.07);
  &.placeholderExpanded {
    height: ${props => `${props.placeholderToValue}px`};
  }
`;

export const SSvgButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
  }
`;
