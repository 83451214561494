import { useState } from 'react';
import Arrow from '../../assets/imgs/cec/summary-right-arrow.svg';
import { SContainer, SExpandedBody, SMainWrapper, SNamTitle } from './ArrowCollapsibleCard.styles';

export interface IArrowCollapsibleCardProps {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const ArrowCollapsibleCard = (props: IArrowCollapsibleCardProps) => {
  const { title, children } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpand = () => {
    setExpanded(prev => !prev);
  };

  const renderContent = () => (
    <SExpandedBody $expanded={expanded} role="cardExpandedBody">
      {' '}
      {children}
    </SExpandedBody>
  );
  return (
    <SContainer role="collapsibleCard">
      <SMainWrapper onClick={toggleExpand} $expanded={expanded} role="cardContent">
        <SNamTitle role="title">{title}</SNamTitle>
        <div className="image" role="iconContainer">
          <img src={Arrow} alt="right-arrow" role="icon" />
        </div>
      </SMainWrapper>
      {renderContent()}
    </SContainer>
  );
};
