import { createContext, useContext, useMemo } from 'react';

interface LayoutContextProps {
  toggleTabBarVisibility: () => void;
  toggleHeaderMenuVisability: () => void;
  setTabsState: React.Dispatch<React.SetStateAction<boolean>>;
  setBackLink: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSubTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDoShowDots: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setMapOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMapOpen: boolean;
  isMenuOpen: boolean;
  currentTitle: string;
}

const layoutContextDefualtValue: LayoutContextProps = {
  toggleTabBarVisibility: () => {},
  toggleHeaderMenuVisability: () => {},
  setTabsState: () => {},
  setBackLink: () => {},
  setMessage: () => {},
  setSubTitle: () => {},
  setDoShowDots: () => {},
  setMapOpen: () => {},
  isMenuOpen: false,
  isMapOpen: false,
  currentTitle: '',
};

const LayoutContext = createContext<LayoutContextProps>(layoutContextDefualtValue);

export const useLayoutContext = () => useContext(LayoutContext);

interface LayoutContextProviderProps {
  setTabsState: React.Dispatch<React.SetStateAction<boolean>>;
  setBackLink: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSubTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDoShowDots: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setHeaderMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setMapOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMapOpen: boolean;
  isMenuOpen: boolean;
  children: React.ReactNode;
  currentTitle: string;
}
export const LayoutContextProvider = (props: LayoutContextProviderProps) => {
  const {
    setTabsState,
    children,
    setBackLink,
    setMessage,
    setSubTitle,
    setDoShowDots,
    setHeaderMenu,
    setMapOpen,
    isMapOpen,
    isMenuOpen,
    currentTitle,
  } = props;

  const toggleTabBarVisibility = () => {
    setTabsState(prev => !prev);
  };
  const toggleHeaderMenuVisability = () => {
    setHeaderMenu(prev => !prev);
  };

  const value = useMemo(
    () => ({
      toggleTabBarVisibility,
      setTabsState,
      setBackLink,
      setMessage,
      setSubTitle,
      setDoShowDots,
      toggleHeaderMenuVisability,
      setMapOpen,
      isMapOpen,
      isMenuOpen,
      currentTitle,
    }),
    [isMenuOpen, isMapOpen, currentTitle]
  );

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};
