import {
  LoneWorkerPersonalAlarm,
  LoneWorkerPersonalAlarmResponse,
  UpdateTimerData,
} from '../../containers/LoneWorkerTimer/loneWorkerTimerSlice/types';
import { getAntiForgeryToken } from '../authApi/authAPI';
import { ApiCore } from '../utils/core';

const ApiLoneWorkerTimer = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: true,
  singleExtra: true,
  url: '',
});

export const getWorkAlonePersonalAlarmStatus = async () => {
  return ApiLoneWorkerTimer.performExtra<LoneWorkerPersonalAlarmResponse>({
    extraResource: `PersonalAlarm`,
    method: 'GET',
  });
};

export const getWorkAlonePersonalAlarmById = async (alarmId?: number) => {
  return ApiLoneWorkerTimer.performExtra<LoneWorkerPersonalAlarmResponse>({
    extraResource: `PersonalAlarm/${alarmId}`,
    method: 'GET',
  });
};

export const createWorkAlonePersonalAlarm = async (alarm: LoneWorkerPersonalAlarm) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiLoneWorkerTimer.performExtra<LoneWorkerPersonalAlarmResponse>({
    extraResource: `PersonalAlarm`,
    method: 'post',
    model: { ...alarm },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const cancelWorkAlonePersonalAlarm = async () => {
  const csrfToken = await getAntiForgeryToken();

  return ApiLoneWorkerTimer.performExtra<LoneWorkerPersonalAlarmResponse>({
    extraResource: `PersonalAlarm/Cancel`,
    method: 'post',
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const setOffWorkAlonePersonalAlarm = async () => {
  const csrfToken = await getAntiForgeryToken();

  return ApiLoneWorkerTimer.performExtra<LoneWorkerPersonalAlarmResponse>({
    extraResource: `PersonalAlarm/SetOff`,
    method: 'post',
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const updateWorkAlonePersonalAlarm = async (updateTimerData: UpdateTimerData) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiLoneWorkerTimer.performExtra<LoneWorkerPersonalAlarmResponse>({
    extraResource: `PersonalAlarm/update`,
    method: 'post',
    model: { ...updateTimerData },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};
