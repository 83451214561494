import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SBottomSheet = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  padding: ${toRem(20)};
  transform: translate(-50%);
  background-color: ${palette.prussianBlue2};
  width: 100%;
  max-width: 26rem;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-top-right-radius: ${toRem(15)};
  border-top-left-radius: ${toRem(15)};
`;

export const SIntroIcon = styled.img`
  margin-bottom: ${toRem(16)};
`;

export const SCurtainHandle = styled.svg`
  width: ${toRem(135)};
  height: ${toRem(5)};
  place-self: center;
  border-radius: ${toRem(5)};
`;

export const SContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${toRem(16)};
`;

export const STitleText = styled.p`
  font-size: ${toRem(18)};
  color: ${palette.white};
  font-family: 'Roboto-Medium';
  line-height: ${toRem(28)};
  text-align: start;
  width: 100%;
`;

export const SDescriptionTextContainer = styled.div`
  font-size: ${toRem(14)};
  color: ${palette.white};
  text-align: start;
  margin-top: ${toRem(16)};
  margin-bottom: ${toRem(32)};
  min-width: 100%;
`;

export const SDescriptionTextRow = styled.div<{ $isLastRow: boolean }>`
  font-size: ${toRem(15)};
  display: flex;
  margin-bottom: ${props => (props.$isLastRow ? 0 : toRem(38))};
  color: ${palette.white};
  .numberWrapper {
    position: relative;
  }
  .progressLine {
    position: absolute;
    height: 100%;
    margin: ${toRem(5)} 0 0 ${toRem(12)};
    width: ${toRem(1)};
    background: ${props => (props.$isLastRow ? palette.transparent : palette.doveGray)};
  }
  .numberContainer {
    font-size: ${toRem(14)};
    height: ${toRem(24)};
    width: ${toRem(24)};
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: ${palette.earthYellow};
    font-family: 'Roboto-Medium';
    color: ${palette.prussianBlue2};
    margin-right: ${toRem(12)};
  }
`;
