import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';
import { TextField } from '../../components/TextField/TextField';
import { Button } from '../../components/Button/Button';

interface STextFieldParams {
  error?: boolean;
  marginTop?: string;
}

interface SButtonParams {
  $error?: boolean;
}

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const STitle = styled.h1`
  font-size: 1.125rem;
  font-family: 'Roboto-Medium';
  color: ${palette.white};
  margin: 60px auto 0 auto;
  width: 88%;
`;

export const SSubTitle = styled.h2`
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  color: ${palette.silver};
  line-height: 1.3;
  margin: 15px auto;
  width: 88%;
`;

export const SForm = styled.form`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  width: 90%;
  margin: 1rem auto;
`;

export const STextField = styled(TextField)<STextFieldParams>`
  input {
    color: ${palette.white};
    font-family: 'Roboto-Regular';
    font-size: 0.875rem;
    margin-top: ${props => props.marginTop};
    ${props =>
      props.error &&
      css`
        border-color: ${palette.tartOrange};
      `}
  }
  input::placeholder {
    font-size: 0.875rem;
    font-family: 'Roboto-Regular';
    color: ${palette.grayx11gray};
  }
`;

export const SError = styled.p`
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  color: ${palette.tartOrange};
  margin-top: 12px;
  align-self: flex-start;
  padding-left: 10px;
`;

export const SButton = styled(Button)<SButtonParams>`
  width: 100%;
  margin-top: 0.875rem;
  button {
    max-width: 200rem;
    width: 100%;
    font-size: 1rem;
    padding: 0.8125rem 0;
    font-family: 'Roboto-Medium';
    color: ${palette.raisinBlack3};
    ${props =>
      props.$error &&
      css`
        opacity: 0.5;
        cursor: default;
      `}
  }
  margin-bottom: 3.5rem;
`;
export const SStretchedSpace = styled.div`
  flex-grow: 1;
`;
