import React, { useEffect, useState } from 'react';
import Drawer from 'react-bottom-drawer';
import { ChecklistItem } from '../../containers/Checklists/checklistsSlice/types';
import { STitle, SSubTitle, SAddComment, SCommentsList } from './ChecklistBottomSheet.styles';
import { translate } from '../../utils/translate';
import { SDrawerWrapper } from '../cec/CecTextTemplatesBottomSheet/CecTextTemplatesBottomSheet';
import { ReactComponent as Add } from '../../assets/imgs/checklists/add.svg';
import { useAppDispatch } from '../../hooks';
import { sendComment } from '../../containers/Checklists/checklistsSlice/actionCreators';
import ChecklistInput from './ChecklistInput';
import ChecklistComment from './ChecklistComment';
import { ChecklistStatus } from '../../utils/enums';

export interface ChecklistBottomSheetProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  selectedItem: ChecklistItem;
  status: ChecklistStatus;
}

export const ChecklistBottomSheet = (props: ChecklistBottomSheetProps) => {
  const { isOpen, toggleIsOpen, selectedItem, status } = props;

  const dispatch = useAppDispatch();

  const [chatBoxOpen, setChatBoxOpen] = useState(false);

  useEffect(() => {
    setChatBoxOpen(false);
  }, [isOpen]);

  const handleClickAddComment = () => {
    setChatBoxOpen(!chatBoxOpen);
  };

  const addComment = (textMessage: string, photoFile?: string) => {
    const model = {
      text: textMessage,
      imageFileName: photoFile || null,
    };
    dispatch(sendComment(selectedItem.checklistID, selectedItem.id, model));
  };

  return (
    <SDrawerWrapper role="checklistBottomsheet">
      <Drawer className="profileDrawer" isVisible={isOpen} onClose={toggleIsOpen} hideScrollbars>
        <STitle role="title">{`${selectedItem.sortIndex}. ${selectedItem.name}`}</STitle>
        {selectedItem.comments.length > 0 && (
          <>
            <SSubTitle role="subtitle">{translate(`comments`)}:</SSubTitle>
            <SCommentsList role="commentsList">
              {selectedItem.comments.map(comment => (
                <ChecklistComment key={comment.id} comment={comment} />
              ))}
            </SCommentsList>
          </>
        )}
        {status !== ChecklistStatus.Ended && (
          <>
            {!chatBoxOpen ? (
              <SAddComment onClick={handleClickAddComment} role="addComment">
                <Add role="addIcon" /> {translate(`add_comment`)}
              </SAddComment>
            ) : (
              <ChecklistInput onSend={addComment} />
            )}
          </>
        )}
      </Drawer>
    </SDrawerWrapper>
  );
};
