import { translate } from '../../utils/translate';
import AvailabilityChip from '../AvailabilityChip/AvailabilityChip';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { SContainer, STextContainer } from './ReadReceiptUserThumb.styles';

export interface ReadReceiptDateElementType {
  date?: string;
  time?: string;
}

export interface IReadReceiptUserThumbProps {
  title: string;
  subTitle?: string;
  showAvailability?: boolean;
  dateElements?: ReadReceiptDateElementType;
  imageSource?: string;
}

export const ReadReceiptUserThumb = (props: IReadReceiptUserThumbProps) => {
  const { title, dateElements, imageSource, subTitle, showAvailability } = props;
  return (
    <SContainer role="ReadReceiptUserThumb">
      <ProfilePicture readyPhotoSource={imageSource} profilePictureFileName={null} diameter={36} />
      <STextContainer role="titleContainer">
        <p className="title" role="title">
          {title}
        </p>
        {subTitle && (
          <p className="subTitle" role="subtitle">
            {subTitle}
          </p>
        )}
        {showAvailability && <AvailabilityChip isAvailable={false} />}
      </STextContainer>
      <p className="date" role="dateText">
        {dateElements ? (
          <>
            {dateElements.date && dateElements.date + ', '}
            <span className="time" role="time">
              {dateElements.time}
            </span>
          </>
        ) : (
          translate('messages_notSeen')
        )}
      </p>
    </SContainer>
  );
};
