import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Subject = styled.p<any>`
  font-weight: 600;
  max-width: 15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Roboto-Regular';
  font-size: 0.7rem;
  border: solid 0.01rem;
  padding: 0.1rem 0.3rem;
  border-radius: 0.2rem;
  border-color: ${props =>
    props.ended ? props.theme.palette.border.primary : props.theme.palette.background.danger};
`;

export const SimpleText = styled.p<any>`
  margin: ${props => props.margin};
  color: ${props =>
    props.gray ? props.theme.palette.text.documentPrimary : props.theme.palette.text.menuPrimary};
  font-family: 'Roboto-Regular';
  font-size: ${props => props.fontSize};
  padding-left: 0.3rem;
  max-width: 18rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const LeftContainer = styled.p`
  margin-right: 0.5rem;
  max-height: 45px;
`;

export const MessageItemContainer = styled.div`
  border-bottom: 1px solid ${palette.queenBlue};
  padding-bottom: 5px;
  padding-top: 5px;
`;

export const MessagesTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  overflow: hidden;
  margin-right: 0.5rem;
  text-overflow: ellipsis;
`;

export const GroupsTitle = styled.h3`
  font-weight: 500;
  font-family: 'Roboto-Regular';
  font-size: 16;
  color: ${props => props.theme.palette.text.menuPrimary};
  max-width: 90%;
  padding-bottom: 0.5rem;
`;

export const MessagesText = styled.p`
  font-family: 'Roboto-Regular';
  font-size: 12;
  color: ${props => props.theme.palette.text.primary};
`;

export const WhiteSpan = styled.span`
  color: ${props => props.theme.palette.text.menuPrimary};
`;

export const BottomRow = styled.div`
  display: flex;
  align-items: center;
`;

export const SSelectAll = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 10px;
`;
