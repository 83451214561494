import { useEffect, useRef, useState } from 'react';
import { EmergencyType } from '../../containers/Alarm/AlarmSlice/types';

export const useTopBottomScrollLimit = (
  alarmLists: EmergencyType[],
  initialLimit = 18,
  scrollOffset = 250
) => {
  const alarmRef = useRef<HTMLDivElement>(null);
  const topArrowRef = useRef<HTMLDivElement>(null);
  const bottomArrowRef = useRef<HTMLDivElement>(null);
  const [limit, setLimit] = useState(initialLimit);
  const limitRef = useRef(limit);

  limitRef.current = limit;

  useEffect(() => {
    const checkVisibility = () => {
      if (!alarmRef.current || !bottomArrowRef.current) return;
      const isBottomVisible =
        alarmRef.current.offsetHeight + alarmRef.current.scrollTop < alarmRef.current.scrollHeight;
      bottomArrowRef.current.style.visibility = isBottomVisible ? 'visible' : 'hidden';
    };

    checkVisibility();
  }, [limit, alarmLists]);

  const checkScroll = () => {
    if (!alarmRef.current) {
      return;
    }
    const currentAlarmRef = alarmRef.current;

    const leftSide = Math.round(currentAlarmRef.offsetHeight + currentAlarmRef.scrollTop);
    if (leftSide >= currentAlarmRef.scrollHeight) {
      setLimit(limitRef.current + 18);

      setTimeout(() => {
        if (!bottomArrowRef.current) return;
        const tmpLeftSide = Math.round(currentAlarmRef.offsetHeight + currentAlarmRef.scrollTop);
        if (tmpLeftSide >= currentAlarmRef.scrollHeight) {
          bottomArrowRef.current.style.visibility = 'hidden';
          bottomArrowRef.current.style.opacity = '0';
        } else {
          bottomArrowRef.current.style.visibility = 'visible';
          bottomArrowRef.current.style.opacity = '1';
        }
      }, 1200);
    }

    if (!topArrowRef.current) return;
    topArrowRef.current.style.visibility = 'hidden';
    topArrowRef.current.style.opacity = '0';
    if (currentAlarmRef.scrollTop > 0) {
      topArrowRef.current.style.visibility = 'visible';
      topArrowRef.current.style.opacity = '1';
    }
  };

  const handleScroll = (top: boolean) => {
    const scrollBy = top ? -scrollOffset : scrollOffset;
    alarmRef.current?.scrollBy({ top: scrollBy, left: 0, behavior: 'smooth' });
  };

  return { alarmRef, topArrowRef, bottomArrowRef, checkScroll, handleScroll, limit };
};
