import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import { toRem } from '../../../utils/toRem';

export const SContainer = styled.div<{ $isSent: boolean }>`
  display: flex;
  margin-bottom: 0.5rem;
  margin-left: ${props => !props.$isSent && '2rem'};
  justify-content: ${props => (props.$isSent ? 'flex-end' : 'flex-start')};
`;

export const SContentContainer = styled.div<{ $isSent: boolean; $started: boolean }>`
  padding: 0.75rem;
  position: relative;
  border-left: ${props => !props.$isSent && `2px solid ${palette.gainsBoro4}`};
  border-right: ${props => props.$isSent && `2px solid ${palette.gainsBoro4}`};
  width: 75%;
  background-color: ${props => (props.$isSent ? palette.charcoal2 : palette.cloudBurst)};
  border-bottom-left-radius: ${props => (props.$isSent ? toRem(12) : 0)};
  border-top-left-radius: ${props => (props.$isSent ? toRem(12) : 0)};
  border-bottom-right-radius: ${props => (props.$isSent ? 0 : toRem(12))};
  border-top-right-radius: ${props => (props.$isSent ? 0 : toRem(12))};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const STextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 0.875rem;
  font-weight: 700;
  font-family: 'Roboto-Bold';
  line-height: 1.25rem;
  color: ${palette.whisperGray};
  vertical-align: middle;
`;

export const STitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .started-icon {
    margin-right: 0.5rem;
  }
  margin-bottom: 0.375rem;
`;

export const STitle = styled.p<{ $started: boolean }>`
  font-size: 0.75rem;
  font-weight: 500;
  font-family: 'Roboto-Medium';
  color: ${props => (props.$started ? palette.honeyYellow : palette.silver)};
`;
