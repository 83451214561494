import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SReactionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${toRem(4)};
`;

export const SReactionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${toRem(3)} ${toRem(6)};
  background-color: ${palette.cloudBurst};
  border: 1px solid ${palette.navyBlue};
  border-radius: ${toRem(20)};
  font-family: 'Roboto-Medium';
  font-size: ${toRem(12)};
`;

export const SReactionEmoji = styled.p`
  font-size: ${toRem(10)} !important;
  margin-right: ${toRem(4)};
  font-family: 'AppleColorEmoji', serif;
`;

export const SReactionCount = styled.p`
  fonts-size: ${toRem(11)};
  line-height: ${toRem(14.3)};
  color: ${palette.navy20};
`;
