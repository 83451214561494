import React from 'react';
import Checklist from '../../containers/Checklists/Checklist';
import { Layout } from '../../components/Layout/Layout';
import { useLocation } from 'react-router-dom';
import { NavigatedPageState } from '../chat/ReadReceipts';

function ChecklistPage() {
  const location = useLocation();
  const data: any = location.state;
  const locationState = location.state as NavigatedPageState;

  return (
    <Layout
      isMessageLayout
      message="checkListInfo_title"
      to={locationState?.prevPath || (data?.fromOverview ? '/overview' : '/checklists')}
      showBottomTabs
      showDots
    >
      <Checklist />
    </Layout>
  );
}

export default ChecklistPage;
