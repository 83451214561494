import { useLocation } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import { Login } from '../containers/Login/Login';
import styled from 'styled-components';

const SLayout = styled(Layout)`
  min-height: 100vh;
  height: 100%;
`;

export const LoginPage = () => {
  const location = useLocation();
  return (
    // hidden elements backBtn to={`/intro`}   add it to layout to appear intro page
    <SLayout isAuthLayout={false}>
      <Login mode={location.pathname === '/sso' ? 'sso' : undefined} />
    </SLayout>
  );
};
