import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getGroupMembers } from '../../apis/groupsAPI';
import emptyList from '../../assets/imgs/NotFound/no-result.svg';
import { GroupSelectItem } from '../../components/Chat/GroupSelectItem';
import { HighlightSearchTerm } from '../../components/Checklists/HighlightSearchTerm/HighlightSearchTerm';
import { EmptyListFallback } from '../../components/EmptyListFallback/EmptyListFallback';
import Loader from '../../components/Loader/Loader';
import { Group } from '../../containers/GroupsList/groupsSlice/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { palette } from '../../theme/colors';
import { GroupType } from '../../utils/enums';
import { GroupsToShow } from '../../utils/global';
import { decideIfGroupDisabled } from '../CreateHoldingStatement/helpers';
import { selectGroups, selectGroupsIsLoading } from '../GroupsList/groupsSlice';
import { selectUser } from '../Login/LoginSlice';
import {
  getSelectedGroupType,
  getSelectedGroups,
  getSelectedUsers,
  setSelectedGroupType,
  setSelectedGroups,
  setSelectedUsers,
} from './createMessageSlice';
import {
  addGroupMembersToSelectedAndGetList,
  checkGroupsType,
  getSelectedUsersAfterGroupUnselection,
  isGroupActive,
} from './helpers';

export interface SelectedGroup {
  id: number;
  name: string;
  groupType: GroupType;
  hidden?: boolean;
  isChecked?: boolean;
}

interface SelectGroupsListProps {
  types?: string[];
  searchText?: string;
  onlyNormal?: boolean;
  groupsToHide?: number[] | null;
  isActive?: boolean;
  emptyListTx?: string;
}

export const SList = styled.div`
  height: 100%;
  padding: 0rem 0 5rem 0;
  .item-container + * {
    padding-top: 10px;
    border-top: 1px solid ${palette.prussianBlue4};
  }

  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SelectGroupsList = (props: SelectGroupsListProps) => {
  const {
    types,
    searchText,
    onlyNormal,
    groupsToHide,
    isActive = true,
    emptyListTx = 'all_groups_added_as_recipients',
  } = props;
  const dispatch = useAppDispatch();

  const groups = useAppSelector(selectGroups);
  const selectedGroups = useAppSelector(getSelectedGroups);
  const isLoading = useAppSelector(selectGroupsIsLoading);
  const selectedUsers = useAppSelector(getSelectedUsers);
  const selectedGroupType = useAppSelector(getSelectedGroupType);
  const authUser = useAppSelector(selectUser);

  const [groupList, setGroupList] = useState<Group[]>([]);

  useEffect(() => {
    let searchedGroups = [...groups];

    if (types?.length === 1) {
      const isMemberOfGroupSelected = types[0] === GroupsToShow.MemberOfGroup;
      searchedGroups = searchedGroups.filter(group => group.member === isMemberOfGroupSelected);
    }

    if (onlyNormal) {
      searchedGroups = searchedGroups.filter(grp => grp.groupType === GroupType.Normal);
    }

    if (groupsToHide) {
      searchedGroups = searchedGroups.filter(grp => !groupsToHide.includes(grp.id));
    }
    setGroupList(searchedGroups);
  }, [types, groups]);

  const onGroupClick = async (selectedGroup: SelectedGroup) => {
    const groupId = selectedGroup.id;
    const selectedGroupsList = [...selectedGroups];
    const foundGroupIndex = selectedGroupsList.findIndex(group => group.id === groupId);
    if (foundGroupIndex > -1) {
      const newSelectedUsers = getSelectedUsersAfterGroupUnselection(selectedUsers, groupId);
      dispatch(setSelectedUsers(newSelectedUsers));
      selectedGroupsList.splice(foundGroupIndex, 1);
    } else {
      selectedGroupsList.push(selectedGroup);
      const groupMembers = await getGroupMembers({ id: selectedGroup.id });
      const newSelectedUsers = addGroupMembersToSelectedAndGetList(
        groupMembers.filter(user => user.userID !== authUser?.id),
        selectedUsers
      );
      dispatch(setSelectedUsers(newSelectedUsers));
    }
    dispatch(setSelectedGroupType([selectedGroup.groupType]));
    dispatch(setSelectedGroups(selectedGroupsList));
    if (!selectedGroupsList.length) {
      dispatch(setSelectedGroupType([]));
    }
  };

  const showGroupsList = isActive && !!groupList.length;
  const showEmptyFallback = isActive && !groupList.length;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {showGroupsList && (
        <SList role="selectGroupsList">
          {groupList.map(group => {
            const type = checkGroupsType(group.groupType);

            return (
              <GroupSelectItem
                key={group.id}
                name={HighlightSearchTerm(group.name, searchText)}
                membersCount={group.groupMembersCount}
                photoFileName={group.imageFileName || undefined}
                type={type}
                isSelected={isGroupActive(selectedGroups, group.id)}
                disable={decideIfGroupDisabled({
                  group,
                  selectedGroupType,
                  selectedGroups: selectedGroups.map(g => g.id),
                  checkPreSelectedGroups: undefined,
                })}
                onCardPress={() =>
                  onGroupClick({
                    id: group.id,
                    name: group.name,
                    hidden: group.hidden,
                    groupType: group.groupType,
                  })
                }
              />
            );
          })}
        </SList>
      )}
      {showEmptyFallback && (
        <EmptyListFallback
          src={emptyList}
          listLength={groupList.length ?? 0}
          isLoading={isLoading}
          searchTerm={searchText}
          emptyListTx={emptyListTx}
          noSearchTx={'groups_not_found_by_search'}
        />
      )}
    </>
  );
};
