import { SImage, SImagesRow, SMonthText } from './ChatMedia.styles';
import { dateFormats, getDateFormat } from '../../utils/date';

import { MediaByMonth } from './ChatMedia';
import { useMemo } from 'react';

interface ImagesByMonthListProps {
  mediaByMonth: MediaByMonth;
  onImageClick: (imageBase64: string, sent: string) => void;
}

export const ImagesByMonthList = (props: ImagesByMonthListProps) => {
  const { mediaByMonth, onImageClick } = props;
  const { monthKey, images } = mediaByMonth;
  const monthText = useMemo(() => {
    if (monthKey) {
      return getDateFormat(`${monthKey}-01`, dateFormats.monthNamePlusYear);
    }
    return '';
  }, [monthKey]);
  return (
    <div role={`imagesByMonth-${monthKey}`}>
      <SMonthText role="monthText">{monthText}</SMonthText>
      <SImagesRow role="imagesRow">
        {images.map((img, index) => (
          <SImage
            key={`image-${monthKey}-${index}`}
            role={`image-${monthKey}-${index}`}
            src={img.imageBase64}
            onClick={() => onImageClick(img.imageBase64, img.sent)}
          />
        ))}
      </SImagesRow>
    </div>
  );
};
