import React, { useEffect, useState } from 'react';
import { palette } from '../../theme/colors';
import { translate } from '../../utils/translate';
import { ActionButton } from '../ActionButtons/ActionButtons.style';
import { Backdrop } from '../Backdrop/Backdrop';
import { VerticalNumberCounterWithPrevAndNext } from '../VerticalNumberCounter/VerticalNumberCounterWithPrevAndNext';
import {
  SContainer,
  SBottomSheet,
  SCurtainHandle,
  STitleText,
  STimeRow,
} from './TimeSelectionBottomSheet.styles';

export interface TimeSelectionBottomSheetProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialHours?: number;
  initialMinutes?: number;
  titleTx?: string;
  activeCountdownSetup: (hours: number, minutes: number) => void;
}

export const TimeSelectionBottomSheet = (props: TimeSelectionBottomSheetProps) => {
  const {
    isOpen,
    setIsOpen,
    initialHours = 0,
    initialMinutes = 0,
    titleTx = 'alarm_countdown_setup',
    activeCountdownSetup,
  } = props;
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  useEffect(() => {
    setHours(initialHours);
  }, [initialHours]);

  useEffect(() => {
    setMinutes(initialMinutes);
  }, [initialMinutes]);

  if (!isOpen) return <></>;

  return (
    <>
      <SBottomSheet role="timeSelectionBottomsheet">
        <SCurtainHandle role="header">
          <rect width="400" height="100" fill={palette.silver} />
        </SCurtainHandle>
        <SContainer role="content">
          <STitleText role="title">{translate(titleTx)}</STitleText>
          <STimeRow role="timeRow">
            <VerticalNumberCounterWithPrevAndNext
              titleTx="alarm_hours"
              onNumberChange={newHours => setHours(newHours as number)}
              initialValue={hours}
              isTimeSelection
              maxValue={23}
            />
            <VerticalNumberCounterWithPrevAndNext
              titleTx="alarm_minutes"
              onNumberChange={newMinutes => setMinutes(newMinutes as number)}
              initialValue={minutes}
              isTimeSelection
              maxValue={59}
            />
          </STimeRow>
          <ActionButton
            type="button"
            tx="confirm"
            onClick={() => activeCountdownSetup(hours, minutes)}
            role="confirmButton"
          />
        </SContainer>
      </SBottomSheet>
      <Backdrop setModal={setIsOpen} />
    </>
  );
};
