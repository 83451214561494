/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */

import isElectron from 'is-electron';
import packageJson from '../../../package.json';
import { LoginForm } from '../../containers/Login/Login';
import { ELanguages } from '../../i18n';
import { getDeviceToken } from '../../notifications/device.service';
import { saveItem } from '../../utils/storage';
import { UserById } from '../userAPI/types';
import { ApiCore } from '../utils/core';
import {
  ChangePasswordParams,
  ChangeProfilePhotoParams,
  EditAvailabilityStatus,
  EditSecondaryNumberParams,
  ForgetPasswordParams,
  LightUser,
  ResetPasswordParams,
  SSOParams,
  Support,
  User,
  ValidateResetTokenParams,
  VerifyParams,
} from './types';
const url = 'account';

const userAPI = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: false,
  singleExtra: true,
  url: url,
});

const ApiNotifications = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: true,
  singleExtra: true,
  url: '',
});

const getClientType = () => (isElectron() ? 2 : 1); // 0: Mobile, 1: Web, 2: Desktop

export const getAntiForgeryToken = async () => {
  const csrf = await userAPI.performExtra<any>({
    method: 'GET',
    extraResource: 'AntiForgeryToken',
  });
  saveItem('csrf', csrf.requestVerificationToken);
  return csrf.requestVerificationToken;
};

export const login = async ({ email, password }: LoginForm) => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<User>({
    method: 'POST',
    extraResource: 'login',
    returnErrorResponseData: true,
    model: {
      email,
      password,
      clientType: getClientType(),
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const loginWithCode = async ({ code, email, password }: LoginForm) => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<User>({
    method: 'POST',
    extraResource: 'login',
    model: {
      code,
      email,
      password,
      clientType: getClientType(),
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const ssoLogin = async ({
  email,
  code,
  url,
  provider,
}: {
  email: string | null;
  code: string;
  url: string;
  provider?: string;
}) => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<User>({
    method: 'POST',
    extraResource: 'login',
    model: {
      email,
      oauth_code: code,
      redirect_url: url,
      sso_provider: provider,
      clientType: getClientType(),
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};
export const fetchSsoUrl = async (url: string) => {
  return userAPI.performExtra<SSOParams>({
    method: 'GET',
    extraResource: url,
  });
};

export const forgetPassword = async ({ email }: ForgetPasswordParams) => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: 'Password/Token',
    model: {
      email,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const validateResetToken = async ({ userId, token }: ValidateResetTokenParams) => {
  return userAPI.performExtra<{ ok?: boolean }>({
    method: 'GET',
    extraResource: `password/validatetoken`,
    params: { userId: userId, token: token },
  });
};

export const resetPassword = async (params: ResetPasswordParams) => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: `password/reset`,
    model: params,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const getSupportInfo = async () => {
  return userAPI.performExtra<Support[]>({
    method: 'GET',
    extraResource: `support`,
  });
};

export const changePassword = async (params: ChangePasswordParams) => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<{ ok?: boolean }>({
    method: 'POST',
    extraResource: `ChangePassword`,
    model: params,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const editSecondaryNumber = async (params: EditSecondaryNumberParams) => {
  const csrfToken = await getAntiForgeryToken();

  const { phoneNumbers, userById } = params;
  return userAPI.performExtra<UserById>({
    method: 'POST',
    model: { ...userById, phoneNumber: phoneNumbers },
    extraResource: '',
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const editAvailabilityStatus = async (params: EditAvailabilityStatus) => {
  const csrfToken = await getAntiForgeryToken();

  const { unavailable, unavailableDateTo } = params;
  return userAPI.performExtra<UserById>({
    method: 'POST',
    model: { unavailable, unavailableDateTo },
    extraResource: '',
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const _changeProfileImage = async (params: ChangeProfilePhotoParams) => {
  const { photoUrl, userById } = params;
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<UserById>({
    method: 'POST',
    model: { ...userById, photoFileName: photoUrl },
    extraResource: '',
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

// Verify user password

export const verifyPassword = async (password: VerifyParams) => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<any>({
    method: 'POST',
    extraResource: 'validate-password',
    model: password,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const getEmergencyTypes = async () => {
  return userAPI.performExtra<any>({
    method: 'GET',
    extraResource: 'EmergencyTypes',
  });
};

export const getAccountInfo = async () => {
  return userAPI.performExtra<User>({
    method: 'GET',
    extraResource: 'info/app',
  });
};

export const getLightInfo = async () => {
  return userAPI.performExtra<LightUser>({
    method: 'GET',
    extraResource: 'roles',
  });
};

export const logoutRequest = async () => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<User>({
    method: 'POST',
    extraResource: isElectron() ? `logout?deviceId=${getDeviceToken()}` : 'logout',
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const setUserSettings = async (data: {
  notifyWithEmail: boolean;
  notifyChecklists: boolean;
  notifyDocuments: boolean;
  phoneNumber: string | null | undefined;
  photoFileName: string | undefined;
  displayName: string | undefined;
}) => {
  const csrfToken = await getAntiForgeryToken();
  return userAPI.performExtra<any>({
    method: 'POST',
    extraResource: '',
    model: data,
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const savePreferredLanguage = async (lang: ELanguages) => {
  const csrfToken = await getAntiForgeryToken();

  return userAPI.performExtra<User>({
    method: 'POST',
    extraResource: 'language',
    model: {
      language: lang,
    },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const updateDeviceId = async (token: string | null) => {
  if (!token) return;
  const csrfToken = await getAntiForgeryToken();

  return ApiNotifications.performExtra<any>({
    extraResource: `devices`,
    method: 'post',
    model: { id: token, type: 7, appVersion: packageJson.version },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const deleteDevice = async (token: string | null) => {
  if (!token) return;
  const csrfToken = await getAntiForgeryToken();

  return ApiNotifications.performExtra<any>({
    extraResource: `devices/${token}`,
    method: 'delete',
    model: { id: token, type: 7 },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};
