// [TODO] maybe duplication need to be refactored later now for time sake
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import ChatDetails from '../../containers/ChatDetails/ChatDetails';

function ChatDetailsFromHoldingStatementPage() {
  const { id } = useParams();

  const [isAlarmActive, setIsAlarmActive] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const onClickOptions = () => {
    setOptionsOpen(!optionsOpen);
  };

  return (
    <Layout
      isMessageLayout
      message={'messages_details'}
      to={`/message/${id}/fromHoldingStatement`}
      subMessageText={''}
      showDots
      isAlarmActive={isAlarmActive}
      dotsCallBack={onClickOptions}
      showBottomTabs
    >
      <ChatDetails
        optionsOpen={optionsOpen}
        setOptionsOpen={setOptionsOpen}
        onClickOptions={setOptionsOpen}
        setIsAlarmActive={setIsAlarmActive}
        fromHoldingStatment={true}
      />
    </Layout>
  );
}

export default ChatDetailsFromHoldingStatementPage;
