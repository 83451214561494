import { useRef, useState } from 'react';
import { SReactionContainer } from './MessageOptionsReactionsSelectionRow.styles';
import { palette } from '../../theme/colors';
import { MessageReactionType } from '../../containers/ChatsList/chatListSlice/types';

export interface MessageOptionsReactionsSelectionRowProps {
  reactionType: MessageReactionType;
  isSelected?: boolean;
  onClick: () => void;
  onRelease: () => void;
}

function MessageOptionsReactionsSelectionRowItem(props: MessageOptionsReactionsSelectionRowProps) {
  const { reactionType, isSelected, onClick, onRelease } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const [isHolding, setIsHolding] = useState(false);

  const scaleContainer = (scale = 1) => {
    if (containerRef.current) {
      containerRef.current.style.transform = `scale(${scale})`;
      containerRef.current.style.backgroundColor =
        scale === 1 ? palette.prussianBlue3 : palette.policeBlue;
    }
    if (iconRef.current) {
      iconRef.current.style.transform = `scale(${scale})`;
    }
  };

  const onTouchEnd = () => {
    setIsHolding(false);
    scaleContainer();
    onRelease();
  };

  const onTouchStart = () => {
    setIsHolding(true);
    scaleContainer(0.75);
    onClick();
  };

  const onMouseLeave = () => {
    if (isHolding) {
      onTouchEnd();
    }
  };

  return (
    <SReactionContainer
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onTouchStart}
      onMouseUp={onTouchEnd}
      onMouseLeave={onMouseLeave}
      role="reactionSelectContainer"
      ref={containerRef}
      isSelected={isSelected}
    >
      <p ref={iconRef}>{reactionType.unicode}</p>
    </SReactionContainer>
  );
}

export default MessageOptionsReactionsSelectionRowItem;
