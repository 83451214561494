import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import UserNameImg from '../../assets/imgs/login/login-username.svg';
import twoFAPic from '../../assets/imgs/login/two-factor-authentication.svg';
import useForm from '../../utils/customHooks/useForm';
import { secondsToTimeFormat } from '../../utils/date';
import { selectUser } from '../Login/LoginSlice';
import { login } from '../Login/LoginSlice/actionCreators';
import {
  select2faError,
  selectEmail,
  selectPassword,
  setEmail,
  setIsError,
  setPassword,
} from './Login2faSlice';
import { fetchLogin2FA } from './Login2faSlice/actionCreators';
import {
  SButton,
  SErroText,
  SForm,
  SImg,
  SInput,
  SLInk,
  SLinkText,
  SPage,
  SParagraph,
  SSection,
  SSmall,
  STimer,
} from './styles';

const SEmptyBox = styled.div`
  height: 40px;
`;
export const Login2fa = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { t } = useTranslation();

  const login2faError = useSelector(select2faError);
  const user = useSelector(selectUser);
  const email = useSelector(selectEmail);
  const password = useSelector(selectPassword);
  const [timerLoading, setTimerLoading] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(59);
  const { inputs, handleChange } = useForm({
    initial: {
      code: '',
      email: email,
      password: password,
    },
    validateSchema: {},
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputs.code.length === 0) {
      dispatch(setIsError(true));
    } else {
      dispatch(
        fetchLogin2FA({
          code: inputs.code,
          email: inputs.email,
          password: inputs.password,
        })
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timerLoading) setSeconds((sec: number) => sec - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [timerLoading]);
  const onResend = () => {
    setTimerLoading(true);
    dispatch(login({ email: inputs.email, password: inputs.password }));
    setTimeout(() => {
      setTimerLoading(false);
      setSeconds(60);
    }, 60000);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e as React.ChangeEvent<HTMLInputElement>);

  useEffect(() => {
    if (user?.email === email) {
      dispatch(setIsError(false));
      navigation('/login', { replace: true });
      dispatch(setEmail(''));
      dispatch(setPassword(''));
    }
  }, [user]);

  return (
    <SPage>
      <SImg>
        <img src={twoFAPic} alt="2faPic" />
      </SImg>

      <SSection>
        <SParagraph>{t('two_fa_title')}</SParagraph>
        <SForm onSubmit={handleSubmit}>
          <SInput
            isShadow
            error={login2faError}
            type="text"
            name="code"
            placeHolderTx={t('two_fa_input_placeholder')}
            value={inputs.code}
            onChange={onChange}
            StartAdornment={<img src={UserNameImg} alt="username icon" />}
          />
          <>
            {login2faError ? <SErroText>{t('two_fa_input_error')}</SErroText> : <div />}
            <SEmptyBox />
          </>
          <SSmall>{t(`two_fa_info_text`, { phone: user?.TwoFactorAuthPhone })}</SSmall>
          <SButton text={t('two_fa_submit')} />
        </SForm>
        {timerLoading ? (
          <SLinkText>
            {t('resend_timer_text')}
            <STimer>{secondsToTimeFormat(seconds)}</STimer>
          </SLinkText>
        ) : (
          <SLinkText>
            {t('two_fa_resend_text')}
            <SLInk onClick={onResend}> {t('two_fa_resend_button')}</SLInk>
          </SLinkText>
        )}
      </SSection>
    </SPage>
  );
};
