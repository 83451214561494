import styled, { css } from 'styled-components';
import { palette } from '../../../theme/colors';
import isElectron from 'is-electron';

export const SChatInputContainer = styled.div`
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  z-index: 15;
  width: 26rem;
  background-color: ${palette.prussianBlue2};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.625rem 1.25rem;
`;

export const SContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

export const STextContainer = styled.form<{ $withMargin?: boolean }>`
  ${props =>
    props.$withMargin &&
    css`
      margin-right: 1.25rem;
    `}
  width: 100%;
`;

export const STextInput = styled.textarea`
  resize: none;
  border-radius: 20px;
  border: none;
  line-height: 1rem;
  background-color: ${palette.ebony};
  color: ${palette.white};
  height: 2.5rem;
  width: 100%;
  padding: 0.7rem 3.9rem 0.7rem 0.75rem;

  font-family: 'Roboto-Regular';
  ::-webkit-scrollbar {
    display: none;
  }
  :focus-visible {
    outline: none;
  }

  &::placeholder {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    font-size: 0.875rem;
    color: ${palette.ashGray};
  }
`;

export const STextInputWrapper = styled.div`
  position: relative;
`;

export const STextCounter = styled.p`
  position: absolute;
  font-family: 'Roboto-Regular';
  right: 0.6875rem;
  top: 50%;
  font-size: 0.5625rem;
  transform: translateY(-50%);
  color: ${palette.gainsBoro3};
`;

export const SIcon = styled.button<{ $notValid?: boolean }>`
  border: 0;
  background-color: transparent;
  outline: 0;
  min-width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  ${props =>
    props.$notValid &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}

  align-self: flex-end;
  margin-bottom: 0.55rem;
`;

export const SFakeFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: fit-content;
  width: 100%;
  background-color: ${isElectron() ? palette.prussianBlue2 : palette.transparent};
`;
