/* eslint-disable @typescript-eslint/no-shadow */

import { useState } from 'react';
import MapModal from '../../components/MapModal/MapModal';
import { Modal } from '../../components/Modal/Modal';
import { MapStateModel } from '../../containers/Chat/models';
import { Chat } from '../../containers/ChatsList/chatListSlice/types';
import { dateFormats, getDateFormatCustom } from '../date';

export const useMapModal = (message?: Chat) => {
  const [mapState, setMapState] = useState<MapStateModel>({
    mapModalOpen: { open: false, isAlarm: false },
    longitude: 0,
    latitude: 0,
    address: '',
    sent: undefined,
  });
  const setMapModalOpen = (props: MapStateModel['mapModalOpen']) =>
    setMapState(prev => ({
      ...prev,
      mapModalOpen: props,
    }));

  const renderMapModal =
    (
      mapState: MapStateModel,
      setMapModalOpen: (props: MapStateModel['mapModalOpen']) => void,
      message?: Chat
    ) =>
    () => {
      if (!mapState.mapModalOpen || !message) return <></>;

      return (
        <Modal
          isOpen={mapState.mapModalOpen.open}
          setIsOpen={() => setMapModalOpen({ open: false, isAlarm: false })}
        >
          <MapModal
            isAlarm={mapState.mapModalOpen.isAlarm}
            longitude={mapState.longitude}
            latitude={mapState.latitude}
            address={mapState.address}
            type={message.emergencyTypeName!}
            recalled={message.recalled}
            sent={
              mapState.sent
                ? mapState.sent
                : getDateFormatCustom(message.sent, dateFormats.monthNameShortDateTimeNoComma24)
            }
          />
        </Modal>
      );
    };

  const toggleMapModal = (
    lng: number,
    lat: number,
    address: string,
    sent: string,
    isAlarm: boolean
  ) => {
    setMapState({
      mapModalOpen: { open: true, isAlarm },
      latitude: lat,
      longitude: lng,
      address: address,
      sent: sent,
    });
  };

  return {
    renderMapModal: renderMapModal(mapState, setMapModalOpen, message),
    toggleMapModal,
  };
};
