import * as React from 'react';
import { OfflineApp } from '../../components/OfflineApp/OfflineApp';
import { useAppSelector } from '../../hooks';
import { selectIsOnLine } from './checkAppAvailableSlice';
import { checkNetworkAvailability } from './helpers';

const RECHECK_STATUS_TIME = 7500;

export const CheckAppAvailable = ({ children }: { children: JSX.Element }) => {
  const isOnline = useAppSelector(selectIsOnLine);

  React.useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;

    if (!isOnline) {
      interval = setInterval(
        () => checkNetworkAvailability({ checkIfNetworkBack: true }),
        RECHECK_STATUS_TIME
      );
    }
    if (isOnline) {
      if (interval !== undefined) {
        clearInterval(interval);
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isOnline]);

  return <>{isOnline ? children : <OfflineApp />}</>;
};
