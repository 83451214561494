import styled from 'styled-components';

export const SelectItemContainer = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const MessagesTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .availabilityChip {
    padding-left: 0.3rem;
  }
`;
