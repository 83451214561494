import React, { useEffect, useState } from 'react';
import { getImageWithSize } from '../../apis/mediaAPI';
import { Comment } from '../../containers/Checklists/checklistsSlice/types';
import { SComment, SAttachementContainer, SImageContainer } from './ChecklistComment.styles';
import { getNumbersBasedDateTextByLanguage } from '../../utils/date';
import { trunctateText } from '../../utils/truncate';
import ImagesModal from '../MessageItem/ImageMessage/ImagesModal';
import { translate } from '../../utils/translate';
import HyperLinkText from '../HyperlinkText/HyperLinkText';

interface CommentProps {
  comment: Comment;
}

function ChecklistComment(props: CommentProps) {
  const { comment } = props;
  const { id, author, text, imageFileName, sent, type } = comment;

  const types = {
    Checked: translate('checklist_marked_as_done'),
    Unchecked: translate('checklist_marked_as_undone'),
    Regular: translate('checklist_regular'),
  };

  const [image, setImage] = useState<string | null>(null);
  //const [size, setSize] = useState<string>('');
  const [openImage, setOpenImage] = useState(false);

  useEffect(() => {
    let mounted = true;
    const getSourceImage = async () => {
      const res = await getImageWithSize({
        imageName: imageFileName,
        size: 'medium',
        svg: false,
      });
      if (mounted) {
        setImage(res.image);
      }
    };

    if (imageFileName) {
      getSourceImage();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <SComment key={id} className="comment" id={`checklistComment-${id}`}>
      <div className="wrapper" role="commentContainer">
        <p className="author" role="author">
          {author}
        </p>

        {(type !== 'Regular' || text) && (
          <p className="text" role="content">
            <HyperLinkText text={text ? trunctateText(text, 360) : types[type]} />
          </p>
        )}
        {image && (
          <SAttachementContainer role="attachmentsContainer">
            <SImageContainer role="imageContainer">
              <img src={image} alt="" onClick={() => setOpenImage(true)} role="image" />
              <ImagesModal
                imgKey={0}
                img={image}
                isOpen={openImage}
                closeModal={() => setOpenImage(false)}
              />
            </SImageContainer>
          </SAttachementContainer>
        )}
      </div>
      <div>
        <p className="time">
          {getNumbersBasedDateTextByLanguage({ dateText: sent, showOnlyTimeIfToday: true })}
        </p>
      </div>
    </SComment>
  );
}

export default ChecklistComment;
