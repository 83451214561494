import styled from 'styled-components';

const SNext = styled.div`
  position: absolute;
  height: 100%;
  width: 3rem;
  top: 0;
  cursor: pointer;
  z-index: 3;
  align-items: center;
  .prevArrow,
  .nextArrow {
    position: absolute;
    top: calc(50% - 20px);
  }
  .prevArrow {
    transform: rotate(-90deg);
  }
  .nextArrow {
    transform: rotate(90deg);
  }
`;

export const SImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const SRight = styled(SNext)`
  right: 0;
`;

export const SLeft = styled(SNext)`
  left: 0;
`;
