/* eslint-disable-next-line import/named */
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../utils/translate';

export interface IErrorHandlerProps {
  children: React.ReactNode;
}

export const ErrorHandler = (props: IErrorHandlerProps) => {
  const { children } = props;
  const navigate = useNavigate();

  const fallbackRender = ({ error, resetErrorBoundary }: FallbackProps) => {
    alert(`${translate('error')} ${error}`);
    resetErrorBoundary();

    return <></>;
  };

  const resetErrorBoundaryError = (
    details:
      | {
          reason: 'imperative-api';
          args: any[];
        }
      | {
          reason: 'keys';
          prev: any[] | undefined;
          next: any[] | undefined;
        }
  ) => {
    navigate('/dashboard');
    navigator.clipboard.writeText(`Error: ${details} , Reason: ${details.reason}`);
  };

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onReset={resetErrorBoundaryError}>
      {children}
    </ErrorBoundary>
  );
};
