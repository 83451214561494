import { useState } from 'react';

const useLongClickEvent = () => {
  const [isLongClick, setIsLongClick] = useState(false);
  const maxHoldSecs = 1;
  let time = 0;
  let timer: any;

  const getTimer = () => {
    return setInterval(() => {
      time++;
      if (time > maxHoldSecs) {
        clearInterval(timer);
        setIsLongClick(true);
      }
    }, 1000);
  };

  const resetTimer = () => {
    time = 0;
    timer = null;
  };

  const handleClickDown = (): void => {
    setIsLongClick(false);
    resetTimer();
    timer = getTimer();
  };

  const handleClickUp = (): void => {
    setIsLongClick(false);
    clearInterval(timer);
    resetTimer();
  };

  return {
    isLongClick,
    handleClickDown,
    handleClickUp,
  };
};

export default useLongClickEvent;
