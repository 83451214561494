import styled from 'styled-components';
import { palette } from '../../../theme/colors';
import { Button } from '../../Button/Button';

export const SDrawerWrapper = styled.div`
  .profileDrawer {
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1001;
    @media (min-width: 450px) {
      max-width: 26rem;
      margin: auto;
    }
    background-color: ${palette.prussianBlue2};
    max-height: 65vh;
    @media (max-height: 787px) {
      max-height: 90vh;
    }
  }
  .profileDrawer__backdrop {
    z-index: 1000;
  }
  .profileDrawer__handle-wrapper {
    padding: 0;
  }
  .profileDrawer__handle {
    width: 36%;
    margin-top: 1.3125rem;
  }
  .profileDrawer__content {
    padding: 0 1rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .STitle,
  .SSubtitle {
    font-weight: 500;
    font-size: 1.125rem;
    font-family: 'Roboto-Medium';
    color: ${palette.white};
    line-height: 1.5rem;
    text-align: center;

    margin-bottom: 1rem;
  }

  li {
    list-style: decimal;
    list-style-position: inside;
    text-indent: -1rem;
    padding-left: 1rem;
  }
  .SDescription,
  .SSteps {
    font-weight: 400;
    font-size: 0.875rem;
    font-family: 'Roboto-Regular';
    color: ${palette.Gray88};
    line-height: 1rem;

    margin-bottom: 1rem;
  }
  .SSteps {
    margin: 0;
  }
  .SSubtitle {
    font-size: 0.875rem;
    font-family: 'Roboto-Bold';
    text-align: left;
  }

  .alarmIcon {
    height: 3.25rem;
    width: 3.625rem;
    margin: 1.625rem auto;
  }
`;

export const SLine = styled.hr`
  width: 100%;
  border: 1px solid ${palette.prussianBlue5};
  margin: 1rem 0 2rem 0;
`;

export const SButton = styled(Button)`
  button {
    font-size: 1rem;
    line-height: 1.18rem;
    max-width: 400rem;
    font-family: 'Roboto-Medium';
    color: ${palette.raisinBlack3};
  }
`;
