import styled from 'styled-components';
import { palette } from '../../theme/colors';
import { toRem } from '../../utils/toRem';

export const SImage = styled.img`
  cursor: pointer;
`;

export const GreyOutlinedBtn = styled.span`
  font-family: 'Roboto-Regular';
  font-size: 10px;
  font-weight: 400;
  color: ${palette.white};
`;

export const RecipientButtonWrapper = styled.button`
  background-color: ${palette.fadedDarkBlue};
  outline: 0;
  border: 0;
  border: 1px solid ${palette.queenBlue};
  min-height: ${toRem(32)};
  border-radius: 8px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
`;
