import { getAntiForgeryToken } from '../authApi/authAPI';
import { ApiCore } from '../utils/core';

const ApiTestAlarm = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  remove: true,
  singleExtra: true,
  url: 'Account/AlarmTest',
});

export const createTestAlarm = async (deviceToken: string | null) => {
  if (!deviceToken) return;
  const csrfToken = await getAntiForgeryToken();

  return ApiTestAlarm.performExtra<{ testId: number }>({
    extraResource: `Initiate`,
    method: 'post',
    model: { token: deviceToken, deviceType: 7 },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};

export const sendTestAlarmResult = async (success: boolean, testId: number) => {
  const csrfToken = await getAntiForgeryToken();

  return ApiTestAlarm.performExtra<any>({
    extraResource: `ReportStatus`,
    method: 'post',
    model: { testId: testId, success: success },
    headers: { 'X-XSRF-Token': csrfToken },
  });
};
