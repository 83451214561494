import styled from 'styled-components';
import { Page } from '../../components/Page/Page';
import { palette } from '../../theme/colors';

export const SPage = styled(Page)`
  padding: 1.25rem 0 0;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const BroadcastView = styled.div`
  padding: 0 1.25rem;
  min-height: 0;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const AddGroupButton = styled.div`
  height: 36px;
  width: 36px;
  padding: 11px 11px;
  border-radius: 50px;
  background-color: ${palette.honeyYellow};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const SubjectContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: ${palette.queenBlue};
`;

export const SubjectLabel = styled.div`
  font-size: 14px;
`;

export const SubjectInput = styled.input`
  flex: 1;
  font-size: 14px;
  height: 45px;
  border: none;
  padding: 25px 30px;
  color: ${palette.white};
  background-color: transparent;
  :focus {
    outline: none;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${palette.raisinBlack} inset;
    -webkit-text-fill-color: ${palette.white};
  }
`;

export const SubjectIcon = styled.span`
  cursor: text;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
`;
