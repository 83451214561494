import styled from 'styled-components';

export const SSpace = styled.div`
  height: 1rem;
`;

export const SName = styled.p`
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  min-width: 250px;
  padding-top: 12px;
  padding-bottom: 12px;
}
`;
