import GroupsIcon from '../../assets/imgs/general/groups-place-holder-with-background.svg';
import Folder from '../../assets/imgs/documents/document-folder.svg';
import OfflineFolder from '../../assets/imgs/documents/document-offline-folder.svg';
import File from '../../assets/imgs/documents/document-file.svg';
import OfflineFile from '../../assets/imgs/documents/fileCloudYellow.svg';

export type DocumentIconType =
  | 'GROUP_FOLDER'
  | 'OFFLINE_GROUP_FOLDER'
  | 'FOLDER'
  | 'OFFLINE_FOLDER'
  | 'FILE'
  | 'OFFLINE_FILE';

export const documentIcons: Record<DocumentIconType, string> = {
  GROUP_FOLDER: GroupsIcon,
  OFFLINE_GROUP_FOLDER: GroupsIcon,
  FOLDER: Folder,
  OFFLINE_FOLDER: OfflineFolder,
  FILE: File,
  OFFLINE_FILE: OfflineFile,
};
