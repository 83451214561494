import { useAppSelector } from '../../hooks';
import { useDispatch } from 'react-redux';
import { selectUser } from '../../containers/Login/LoginSlice';
import { SUserContainer, SUserInfoContainer } from './MessageReactionsBottomSheet.styles';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { t } from 'i18next';
import { MessageReactionsItem } from '../../containers/ChatsList/chatListSlice/types';
import {
  closeMessageReactionsBottomSheet,
  toggleMessageReaction,
} from '../../containers/ChatsList/chatListSlice/actionCreators';

interface MessageReactionsBottomSheetItemProps {
  reaction: MessageReactionsItem;
  isLastItem?: boolean;
  id: string;
}

export const MessageReactionsBottomSheetItem = (props: MessageReactionsBottomSheetItemProps) => {
  const dispatch = useDispatch();
  const { reaction, isLastItem = false, id } = props;
  const authUser = useAppSelector(selectUser);
  const isAuthUser = reaction.userId === authUser?.id;

  const onClick = () => {
    if (isAuthUser) {
      const messageOrReplyId = reaction.replyId ?? reaction.messageId;
      dispatch(toggleMessageReaction(messageOrReplyId, reaction.reactionTypeId));
      dispatch(closeMessageReactionsBottomSheet());
    }
  };
  return (
    <>
      <SUserContainer isAuthUser={isAuthUser} isLastItem={isLastItem} onClick={onClick} id={id}>
        <ProfilePicture profilePictureFileName={reaction.photoFileName} diameter={32} />
        <SUserInfoContainer>
          <p className="name">{isAuthUser ? t('messages_you') : reaction.userName}</p>
          {isAuthUser && <p className="removeReaction">{t('messages_tap_to_remove_reaction')}</p>}
        </SUserInfoContainer>
      </SUserContainer>
    </>
  );
};
