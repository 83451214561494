import styled, { css } from 'styled-components';
import { palette } from '../../theme/colors';

interface SButtonProps {
  center?: boolean;
  $disabled?: boolean;
}

const SButton = styled.button`
  display: flex;
  place-content: space-between;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: ${palette.prussianBlue2};
  border-radius: 14px;

  label {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  p {
    font-size: 16px;
  }

  :focus-visible {
    outline: none;
  }
`;

export const SOptions = styled.div<{ haveMaxHeight?: boolean }>`
  padding: 0 1rem;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  max-height: ${props => (props.haveMaxHeight ? '90vh' : 'none')};
  max-width: 26rem;
  width: 100%;
  z-index: 999;
  cursor: default;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SOptionsList = styled.div`
  background-color: ${palette.prussianBlue2};
  border-radius: 14px;
  margin-bottom: 0.5rem;
`;

export const SOption = styled(SButton)<SButtonProps>`
  padding: 1rem 1.5rem 1rem 2.2rem;
  margin: 0.25rem 0;
  display: flex;
  width: 100%;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};

  p {
    font-family: 'Roboto-Regular';
    font-weight: 400;
    line-height: 24px;
    color: ${palette.white};
  }
  ${props =>
    props.$disabled &&
    css`
      p {
        color: ${palette.spanishGray};
      }
      img {
        opacity: 0.5;
      }
    `}

  .disabledDescription {
    margin-top: 0.25rem;
    font-family: 'Roboto-Regular';
    color: ${palette.ashesGray};
    line-height: 18px;
    font-size: 0.8125rem;
  }

  img {
    width: 20px;
  }

  .optionTitleContainer {
    text-align: left;
  }
`;

export const SCancel = styled(SButton)`
  margin-bottom: 2rem;

  min-height: 57px;

  p {
    font-weight: 600;
    color: ${palette.honeyYellow};
  }
`;
