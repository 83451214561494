import { Support } from '../../apis/authApi/types';

export interface SupportsGroupedItem {
  id: number;
  name: string;
  items: Support[];
}

export const getSupportsByAccountGrouped = (supports: Support[]) => {
  const supportsByAccount = {} as { [key: string]: SupportsGroupedItem };
  supports?.forEach(support => {
    if (!supportsByAccount[support.id]) {
      supportsByAccount[support.id] = {
        id: support.id,
        name: support.name,
        items: [],
      };
    }
    supportsByAccount[support.id].items.push(support);
  });
  return Object.values(supportsByAccount);
};
