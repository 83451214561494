import styled from 'styled-components';
import { palette } from '../../../theme/colors';

export const STitle = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 700;
  font-size: 18px;
  margin-top: 10px;
`;

export const SText = styled.p`
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
`;

export const SButtonContainer = styled.div`
  .test-alarm-btn {
    color: ${palette.white};
    background-color: ${palette.appleGreeen};
    margin-bottom: 30px;
  }
`;
