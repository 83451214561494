import styled, { css } from 'styled-components';
import { palette } from '../../../theme/colors';
import { SClampLines } from '../../Chat/ChatBox.styles';
import { toRem } from '../../../utils/toRem';

interface SReplyContainerProps {
  $isSent: boolean;
  $isDeleted: boolean;
}

export const SReplyContainer = styled.div<SReplyContainerProps>`
  display: flex;
  flex-direction: row;

  width: 100%;
  min-height: 2rem;
  cursor: pointer;

  padding-right: 0.75rem;
  margin-bottom: ${toRem(6)};

  ${props =>
    !props.$isDeleted &&
    css`
      &:hover {
        opacity: 0.7;
      }
    `}
  ${props =>
    props.$isDeleted &&
    css`
      cursor: not-allowed;
    `}

  .alarmMessageIcon {
    margin-right: 0.75rem;
    height: 1.25rem;
    align-self: center;
  }
  .photoMessageIcon {
    margin-right: 0.5rem;
    height: 2rem;
    align-self: center;
    width: 2rem;
    border-radius: 4px;
  }
`;

export const ReplyTextContainer = styled.div`
  flex-grow: 1;
  font-size: 0.75rem;
  align-self: center;
  .senderTitle {
    font-family: 'Roboto-Medium';
    color: ${palette.whisperGray};
    font-size: ${toRem(14)};
    font-weight: 600;
    margin-bottom: ${toRem(2)};
    ${SClampLines}
  }

  .messageBody {
    font-family: 'Roboto-Regular';
    color: ${palette.silver};
    font-weight: 400;
    ${SClampLines}
  }
`;

export const SLine = styled.hr`
  margin: 0;
  border-radius: 5px;
  border: 0;

  border-left: 2px solid ${palette.honeyYellow};
  margin-right: 0.75rem;
`;
