import React from 'react';
import { Reply } from '../../../containers/ChatsList/chatListSlice/types';
import { dateFormats, getDateFormatCustom } from '../../../utils/date';
import { SMessageDateSeparator } from '../MessageItem';
import TextMessage from '../TextMessage/TextMessage';

import AudioMessage from '../AudioMessage/AudioMessage';
import _ from 'lodash';
import FileMessage from '../FileMessage/FileMessage';
import ImageMessage from '../ImageMessage/ImageMessage';
import LocationMessage from '../LocationMessage.tsx/LocationMessage';
import { ReplyToMessage as ReplyToMessageComponent } from '../ReplyToMessage/ReplyToMessage';
import { ReplyToMessageShorted } from '../../../containers/Chat/Chat';
import { useMessageRefsContext } from '../../../utils/customHooks/MessageRefsContext';
import { ChecklistMessage } from '../ChecklistMessage/ChecklistMessage';
import { ReplyPurpose } from '../../../utils/enums';
import { fromPurposeChecklistMessageTitleTX } from '../../../containers/Chat/helpers';
import { useAppDispatch } from '../../../hooks';
import { onReplyToMessageClick } from '../../../containers/ChatsList/chatListSlice/actionCreators';
import DeletedMessage from '../DeletedMessage/DeletedMessage';

interface ChatReplyProps {
  reply: Reply;
  userId: number;
  isSameDay: boolean;
  isSameSender: boolean;
  emergencyTypeName: string | null;
  setShowBottomTabs?: React.Dispatch<React.SetStateAction<boolean>>;
  onPlayClick: (id: number | null) => void;
  isAudioActive: boolean;
  toggleOptions?: () => void | undefined;
  onMapClick: (
    longitude: number,
    latitude: number,
    address: string,
    date: string,
    isAlarm: boolean
  ) => void;
  showOnlyAttachment?: boolean;

  ReplyToMessage?: ReplyToMessageShorted;
  hasReply?: boolean;
  didFetchPhoto?: () => void;
}

function ChatReply(props: ChatReplyProps) {
  const {
    reply,
    userId,
    isSameDay,
    isSameSender,
    emergencyTypeName,
    onPlayClick,
    isAudioActive,
    toggleOptions,
    onMapClick,
    showOnlyAttachment = false,

    hasReply,
    ReplyToMessage,
    didFetchPhoto,
  } = props;
  const { scrollToRef } = useMessageRefsContext();
  const dispatch = useAppDispatch();

  const isCheckListReply = !!reply.checklistID;

  const onReplyClick = () => {
    if (ReplyToMessage && !ReplyToMessage.isDeleted) {
      dispatch(onReplyToMessageClick(ReplyToMessage, scrollToRef));
    }
  };

  const isTextReply =
    reply.photoFileNames?.length === 0 &&
    reply.documentFileNames?.length === 0 &&
    !reply.locationID &&
    !showOnlyAttachment &&
    reply.text &&
    !isCheckListReply;

  const isPhotosMessage = !_.isEmpty(reply.photoFileNames);
  const isDocumentsMessage = !_.isEmpty(reply.documentFileNames);

  const renderReplyToMessage = () => (
    <ReplyToMessageComponent
      isSent={reply.senderID === userId}
      messageBody={ReplyToMessage?.messageText || ''}
      messageIcon={ReplyToMessage?.messageIcon}
      senderTitle={ReplyToMessage?.senderName || ''}
      onClick={() => onReplyClick()}
      isDeleted={!!ReplyToMessage?.isDeleted}
    />
  );

  const renderMessage = () => (
    <>
      {isCheckListReply && (
        <ChecklistMessage
          checklistLink={`/checklist/${reply?.checklistID}`}
          LinkState={{ prevPath: location.pathname }}
          started={reply.purpose === ReplyPurpose.ChecklistReactivated}
          startedTx={reply.purpose ? fromPurposeChecklistMessageTitleTX[reply.purpose] : ''}
          isSent={reply.senderID === userId}
          checklistName={reply?.checklistName || ''}
          key={`checklists-${reply?.checklistID}`}
        />
      )}
      {reply.locationID && (
        <LocationMessage
          text={!showOnlyAttachment ? reply.text : ''}
          isSent={reply.senderID === userId}
          sentDateTime={getDateFormatCustom(reply.sent, dateFormats.simpleTime)}
          isSameSender={isSameSender}
          senderName={reply.senderName}
          locationId={reply.locationID}
          senderImage={reply.photoFileName!}
          toggleModal={toggleOptions}
          onMapClick={(lat, lng, address) => {
            onMapClick(
              lat,
              lng,
              address,
              getDateFormatCustom(reply.sent, dateFormats.yearMonthDayTimeNoComma24),
              false
            );
          }}
          showOnlyAttachment={showOnlyAttachment}
          messageTo={null}
          edited={reply.edited}
          renderReplyToMessage={hasReply ? renderReplyToMessage : undefined}
          id={reply.id}
        />
      )}
      {isTextReply && (
        <TextMessage
          id={reply.id}
          isSent={reply.senderID === userId}
          key={`text${reply.id}`}
          sentDateTime={getDateFormatCustom(reply.sent, dateFormats.simpleTime)}
          messageText={reply.text}
          isSameSender={isSameSender}
          senderName={reply.senderName}
          emergencyRecall={reply.emergencyRecall}
          emergencyTypeName={emergencyTypeName!}
          senderImage={reply.photoFileName!}
          toggleModal={toggleOptions}
          hideMenu={false}
          showOnlyAttachment={showOnlyAttachment}
          messageTo={null}
          edited={reply.edited}
          renderReplyToMessage={hasReply ? renderReplyToMessage : undefined}
        />
      )}
      {isPhotosMessage && (
        <ImageMessage
          id={reply.id}
          photoFileNames={reply.photoFileNames!}
          senderName={reply.senderName}
          isSameSender={isSameSender}
          senderImage={reply.photoFileName!}
          isSent={reply.senderID === userId}
          sentDateTime={getDateFormatCustom(reply.sent, dateFormats.simpleTime)}
          messageText={!showOnlyAttachment ? reply.text : ''}
          toggleModal={toggleOptions}
          showOnlyAttachment={showOnlyAttachment}
          messageTo={null}
          edited={reply.edited}
          renderReplyToMessage={hasReply ? renderReplyToMessage : undefined}
          didFetchPhoto={didFetchPhoto}
        />
      )}
      {isDocumentsMessage && (
        <FileMessage
          id={reply.id}
          key={`file${reply.id}`}
          text={!showOnlyAttachment ? reply.text : ''}
          documents={reply.attachments}
          senderName={reply.senderName}
          senderImage={reply.photoFileName!}
          isSameSender={isSameSender}
          isSent={reply.senderID === userId}
          sentDateTime={getDateFormatCustom(reply.sent, dateFormats.simpleTime)}
          toggleModal={toggleOptions}
          showOnlyAttachment={showOnlyAttachment}
          messageTo={null}
          edited={reply.edited}
          renderReplyToMessage={hasReply ? renderReplyToMessage : undefined}
        />
      )}
      {_.map(reply.audioFileNames, (document, index) => (
        <AudioMessage
          id={reply.id}
          messageId={reply.id}
          key={`audio${reply.id}-${index}`}
          isSent={reply.senderID === userId}
          sentDateTime={getDateFormatCustom(reply.sent, dateFormats.simpleTime)}
          senderImage={reply.photoFileName!}
          isSameSender={isSameSender}
          audioFile={document}
          isAudioActive={isAudioActive}
          senderName={reply.senderName}
          onPlayClick={onPlayClick}
          toggleModal={toggleOptions}
          showOnlyAttachment={showOnlyAttachment}
          messageTo={null}
          edited={reply.edited}
          renderReplyToMessage={hasReply ? renderReplyToMessage : undefined}
        />
      ))}
    </>
  );

  const renderDeletedMessage = () => (
    <DeletedMessage sentDateTime={getDateFormatCustom(reply.sent, dateFormats.simpleTime)} />
  );
  return (
    <>
      {!isSameDay && !showOnlyAttachment && (
        <SMessageDateSeparator className="reply-SMessageDateSeparator">
          {getDateFormatCustom(reply.sent, dateFormats.monthNamePlusDate)}
        </SMessageDateSeparator>
      )}
      {reply.deleted ? renderDeletedMessage() : renderMessage()}
    </>
  );
}

export default ChatReply;
