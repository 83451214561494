import React from 'react';
import ReactDOM from 'react-dom';
import {
  SCancelButton,
  SDialog,
  SModalWrapper,
  SMoreAlarmsContainer,
  SOverLay,
  SSendAlarmButton,
  SText,
  SWarningIconContainer,
} from './ActiveAlarmsModal.style';
import { ReactComponent as WarningIcon } from '../../assets/imgs/alarms/warning-red.svg';
import { ReactComponent as FireIcon } from '../../assets/imgs/alarms/fire-white.svg';
import ActiveAlarmsModalItem from './ActiveAlarmsModalItem';
import { useNavigate } from 'react-router-dom';
import { useActiveAlarms } from '../../utils/customHooks/useActiveAlarms';
import { sendAlarm } from '../../containers/Alarm/AlarmSlice/actionCreators';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import Loader from '../Loader/Loader';

function ActiveAlarmsModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, isLoading, sendAlarmProps, displayedAlarms, moreText, changeIsOpen } =
    useActiveAlarms();

  const handleClose = () => {
    changeIsOpen(false);
    navigate('/');
  };
  const handleSend = () => {
    if (!sendAlarmProps) {
      changeIsOpen(false);
      navigate('/');
      return;
    } else {
      const { messageModel, location, customText } = sendAlarmProps;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(sendAlarm(messageModel, location, navigate, customText || ''));
      changeIsOpen(false);
    }
  };

  return isOpen ? (
    ReactDOM.createPortal(
      <React.Fragment>
        <SOverLay />
        <SModalWrapper role="confirmationDialog">
          <SDialog role="dialogContent">
            {isLoading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <SWarningIconContainer>
                  <WarningIcon />
                </SWarningIconContainer>
                <SText className="title">{t('alarm_active_alarms_title')}</SText>
                {displayedAlarms.map((alarm, index) => (
                  <ActiveAlarmsModalItem alarm={alarm} key={`alarm-${index}`} />
                ))}
                {moreText && (
                  <SMoreAlarmsContainer>
                    <FireIcon />
                    <SText className="moreAlarms">{moreText}</SText>
                  </SMoreAlarmsContainer>
                )}
                <SText className="title continueQuestion">
                  {t('alarm_active_alarms_send_confirmation')}
                </SText>
                <SSendAlarmButton tx="alarm_send_alarm" onClick={handleSend} />
                <SCancelButton tx="cancel" onClick={handleClose} />
              </React.Fragment>
            )}
          </SDialog>
        </SModalWrapper>
      </React.Fragment>,
      document.body
    )
  ) : (
    <></>
  );
}

export default ActiveAlarmsModal;
