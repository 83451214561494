import styled from 'styled-components';
import { palette } from '../../theme/colors';

export const GroupItemContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  &.alarm-group + * {
    border-top: 1px solid ${palette.prussianBlue4};
  }
`;
